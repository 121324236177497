import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setUser } from "../Signin/Signin.reducer";
import {
    addToGreenhouseAgency,
    addToJobvite,
    addToLever,
    addToLoxoAgency,
    addToZohoRecruit,
    fetchLoxoCredentials,
    getGreenhouseJobs,
    getJobviteJobs,
    getLeverJobs,
    getLeverStages,
    postGreenhouseCredentials,
    postJobviteCredentials,
    postLoxoCredentials,
    resetIntegration,
    revokeSlackInvitation,
    revokeZohoRecruit,
    sendSlackInvitation,
    setGreenhouseJobs,
    setJobviteJobs,
    setLeverJobs,
    setLeverStages,
    setLoxoCredentials,
} from "./integrations.reducer";
import {
    IAddToATSPayload,
    IAddToGreenhousePayload,
    IAddToLeverPayload,
    IAddToLeverResponse,
    ILeverPostCandidatePayload,
    ILoxoCredentials,
    ILoxoCredentialsResponse,
    IPostGreenhouseCredentialsPayload,
    IPostJobviteCredentialsPayload,
    ISendSlackInvitationPayload,
    ISlackAuthResponse,
} from "./integrations.types";

function* fetchLoxoCredentialsSaga({
    payload,
}: {
    payload: ILoxoCredentials & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse = yield call(
            new API().get,
            "/loxo/get-creds"
        );

        const loxoCreds = {
            agencySlug: response?.data.agencySlug || "",
            apiKey: response?.data.apiKey || "",
            apiPassword: response?.data.apiPassword || "",
        };
        yield put(setLoxoCredentials(loxoCreds));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postLoxoCredentialsSaga({
    payload,
}: {
    payload: ILoxoCredentials & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ILoxoCredentialsResponse & ILoxoCredentials =
            yield call(new API().post, "/loxo/on-board", {
                agencySlug: payload.agencySlug,
                loxoAPIKey: payload.apiKey,
                loxoAPISecret: payload.apiPassword,
            });

        const loxoCreds = {
            agencySlug: payload.agencySlug,
            apiKey: payload.apiKey,
            apiPassword: payload.apiPassword,
        };
        yield put(setLoxoCredentials(loxoCreds));
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLoxoAgencySaga({
    payload,
}: {
    payload: IAddToATSPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload: IAddToATSPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response: { message: string } = yield call(
            new API().post,
            "/loxo/add-to-agency",
            triggerPayload
        );

        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToGreenhouseAgencySaga({
    payload,
}: {
    payload: IAddToGreenhousePayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (payload.jobId) triggerPayload.jobId = payload.jobId;
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(
            new API().post,
            "/greenhouse/post-candidate",
            triggerPayload
        );
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postGreenhouseCredentialsSaga({
    payload,
}: {
    payload: IPostGreenhouseCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/greenhouse/post-creds", {
            apiKey: payload.apiKey,
            serviceUser: payload.serviceUser,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const { apiKey, serviceUser } = response.data;
        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                greenHouse: {
                    apiKey,
                    serviceUser,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getGreenhouseJobsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/greenhouse/fetch-jobs");
        if (!response) return;

        yield put(setGreenhouseJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToZohoRecruitSaga({
    payload,
}: {
    payload: IAddToATSPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload: IAddToATSPayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(
            new API().post,
            "/zoho/post-candidate",
            triggerPayload
        );
        if (isEmpty(response.message)) return;

        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* revokeZohoRecruitSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: { message: string } = yield call(
            new API().get,
            "/zoho/revoke"
        );
        if (!response) return;

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(setSuccessNotification(response.message));
        yield put(
            setUser({
                ...userStateValue,
                zohoRefreshToken: "",
            })
        );
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* sendSlackInvitationSaga({
    payload,
}: {
    payload: ISendSlackInvitationPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: ISlackAuthResponse = yield call(
            new API().get,
            "/slack/auth"
        );
        const { data } = response;
        window.location.href = data;
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* revokeSlackInvitationSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        const response = yield call(new API().get, "/slack/revoke-access");
        if (!response) return;

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                isSlackConnected: false,
            })
        );
    } catch (err) {
        console.error("error while authenticating with slack");
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverJobsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/jobs");
        if (!response) return;

        yield put(setLeverJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getLeverStagesSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/lever/get-data/stages");
        if (!response) return;

        yield put(setLeverStages(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToLeverSaga({
    payload,
}: {
    payload: IAddToLeverPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload: ILeverPostCandidatePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }
        if (payload.job.jobId) {
            triggerPayload.jobId = payload.job.jobId;
        }
        if (payload.stage.stageId) {
            triggerPayload.stageId = payload.stage.stageId;
        }

        const response: IAddToLeverResponse = yield call(
            new API().post,
            "/lever/post-candidate",
            triggerPayload
        );
        if (!response) return;

        payload.onSuccess();
        if (response?.duplicate)
            yield put(setErrorNotification(response.message));
        else yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postJobviteCredentialsSaga({
    payload,
}: {
    payload: IPostJobviteCredentialsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().post, "/job-vite/add-creds", {
            apiKey: payload.apiKey,
            apiSecret: payload.apiSecret,
        });
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                jobVites: {
                    apiKey: payload.apiKey,
                    apiSecret: payload.apiSecret,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addToJobviteSaga({
    payload,
}: {
    payload: IAddToGreenhousePayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload: IAddToGreenhousePayload = {
            candidateIds: payload.candidateIds,
            projectId: payload.projectId,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }
        if (payload.jobId) triggerPayload.jobId = payload.jobId;

        const response = yield call(
            new API().post,
            "/job-vite/add-candidates",
            triggerPayload
        );
        if (!response) return;

        if (payload.onSuccess) payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getJobviteJobsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/job-vite/get-job");
        if (!response) return;

        yield put(setJobviteJobs(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchLoxoCredentials.type, fetchLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(postLoxoCredentials.type, postLoxoCredentialsSaga),
        // @ts-ignore
        yield takeLatest(addToLoxoAgency.type, addToLoxoAgencySaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postGreenhouseCredentials.type,
            postGreenhouseCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(addToGreenhouseAgency.type, addToGreenhouseAgencySaga),
        // @ts-ignore
        yield takeLatest(getGreenhouseJobs.type, getGreenhouseJobsSaga),
        // @ts-ignore
        yield takeLatest(sendSlackInvitation.type, sendSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(revokeSlackInvitation.type, revokeSlackInvitationSaga),
        // @ts-ignore
        yield takeLatest(addToZohoRecruit.type, addToZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(revokeZohoRecruit.type, revokeZohoRecruitSaga),
        // @ts-ignore
        yield takeLatest(getLeverJobs.type, getLeverJobsSaga),
        // @ts-ignore
        yield takeLatest(getLeverStages.type, getLeverStagesSaga),
        // @ts-ignore
        yield takeLatest(addToLever.type, addToLeverSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postJobviteCredentials.type,
            postJobviteCredentialsSaga
        ),
        // @ts-ignore
        yield takeLatest(getJobviteJobs.type, getJobviteJobsSaga),
        // @ts-ignore
        yield takeLatest(addToJobvite.type, addToJobviteSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetIntegration.type, CancelSagas, tasks);
}
