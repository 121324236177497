import React, { useState } from "react";

import styles from "./index.module.scss";

interface IReadMoreProps {
    children: string;
}

export default function ReadMore({
    children,
    ...rest
}: IReadMoreProps & React.HTMLAttributes<any>) {
    const text = children;
    const [isReadMore, setIsReadMore] = useState<boolean>(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p className={styles["text"]} {...rest}>
            {isReadMore ? text.slice(0, 150) : text}
            {text.length > 150 ? (
                <span
                    onClick={toggleReadMore}
                    className={styles["read-or-hide"]}
                >
                    {isReadMore ? "...read more" : " show less"}
                </span>
            ) : null}
        </p>
    );
}
