import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty, isUndefined } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../../components/Notification/index.reducer";
import {
    startAction,
    stopAction,
} from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import { changeModalState } from "../../workflow/index.reducer";
import {
    cancelActions,
    fetchSingleCandidateTemplates,
    sendUpdatedTemplates,
    setCandidateIndex,
    setCandidates,
    setFetchTemplateErrorStatus,
    setTemplates,
    setUpdatedTemplates,
    submitPersonalizedInputs,
} from "../reducers/personalizedWorkflow.slice";
import {
    Candidate,
    CandidateNameResponse,
    HyperPersonalizedTemplateResponse,
    SendUpdatedTemplatesPayload,
    SendUpdatedTemplatesResponse,
    SetCandidateIndexPayload,
    SubmitPersonalizedInputsPayload,
} from "../types";

function* submitPersonalizedInputsSaga(
    action: PayloadAction<SubmitPersonalizedInputsPayload>
): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { candidateIDs, projectID, fetchNamesAction } = action.payload;

        const state = yield select();
        const characterCount = get(
            state,
            "personalizedWorkflow.characterCount"
        );
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");

        yield put(startAction({ action: fetchNamesAction }));

        const hyperPersonalizedPayload: {
            candidateIds: string[];
            dummy?: boolean;
        } = {
            candidateIds: candidateIDs,
        };
        if (projectID === "9999") {
            hyperPersonalizedPayload.dummy = true;
        }
        const candidateNamesResponse: CandidateNameResponse = yield call(
            new API().post,
            "/hyper-personalization/candidate-name",
            hyperPersonalizedPayload
        );

        yield put(stopAction({ action: fetchNamesAction }));

        if (isEmpty(candidateNamesResponse?.candidates)) return;

        const candidates: Candidate[] = candidateNamesResponse.candidates.map(
            ({ _id, name }) => ({ id: _id, name, fetchTemplateStatus: "IDLE" })
        );

        yield put(setCandidates(candidates));

        const candidatesIdsInOrder = candidates.map(({ id }) => id);

        yield put(changeModalState("DEFAULT"));

        let personalizationPayload = {};
        if (projectID === "9999") {
            personalizationPayload = {
                dummy: true,
                candidateIds: candidatesIdsInOrder.slice(0, 3),
            };
        } else {
            personalizationPayload = {
                projectId: projectID,
                candidateIds: candidatesIdsInOrder.slice(0, 1),
                toneOfVoice,
                characterCount,
                outreachIntent: "",
            };
        }
        const personalizationResponse: HyperPersonalizedTemplateResponse =
            yield call(
                new API().post,
                "/hyper-personalization/send",
                personalizationPayload
            );
        if (isEmpty(personalizationResponse?.templates)) return;

        yield put(setTemplates(personalizationResponse.templates));
    } catch (error) {
        console.log({ error });
        handleException(error);
        yield put(
            setErrorNotification("error while fetching personalized templates")
        );
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* sendUpdatedTemplatesSaga(
    action: PayloadAction<SendUpdatedTemplatesPayload>
): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { projectId } = action.payload;

        const state = yield select();
        const updatedCandidate = get(state, "personalizedWorkflow.candidate");
        const characterCount = get(
            state,
            "personalizedWorkflow.characterCount"
        );
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");

        const response: SendUpdatedTemplatesResponse = yield call(
            new API().post,
            "/hyper-personalization/edit",
            {
                projectId,
                candidateIds: [updatedCandidate.id],
                connReq: updatedCandidate.connectionReq,
                outreachIntent: "",
                characterCount,
                toneOfVoice,
                ...updatedCandidate,
            }
        );
        if (isEmpty(response?.templates)) return;

        yield put(setUpdatedTemplates(response.templates));
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error({ error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchSingleCandidateTemplatesSaga(
    action: PayloadAction<SetCandidateIndexPayload>
): SagaIterator {
    try {
        const {
            projectId,
            id: candidateId,
            action: loadingAction,
        } = action.payload;
        const state = yield select();
        const characterCount = get(
            state,
            "personalizedWorkflow.characterCount"
        );
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");
        const candidates: Candidate[] = get(
            state,
            "personalizedWorkflow.candidates"
        );

        const isCandidateTemplatesExists = candidates.find(
            ({ id }) => id === candidateId
        );

        if (
            isUndefined(isCandidateTemplatesExists?.connectionReq) ||
            isUndefined(isCandidateTemplatesExists?.email) ||
            isUndefined(isCandidateTemplatesExists?.inMail)
        ) {
            yield put(startAction({ action: loadingAction }));

            const personalizationResponse: HyperPersonalizedTemplateResponse =
                yield call(new API().post, "/hyper-personalization/send", {
                    projectId,
                    candidateIds: [candidateId],
                    toneOfVoice,
                    characterCount,
                    outreachIntent: "",
                });
            if (isEmpty(personalizationResponse?.templates)) {
                throw new Error("Personalization templates are empty");
            }

            yield put(setTemplates(personalizationResponse.templates));
        }
    } catch (error) {
        console.log({ error });
        handleException(error);
        yield put(
            setErrorNotification("error while fetching personalized templates")
        );
        yield put(
            setFetchTemplateErrorStatus({ candidateId: action.payload.id })
        );
    } finally {
        yield put(stopAction({ action: action.payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(
            submitPersonalizedInputs.type,
            submitPersonalizedInputsSaga
        ),
        // @ts-ignore
        yield takeLatest(sendUpdatedTemplates.type, sendUpdatedTemplatesSaga),
        // @ts-ignore
        yield takeEvery(
            setCandidateIndex.type,
            fetchSingleCandidateTemplatesSaga
        ),
        // @ts-ignore
        yield takeEvery(
            fetchSingleCandidateTemplates.type,
            fetchSingleCandidateTemplatesSaga
        ),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
