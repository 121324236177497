import React from "react";
import { Grid, Paper } from "@mui/material";

import Candidates from "./Components/Candidates/Candidates";
import Filters from "./Components/Filters/Filters";

const AdvancedFilters = () => {
    return (
        <Grid container height="100vh">
            <Grid item sm={4} pl={0} lg={3}>
                <Filters />
            </Grid>
            <Grid item sm={8} lg={9} pl="20px">
                <Candidates />
            </Grid>
        </Grid>
    );
};

export default AdvancedFilters;
