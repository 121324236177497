import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type {
    DraggableProvided,
    DropResult,
    DroppableProvided,
} from "react-beautiful-dnd/index";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { addIcon } from "../../constant";
import { createNewWorkflow } from "../workflow/index.reducer";
import { IComponentList } from "../workflow/workflow.types";
import Delay from "./components/delay";
import Email from "./components/email";
import Recommendation from "./components/recommendation";
import Reminder from "./components/reminder";
import style from "./index.module.scss";

export default function EditWorkflow() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [componentList, setComponentList] = useState<IComponentList[]>([]);
    const [saveState, setSaveState] = useState<IComponentList[]>([]);
    const [isEditing, setIsEditing] = useState(true);
    const [showBtn, setShowBtn] = useState(true);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const showConfirmModal = () => setIsConfirmModalOpen(true);
    const closeConfirmModal = () => setIsConfirmModalOpen(false);

    useEffect(() => {
        if (!location.state) navigate("/workflows");

        let tmpList = [];
        for (let val of location.state.steps) {
            let tmp;

            if (val.eventName === "Reminder") {
                tmp = {
                    type: "rem",
                    editorVal: val.eventBody.body,
                };
            } else if (val.eventName === "Delay") {
                tmp = {
                    type: "delay",
                    weeks: val.eventBody.weeks,
                    days: val.eventBody.days,
                    hours: val.eventBody.hours,
                    mins: val.eventBody.mins,
                };
            } else if (val.eventName === "Email") {
                tmp = {
                    type: "email",
                    subject: val.eventBody.subject,
                    editorVal: val.eventBody.body,
                };
            }

            tmpList.push(tmp);
        }

        setShowBtn(!location.state.completed);
        //@ts-ignore
        setComponentList(tmpList);
    }, []);

    useEffect(() => {
        if (JSON.stringify(componentList).length === 2)
            return setIsEditing(true);
        if (JSON.stringify(saveState) === JSON.stringify(componentList))
            return setIsEditing(false);
        setIsEditing(true);
    }, [componentList, saveState]);

    const handleTimeChange = useCallback(
        (tarInd: number, key: string, newValue: string) => {
            let tmpArr = Array.from(componentList);
            //@ts-ignore
            tmpArr[tarInd][key] = newValue;
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const handleSubjectChange = useCallback(
        (tarInd: number, newValue: string) => {
            let tmpArr = Array.from(componentList);
            //@ts-ignore
            tmpArr[tarInd].subject = newValue;
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const handleEditorValueChange = useCallback(
        (tarInd: number, newValue: string) => {
            let tmpArr = Array.from(componentList);
            tmpArr[tarInd].editorVal = newValue;
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            let tmpArr = Array.from(componentList);
            tmpArr[tarInd].isOpen = !componentList[tarInd].isOpen;
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const handleRemove = useCallback(
        (tarInd: number) => {
            let tmpArr = Array.from(componentList);
            tmpArr.splice(tarInd, 1);
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const handleDuplicate = useCallback(
        (tarInd: number) => {
            let tmpArr = Array.from(componentList);
            tmpArr.splice(tarInd, 0, { ...tmpArr[tarInd] });
            setComponentList(tmpArr);
        },
        [componentList]
    );

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;

            const { source, destination } = result;
            const isSrcComponent = source.droppableId === "col-2";
            const isDstComponent = destination.droppableId === "col-2";

            if (isSrcComponent && !isDstComponent) return;

            if (!isSrcComponent && isDstComponent) {
                let tmpList = Array.from(componentList);

                tmpList.splice(destination.index, 0, {
                    isOpen: true,
                    type: result.draggableId,
                    editorVal: "",
                    subject: "",
                    hours: "0",
                    mins: "5",
                    days: "0",
                    weeks: "0",
                });
                setComponentList(tmpList);
            } else {
                let tmpList = Array.from(componentList);
                let x = tmpList[source.index];
                tmpList[source.index] = tmpList[destination.index];
                tmpList[destination.index] = x;
                setComponentList(tmpList);
            }
        },
        [componentList]
    );

    const generateSteps = () => {
        let steps = [];

        for (let val of componentList) {
            let tmp;

            if (val.type === "rem") {
                tmp = {
                    eventEnum: 3,
                    eventName: "Reminder",
                    eventBody: {
                        body: val.editorVal,
                    },
                };
            } else if (val.type === "delay") {
                tmp = {
                    eventEnum: 2,
                    eventName: "Delay",
                    eventBody: {
                        hours: val.hours,
                        weeks: val.weeks,
                        days: val.days,
                        mins: val.mins,
                        duration:
                            //@ts-ignore
                            (val.weeks * 7 * 24 * 60 +
                                //@ts-ignore
                                val.days * 24 * 60 +
                                //@ts-ignore
                                val.hours * 60 +
                                //@ts-ignore
                                val.mins) *
                            1,
                    },
                };
            } else if (val.type === "email") {
                tmp = {
                    eventEnum: 1,
                    eventName: "Email",
                    eventBody: {
                        subject: val.subject,
                        body: val.editorVal,
                    },
                };
            }

            steps.push(tmp);
        }

        return steps;
    };

    const handleSave = () => {
        //@ts-ignore
        const steps = generateSteps();

        dispatch(
            createNewWorkflow({
                name: location.state.name,
                _id: params.id,
                steps: steps,
                completed: false,
            })
        );

        setSaveState(componentList);
    };

    const handleFinalise = () => {
        //@ts-ignore
        const steps = generateSteps();

        dispatch(
            createNewWorkflow({
                name: location.state.name,
                _id: params.id,
                steps: steps,
                completed: true,
            })
        );

        setIsEditing(false);
        setShowBtn(false);
        closeConfirmModal();
        navigate("/workflows");
    };

    return (
        <div className={style["wk"]}>
            <div className={style["wk__container"]}>
                <Navbar />

                <div className={style["wk__header"]}>
                    <div className={style["wk__headerLeft"]}>
                        <p className={style["wk__heading"]}>
                            {location.state.name}
                        </p>
                        <p className={style["wk__createDate"]}>
                            {new Date(
                                location.state.createdAt
                            ).toLocaleDateString()}
                        </p>
                    </div>
                    <div className={style["wk__headerRight"]}>
                        {showBtn ? (
                            isEditing ? (
                                <Button
                                    label="Save"
                                    variant="primary"
                                    onClick={handleSave}
                                />
                            ) : (
                                <Button
                                    label="Finalise"
                                    variant="primary"
                                    onClick={showConfirmModal}
                                />
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <DragDropContext onDragEnd={(res: any) => onDragEnd(res)}>
                    <div className={style["wk__body"]}>
                        <div className={style["wk__sidebar"]}>
                            {/* <div className={style["wk__searchBox"]}>
                <input className={style["wk__search"]} type="text" placeholder="Search" />
              </div> */}

                            <Droppable droppableId="col-1">
                                {(dropProvider: DroppableProvided) => (
                                    <div
                                        className={style["wk__sidebarScroll"]}
                                        ref={dropProvider.innerRef}
                                        {...dropProvider.droppableProps}
                                    >
                                        <div className={style["wk__item"]}>
                                            <p
                                                className={
                                                    style["wk__itemHead"]
                                                }
                                            >
                                                Out reach &nbsp;
                                                <img
                                                    src={addIcon}
                                                    alt="add-icon"
                                                />
                                            </p>
                                            <Draggable
                                                draggableId="email"
                                                index={0}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Email
                                                    </p>
                                                )}
                                            </Draggable>

                                            <Draggable
                                                draggableId="inmail"
                                                index={1}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Inmail
                                                    </p>
                                                )}
                                            </Draggable>

                                            <Draggable
                                                draggableId="msg"
                                                index={2}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Messages
                                                    </p>
                                                )}
                                            </Draggable>

                                            <Draggable
                                                draggableId="rem"
                                                index={3}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Call reminder
                                                    </p>
                                                )}
                                            </Draggable>

                                            <Draggable
                                                draggableId="delay"
                                                index={4}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Delay
                                                    </p>
                                                )}
                                            </Draggable>

                                            <Draggable
                                                draggableId="linkedin"
                                                index={4}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        LinkedIn Req.
                                                    </p>
                                                )}
                                            </Draggable>
                                        </div>

                                        {/* <div className={style["wk__item"]}>
                      <p className={style["wk__itemHead"]}>Templates &nbsp; <img src={addIcon} alt="" /></p>
                      <p className={style["wk__itemLink"]}>Template 1</p>
                      <p className={style["wk__itemLink"]}>Template 2</p>
                      <p className={style["wk__itemLink"]}>Template 3</p>
                      <p className={style["wk__itemLink"]}>Template 4</p>
                      <p className={style["wk__itemLink"]}>Template 5</p>
                    </div> */}
                                    </div>
                                )}
                            </Droppable>
                        </div>

                        <Droppable droppableId="col-2">
                            {(dropProvider: DroppableProvided) => (
                                <div
                                    className={style["wk__main"]}
                                    ref={dropProvider.innerRef}
                                    {...dropProvider.droppableProps}
                                >
                                    <div className={style["wk__legendBox"]}>
                                        <div
                                            className={
                                                style["wk__legendWrapper"]
                                            }
                                        >
                                            <div
                                                className={
                                                    style["wk__flowWrapper"]
                                                }
                                            >
                                                <p
                                                    className={
                                                        style["wk__legend"]
                                                    }
                                                >
                                                    Setup flow
                                                </p>
                                                <div
                                                    className={
                                                        style["wk__mainBox"]
                                                    }
                                                >
                                                    {componentList.map(
                                                        (val, i) => {
                                                            if (
                                                                val.type ===
                                                                "email"
                                                            ) {
                                                                return (
                                                                    <Email
                                                                        index={
                                                                            i
                                                                        }
                                                                        key={i}
                                                                        isOpen={
                                                                            val.isOpen
                                                                        }
                                                                        subject={
                                                                            val.subject
                                                                        }
                                                                        editorVal={
                                                                            val.editorVal
                                                                        }
                                                                        setSubject={
                                                                            handleSubjectChange
                                                                        }
                                                                        setEditorVal={
                                                                            handleEditorValueChange
                                                                        }
                                                                        toggleCollapse={
                                                                            handleCollapseChange
                                                                        }
                                                                        handleRemove={
                                                                            handleRemove
                                                                        }
                                                                        handleDuplicate={
                                                                            handleDuplicate
                                                                        }
                                                                    />
                                                                );
                                                            } else if (
                                                                val.type ===
                                                                "rem"
                                                            ) {
                                                                return (
                                                                    <Reminder
                                                                        index={
                                                                            i
                                                                        }
                                                                        key={i}
                                                                        isOpen={
                                                                            val.isOpen
                                                                        }
                                                                        editorVal={
                                                                            val.editorVal
                                                                        }
                                                                        setEditorVal={
                                                                            handleEditorValueChange
                                                                        }
                                                                        toggleCollapse={
                                                                            handleCollapseChange
                                                                        }
                                                                        handleRemove={
                                                                            handleRemove
                                                                        }
                                                                        handleDuplicate={
                                                                            handleDuplicate
                                                                        }
                                                                    />
                                                                );
                                                            } else if (
                                                                val.type ===
                                                                "delay"
                                                            ) {
                                                                return (
                                                                    <Delay
                                                                        index={
                                                                            i
                                                                        }
                                                                        key={i}
                                                                        isOpen={
                                                                            val.isOpen
                                                                        }
                                                                        toggleCollapse={
                                                                            handleCollapseChange
                                                                        }
                                                                        handleRemove={
                                                                            handleRemove
                                                                        }
                                                                        handleDuplicate={
                                                                            handleDuplicate
                                                                        }
                                                                        hours={
                                                                            val.hours
                                                                        }
                                                                        mins={
                                                                            val.mins
                                                                        }
                                                                        days={
                                                                            val.days
                                                                        }
                                                                        weeks={
                                                                            val.weeks
                                                                        }
                                                                        handleTimeChange={
                                                                            handleTimeChange
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            {componentList.some(
                                                (val) => val.type === "email"
                                            ) ? (
                                                <Recommendation />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>

                <Modal
                    open={isConfirmModalOpen}
                    onOk={closeConfirmModal}
                    onCancel={closeConfirmModal}
                    title={"Confirm Finalize"}
                    width={"50%"}
                >
                    <div className={style["wk__modal"]}>
                        <p>Are you sure you want to finalize this workflow?</p>
                        <p>You won't be able to edit after this</p>
                    </div>
                    <div className={style["wk__modalBtn"]}>
                        <Button
                            label="Cancel"
                            variant="secondary"
                            onClick={closeConfirmModal}
                        />
                        <Button
                            label="Confirm"
                            variant="primary"
                            onClick={handleFinalise}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
