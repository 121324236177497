import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import style from "./index.module.scss";
import Button from '../Button';

interface clockModalProps {
  closeModal: () => void,
  handleSelectDate: (dayVal: Dayjs | null) => void
}

export default function DateModal(props: clockModalProps) {
  const { closeModal, handleSelectDate } = props;
  const [dayValue, setDayValue] = useState<Dayjs | null>(dayjs(''));

  return (
    <div className={style["modal"]}>
      <div className={style["modal__container"]}>

        <div className={style["modal__dateBox"]}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date&Time picker"
              value={dayValue}
              onChange={setDayValue}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>

        <div className={style["modal__btnBox"]}>
          <Button label="Cancel" variant="secondary" onClick={closeModal} />
          <Button label={dayjs(dayValue).isValid() ? "Confirm" : "Choose Date"} variant="primary" onClick={() => handleSelectDate(dayValue)} />
        </div>
      </div>
    </div>
  )
}
