import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    IAccountRootState,
    ICheckoutPayload,
    IUserPlan,
} from "./myAccount.types";

const initialState: IAccountRootState = {
    plans: [],
    userPlan: {
        name: "Free",
        createdAt: "2023-04-27T07:49:01.798Z",
        validTill: "Forever",
        remainingCredits: 30,
        totalCredits: 100,
    },
};

const reducers = {
    fetchPlans(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setPlans(state: typeof initialState, { payload }: { payload: any[] }) {
        state.plans = payload;
    },
    fetchUserPlan(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setUserPlan(
        state: typeof initialState,
        { payload }: { payload: IUserPlan }
    ) {
        state.userPlan = payload;
    },
    createCheckoutSession(
        state: typeof initialState,
        { payload }: { payload: IActionPayload & ICheckoutPayload }
    ) {},
    resetMyAccount(state: typeof initialState) {},
};

export const myAccountSlice = createSlice({
    name: "myAccount",
    initialState,
    reducers,
});

export default myAccountSlice.reducer;

export const fetchPlans = myAccountSlice.actions.fetchPlans;
export const setPlans = myAccountSlice.actions.setPlans;
export const fetchUserPlan = myAccountSlice.actions.fetchUserPlan;
export const setUserPlan = myAccountSlice.actions.setUserPlan;
export const createCheckoutSession =
    myAccountSlice.actions.createCheckoutSession;
export const resetMyAccount = myAccountSlice.actions.resetMyAccount;

export const selectUserPlan = (state: RootState) => state.myAccount.userPlan;
