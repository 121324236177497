import { ChangeEvent, useState } from "react";
import {
    Radio,
    Select,
    notification,
    type RadioChangeEvent,
    type SelectProps,
} from "antd";
import { useDispatch } from "react-redux";

import { createNewProject } from "../../../pages/allProjects/index.reducer";
import Button from "../../Button";
import style from "./index.module.scss";

interface projectModalProps {
    closeModal: () => void;
}

const memberDropDown: SelectProps["options"] = [
    { label: "Harsh", value: 1 },
    { label: "Shreya", value: 2 },
    { label: "John", value: 3 },
    { label: "Cena", value: 4 },
    { label: "Kane", value: 5 },
    { label: "Red", value: 6 },
    { label: "Blue", value: 7 },
    { label: "Green", value: 8 },
];

export default function ProjectModal(props: projectModalProps) {
    const [nameInput, setNameInput] = useState("");
    const [functionInput, setFunctionInput] = useState<string | undefined>(
        undefined
    );
    const [minValueInput, setMinValueInput] = useState<number | undefined>(
        undefined
    );
    const [maxValueInput, setMaxValueInput] = useState<number | undefined>(
        undefined
    );
    const [locationInput, setLocationInput] = useState<string | undefined>(
        undefined
    );
    const [descInput, setDescInput] = useState("");
    const [visibleVal, setVisibleVal] = useState(1);
    const [selectedMember, setSelectedMember] = useState<number[]>();
    const [api, contextHolder] = notification.useNotification();

    const onRadioChange = (e: RadioChangeEvent) =>
        setVisibleVal(e.target.value);
    const onSelectMemberChange = (value: number[]) => setSelectedMember(value);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        if (!nameInput) return openNotification(`Project Name can't be empty`);
        else if (!functionInput)
            return openNotification(`Project Function can't be empty`);
        else if (!minValueInput)
            return openNotification(`Min. work experience is required`);
        else if (!maxValueInput)
            return openNotification(`Max. work experience is required`);
        else if (minValueInput <= 0)
            return openNotification(
                `Min. work experience can't be less than zero`
            );
        else if (maxValueInput <= 0)
            return openNotification(
                `Max. work experience can't be less than zero`
            );
        else if (maxValueInput < minValueInput)
            return openNotification(
                `Max. work experience can't be less than Min. work experience`
            );
        else if (!locationInput)
            return openNotification(`Project Function can't be empty`);

        const body = {
            name: nameInput,
            type: functionInput,
            workExpRange: {
                min: minValueInput,
                max: maxValueInput,
            },
            location: locationInput,
            description: descInput,
            visibility: selectedMember,
        };
        dispatch(createNewProject(body));
        closeModal();
    };

    const { closeModal } = props;

    const openNotification = (message: string) => {
        api.error({
            message: message,
            placement: "bottomLeft",
        });
    };

    return (
        <div className={style["pmodal"]}>
            {contextHolder}
            <div className={style["pmodal__container"]}>
                <div className={style["pmodal__item"]}>
                    <div className={style["pmodal__inputBox"]}>
                        <input
                            type="text"
                            className={style["pmodal__input"]}
                            placeholder="Project Name"
                            value={nameInput}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNameInput(e.target.value)
                            }
                        />
                    </div>
                    <div className={style["pmodal__inputBox"]}>
                        <input
                            type="text"
                            className={style["pmodal__input"]}
                            placeholder="Project Function"
                            value={functionInput}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFunctionInput(e.target.value)
                            }
                        />
                        {/* <Select
              bordered={false}
              className={style["pmodal__select"]}
              placeholder="Project Function"
              value={functionInput}
              options={[
                { value: '1', label: 'Software Developer' },
                { value: '2', label: 'Technical Manger' },
                { value: '3', label: 'Data Science & ML' },
              ]}
              onChange={(value: string) => setFunctionInput(value)}
            /> */}
                    </div>
                </div>

                <div className={style["pmodal__item"]}>
                    <label className={style["pmodal__label"]}>
                        Work Experience Range
                    </label>
                    <div className={style["pmodal__inputBox"]}>
                        <input
                            type="number"
                            className={style["pmodal__input"]}
                            placeholder="Min Value(in years)"
                            value={minValueInput}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setMinValueInput(Number(e.target.value))
                            }
                        />
                        {/* <Select
              bordered={false}
              className={style["pmodal__select"]}
              placeholder="Min Value"
              value={minValueInput}
              options={[
                { value: '0', label: '0' },
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '6' },
                { value: '7', label: '7' }
              ]}
              onChange={(value: string) => setMinValueInput(value)}
            /> */}
                    </div>

                    <div className={style["pmodal__inputBox"]}>
                        <input
                            type="number"
                            className={style["pmodal__input"]}
                            placeholder="Max Value(in years)"
                            value={maxValueInput}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setMaxValueInput(Number(e.target.value))
                            }
                        />
                        {/* <Select
              bordered={false}
              className={style["pmodal__select"]}
              placeholder="Max Value"
              value={maxValueInput}
              options={[
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '6' },
                { value: '7', label: '7' }
              ]}
              onChange={(value: string) => setMaxValueInput(value)}
            /> */}
                    </div>
                </div>

                <div className={style["pmodal__item"]}>
                    <label className={style["pmodal__label"]}>
                        Job Location
                    </label>
                    <input
                        type="text"
                        className={style["pmodal__input"]}
                        placeholder="Enter Location"
                        value={locationInput}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setLocationInput(e.target.value)
                        }
                    />
                    {/* <Select
            bordered={false}
            className={style["pmodal__select"]}
            placeholder="Select Location"
            value={locationInput}
            options={[
              { value: 'texas', label: 'texas' },
              { value: 'california', label: 'california' },
              { value: 'USA', label: 'USA' },
            ]}
            onChange={(value: string) => setLocationInput(value)}
          /> */}
                </div>

                <div className={style["pmodal__item"]}>
                    <label className={style["pmodal__label"]}>
                        Project Description
                    </label>
                    <textarea
                        className={style["pmodal__textarea"]}
                        value={descInput}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setDescInput(e.target.value)
                        }
                    ></textarea>
                </div>

                <div className={style["pmodal__item"]}>
                    <label className={style["pmodal__label"]}>
                        Project Visible to:
                    </label>
                    <div className={style["pmodal__checkbox"]}>
                        <Radio.Group
                            className={style["pmodal__checkboxGroup"]}
                            onChange={onRadioChange}
                            value={visibleVal}
                        >
                            <Radio value={1}>Only you</Radio>
                            <br />
                            <Radio value={2}>Individual member</Radio>
                            <br />

                            {visibleVal === 2 ? (
                                <div className={style["pmodal__memberBox"]}>
                                    <Select
                                        className={style["pmodal__memberInput"]}
                                        mode="multiple"
                                        allowClear
                                        bordered={false}
                                        placeholder="Select Members"
                                        style={{ width: "100%" }}
                                        value={selectedMember}
                                        onChange={onSelectMemberChange}
                                        options={memberDropDown}
                                    />
                                </div>
                            ) : (
                                ""
                            )}

                            <br />
                            <Radio value={3}>Everyone in the team</Radio>
                        </Radio.Group>
                    </div>
                </div>

                <div className={style["pmodal__btnBox"]}>
                    <Button
                        label="Cancel"
                        variant="secondary"
                        onClick={closeModal}
                    />
                    <Button
                        label="Create"
                        variant="primary"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}
