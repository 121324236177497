import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    INudgePayload,
    editNudge,
    leaveNudge,
    setNudges,
} from "./Nudges.reducer";

function* editNudgeSaga({
    payload,
}: {
    payload: INudgePayload & IActionPayload;
}): SagaIterator {
    const { type, value, action } = payload;

    try {
        yield put(startAction({ action }));

        const response = yield call(new API().post, "/auth/nudges", {
            [type]: value,
        });

        const state = yield select();
        const updatedNudges = {
            ...state.nudges,
            [type]: true,
        };

        if (response?.success) {
            yield put(setNudges(updatedNudges));
        } else {
            throw new Error("Error in updating nudge status");
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(editNudge.type, editNudgeSaga),
    ];
    yield takeLatest(leaveNudge.type, CancelSagas, tasks);
}
