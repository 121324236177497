import { Button, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectIsExtensionInstalled } from "../../container/AppContainer/AppContainer.reducer";
import { openExtensionLink, openLinkedinRecruiter } from "../../utils/API";
import {
    HQ_EASYSOURCE_WEBSITE,
    HQ_EXTENSION_ON_BOARDING,
    HQ_OPEN_IN_NEW_PAGE_EXTENSION,
} from "../../utils/Constants";
import handleCleverTap from "../../utils/clevertap";
import ChromeExtension from "../ChromeExtension";
import SourceCandidatesMenu from "../SourceCandidatesMenu/SourceCandidatesMenu";

type ExtensionBtnProps = {
    variant?: "default" | "low";
};

export default function ExtensionBtn({
    variant = "default",
}: ExtensionBtnProps) {
    const location = useLocation();
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);

    const sendExtensionEvent = (url: string, type: string) => {
        handleCleverTap(`source candidates ${type}`);
        window.postMessage(
            {
                type:
                    url === openExtensionLink
                        ? HQ_OPEN_IN_NEW_PAGE_EXTENSION
                        : HQ_EXTENSION_ON_BOARDING,
                from: HQ_EASYSOURCE_WEBSITE,
                url,
            },
            "*"
        );
    };

    return (
        <>
            {isExtensionInstalled ? (
                <Button
                    sx={{
                        padding: "4px 8px",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#334D6E",
                        margin: "0 10px",
                        borderColor: "#d9d9d9",
                        boxShadow: "rgba(16, 156, 241, 0.24) 0px 4px 10px 0px",
                        opacity: location.pathname === "/workflows" ? 0.5 : 1,
                    }}
                    onClick={() =>
                        sendExtensionEvent(openExtensionLink, "Linkedin")
                    }
                >
                    Source Candidates
                </Button>
            ) : (
                <ChromeExtension variant={variant} />
            )}
        </>
    );
}
