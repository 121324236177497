import { PayloadAction, nanoid } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import {
    AddFilesPayload,
    SetFilesPayload,
    addFiles,
    cancelActions,
    setFilesInState,
} from "../reducers/workflow-upload.slice";

function* addFilesSaga(action: PayloadAction<AddFilesPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { files, projectId, close } = action.payload;
        const payload = new FormData();
        for (const file of files) {
            payload.append("files", file);
        }
        payload.append("projectId", projectId);

        const response = yield call(new API().post, "upload/files", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.length !== files.length) {
            throw new Error("error while uploading files");
        }

        const filesWithLink: SetFilesPayload = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            filesWithLink.push({
                file: response[i],
                name: file.name,
            });
        }

        yield put(setFilesInState(filesWithLink));
        close();
    } catch (error) {
        console.error({ error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(addFiles.type, addFilesSaga),
    ];

    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
