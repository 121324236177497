import Modal from "../Modal";
import WorkflowModal from "../Navbar/workflowModal";

export default function CreateWorkflowModal({ open, close }: {
    open: boolean,
    close: () => void
}) {
  return (
    <Modal
      open={open}
      onOk={close}
      onCancel={close}
      title={"New Workflow"}
      width={"50%"}
    >
      <WorkflowModal closeModal={close} />
    </Modal>
  );
}
