import { useEffect } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { userInitialIcon } from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    getActivities,
    selectActivities,
} from "../../../allProjects/index.reducer";
import style from "./index.module.scss";

interface propsType {
    id: string;
}

export default function ActivityTab(props: propsType) {
    const { id } = props;
    const dispatch = useDispatch();
    const activities = useSelector(selectActivities);
    const isActivityLoading = useSelector(checkIfLoading(getActivities.type));

    useEffect(() => {
        dispatch(getActivities({ id, action: getActivities.type }));
    }, []);

    if (isActivityLoading)
        return (
            <div className={style["act__loader"]}>
                <Spin />
            </div>
        );
    return (
        <div className={style["act"]}>
            <div className={style["act__container"]}>
                {/* <div className={style["act__actionBox"]}>
                    <div className={style["act__checkbox"]}>
                        <Checkbox>
                            <p className={style["act__checkboxText"]}>
                                Show activity for all jobs (3)
                            </p>
                        </Checkbox>
                    </div>
                    <div className={style["act__checkbox"]}>
                        <Checkbox>
                            <p className={style["act__checkboxText"]}>
                                Show activity for only jobs (3)
                            </p>
                        </Checkbox>
                    </div>
                </div> */}

                <div className={style["act__body"]}>
                    {activities.map((val, i) => {
                        return (
                            <div className={style["act__item"]} key={i}>
                                <div className={style["act__userBox"]}>
                                    <img
                                        className={style["act__userImg"]}
                                        src={userInitialIcon}
                                        alt=""
                                    />
                                </div>
                                <div className={style["act__itemTextBox"]}>
                                    <p className={style["act__itemText"]}>
                                        {val.body}
                                    </p>
                                </div>
                                <div className={style["act__dateBox"]}>
                                    <p className={style["act__date"]}>
                                        {new Date(
                                            val.createdAt
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
