import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { slackLogo } from "../../../constant";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser } from "../../Signin/Signin.reducer";
import {
    revokeSlackInvitation,
    sendSlackInvitation,
} from "../integrations.reducer";
import ConnectWrapper from "./connectWrapper";

export default function SlackIntegration() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isConnectingToSlack = useSelector(
        checkIfLoading(sendSlackInvitation.type)
    );
    const isRevokingSlack = useSelector(
        checkIfLoading(revokeSlackInvitation.type)
    );

    const isSlackConnected = user?.isSlackConnected;

    const handleSlack = () => {
        if (isSlackConnected) {
            dispatch(
                revokeSlackInvitation({
                    action: sendSlackInvitation.type,
                })
            );
        } else {
            dispatch(
                sendSlackInvitation({
                    action: sendSlackInvitation.type,
                    navigate: (url) => navigate(url),
                })
            );
        }
    };

    return (
        <ConnectWrapper
            logo={slackLogo}
            logoAlt="slack-logo"
            title="Slack"
            description="EasySource's integration with Slack streamlines collaboration and simplifies workspace invitations."
        >
            <Button
                variant={isSlackConnected ? "outlined" : "contained"}
                color={isSlackConnected ? "error" : "primary"}
                onClick={handleSlack}
                disabled={isConnectingToSlack || isRevokingSlack}
            >
                <ButtonTextWithLoading
                    isLoading={isConnectingToSlack || isRevokingSlack}
                    text={isSlackConnected ? "Revoke" : "Connect"}
                />
            </Button>
        </ConnectWrapper>
    );
}
