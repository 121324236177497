import axios from "axios";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import {
    call,
    cancelled,
    put,
    select,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import { setNudges } from "../../components/Nudges/Nudges.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setUser } from "../Signin/Signin.reducer";
import { IGetProjectPayload } from "../allProjects/allProjects.types";
import { getProject, setUserEmail } from "../allProjects/index.reducer";
import { IMessage } from "../message/message.types";
import { fetchUserPlan } from "../myAccount/myAccount.reducer";
import { changeModalState } from "../workflow/index.reducer";
import { WORKFLOW_MODES } from "../workflow/workflow.types";
import {
    addToWorkflow,
    copyToProject,
    getCandidateEmail,
    getProjectCandidates,
    getProjectStats,
    getProjectWorkflows,
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByPersonalEmails,
    getShowByProjectFilters,
    getShowByRecent,
    getShowByRelevance,
    getWorkflowEmailStats,
    getWorkflowLinkedInStats,
    leaveProject,
    moveTo,
    setCandidateEmails,
    setCandidates,
    setProjectStats,
    setProjectWorkflows,
    setSelectAllCandidates,
    setSelectedCandidates,
    setTotalCandidates,
    setWorkflowEmailStats,
    setWorkflowLinkedInStats,
} from "./index.reducer";
import {
    IAddToWorkflow,
    IAddToWorkflowPayload,
    ICandidateEmailPayload,
    ICandidateEmailResponse,
    ICopyToProjectPayload,
    IGetProjectCandidatesResponse,
    IGetProjectWorkflows,
    IMostRecentPayload,
    IMoveToPayload,
    IProjectFiltersRequestPayload,
    IProjectStage,
    IRelevantCandPayload,
    IShowByRelevanceResponse,
} from "./project.types";

// function updateCandidateEmail(
//     project: { candidates: any[] },
//     candidateIds: Array<string>,
//     candidatesWithEmails: Array<{ _id: string; email: string }>
// ) {
//     const updatedProjectCandidates = project.candidates.map(
//         (candidate: any) => {
//             if (candidateIds.includes(candidate._id)) {
//                 const candidateEmail = candidatesWithEmails.find(
//                     (item) => item._id === candidate._id
//                 );
//                 return {
//                     ...candidate,
//                     email: candidateEmail?.email ?? candidate.email,
//                 };
//             }
//             return candidate;
//         }
//     );

//     return {
//         ...project,
//         candidates: updatedProjectCandidates,
//     };
// }

function* copyToProjectSaga({
    payload,
}: {
    payload: ICopyToProjectPayload;
}): SagaIterator {
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload = { ...payload };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(
            new API().post,
            "/v2/project/add-candidate",
            triggerPayload
        );

        if (!response) return;
        yield put(setSelectAllCandidates(false));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* moveToSaga({ payload }: { payload: IMoveToPayload }): SagaIterator {
    try {
        const { type, ...rest } = payload;
        const response = yield call(
            new API().put,
            "/candidate/project-stage",
            rest
        );

        if (!response) return;

        const state = yield select();
        if (type && type === "get-user-email") {
            const userEmailStateValue = get(state, "allProjects.userEmail");
            const tmpUserEmail = userEmailStateValue.map((item: IMessage) => {
                if (payload.candidateIds.includes(item.candidate._id)) {
                    return {
                        ...item,
                        stage: payload.stage,
                    };
                }
                return item;
            });
            yield put(setUserEmail(tmpUserEmail));
        } else {
            const candidateStateValue = get(state, "project.candidates");
            const tmpCandidates = candidateStateValue.map((item: any) => {
                if (payload.candidateIds.includes(item._id)) {
                    return {
                        ...item,
                        stage: payload.stage,
                    };
                }

                return item;
            });
            yield put(setCandidates(tmpCandidates));
            yield put(
                getProjectStats({
                    projectId: payload.projectId,
                    action: getProjectStats.type,
                })
            );
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* addToWorkflowSaga({
    payload,
}: {
    payload: IAddToWorkflowPayload;
}): SagaIterator {
    try {
        const { onSuccess, action, ...rest } = payload;
        yield put(startAction({ action }));

        const state = yield select();
        const workflowMode: WORKFLOW_MODES = get(
            state,
            "workflow.workflowMode"
        );
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );
        const characterCount = get(
            state,
            "personalizedWorkflow.characterCount"
        );
        const toneOfVoice = get(state, "personalizedWorkflow.toneOfVoice");

        const triggerPayload: IAddToWorkflow = {
            ...rest,
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidate = [];
        }

        const response = yield call(new API().post, "/workflow/trigger", {
            ...triggerPayload,
            hyperPersonalized:
                workflowMode === "PERSONALIZED_WORKFLOW" ? true : false,
            toneOfVoice:
                workflowMode === "PERSONALIZED_WORKFLOW" ? toneOfVoice : "",
            wordCount:
                workflowMode === "PERSONALIZED_WORKFLOW" ? characterCount : "",
            outreachIntent: "",
        });

        if (!response) return;

        if (onSuccess)
            onSuccess({
                workflowId: response.data.workflowModel._id,
                workflowCredits: response.data.candidateCreditCount,
            });

        const installExtension = get(state, "nudges.installExtension");
        const addToWorkflow = get(state, "nudges.addToWorkflow");
        const showEmail = get(state, "nudges.showEmail");

        const tmpNudge = {
            installExtension,
            addToWorkflow,
            showEmail,
        };
        tmpNudge.addToWorkflow = true;
        yield put(setNudges(tmpNudge));

        yield put(setSelectAllCandidates(false));
        yield put(changeModalState("DEFAULT"));
        handleCleverTap("OutReach Started Successfully", {
            triggerPayload,
        });
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCandidateEmailSaga({
    payload,
}: {
    payload: IActionPayload & ICandidateEmailPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        const projectStateValue = get(state, "allProjects.project");
        const isSse =
            projectStateValue?.projectStatus === "IN_PROGRESS" ||
            projectStateValue?.projectStatus === "PENDING";

        const response: ICandidateEmailResponse = yield call(
            new API().post,
            "/candidate/getEmail",
            {
                isSse,
                candidateIds: payload.candidateIds,
            }
        );
        if (response?.code === 403) {
            throw new Error(response?.message);
        }
        if (response?.data?.warning) {
            yield put(setErrorNotification(response.message));
        }
        if (response?.firstEmailNA === "Mid") {
            yield put(
                setUser({
                    ...userStateValue,
                    firstEmailNA: response.firstEmailNA,
                })
            );
        }

        const installExtension = get(state, "nudges.installExtension");
        const addToWorkflow = get(state, "nudges.addToWorkflow");
        const showEmail = get(state, "nudges.showEmail");

        const tmpNudge = {
            installExtension,
            addToWorkflow,
            showEmail,
        };
        tmpNudge.showEmail = true;
        yield put(setNudges(tmpNudge));

        yield put(fetchUserPlan({ action: fetchUserPlan.type }));

        const personalEmails = response.data.showEmail.filter(
            (item) => !isEmpty(item.personal_email)
        );
        handleCleverTap("Show Email", {
            requestedEmails: payload.candidateIds.length,
            foundEmails: personalEmails?.length || 0,
        });

        yield put(setCandidateEmails(response.data));
    } catch (err: any) {
        yield put(setErrorNotification(err.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectStatsSaga({
    payload,
}: {
    payload: { projectId: number } & IActionPayload;
}): SagaIterator {
    const cancelTokenSource = axios.CancelToken.source();
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/v2/project/stats/${payload.projectId}`,
            {
                cancelToken: cancelTokenSource.token,
            }
        );
        if (isEmpty(response?.result)) return;

        yield put(setProjectStats(response.result));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
        if (yield cancelled()) {
            cancelTokenSource.cancel();
        }
    }
}

function* getShowByRelevanceSaga({
    payload,
}: {
    payload: IRelevantCandPayload & IActionPayload;
}): SagaIterator {
    try {
        handleCleverTap("Show by relevance");

        yield put(startAction({ action: payload.action }));

        const response: IShowByRelevanceResponse = yield call(
            new API().get,
            `/relevancy/${payload.projectId}`
        );
        if (!response) return;

        yield put(setCandidates(response.candidateData));
        const candidateIds = response.candidateData.map((item) => item._id);
        yield put(
            setSelectedCandidates({
                candidateId: [...candidateIds],
                pageNo: 1,
            })
        );
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectWorkflowsSaga({
    payload,
}: {
    payload: IGetProjectWorkflows & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/workflow/get/${payload.projectId}`
        );

        if (isEmpty(response?.data)) return;

        yield put(setProjectWorkflows(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getShowByRecentSaga({
    payload,
}: {
    payload: IMostRecentPayload & IActionPayload;
}): SagaIterator {
    handleCleverTap("Show by recent");
    yield put(
        getProject({ projectId: payload.projectId, action: getProject.type })
    );
}

function* getShowByProjectFiltersSaga({
    payload,
}: {
    payload: IGetProjectWorkflows & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const projectFiltersStateValue = state.project.projectFilters;
        const showByMaxExperienceStateValue = state.project.showByMaxExperience;
        const showByMinExperienceStateValue = state.project.showByMinExperience;
        const showByPersonalEmailsStateValue =
            state.project.showByPersonalEmails;
        const showByRelevanceStateValue = state.project.showByRelevance;

        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);

        const sortBy = showByMaxExperienceStateValue
            ? "maxExp"
            : showByMinExperienceStateValue
            ? "minExp"
            : showByRelevanceStateValue
            ? "relevance"
            : "";

        const filterByPayload = [...appliedFilters];

        const requestPayload: IProjectFiltersRequestPayload = {
            filterBy: filterByPayload,
            sortBy: sortBy,
        };

        const url = `/v2/project/get/${payload.projectId}/candidates?start=0&limit=50`;
        const response: IGetProjectCandidatesResponse = yield call(
            new API().post,
            url,
            requestPayload
        );
        if (isEmpty(response?.data)) return;

        yield put(setCandidates(response.data.candidates));
        yield put(setTotalCandidates(response.data.totalCandidates));

        const candidateIds = response.data.candidates.map((item) => item._id);
        yield put(
            setSelectedCandidates({
                candidateId: candidateIds,
                pageNo: 1,
            })
        );
    } catch (err: any) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectCandidatesSaga({
    payload,
}: {
    payload: IActionPayload & IGetProjectPayload;
}): SagaIterator {
    const cancelTokenSource = axios.CancelToken.source();
    try {
        yield put(startAction({ action: payload.action }));

        const start = payload.start ? payload.start - 1 : 0;
        const limit = payload.limit ?? 5;

        const state = yield select();
        const showByMaxExperienceStateValue = get(
            state,
            "project.showByMaxExperience"
        );
        const showByMinExperienceStateValue = get(
            state,
            "project.showByMinExperience"
        );
        const showByPersonalEmailsStateValue = get(
            state,
            "project.showByPersonalEmails"
        );
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .map((key: string) => {
                if (projectFiltersStateValue[key as IProjectStage]) {
                    return key;
                }
                return null;
            })
            .filter((item) => item);

        let filterByPayload: (string | null)[] = [];
        if (showByPersonalEmailsStateValue) filterByPayload = ["personalEmail"];
        if (appliedFilters.length)
            filterByPayload = [...filterByPayload, ...appliedFilters];

        const requestPayload: IProjectFiltersRequestPayload = {
            filterBy: filterByPayload,
        };

        if (showByMinExperienceStateValue) requestPayload.sortBy = "minExp";
        if (showByMaxExperienceStateValue) requestPayload.sortBy = "maxExp";

        const response: IGetProjectCandidatesResponse = yield call(
            new API().post,
            `/v2/project/get/${payload.projectId}/candidates?start=${start}&limit=${limit}`,
            requestPayload,
            {
                cancelToken: cancelTokenSource.token,
            }
        );
        if (isEmpty(response?.data)) return;

        yield put(setCandidates(response.data.candidates));
        yield put(setTotalCandidates(response.data.totalCandidates));

        const candidateIds = response.data.candidates.map((item) => item._id);
        yield put(
            setSelectedCandidates({
                candidateId: [...candidateIds],
                pageNo: start + 1,
                canSelectAll:
                    isEmpty(requestPayload.filterBy) && !requestPayload.sortBy,
            })
        );
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
        if (yield cancelled()) {
            cancelTokenSource.cancel();
        }
    }
}

function* getWorkflowEmailStatsSaga({
    payload,
}: {
    payload: IActionPayload & { workflowId: string; projectId: number };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let apiUrl;
        if (payload.workflowId !== "all") {
            apiUrl = `/workflow/${payload.workflowId}/stats/emails`;
        } else
            apiUrl = `/workflow/${payload.projectId}/${payload.workflowId}/stats/emails`;

        const response = yield call(new API().get, apiUrl);
        yield put(setWorkflowEmailStats(response.data));
    } catch (err) {
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getWorkflowLinkedInStatsSaga({
    payload,
}: {
    payload: IActionPayload & { workflowId: string; projectId: number };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let apiUrl;
        if (payload.workflowId !== "all") {
            apiUrl = `/workflow/${payload.workflowId}/stats/linkedIn`;
        } else
            apiUrl = `/workflow/${payload.projectId}/${payload.workflowId}/stats/linkedIn`;

        const response = yield call(new API().get, apiUrl);
        yield put(setWorkflowLinkedInStats(response.data));
    } catch (err) {
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(copyToProject.type, copyToProjectSaga),
        // @ts-ignore
        yield takeLatest(moveTo.type, moveToSaga),
        // @ts-ignore
        yield takeLatest(addToWorkflow.type, addToWorkflowSaga),
        // @ts-ignore
        yield takeLatest(getProjectStats.type, getProjectStatsSaga),
        // @ts-ignore
        yield takeEvery(getCandidateEmail.type, getCandidateEmailSaga),
        // @ts-ignore
        yield takeLatest(getProjectWorkflows.type, getProjectWorkflowsSaga),
        // @ts-ignore
        yield takeLatest(getShowByRecent.type, getShowByRecentSaga),
        // @ts-ignore
        yield takeLatest(getShowByRelevance.type, getShowByProjectFiltersSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByProjectFilters.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByMaxExperience.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByMinExperience.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getShowByPersonalEmails.type,
            getShowByProjectFiltersSaga
        ),
        // @ts-ignore
        yield takeLatest(getProjectCandidates.type, getProjectCandidatesSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowEmailStats.type, getWorkflowEmailStatsSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getWorkflowLinkedInStats.type,
            getWorkflowLinkedInStatsSaga
        ),
    ];
    // @ts-ignore
    yield takeLatest(leaveProject.type, CancelSagas, tasks);
}
