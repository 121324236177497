import { useEffect } from "react";
import { Table as AntTable, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";

import useWindowSize from "../../../../hooks/useWindowSize";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    fetchInvitations,
    selectInvitations,
} from "../../manageTeams.reducers";
import { IInvitationsTable } from "../../manageTeams.types";
import style from "./invitationsTable.module.scss";

export default function InvitationsTable(props: any) {
    const invitations = useSelector(selectInvitations);
    const isFetchingInvitations = useSelector(
        checkIfLoading(fetchInvitations.type)
    );
    const dispatch = useDispatch();
    const size = useWindowSize();
    const overflowTableHeight = size.height - 404;

    useEffect(() => {
        dispatch(fetchInvitations({ action: fetchInvitations.type }));
    }, []);

    const handleRoleChange = (index: number, newValue: string) => {
        // let tmpData = Array.from(tableDate);
        // invitations[index].role = newValue;
        // setTableDate(tmpData);
    };

    const columns: ColumnsType<IInvitationsTable> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (text, record, index) => {
                return (
                    <Select
                        bordered={false}
                        className={style["table__select"]}
                        placeholder="Select Role"
                        value={invitations[index].role.toLocaleLowerCase()}
                        onChange={(e: any) => handleRoleChange(index, e)}
                        options={[
                            { value: "admin", label: "Admin" },
                            { value: "member", label: "Member" },
                        ]}
                        disabled={true}
                    />
                );
            },
        },
        // {
        //   title: 'Action',
        //   dataIndex: 'createdAt',
        //   key: 'createdAt',
        //   render: (text, record, i) => {
        //     let modify = members[i].role
        //     return (
        //       <Button label={modify ? "Remove" : "Modify"} variant={modify ? "secondary" : "primary"} />
        //     )
        //   }
        // }
    ];

    return (
        <AntTable
            loading={isFetchingInvitations}
            columns={columns}
            dataSource={invitations.filter(
                ({ status }) => status === "PENDING"
            )}
            scroll={{ y: overflowTableHeight }}
            pagination={false}
        />
    );
}
