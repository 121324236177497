import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Stack, Switch } from "@mui/material";
import { Button, Checkbox, Divider, Dropdown, MenuProps, Space } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "dayjs";
import { EventSourcePolyfill } from "event-source-polyfill";
import firebase from "firebase/compat/app";
import { isArray, isEmpty, isString, sample } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import { arrowDownIcon } from "../../constant";
import { setSSECandidate } from "../../container/AppContainer/AppContainer.reducer";
import store from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import API, { apiBase } from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import {
    getProject,
    removeCandidateFromProject,
    selectAllProjects,
    selectCurrProject,
    sendEmail,
} from "../allProjects/index.reducer";
import MsgModal from "./components/MsgModal";
import AddToWorkflow from "./components/addToWorkflow/addToWorkflow";
import ATSIntegration from "./components/atsIntegration/atsIntegration";
import ProjectCandidates from "./components/candidates/candidates";
import CreditRewardModal from "./components/creditsRewardModal";
import DeleteModal from "./components/deleteModal";
import ProjectHeader from "./components/header";
import ProjectStats from "./components/projectStats";
import SelectWorkflowModal from "./components/selectWorkflowModal";
import ShowAllEmails from "./components/showAllEmails/showAllEmails";
import SortCandidates from "./components/sortCandidates";
import style from "./index.module.scss";
import {
    addToWorkflow,
    copyToProject,
    getShowByPersonalEmails,
    getShowByProjectFilters,
    selectCandidateCurrentPage,
    selectProjectAllCandidates,
    selectProjectCandidates,
    selectProjectFilters,
    selectProjectTotalCandidates,
    selectSelectedCandidates,
    selectShowByPersonalEmails,
    setSelectAllCandidates,
    setSelectedCandidates,
    setShowByPersonalEmails,
} from "./index.reducer";
import { IProjectStage, IProjectView, IStageFilters } from "./project.types";

type Props = {
    setFilters: (filters: IStageFilters) => void;
    open: boolean;
    handleClose: () => void;
    handleToggle: () => void;
};

const initialFilters = {
    PIPELINE: false,
    CONTACTED: false,
    RESPONDED: false,
    SHORTLISTED: false,
    REJECTED: false,
    NOT_INTERESTED: false,
    ON_HOLD: false,
};

const useOutsideClick = <T extends HTMLElement>(
    callback: () => void
): MutableRefObject<T | null> => {
    const ref = useRef<T>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [callback]);

    return ref;
};

function FilterDropdown(props: Props) {
    const { setFilters, open, handleClose, handleToggle } = props;
    const showByProjectFilters = useSelector(selectProjectFilters);
    const [projectFilters, setProjectFilters] = useState(showByProjectFilters);
    const isEveryFilterIsUnChecked = Object.values(projectFilters).every(
        (filter) => !filter
    );
    const ref = useOutsideClick<HTMLDivElement>(() => {
        handleClose();
        setProjectFilters(showByProjectFilters);
    });

    useEffect(() => {
        if (isEveryFilterIsUnChecked) {
            setFilters(initialFilters);
        }
    }, [isEveryFilterIsUnChecked]);

    const handleCheckBoxChange = (e: CheckboxChangeEvent) => {
        e.stopPropagation();
        const value = e.target.checked;
        setProjectFilters((prev) => ({
            ...prev,
            [e.target.id as string]: value,
        }));
    };

    const getElement = (text: string, id: IProjectStage) => {
        return (
            <div
                className={style["sp__link"]}
                onClick={(e) => e.stopPropagation()}
            >
                <Checkbox
                    id={id}
                    checked={projectFilters[id]}
                    onChange={handleCheckBoxChange}
                >
                    {text}
                </Checkbox>
            </div>
        );
    };

    const filterDropDown: MenuProps["items"] = [
        {
            label: getElement("In Pipeline", "PIPELINE"),
            key: "0",
        },
        {
            label: getElement("Contacted", "CONTACTED"),
            key: "1",
        },
        {
            label: getElement("Responded", "RESPONDED"),
            key: "2",
        },
        {
            label: getElement("Shortlisted", "SHORTLISTED"),
            key: "3",
        },
        {
            label: getElement("Rejected", "REJECTED"),
            key: "4",
        },
        {
            label: getElement("Not interested", "NOT_INTERESTED"),
            key: "5",
        },
        {
            label: getElement("Keep on hold", "ON_HOLD"),
            key: "6",
        },
    ];

    const handleFiltersApply = () => {
        setFilters(projectFilters);
        handleClose();
    };

    return (
        <div ref={ref}>
            <Dropdown
                open={open}
                trigger={["click"]}
                menu={{
                    items: filterDropDown,
                }}
                dropdownRender={(menu) => (
                    <Stack
                        alignItems="center"
                        // ref={ref}
                        sx={{
                            backgroundColor: "#fff",
                            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.1)",
                            borderRadius: "1rem",
                        }}
                        px={0.5}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {React.cloneElement(menu as React.ReactElement, {
                            style: {
                                boxShadow: "none",
                                borderRadius: "0",
                            },
                        })}
                        <Divider style={{ margin: 0 }} />
                        <Space style={{ padding: 8 }}>
                            <Button type="primary" onClick={handleFiltersApply}>
                                Apply
                            </Button>
                        </Space>
                    </Stack>
                )}
            >
                <div
                    className={`${style["sp__actionItem"]} ${style["sp__actionItem-filter"]}`}
                    onClick={handleToggle}
                >
                    Filter{" "}
                    <img
                        className={style["sp__actionItem-img"]}
                        src={arrowDownIcon}
                        alt=""
                    />
                </div>
            </Dropdown>
        </div>
    );
}

export default function Project() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const projectData = useSelector(selectCurrProject);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const candidates = useSelector(selectProjectCandidates);
    const showByEmailId = useSelector(selectShowByPersonalEmails);
    const allProjectsList = useSelector(selectAllProjects);
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const candidatePageNo = useSelector(selectCandidateCurrentPage);
    const selectAllCandidates = useSelector(selectProjectAllCandidates);
    const totalCandidates = useSelector(selectProjectTotalCandidates);
    const [showFilters, setShowFilters] = useState(false);

    const handleToggleDropdown = () => setShowFilters((prev) => !prev);
    const handleCloseDropdown = () => setShowFilters(false);

    const selectCount = selectedCandidates.length;
    const candidateIds = candidates.map((item) => item._id);
    const selectedCandidateIds = selectedCandidates.map(
        (item) => item.candidateId
    );
    const pageSelectCount = candidateIds.filter((item) =>
        selectedCandidateIds.includes(item)
    ).length;
    const detailedCandidates = candidates.filter((candidate) =>
        selectedCandidateIds.includes(candidate._id)
    );
    const hasNoEmailEvery = detailedCandidates.every(
        (candidate) =>
            isArray(candidate.email) &&
            !candidate.email.length &&
            isArray(candidate.professional_email) &&
            !candidate.professional_email.length
    );
    const hasEmailEvery = detailedCandidates.every(
        (candidate) =>
            (isArray(candidate.email) && candidate.email.length) ||
            (isArray(candidate.professional_email) &&
                candidate.professional_email.length)
    );
    const selectAllCheck =
        !!candidateIds.length &&
        candidateIds.every((item) => {
            const value =
                selectedCandidateIds.length &&
                selectedCandidateIds.includes(item)
                    ? true
                    : false;
            return value;
        });
    const isSSEOngoing =
        projectData?.projectStatus === "IN_PROGRESS" ||
        projectData?.projectStatus === "PENDING";

    const getUserFromLS = new API().getLocalStorage("user");
    const user = getUserFromLS ? JSON.parse(getUserFromLS) : null;

    const copyDropDown = allProjectsList?.map((item: any) => {
        return {
            label: (
                <p
                    className={style["sp__link"]}
                    onClick={() => handleCopyTo(item._id)}
                >
                    {item.name}
                </p>
            ),
            key: `${item._id}`,
        };
    });

    const [searchText, setSearchText] = useState<string>("");
    const [isSlectWkModalOpen, setIsSlectWkModalOpen] =
        useState<boolean>(false);
    const [isMsgModalOpen, setIsMsgModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [wkTemp, setWkTemp] = useState();
    const [wkDate, setWkDate] = useState("");
    const [msgSubject, setMsgSubject] = useState("");
    const [msgValue, setMsgValue] = useState("");
    const [activeView, setActiveView] = useState<IProjectView>("list");

    const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const showSlectWkModal = () => {
        handleCleverTap("Add to workflow", {
            candidates: selectedCandidateIds?.length,
            projectId: params?.id,
            partialEmails: !hasEmailEvery && !hasNoEmailEvery,
        });

        if (!selectedCandidates.length) {
            dispatch(setErrorNotification("Please select candidates"));
            return;
        }

        navigate("/workflows", {
            state: {
                candidates: selectedCandidateIds,
                projectId: params.id,
            },
        });
    };
    const closeSlectWkModal = () => setIsSlectWkModalOpen(false);

    useEffect(() => {
        if (!user?._id) return;

        let source: any;
        (async () => {
            const auth = firebase.auth();
            const webToken = await auth.currentUser?.getIdToken(true);
            const cookie = document.cookie.split("; ");
            const accessTokenString =
                cookie
                    .find((item) => item.indexOf("accessToken") > -1)
                    ?.split("=") || [];
            const token = accessTokenString[1];

            if (!webToken && !token) return;

            const url = `${apiBase}/auth/user/${user?._id}/events`;
            const headers = {
                appType: "extension",
                version: "1.0",
                timezone: "-330",
                "x-authorization": token,
                "x-webAuthorization": webToken,
            };

            source = new EventSourcePolyfill(url, {
                headers,
                heartbeatTimeout: 300000,
            });
            source.onerror = () => source.close();

            source.addEventListener(user._id, (e: any) => {
                const data = isString(e.data) ? JSON.parse(e.data) : e.data;
                if (isEmpty(data)) return;
                store.dispatch(setSSECandidate(data));
            });
        })();

        return () => {
            if (source) source.close();
        };
    }, [user]);

    const showMsgModal = () => {
        handleCleverTap("Send Email", {
            "Project id": params?.id,
            emailAuthorized: !!user?.emailAuthorized,
        });

        if (!user.emailAuthorized) {
            dispatch(setErrorNotification("Please authorize your email first"));
            return;
        }

        if (!hasEmailEvery && !hasNoEmailEvery) {
            dispatch(
                setErrorNotification(
                    "Only candidates with Email IDs will receive emails"
                )
            );
        }

        setIsMsgModalOpen(true);
    };
    const closeMsgModal = () => setIsMsgModalOpen(false);

    const onToggleShowByEmail = (checked: boolean) => {
        dispatch(
            getShowByPersonalEmails({
                projectId: params.id,
                showByPersonalEmails: checked,
                action: getShowByPersonalEmails.type,
            })
        );
    };

    useEffect(() => {
        dispatch(getProject({ projectId: params.id, action: getProject.type }));
        window.history.replaceState({}, document.title);

        return () => {
            dispatch(setShowByPersonalEmails(false));
            dispatch(
                setSelectedCandidates({
                    candidateId: [],
                    deselectAll: candidatePageNo,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSendMsg = () => {
        if (!selectCount) return;

        handleCleverTap("Confirm send email", {
            "Project id": params?.id,
            candidates: selectedCandidateIds?.toString(),
        });

        dispatch(
            sendEmail({
                candidates: selectedCandidateIds,
                emailRecipients: [],
                subject: msgSubject,
                body: msgValue,
                projectId: Number(params.id),
                action: sendEmail.type,
                onSuccess: closeMsgModal,
            })
        );
    };

    const handleSetFilters = (projectFilters: IStageFilters) => {
        dispatch(
            getShowByProjectFilters({
                projectId: params.id,
                projectFilters,
                action: getShowByProjectFilters.type,
            })
        );
    };
    const handleCopyTo = (id: string) => {
        if (!selectCount) return;

        const payload = {
            projectId: id,
            candidateIds: selectedCandidateIds,
        };
        dispatch(copyToProject(payload));
    };

    const handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            handleCleverTap("Select All candidates", {
                "Project id": params?.id,
                candidates: candidateIds?.length,
            });

            dispatch(
                setSelectedCandidates({
                    candidateId: [...candidateIds],
                    pageNo: candidatePageNo,
                })
            );
            dispatch(setSelectAllCandidates(true));
        } else {
            dispatch(setSelectAllCandidates(false));
        }
    };

    const handleAddToWk = () => {
        if (!wkTemp || !params.id) return;

        dispatch(
            addToWorkflow({
                workflowTemplateId: wkTemp,
                scheduledFor: dayjs(wkDate).toISOString(),
                project: Number(params.id),
                candidate: selectedCandidateIds,
                action: addToWorkflow.type,
            })
        );
        closeSlectWkModal();
    };

    const handleRemoveCandidate = () => {
        if (!selectCount || !params.id) return;

        let payload = {
            projectId: params.id,
            candidateIds: selectedCandidateIds,
        };

        dispatch(removeCandidateFromProject(payload));
        closeDeleteModal();
    };

    const handleSelectAllCandidates = () => {
        dispatch(setSelectAllCandidates(!selectAllCandidates));
    };

    const handleListView = () => setActiveView("list");

    const handleGridView = () => {
        const localView = new API().getLocalStorage("gridView");
        if (localView) {
            setActiveView(localView as IProjectView);
            return;
        }

        const sampleArrEle = sample(["A", "B"]);
        const gridView = `grid${sampleArrEle}` as IProjectView;
        new API().setLocalStorage({ key: "gridView", value: gridView });
        setActiveView(gridView);
    };

    const onClickRefreshIcon = () => {
        dispatch(
            getProject({
                projectId: params.id,
                action: getProject.type,
            })
        );
    };

    if (isProjectLoading) return <Loader />;
    return (
        <div className={style["sp"]}>
            <div className={style["sp__container"]}>
                <Navbar
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value as string)}
                />
                <ProjectHeader
                    activeView={activeView}
                    handleListView={handleListView}
                    handleGridView={handleGridView}
                    onClickRefreshIcon={onClickRefreshIcon}
                />
                <div className={style["sp__actionbar"]}>
                    <ProjectStats />
                    <FilterDropdown
                        setFilters={handleSetFilters}
                        open={showFilters}
                        handleClose={handleCloseDropdown}
                        handleToggle={handleToggleDropdown}
                    />
                    {/* {projectData?.projectStatus === "IN_PROGRESS" ||

                    projectData?.projectStatus === "PENDING" ? null : (
                        <Link
                            to={`/projects/${params.id}/statistics`}
                            className={style["sp__statistics"]}
                        >
                            View Statistics
                        </Link>
                    )} */}
                </div>
                <div className={style["sp__body"]}>
                    <div className={style["sp__bodyAction"]}>
                        <Checkbox
                            checked={selectAllCheck}
                            onChange={handleSelectAll}
                            className={style["sp__checkbox"]}
                        >
                            {selectAllCandidates
                                ? `${totalCandidates} results`
                                : selectCount
                                ? `${selectCount} results`
                                : "Select All"}
                        </Checkbox>
                        <div className={style["sp__bodyActionList"]}>
                            <div className={style["sp__bodyActionCtas"]}>
                                <AddToWorkflow handleClick={showSlectWkModal} />
                                <ATSIntegration />
                            </div>
                            <div
                                className={style["sp__bodyActionItem-wrapper"]}
                            >
                                {/* {!isSSEOngoing && activeView === "list" ? (
                                    <ShowAllEmails />
                                ) : null} */}
                                <div
                                    className={style["sp__bodyActionItem"]}
                                    id="personalEmailToggle"
                                >
                                    Email IDs
                                    <Switch
                                        checked={showByEmailId}
                                        onChange={(e) =>
                                            onToggleShowByEmail(
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                                {/* <div
                                    className={`${
                                        style["sp__bodyActionItem-wrapper"]
                                    } ${
                                        selectCount ? "" : style["sp__disabled"]
                                    }`}
                                >
                                    <IconButton
                                        disabled={hasNoEmailEvery}
                                        onClick={showMsgModal}
                                        sx={{
                                            padding: "6px",
                                        }}
                                    >
                                        <Tooltip title="Send email">
                                            <img
                                                src={profileIcon}
                                                alt="send-email"
                                                width={28}
                                                height={28}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    <Dropdown
                                        trigger={["click"]}
                                        menu={{ items: copyDropDown }}
                                        disabled={!selectCount}
                                    >
                                        <Tooltip title="Copy to project">
                                            <div
                                                className={
                                                    style["sp__bodyActionItem"]
                                                }
                                            >
                                                <img
                                                    src={cloneIcon}
                                                    alt="copy-candidates"
                                                />
                                            </div>
                                        </Tooltip>
                                    </Dropdown>
                                    <Tooltip title="Delete candidate">
                                        <div
                                            className={
                                                style["sp__bodyActionItem"]
                                            }
                                            onClick={showDeleteModal}
                                        >
                                            <img
                                                src={trashGrayIcon}
                                                alt="delete-candidates"
                                            />
                                        </div>
                                    </Tooltip>
                                </div> */}
                            </div>
                        </div>
                        <SortCandidates />
                    </div>
                    {/* {selectAllCheck ? (
                        <div className={style["sp__paginationPrompt"]}>
                            <p className={style["sp__paginationPrompt-text"]}>
                                All{" "}
                                <b>
                                    {selectAllCandidates
                                        ? `${totalCandidates} candidates in this project`
                                        : `${pageSelectCount} candidates on this page`}
                                </b>{" "}
                                are selected.
                            </p>
                            <button
                                className={style["sp__paginationPrompt-btn"]}
                                onClick={handleSelectAllCandidates}
                            >
                                {selectAllCandidates
                                    ? "Clear selection"
                                    : "Select all candidates in this project"}
                            </button>
                        </div>
                    ) : null} */}
                    <ProjectCandidates
                        activeView={activeView}
                        searchText={searchText}
                        selectAllCheck={selectAllCheck}
                    />
                </div>
                <Modal
                    open={isSlectWkModalOpen}
                    onOk={closeSlectWkModal}
                    onCancel={closeSlectWkModal}
                    title={"Add to Workflow"}
                    width={"400px"}
                >
                    <SelectWorkflowModal
                        closeModal={closeSlectWkModal}
                        wkDate={wkDate}
                        setWkDate={setWkDate}
                        wkTemp={wkTemp}
                        setWkTemp={setWkTemp}
                        handleAddToWk={handleAddToWk}
                    />
                </Modal>
                <Modal
                    open={isMsgModalOpen}
                    onOk={closeMsgModal}
                    onCancel={closeMsgModal}
                    title={"Send Message"}
                    width={"70%"}
                >
                    <MsgModal
                        handleSendMsg={handleSendMsg}
                        msgValue={msgValue}
                        setMsgValue={setMsgValue}
                        msgSubject={msgSubject}
                        setMsgSubject={setMsgSubject}
                    />
                </Modal>
                <DeleteModal
                    title="Are you sure you want to delete?"
                    open={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    onSubmit={handleRemoveCandidate}
                />
            </div>
            <CreditRewardModal />
        </div>
    );
}
