import { isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { sampleRecommendationTemplates } from "../../constant";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    createNewTemplate,
    editTemplate,
    getChatGptTemplates,
    getTemplateList,
    leaveTemplate,
    setChatGptTemplates,
    setTemplateList,
} from "./index.reducer";

function* getTemplateListSaga(): SagaIterator {
    try {
        const response = yield call(new API().get, "/template/get");
        if (isEmpty(response?.data)) return;

        yield put(setTemplateList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* createNewTemplateSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            new API().post,
            "/template/create/",
            payload
        );
        if (!response) return;
        yield put(getTemplateList());
        yield put(setSuccessNotification("Template created successfully"));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* editTemplateSaga({ payload }: { payload: any }): SagaIterator {
    let tmp = { ...payload };
    delete tmp._id;
    delete tmp.userId;
    delete tmp.updatedAt;
    delete tmp.createdAt;
    delete tmp.__v;
    delete tmp.action;

    try {
        const response = yield call(
            new API().put,
            "/template/edit/" + payload._id,
            tmp
        );
        if (isEmpty(response)) return;

        yield put(getTemplateList());
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getChatGptTemplatesSaga({
    payload,
}: {
    payload: { projectId: string; type: string } & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let response;
        if (payload.projectId === "9999") {
            response = {
                data: sampleRecommendationTemplates,
                message: "Templates fetched successfully",
            };
        } else {
            const queryParams = payload.projectId
                ? `&projectId=${payload.projectId}`
                : "";
            const url = `/v2/chat-gpt-templates/get?type=${payload.type}${queryParams}`;
            response = yield call(new API().get, url);
        }

        const responseKey =
            payload.type === "email"
                ? "email"
                : payload.type === "inmail"
                ? "inmail"
                : "note";

        if (isEmpty(response.data) || isEmpty(response.data[responseKey]))
            return;

        yield put(setChatGptTemplates(response.data[responseKey]));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(createNewTemplate.type, createNewTemplateSaga),
        // @ts-ignore
        yield takeLatest(getTemplateList.type, getTemplateListSaga),
        // @ts-ignore
        yield takeLatest(editTemplate.type, editTemplateSaga),
        // @ts-ignore
        yield takeLatest(getChatGptTemplates.type, getChatGptTemplatesSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveTemplate.type, CancelSagas, tasks);
}
