import { useEffect, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { pencilIcon } from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    authLogin,
    selectUser,
    updateUser,
} from "../../../Signin/Signin.reducer";
import { orgMember } from "../../../Signin/Signin.types";
import styles from "./index.module.scss";

export default function MyDetails() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const outboundEmail = user?.authorizedEmailAddress
        ? user.authorizedEmailAddress
        : null;
    const isUserFetching = useSelector(checkIfLoading(authLogin.type));
    const isLoading = useSelector(checkIfLoading(updateUser.type));

    const [name, setName] = useState<string | null>(null);
    const [workspace, setWorkspace] = useState<string | null>(null);
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [companyLink, setCompanyLink] = useState<string | null>(null);

    const [canEdit, setCanEdit] = useState({
        name: false,
        workspace: false,
        companyName: false,
        companyLink: false,
    });

    const members = user.orgData?.members;
    const member = members?.find((item: orgMember) => item.userId === user._id);
    const isNameChanged = user.displayName !== name;
    const isWorkspaceChanged = user.orgData?.name !== workspace;
    const isCompanyNameChanged = user.companyName !== companyName;
    const isCompanyLinkChanged = user.companyLink !== companyLink;

    const canDisableButtons =
        (!isNameChanged &&
            !isWorkspaceChanged &&
            !isCompanyNameChanged &&
            !isCompanyLinkChanged) ||
        isUserFetching ||
        isLoading;

    useEffect(() => {
        if (!isEmpty(user)) {
            resetFields();
        }
    }, [user]);

    const resetFields = () => {
        const { displayName, orgData, companyName, companyLink } = user;
        setWorkspace(orgData?.name ?? null);
        setName(displayName ?? null);
        setCompanyName(companyName ?? null);
        setCompanyLink(companyLink ?? null);
    };

    const disableCanEditFields = () => {
        setCanEdit({
            name: false,
            workspace: false,
            companyName: false,
            companyLink: false,
        });
    };

    const onCancel = () => {
        disableCanEditFields();
        resetFields();
    };

    const onChangeDetails = () => {
        disableCanEditFields();
        dispatch(
            updateUser({
                name,
                orgName: workspace,
                companyName,
                companyLink,
                action: updateUser.type,
            })
        );
    };

    const renderFieldValue = (
        field: string,
        value: string | null,
        canEditField: boolean
    ) => {
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            switch (field) {
                case "name":
                    setName(newValue);
                    break;
                case "workspace":
                    setWorkspace(newValue);
                    break;
                case "companyName":
                    setCompanyName(newValue);
                    break;
                case "companyLink":
                    setCompanyLink(newValue);
                    break;
                default:
                    break;
            }
        };

        const handleEditClick = () => {
            setCanEdit({
                ...canEdit,
                [field]: true,
            });
        };

        if (isUserFetching)
            return (
                <span className={styles.fieldValue}>
                    <CircularProgress size={16} />
                </span>
            );
        return canEditField ? (
            <input
                className={styles.fieldValue}
                value={value ?? ""}
                onChange={handleInputChange}
            />
        ) : (
            <span className={styles.fieldValue}>
                {value}
                <img
                    src={pencilIcon}
                    alt="pencil-icon"
                    className={styles.pencilImg}
                    onClick={handleEditClick}
                />
            </span>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.profileContainer}>
                <div className={styles.fieldContainer}>
                    <span className={styles.field}>Name</span>
                    <span className={styles.field}>Email ID</span>
                    {outboundEmail && (
                        <span className={styles.field}>Outbound Email</span>
                    )}
                    <span className={styles.field}>Workspace Name</span>
                    <span className={styles.field}>Company Name</span>
                    <span className={styles.field}>Company URL</span>
                    <span className={styles.field}>Role</span>
                </div>
                <div className={styles.fieldValueContainer}>
                    {renderFieldValue("name", name, canEdit.name)}
                    <span className={styles.fieldValue}>
                        {isUserFetching ? (
                            <CircularProgress size={16} />
                        ) : (
                            user.email
                        )}
                    </span>
                    {outboundEmail && (
                        <span className={styles.fieldValue}>
                            {outboundEmail}
                        </span>
                    )}
                    {renderFieldValue(
                        "workspace",
                        workspace,
                        canEdit.workspace
                    )}
                    {renderFieldValue(
                        "companyName",
                        companyName,
                        canEdit.companyName
                    )}
                    {renderFieldValue(
                        "companyLink",
                        companyLink,
                        canEdit.companyLink
                    )}
                    <span className={styles.fieldValue}>
                        {isUserFetching ? (
                            <CircularProgress size={16} />
                        ) : (
                            member?.role
                        )}
                    </span>
                </div>
            </div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                mt={2}
            >
                <Button
                    variant="contained"
                    disabled={canDisableButtons}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={canDisableButtons}
                    onClick={onChangeDetails}
                >
                    <ButtonTextWithLoading
                        isLoading={isLoading}
                        text="Save changes"
                    />
                </Button>
            </Stack>
        </div>
    );
}
