import { INudgeVariant } from "./Nudges";
import styles from "./Nudges.module.scss";

const getArrowStyles = (variant: INudgeVariant) => {
    switch (variant) {
        case "top": {
            return {
                transform: "rotate(180deg)",
            };
        }
        case "left": {
            return {
                transform: "rotate(90deg)",
            };
        }
        case "right": {
            return {
                transform: "rotate(270deg)",
            };
        }
    }
};

export default function NudgeArrow({
    variant,
    style,
}: {
    variant: INudgeVariant;
    style?: React.CSSProperties;
}) {
    return (
        <div
            className={styles["arrow"]}
            style={{
                ...getArrowStyles(variant),
                ...style,
            }}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}
