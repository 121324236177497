import { useDispatch } from "react-redux";

import style from "./index.module.scss";
import { setAuthorizeModal } from "./mailAlert.reducers";

export default function MailAlert() {
    const dispatch = useDispatch();

    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };

    return (
        <div className={style["app__mailalert"]}>
            <p className={style["app__mailalert-text"]}>
                Your email for outreach needs to be verified. Click on profile
                icon or verify from{" "}
                <span onClick={showAuthorizeModal}>here</span>
            </p>
        </div>
    );
}
