import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import slack from "../../assets/img/slack.svg";
import { setShowModal } from "../../store/reducers/slackSend.reducer";
import API from "../../utils/API";
import handleException from "../../utils/sentry";
import styles from "./SlackConnect.module.scss";

type States = "IDLE" | "LOADING" | "SUCCESS" | "ERROR";

const SECONDS = 4;

export default function SlackConnect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const counterRef = useRef<NodeJS.Timer>();

    const [status, setStatus] = useState<States>("IDLE");
    const [count, setCount] = useState(SECONDS);

    useEffect(() => {
        (async () => {
            setStatus("LOADING");
            try {
                const error = searchParams.get("error");
                const errDesc = searchParams.get("error_description") || "";
                if (error) throw new Error(errDesc);

                const code = searchParams.get("code");
                await new API().post("/slack/auth/redirect", {
                    code,
                });
                dispatch(setShowModal(true));
                setStatus("SUCCESS");
            } catch (error) {
                console.error(error);
                handleException(error);
                setStatus("ERROR");
            }
        })();
    }, [searchParams]);

    useEffect(() => {
        if (count === 0) {
            clearInterval(counterRef.current);
            const url = status === "ERROR" ? "/integrations" : "/teams";
            navigate(url);
            return;
        }

        if (count === SECONDS && (status === "SUCCESS" || status === "ERROR")) {
            counterRef.current = setInterval(() => {
                setCount((prev) => prev - 1);
            }, 1000);
        }
    }, [count, status]);

    return (
        <>
            <Stack
                style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: status === "ERROR" ? "#3F0E40" : "#008095",
                }}
                justifyContent="center"
                alignItems="center"
            >
                {status === "LOADING" ? (
                    <div className={styles["spinner"]}></div>
                ) : (
                    <Stack alignItems="center" spacing={2}>
                        <img
                            src={slack}
                            width={160}
                            height={160}
                            alt="slack-logo"
                        />
                        <Typography
                            style={{ color: "white" }}
                            variant="h4"
                            fontWeight={700}
                        >
                            {status === "ERROR"
                                ? "Error while connecting to slack"
                                : "Slack connect successful"}
                        </Typography>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight={600}
                            style={{ color: "white" }}
                        >
                            Redirecting in {count + 1}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </>
    );
}
