import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import {
    selectEnableConnectionReq,
    setEnableConnectionReq,
} from "../../pages/template/index.reducer";
import InfoButton, { InfoMessage, WorkflowInfo } from "../SetupFlow/InfoButton";

const connectionReqMessages: InfoMessage[] = [
    {
        id: nanoid(),
        answer: "Disable this to skip sending a connection request during your outreach.",
    },
];

export default function DisableConnectionRequestSwitch() {
    const dispatch = useDispatch();
    const enableConnectionRequest = useSelector(selectEnableConnectionReq);

    return (
        <Stack direction="row">
            <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                    <Switch
                        checked={enableConnectionRequest}
                        onChange={(e) => {
                            dispatch(setEnableConnectionReq(e.target.checked));
                        }}
                    />
                }
                label={enableConnectionRequest ? "Disable" : "Enable"}
            />
            <InfoButton>
                <WorkflowInfo messages={connectionReqMessages} />
            </InfoButton>
        </Stack>
    );
}
