import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Input, Select, Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";
import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Button from "../../../../components/Button";
import { selectOpenTriggerModal } from "../../../../container/TourContainer/TourContainer.reducer";
import {
    fetchProjects,
    getWorkflowList,
} from "../../../workflow/index.reducer";
import style from "./index.module.scss";

interface propsType {
    closeModal: () => void;
    handleAddToWk: () => void;
    wkDate: any;
    setWkDate: any;
    wkTemp: any;
    setWkTemp: any;
    triggerWorkflow?: boolean;
    isNameEditable?: boolean;
    saveWk?: boolean;
    onChangeSaveWk?: SwitchChangeEventHandler | undefined;
}

export default function SelectWorkflowModal(props: propsType) {
    const {
        closeModal,
        wkDate,
        setWkDate,
        wkTemp,
        setWkTemp,
        handleAddToWk,
        triggerWorkflow,
        isNameEditable,
        saveWk,
        onChangeSaveWk,
    } = props;
    const [wkDropDown, setWkDropDown] = useState<
        { value: string; label: string }[]
    >([]);

    const dispatch = useDispatch();
    const location = useLocation();
    const workflowList: any = useSelector((state) =>
        get(state, "workflow.workflowList")
    );
    const openTriggerModal = useSelector(selectOpenTriggerModal);

    useEffect(() => {
        dispatch(getWorkflowList());
        dispatch(fetchProjects({ action: fetchProjects.type }));
    }, []);

    useEffect(() => {
        if (isEmpty(workflowList)) return;

        let tmp = [];
        for (let val of workflowList) {
            tmp.push({ label: val.name, value: val._id });
        }
        setWkDropDown(tmp);
    }, [workflowList]);

    return (
        <div className={style["modal"]}>
            <Stack spacing={3}>
                <div className={style["modal__selectBox"]}>
                    {triggerWorkflow ? (
                        <>
                            <label
                                className={style["modal__workflowName-label"]}
                            >
                                Workflow Name<span>*</span>
                            </label>
                            <Input
                                className={style["modal__workflowName"]}
                                type={"text"}
                                value={wkTemp}
                                onChange={(e) =>
                                    setWkTemp(e.target.value as string)
                                }
                                disabled={isNameEditable}
                            />
                        </>
                    ) : (
                        <Select
                            bordered={false}
                            className={style["modal__select"]}
                            placeholder="Select Workflow"
                            value={wkTemp}
                            options={wkDropDown}
                            onChange={(value: string) => setWkTemp(value)}
                        />
                    )}
                </div>

                {location.state?.candidates || openTriggerModal ? (
                    <div className={style["modal__dateBox"]}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Date&Time picker"
                                value={wkDate}
                                onChange={setWkDate}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                ) : null}

                <div className={style["modal__btnBox"]}>
                    {triggerWorkflow ? (
                        <div className={style["modal__saveWk"]}>
                            <p>Save workflow for later</p>
                            <Switch
                                checked={saveWk}
                                onChange={onChangeSaveWk}
                            />
                        </div>
                    ) : (
                        <Button
                            label="Cancel"
                            variant="secondary"
                            onClick={closeModal}
                        />
                    )}
                    <Button
                        label={
                            wkDate
                                ? dayjs(wkDate).isValid()
                                    ? "Confirm"
                                    : "Choose Date"
                                : "Confirm"
                        }
                        variant="primary"
                        onClick={handleAddToWk}
                    />
                </div>
            </Stack>
        </div>
    );
}
