
import { Avatar, Card, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { selectCanShowConversations, selectChatName, selectHcChats, selectIsConversationLoading, setCanShowConversations } from "../ChatHistory.reducer";
import { candidateType, chatsType } from "../ChatHistory.type";
import styles from './Conversation.module.css';

const Conversation = () => {
    const dispatch = useDispatch();
    const chats = useSelector(selectHcChats);
    const canShowConversations = useSelector(selectCanShowConversations);
    const isConversationLoading = useSelector(selectIsConversationLoading);
    const chatName = useSelector(selectChatName)

    return (
        <Dialog
            open={canShowConversations}
            onClose={() => dispatch(setCanShowConversations(false))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {!isConversationLoading && chatName}
            </DialogTitle>
            <DialogContent>
                {isConversationLoading && <Loader />}
                {!isConversationLoading && chats.map((item: chatsType, i: number) => {
                    const { fromUser, candidates } = item;

                    return (
                        <Stack key={i}>
                            <Stack flexDirection={fromUser ? 'row' : 'row-reverse'} >
                                <div className={!fromUser ? styles.floatRight : styles.floatLeft}>
                                    {fromUser? "User" : "HQ"}
                                </div>
                                <Card
                                    // variant="body1"
                                    className={styles.chatBubble}
                                    dangerouslySetInnerHTML={{
                                        __html: item?.message || '',
                                    }}
                                />
                            </Stack>
                            {!!candidates.length &&
                                <Card className={styles.candidateContainer}>
                                    {candidates.map((candidate: candidateType, i) => {
                                        return (
                                            <Card className={styles.candidate}>
                                                <Stack flexDirection="row" alignItems="center">
                                                    <Avatar
                                                        alt={candidate.name}
                                                        src={candidate.profileImage}
                                                        sx={{ marginRight: 2}}
                                                    />
                                                    <Link
                                                        to={candidate.profileUrl}
                                                        target="_blank"
                                                    >
                                                        {candidate.name}
                                                    </Link>
                                                </Stack>
                                                <Typography variant="body2">
                                                    {candidate.title}
                                                </Typography>
                                            </Card>
                                        )
                                    })}
                                </Card>
                            }
                        </Stack>
                    )
                })}
            </DialogContent>
        </Dialog>
    )
}

export default Conversation;