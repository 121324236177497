import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";

import { ICandidateSkills } from "../../project.types";
import style from "./index.module.scss";

export default function Skills({
    all_skills,
    relevancy_skills,
    isLoading,
}: {
    all_skills: ICandidateSkills;
    relevancy_skills: ICandidateSkills;
    isLoading: boolean;
}) {
    const [seeMore, setSeeMore] = useState(false);
    const skillsData = seeMore ? all_skills : all_skills.slice(0, 6);
    const skillsDataLength = skillsData.length;

    if (isLoading) {
        return (
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        );
    }

    return (
        <>
            {skillsDataLength ? (
                <div
                    className={`${style["spcard__itemRight"]} ${style["spcard__skills"]}`}
                >
                    {skillsData.map((name: string) => (
                        <p
                            key={name}
                            className={`${
                                relevancy_skills &&
                                relevancy_skills.length &&
                                relevancy_skills.includes(name)
                                    ? style["spcard__relevantSkill"]
                                    : ""
                            } ${style["spcard__skillsItem"]}`}
                        >
                            {name}
                        </p>
                    ))}
                    {skillsDataLength > 6 ? (
                        <p
                            className={style["spcard__seeMore"]}
                            onClick={() => setSeeMore((prev) => !prev)}
                        >
                            {seeMore ? "See More" : "See Less"}
                        </p>
                    ) : null}
                </div>
            ) : (
                <p className={style["spcard__skillsItem"]}>N/A</p>
            )}
        </>
    );
}
