import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Table as AntTable, Dropdown } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MenuProps } from "rc-menu";
import { MenuInfo } from "rc-menu/lib/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import handleCleverTap from "../../../../utils/clevertap";
import { IProject, IProjectStatus } from "../../../project/project.types";
import { editProject, setAllProjectsList } from "../../index.reducer";
import style from "./index.module.scss";

const getProjectStatus = (type: IProjectStatus) => {
    switch (type) {
        case "ACTIVE": {
            return "bg-green";
        }
        case "ON_HOLD": {
            return "bg-gray";
        }
        case "CLOSED": {
            return "bg-red";
        }
    }
};

export default function Table(props: any) {
    const { data, loading } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const columns: ColumnsType<IProject> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => {
                return (
                    <>
                        {record._id === "9999" ? (
                            <p className={style["table__demo"]}>Sample data</p>
                        ) : null}
                        <div className={style["table__name"]}>
                            {/* <div
                                className={`${style["table__legend"]}
                                    ${getProjectStatus(
                                        record.dashboardStatus
                                    )}`}
                            /> */}
                            <p className={style["table__text"]}>
                                {text}
                                {record._id === "9999" && <>&nbsp; 🛠️</>}
                            </p>
                        </div>
                    </>
                );
            },
        },
        {
            title: "Total",
            dataIndex: "pipelined",
            align: "center",
            key: "candidates",
            render: (pipeline) => (
                <p className={style["table__text"]}>{pipeline}</p>
            ),
        },
        {
            title: "Contacted",
            dataIndex: "contacted",
            align: "center",
            key: "contacted",
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        {
            title: "Responded",
            dataIndex: "responded",
            align: "center",
            key: "responded",
            width: 200,
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        // {
        //     width: 50,
        //     render: (text, record, index) => {
        //         return <RenderMenu record={record} />;
        //     },
        // },
    ];

    const menuClickHandler = (record: any, status: IProjectStatus) => {
        if (record.record.dashboardStatus === status) return;
        if (record.record._id === "9999") {
            const tmpProjects = data.map((item: IProject) => {
                if (item?._id === "9999") {
                    return {
                        ...item,
                        dashboardStatus: status,
                    };
                }

                return item;
            });
            dispatch(setAllProjectsList(tmpProjects));
            return;
        }

        dispatch(
            editProject({
                ...record.record,
                location: record.record.location.map(
                    ({ label, value }: { label: string; value: string }) => ({
                        label,
                        value,
                    })
                ),
                dashboardStatus: status,
            })
        );
    };

    const projectStatusDropdown: MenuProps["items"] = [
        {
            label: (
                <div className={style["table__changeStatus"]}>
                    <CircleIcon
                        sx={{ color: "#4FCA64", width: 12, height: 12 }}
                    />
                    <p>Active</p>
                </div>
            ),
            key: "ACTIVE",
        },
        {
            label: (
                <div className={style["table__changeStatus"]}>
                    <CircleIcon
                        sx={{ color: "#8C8C8C", width: 12, height: 12 }}
                    />
                    <p>On Hold</p>
                </div>
            ),
            key: "ON_HOLD",
        },
        {
            label: (
                <div className={style["table__changeStatus"]}>
                    <CircleIcon
                        sx={{ color: "#E92525", width: 12, height: 12 }}
                    />
                    <p>Closed</p>
                </div>
            ),
            key: "CLOSED",
        },
    ];

    const RenderMenu = (record: any) => {
        return (
            <Dropdown
                trigger={["click"]}
                menu={{
                    items: projectStatusDropdown,
                    onClick: (e: MenuInfo) => {
                        e.domEvent.stopPropagation();
                        menuClickHandler(record, e.key as IProjectStatus);
                    },
                }}
            >
                <div
                    className={style["table__changeStatus"]}
                    onClick={(e) => e.stopPropagation()}
                >
                    <MoreVertIcon
                        style={{ cursor: "pointer" }}
                        fontSize={"small"}
                    />
                </div>
            </Dropdown>
        );
    };

    const onClickProject = (id: number | string) => {
        const isIdNumber = Number.isInteger(id);
        if (isIdNumber) {
            handleCleverTap("Clicks on Project", {
                "Project id": id,
            });
        }

        navigate("/projects/" + id);
    };

    return (
        <div className={style["table"]}>
            <div className={style["table__container"]}>
                <AntTable
                    rowKey={(record) => record._id}
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: "calc(100vh - 186px)" }}
                    pagination={false}
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: () => onClickProject(record._id),
                        };
                    }}
                />
            </div>
        </div>
    );
}
