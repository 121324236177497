import { ReactNode } from "react";
import { Modal as AntModal, Divider, ModalProps } from "antd";

import { closeIcon } from "../../constant";
import style from "./index.module.scss";

interface modalPropsType extends ModalProps {
    title: string | undefined;
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
    children?: ReactNode;
    width?: number | string;
    titleDivider?: boolean;
    className?: string;
    titleStyle?: React.CSSProperties;
}

export default function Modal(props: modalPropsType) {
    const {
        title,
        open,
        onOk,
        onCancel,
        children,
        width,
        titleDivider,
        className,
        titleStyle = {},
        ...rest
    } = props;
    return (
        <AntModal
            centered
            title={
                <>
                    <div className={style["modalTitle"]} style={titleStyle}>
                        {title}
                    </div>
                    {titleDivider ? (
                        <Divider
                            style={{
                                color: "#EBEFF2",
                                margin: "16px 0",
                                borderBlockStartWidth: 3,
                            }}
                        />
                    ) : null}
                </>
            }
            className={className}
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            footer={null}
            closeIcon={
                <img
                    className={style["modalCloseIcon"]}
                    src={closeIcon}
                    alt=""
                />
            }
            width={width ? width : 520}
            destroyOnClose={true}
            {...rest}
        >
            {children}
        </AntModal>
    );
}
