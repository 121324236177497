import React, { useEffect, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Button, Tooltip, Typography } from "@mui/material";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CustomTable from "../../../components/CustomTable/CustomTable";
import Navbar from "../../../components/Navbar";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { openLIProfileOrigin } from "../../../utils/API";
import { exportToCsvc, formatDate } from "../../../utils/helper";
import styles from "./UserList.module.css";
import {
    getHcUserList,
    selectHCUsers,
    selectSortBy,
    selectTotalUsers,
} from "./UserList.reducers";

const columns = [
    {
        title: "User id",
        dataIndex: "_id",
        minWidth: 100,
    },
    {
        title: "Name",
        dataIndex: "name",
        minWidth: 100,
        render: (record: any) => {
            const canShowLinkedin =
                record.linkedInId && record.linkedInId !== "-";

            return (
                <div className={styles.nameContainer}>
                    <Tooltip title="Click to load chats">
                        <Link
                            to={`/humanCircles/chatsHistory/${record.orgId}`}
                            className={styles.link}
                        >
                            {record.name}
                        </Link>
                    </Tooltip>
                    {canShowLinkedin && (
                        <Tooltip
                            title={`${openLIProfileOrigin}/${record.linkedInId}`}
                        >
                            <a
                                className={styles.link}
                                href={`${openLIProfileOrigin}/${record.linkedInId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedInIcon />
                            </a>
                        </Tooltip>
                    )}
                </div>
            );
        },
    },
    {
        title: "Email",
        dataIndex: "email",
    },
    {
        title: "Conversation Count",
        dataIndex: "conversationCount",
    },

    {
        title: "Company",
        dataIndex: "companyName",
        render: (record: any) => {
            const companyName = get(record, "userLiProfile.experience[0].org");

            return (
                <Tooltip title={record.companyLink}>
                    <a
                        // className={styles.link}
                        href={record.companyLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {companyName}
                    </a>
                </Tooltip>
            );
        },
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        render: (record: any) => {
            return formatDate(record.createdAt);
        },
    },
    {
        title: "Last login",
        dataIndex: "lastLogin",
        render: (record: any) => {
            return formatDate(record.lastLogin);
        },
    },
];

const HumanCirclesUser = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectHCUsers);
    const totalUsers = useSelector(selectTotalUsers);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const sortBy = useSelector(selectSortBy);
    const isUsersLoading = useSelector(checkIfLoading(getHcUserList.type));

    useEffect(() => {
        dispatch(
            getHcUserList({
                start: 0,
                limit: pageSize,
                action: getHcUserList.type,
            })
        );
    }, []);

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => {
        setCurrentPage(page);
        dispatch(
            getHcUserList({
                start: page,
                limit: pageSize,
                action: getHcUserList.type,
            })
        );
    };

    const onChangePageSize = (pageSize: number) => {
        setCurrentPage(0);
        setPageSize(pageSize);
        dispatch(
            getHcUserList({
                start: 0,
                limit: pageSize,
                action: getHcUserList.type,
            })
        );
    };

    // const onSortBy = (sortBy: string) => {
    //     setCurrentPage(0);
    //     dispatch(
    //         getHcUserList({
    //             start: 0,
    //             limit: pageSize,
    //             action: getHcUserList.type,
    //             sortBy,
    //         })
    //     );
    // };

    const onExportAllUsers = () => {
        dispatch(
            getHcUserList({
                start: 0,
                limit: totalUsers,
                action: getHcUserList.type,
                isExportAll: true,
            })
        );
    };

    return (
        <div>
            <Navbar />
            <div className={styles.exportButtonContainer}>
                <Typography className={styles.userCount}>
                    Total users: {totalUsers}
                </Typography>
                <Button
                    className={styles.exportButton}
                    onClick={() => exportToCsvc(users)}
                >
                    Export users
                </Button>
                <Button
                    className={styles.exportButton}
                    onClick={onExportAllUsers}
                >
                    Export all users
                </Button>
            </div>
            <CustomTable
                columns={columns}
                total={totalUsers}
                rows={users}
                onPageChange={handlePageChange}
                page={currentPage}
                pageSize={pageSize}
                onPageSizeChange={(value) => onChangePageSize(value)}
                isLoading={isUsersLoading}
            />
        </div>
    );
};

export default HumanCirclesUser;
