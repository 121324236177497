import { useEffect, useState } from "react";
import { Pagination, PaginationProps } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Modal from "../../../../components/Modal";
import {
    getAllProjectsList,
    getCandidate,
    selectAllProjects,
} from "../../../allProjects/index.reducer";
import { addToWorkflow, selectProjectCandidates } from "../../index.reducer";
import { ICandidate } from "../../project.types";
import ActivityTab from "../activityTab";
import CandidateProfileCard from "../candidateProfileCard";
import EmailTab from "../emailTab";
import NotesTab from "../notesTab";
import ProfileTab from "../profileTab";
import ResumeTab from "../resumeTab";
import SelectWorkflowModal from "../selectWorkflowModal";
import style from "./index.module.scss";

type CandidateModalProps = {
    allCadidates?: ICandidate[];
    data: ICandidate;
    index: number;
};

export default function CandidateModal(props: CandidateModalProps) {
    const { id: projectId } = useParams();
    const dispatch = useDispatch();
    const allProjectsList = useSelector(selectAllProjects);
    let candidates = useSelector(selectProjectCandidates);

    if (props?.allCadidates) {
        candidates = props?.allCadidates;
    }

    const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0);
    const [isSlectWkModalOpen, setIsSlectWkModalOpen] = useState(false);
    const [wkTemp, setWkTemp] = useState();
    const [wkDate, setWkDate] = useState("");
    const [currTab, setCurrTab] = useState(4);

    const currentCandidate = candidates[currentCandidateIndex];
    const { _id } = currentCandidate;
    // const showSlectWkModal = () => setIsSlectWkModalOpen(true);
    const closeSlectWkModal = () => setIsSlectWkModalOpen(false);

    useEffect(() => {
        if (isEmpty(allProjectsList))
            dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
        dispatch(getCandidate(_id));
        setCurrentCandidateIndex(
            candidates.findIndex((item) => item._id === props.data._id)
        );
    }, []);

    const handleTabChange = function (tab: number) {
        if (tab === currTab) return;
        setCurrTab(tab);
    };

    const handleAddToWk = () => {
        if (!wkTemp || !projectId) return;

        dispatch(
            addToWorkflow({
                workflowTemplateId: wkTemp,
                scheduledFor: dayjs(wkDate).toISOString(),
                project: Number(projectId),
                candidate: [_id],
                action: addToWorkflow.type,
            })
        );
        closeSlectWkModal();
    };

    const handleNavigation: PaginationProps["onChange"] = (page) => {
        setCurrentCandidateIndex(page - 1);
    };

    return (
        <>
            <div className={style["spcard__navigation"]}>
                <Pagination
                    showSizeChanger={false}
                    total={candidates.length}
                    pageSize={1}
                    current={currentCandidateIndex + 1}
                    onChange={handleNavigation}
                />
            </div>
            <div className={style["spcard__container"]}>
                <div className={style["spcard__card"]}>
                    <CandidateProfileCard
                        variant="modal"
                        projectId={projectId}
                        index={props.index}
                        candidate={currentCandidate}
                    />
                </div>
                <div className={style["spcard__tabs"]}>
                    <div
                        className={`${style["spcard__tabLink"]} ${
                            currTab === 4
                                ? style["spcard__tabLink--active"]
                                : ""
                        }`}
                    >
                        <p onClick={() => handleTabChange(4)}>Profile</p>
                    </div>
                    {/* <div className={`${style["spcard__tabLink"]} ${currTab == 0 ? style["spcard__tabLink--active"] : ""}`}>
                        <p onClick={() => handleTabChange(0)}>Resume</p>
                    </div> */}
                    {/* <div className={`${style["spcard__tabLink"]} ${currTab == 1 ? style["spcard__tabLink--active"] : ""}`}>
                        <p onClick={() => handleTabChange(1)}>Emails</p>
                    </div> */}
                    <div
                        className={`${style["spcard__tabLink"]} ${
                            currTab === 2
                                ? style["spcard__tabLink--active"]
                                : ""
                        }`}
                    >
                        <p onClick={() => handleTabChange(2)}>Notes</p>
                    </div>
                    {/* <div
                        className={`${style["spcard__tabLink"]} ${
                            currTab === 3
                                ? style["spcard__tabLink--active"]
                                : ""
                        }`}
                    >
                        <p onClick={() => handleTabChange(3)}>Activity</p>
                    </div> */}
                    {/* <div className={`${style["spcard__tabLink"]} ${currTab == 4 ? style["spcard__tabLink--active"] : ""}`}>
                        <p onClick={() => handleTabChange(4)}>Events</p>
                    </div> */}
                </div>
                <div className={style["spcard__tab"]}>
                    {currTab === 0 ? <ResumeTab /> : null}
                    {currTab === 1 ? <EmailTab id={_id} /> : null}
                    {currTab === 2 ? <NotesTab id={_id} /> : null}
                    {currTab === 3 ? <ActivityTab id={_id} /> : null}
                    {currTab === 4 ? (
                        <ProfileTab data={currentCandidate} />
                    ) : null}
                </div>
                <Modal
                    open={isSlectWkModalOpen}
                    onOk={closeSlectWkModal}
                    onCancel={closeSlectWkModal}
                    title={"Add to Workflow"}
                    width={"400px"}
                >
                    <SelectWorkflowModal
                        closeModal={closeSlectWkModal}
                        wkDate={wkDate}
                        setWkDate={setWkDate}
                        wkTemp={wkTemp}
                        setWkTemp={setWkTemp}
                        handleAddToWk={handleAddToWk}
                    />
                </Modal>
            </div>
        </>
    );
}
