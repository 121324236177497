import { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { jobviteLogo } from "../../../constant";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser } from "../../Signin/Signin.reducer";
import { postJobviteCredentials } from "../integrations.reducer";
import ConnectWrapper from "./connectWrapper";

function JobviteConnect() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(
        checkIfLoading(postJobviteCredentials.type)
    );

    const [enterCredsModal, setEnterCredsModal] = useState(false);
    const [jobvite, setJobvite] = useState({
        apiKey: "",
        apiSecret: "",
    });
    const [error, setError] = useState({
        apiKey: false,
        apiSecret: false,
    });

    const isJobviteConnected =
        user.jobVites?.apiKey || user.jobVites?.apiSecret;

    useEffect(() => {
        if (isEmpty(user)) return;
        setJobvite({
            apiKey: user.jobVites?.apiKey ?? "",
            apiSecret: user.jobVites?.apiSecret ?? "",
        });
    }, [user]);

    const openCredsModal = () => setEnterCredsModal(true);
    const closeCredsModal = () => {
        setError({
            apiKey: false,
            apiSecret: false,
        });
        setEnterCredsModal(false);
    };

    const handleSubmit = () => {
        if (!jobvite.apiKey) {
            setError({ ...error, apiKey: true });
            return;
        }
        if (!jobvite.apiSecret) {
            setError({ ...error, apiSecret: true });
            return;
        }

        dispatch(
            postJobviteCredentials({
                action: postJobviteCredentials.type,
                apiKey: jobvite.apiKey,
                apiSecret: jobvite.apiSecret,
                onSuccess: closeCredsModal,
            })
        );
    };

    return (
        <>
            <Button
                variant={isJobviteConnected ? "outlined" : "contained"}
                onClick={openCredsModal}
            >
                {isJobviteConnected ? "Manage" : "Connect"}
            </Button>
            <Dialog
                open={enterCredsModal}
                onClose={closeCredsModal}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Connect Jobvite ATS</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Alert severity="info">
                            Kindly note that the below credentials will be used
                            by HireQuotient to send candidate details to Jobvite
                        </Alert>
                        <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography fontSize={14} noWrap>
                                API Key
                            </Typography>
                            <TextField
                                hiddenLabel
                                placeholder="Enter api key"
                                value={jobvite.apiKey}
                                onChange={(e) =>
                                    setJobvite((prev) => ({
                                        ...prev,
                                        apiKey: e.target.value,
                                    }))
                                }
                                sx={{
                                    minWidth: 300,
                                    "& .MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                }}
                                error={error.apiKey}
                                helperText={
                                    error.apiKey ? "Enter valid api key" : null
                                }
                            />
                        </Stack>
                        <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography fontSize={14} noWrap>
                                API Secret
                            </Typography>
                            <TextField
                                hiddenLabel
                                placeholder="Enter api secret"
                                value={jobvite.apiSecret}
                                onChange={(e) =>
                                    setJobvite((prev) => ({
                                        ...prev,
                                        apiSecret: e.target.value,
                                    }))
                                }
                                sx={{
                                    minWidth: 300,
                                    "& .MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                }}
                                error={error.apiSecret}
                                helperText={
                                    error.apiSecret
                                        ? "Enter valid api secret"
                                        : null
                                }
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeCredsModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                    >
                        <ButtonTextWithLoading
                            text="Submit"
                            isLoading={isCredsSubmitting}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default function JobviteAts() {
    return (
        <ConnectWrapper
            logo={jobviteLogo}
            logoAlt="jobvite-logo"
            title="Jobvite"
            description="Jobvite is an applicant tracking system and recruiting platform that helps thousands of companies source, hire and onboard top talent."
            disabled={true}
        >
            <JobviteConnect />
        </ConnectWrapper>
    );
}
