import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";

import Button from "../../../../components/Button";
import "react-quill/dist/quill.snow.css";
import { createNewTemplate } from "../../index.reducer";
import style from "./index.module.scss";

interface propsType {
    closeModal: () => void;
    type: string;
}

export default function NewTemplateModal(props: propsType) {
    const { closeModal, type } = props;
    const dispatch = useDispatch();
    const [quillVal, setQuillVal] = useState("");
    const [subject, setSubject] = useState("");
    const [title, setTitle] = useState("");

    const handleSave = () => {
        let tmp = "";
        if (type === "Connection Request") tmp = "CONNECTION_REQUEST";
        else if (type === "Inmail") tmp = "INMAIL";
        else tmp = "EMAIL";

        dispatch(
            createNewTemplate({
                name: title,
                body: quillVal,
                subject: subject,
                type: tmp,
            })
        );
        closeModal();
    };

    return (
        <div className={style["tab"]}>
            <div className={style["tab__container"]}>
                <div className={style["tab__ccInputBox"]}>
                    <label>Name :</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTitle(e.target.value)
                        }
                    />
                </div>
                <p className={style["tab__ccSubtitle"]}></p>

                <div className={style["tab__ccInputBox"]}>
                    <label>Subject :</label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSubject(e.target.value)
                        }
                    />
                </div>
                <p className={style["tab__ccSubtitle"]}></p>

                <div className={style["tab__quillBox"]}>
                    <ReactQuill
                        value={quillVal}
                        //@ts-ignore
                        onChange={setQuillVal}
                    />
                </div>

                <div className={style["tab__btnBox"]}>
                    <Button
                        label="Save Template"
                        variant="primary"
                        onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    );
}
