import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    startAction,
    stopAction,
} from "../../../../store/reducers/loaders.reducer";
import API, { apiBase } from "../../../../utils/API";
import { CancelSagas } from "../../../../utils/saga.utils";
import { ICandidate } from "../../../project/project.types";
import { setCandidates } from "../Candidates/Candidates.reducer";
import {
    getTypeAhead,
    leavePage,
    searchCandidates,
    setEducationOptions,
    setErrors,
    setIndustryOptions,
    setJobTitleOptions,
    setLocationOptions,
    setSkillOptions,
} from "./Filters.reducer";
import { optionType } from "./Filters.type";

function* getTypeAheadSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const { label, value } = payload;
        const state = yield select();
        const isJobTitle = label === "role";
        const isLocation = label === "location";
        const isSkills = label === "skill";
        const isEducation = label === "education";
        const isIndustry = label === "industry";

        const jobFunction = get(state, "advancedFilters.jobFunction");
        yield put(startAction({ action: getTypeAhead.type }));
        const response = yield call(
            new API().post,
            `${apiBase}/v2/linkedin-type-ahead/type-ahead`,
            [
                {
                    label,
                    value,
                    category: jobFunction.value,
                },
            ]
        );

        if (!response?.data) return;

        const structuredRes: optionType[] = response.data.map(
            (item: optionType) => ({ label: item, value: item })
        );

        if (isJobTitle) yield put(setJobTitleOptions(structuredRes));
        else if (isLocation) yield put(setLocationOptions(response.data));
        else if (isSkills) yield put(setSkillOptions(structuredRes));
        else if (isEducation) yield put(setEducationOptions(structuredRes));
        else if (isIndustry) yield put(setIndustryOptions(response.data));
    } catch (e) {
        console.error("***getTypeAheadSaga err=", e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* searchCandidatesSaga(): SagaIterator {
    try {
        const state = yield select();
        const jobFunction = get(state, "advancedFilters.jobFunction.label", "");
        const jobTitles = get(state, "advancedFilters.jobTitles");
        const skills = get(state, "advancedFilters.skills");
        const experience = get(state, "advancedFilters.experience");
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const visa = get(state, "advancedFilters.visa");
        const education = get(state, "advancedFilters.education");

        const title = jobTitles.map((item: optionType) => item.value);
        const newSkills = skills.map((item: optionType) => item.value);
        const newExperience = experience.map((item: optionType) => item.value);
        const newIndustry = industry.map((item: optionType) => item.label);
        const newLocation = location.map((item: optionType) => item.label);
        const newEducation = education.map((item: optionType) => item.value);

        let errors = {
            title: isEmpty(title) ? "Job Titles should not be empty" : "",
            location: isEmpty(newLocation)
                ? "Geography should not be empty "
                : "",
        };
        yield put(setErrors(errors));
        if (errors.title || errors.location) return;

        yield put(startAction({ action: searchCandidates.type }));
        const response = yield call(
            new API().post,
            `${apiBase}/searches/query-based`,
            {
                jobFunction,
                title,
                skills: newSkills,
                experience: newExperience,
                industry: newIndustry,
                location: newLocation,
                visa: visa.value,
                education: newEducation,
            }
        );

        if (!response?.data) return;

        response.data.map((item: ICandidate) => {
            item.email = ["*****@gmail.com"];
            item.canShowEmail = true;
        });
        yield put(setCandidates(response.data));
    } catch (e) {
        console.error("***searchCandidatesSaga err=", e);
    } finally {
        yield put(stopAction({ action: searchCandidates.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getTypeAhead.type, getTypeAheadSaga),

        // @ts-ignore
        yield takeLatest(searchCandidates.type, searchCandidatesSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
