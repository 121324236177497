import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { IChatGptTemplates, IRootState } from "./template.types";

const initialState: IRootState = {
    templateList: [],
    chatgptTemplates: null,
    editMsgPop: false,
    enableConnectionReq: true,
    enableFollowUpEmail: true,
};

const reducers = {
    getTemplateList(state: typeof initialState) {},
    setTemplateList(state: typeof initialState, { payload }: { payload: any }) {
        state.templateList = payload;
    },
    createNewTemplate(
        state: typeof initialState,
        { payload }: { payload: any }
    ) {},
    editTemplate(state: typeof initialState, { payload }: { payload: any }) {},
    leaveTemplate(state: typeof initialState) {},
    getChatGptTemplates(
        state: typeof initialState,
        {
            payload,
        }: { payload: { projectId?: string; type: string } & IActionPayload }
    ) {},
    setChatGptTemplates(
        state: typeof initialState,
        { payload }: { payload: IChatGptTemplates }
    ) {
        state.chatgptTemplates = payload;
    },
    setEditMsgPopup(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.editMsgPop = payload;
    },
    setEnableConnectionReq(
        state: typeof initialState,
        action: PayloadAction<boolean>
    ) {
        state.enableConnectionReq = action.payload;
    },
    setEnableFollowUpEmail(
        state: typeof initialState,
        action: PayloadAction<boolean>
    ) {
        state.enableFollowUpEmail = action.payload;
    },
};

export const templatePage = createSlice({
    name: "template",
    initialState,
    reducers,
});

export default templatePage.reducer;

export const {
    getTemplateList,
    setTemplateList,
    createNewTemplate,
    editTemplate,
    leaveTemplate,
    getChatGptTemplates,
    setChatGptTemplates,
    setEditMsgPopup,
    setEnableConnectionReq,
    setEnableFollowUpEmail,
} = templatePage.actions;

export const selectTemplateList = (state: RootState) =>
    state.template.templateList;
export const selectChatGptTemplates = (state: RootState) =>
    state.template.chatgptTemplates;
export const selectEditMsgPopup = (state: RootState) =>
    state.template.editMsgPop;
export const selectEnableConnectionReq = (state: RootState) =>
    state.template.enableConnectionReq;
export const selectEnableFollowUpEmail = (state: RootState) =>
    state.template.enableFollowUpEmail;
