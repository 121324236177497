import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash";

import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader";
import styles from "./CustomTable.module.css";
import { columnsType, rowsType } from "./CustomeTable.types";

const CustomTable = ({
    columns,
    rows,
    total,
    onPageChange,
    page,
    pageSize,
    onPageSizeChange,
    isLoading,
    tableHeight,
}: {
    columns: columnsType[];
    rows: rowsType[];
    total: number;
    onPageChange?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    isLoading?: boolean;
    tableHeight?: number;
}) => {
    const size = useWindowSize();
    const overflowTableHeight = size.height - 290;

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
                sx={{ maxHeight: tableHeight || overflowTableHeight }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column, i) => {
                                const title =
                                    column.title.length < 10
                                        ? column.title
                                        : `${column.title.substring(0, 10)}...`;
                                return (
                                    <TableCell
                                        key={`${column.dataIndex}-${i}`}
                                        align={column.align || "center"}
                                        style={{
                                            minWidth: column.minWidth || 150,
                                        }}
                                    >
                                        <Tooltip title={column.title}>
                                            <span>{title}</span>
                                        </Tooltip>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {!isEmpty(rows) && !isLoading && (
                        <TableBody>
                            {rows.map((row: any, index: number) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column, i) => {
                                            const value = row[column.dataIndex];
                                            return (
                                                <TableCell
                                                    key={`${column.dataIndex}-${i}`}
                                                    align={
                                                        column.align || "center"
                                                    }
                                                >
                                                    {column?.render
                                                        ? column?.render(row)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {(isEmpty(rows) || isLoading) && (
                <div
                    className={styles.centerAlign}
                    style={{ height: overflowTableHeight - 80 }}
                >
                    <Loader />
                </div>
            )}
            {total && pageSize && (
                //@ts-ignore
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 500]}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={(e) =>
                        onPageSizeChange &&
                        onPageSizeChange(parseInt(e.target.value))
                    }
                />
            )}
        </Paper>
    );
};

export default CustomTable;
