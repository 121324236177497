import { useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataImg from "../../../../assets/img/no-data-2.png";
import { getAllProjectsList } from "../../../allProjects/index.reducer";
import style from "./index.module.scss";

export default function ReleventProjects() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allProjectsList = useSelector((state) =>
        get(state, "allProjects.projectList")
    );

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    return (
        <div className={style["rvp__container"]}>
            <div className={style["rvp__titlebox"]}>
                <p className={style["rvp__title"]}>Relevant projects</p>
            </div>

            <div className={style["rvp__body"]}>
                {
                    //@ts-ignore
                    allProjectsList.length ? (
                        //@ts-ignore
                        allProjectsList.map((val, i) => {
                            return (
                                <div
                                    className={style["rvp__item"]}
                                    key={i}
                                    onClick={() =>
                                        navigate("/projects/" + val._id)
                                    }
                                >
                                    <div className={style["rvp__datebox"]}>
                                        <p className={style["rvp__date"]}>
                                            {
                                                //@ts-ignore
                                                new Date(val.createdAt)
                                                    .toDateString
                                            }
                                        </p>
                                    </div>

                                    <div className={style["rvp__main"]}>
                                        <div className={style["rvp__user"]}>
                                            <p>PN</p>
                                        </div>

                                        <div
                                            className={style["rvp__detailBox"]}
                                        >
                                            <p className={style["rvp__name"]}>
                                                {val.name}
                                            </p>
                                            <div
                                                className={
                                                    style["rvp__details"]
                                                }
                                            >
                                                <p
                                                    className={
                                                        style["rvp__detailItem"]
                                                    }
                                                >
                                                    Candidates{" "}
                                                    <span
                                                        className={
                                                            style[
                                                                "rvp__detailNumber"
                                                            ]
                                                        }
                                                    >
                                                        {val.candidates.length}
                                                    </span>
                                                </p>
                                                <p
                                                    className={
                                                        style["rvp__detailItem"]
                                                    }
                                                >
                                                    Contacted{" "}
                                                    <span
                                                        className={
                                                            style[
                                                                "rvp__detailNumber"
                                                            ]
                                                        }
                                                    >
                                                        25
                                                    </span>
                                                </p>
                                                <p
                                                    className={
                                                        style["rvp__detailItem"]
                                                    }
                                                >
                                                    Replied{" "}
                                                    <span
                                                        className={
                                                            style[
                                                                "rvp__detailNumber"
                                                            ]
                                                        }
                                                    >
                                                        25
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <>
                            <img
                                src={NoDataImg}
                                alt="no-data-1"
                                className={style["rvp__no-data-img"]}
                            />
                            <p className={style["rvp__no-data-text"]}>
                                No existing projects
                                <br />
                                Create a new project by + icon on the top right
                            </p>
                        </>
                    )
                }
            </div>
        </div>
    );
}
