import { Select } from "antd";

import style from "./index.module.scss";

interface activityModalProps {
    closeModal: () => void;
}

export default function ActivitiesModal(props: activityModalProps) {
    return (
        <div className={style["act"]}>
            <div className={style["act__container"]}>
                <div className={style["act__topbar"]}>
                    <div className={style["act__select"]}>
                        <Select
                            bordered={false}
                            className={style["pmodal__select"]}
                            placeholder="Select Date & Time"
                            options={[
                                { value: "jack", label: "Jack" },
                                { value: "lucy", label: "Lucy" },
                                { value: "Yiminghe", label: "yiminghe" },
                                {
                                    value: "disabled",
                                    label: "Disabled",
                                    disabled: true,
                                },
                            ]}
                        />
                    </div>
                    <div className={style["act__select"]}>
                        <Select
                            bordered={false}
                            className={style["pmodal__select"]}
                            placeholder="Activity Type"
                            options={[
                                { value: "jack", label: "Jack" },
                                { value: "lucy", label: "Lucy" },
                                { value: "Yiminghe", label: "yiminghe" },
                                {
                                    value: "disabled",
                                    label: "Disabled",
                                    disabled: true,
                                },
                            ]}
                        />
                    </div>
                </div>

                <div className={style["act__body"]}>
                    <div className={style["act__filterBox"]}>
                        <div className={style["act__filter"]}>
                            <Select
                                bordered={false}
                                placeholder="Names"
                                options={[
                                    { value: "jack", label: "A to Z" },
                                    { value: "lucy", label: "Z to A" },
                                ]}
                            />
                        </div>
                        <div className={style["act__filter"]}>
                            <Select
                                bordered={false}
                                placeholder="Activities"
                                options={[
                                    { value: "jack", label: "A to Z" },
                                    { value: "lucy", label: "Z to A" },
                                ]}
                            />
                        </div>
                        <div className={style["act__filter"]}>
                            <Select
                                bordered={false}
                                placeholder="Date & Time"
                                options={[
                                    { value: "jack", label: "A to Z" },
                                    { value: "lucy", label: "Z to A" },
                                ]}
                            />
                        </div>
                    </div>

                    <div className={style["act__itemBox"]}>
                        <div className={style["act__item"]}>
                            <p>Shreya</p>
                            <p>Email</p>
                            <p>Jan 24, 2023 04:53 pm</p>
                        </div>
                        <div className={style["act__item"]}>
                            <p>Shreya</p>
                            <p>Email</p>
                            <p>Jan 24, 2023 04:53 pm</p>
                        </div>
                        <div className={style["act__item"]}>
                            <p>Shreya</p>
                            <p>Email</p>
                            <p>Jan 24, 2023 04:53 pm</p>
                        </div>
                        <div className={style["act__item"]}>
                            <p>Shreya</p>
                            <p>Email</p>
                            <p>Jan 24, 2023 04:53 pm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
