import { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Tap from "@tapfiliate/tapfiliate-js";
import { useNavigate } from "react-router-dom";

import API from "../../utils/API";
import styles from "./ThankYou.module.scss";
import { AffiliateResponse } from "./ThankYou.types";
import { Typography } from "@mui/material";

type States = "IDLE" | "LOADING" | "SUCCESS" | "ERROR";

const SECONDS = 4;

export default function ThankYou() {
    const [status, setStatus] = useState<States>("IDLE");
    const [count, setCount] = useState(SECONDS);
    const counterRef = useRef<NodeJS.Timer>();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setStatus("LOADING");
            try {
                const response: AffiliateResponse = await new API().get(
                    "/payment/affiliate-conversion"
                );

                const {
                    customerId,
                    orderAmount,
                    uniqueConversionId,
                    name,
                    email,
                } = response.data;

                Tap.conversion(uniqueConversionId, orderAmount, {
                    customer_id: customerId,
                    meta_data: {
                        name,
                        email,
                    },
                });
                setStatus("SUCCESS");
            } catch (error) {
                console.error({ error });
                setStatus("ERROR");
            }
        })();
    }, []);

    useEffect(() => {
        if (count === 0) {
            clearInterval(counterRef.current);
            navigate("/");
            return;
        }

        if (count === SECONDS && status === "SUCCESS") {
            counterRef.current = setInterval(() => {
                setCount((prev) => prev - 1);
            }, 1000);
        }
    }, [count, status]);

    return (
        <>
            <Stack
                style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "#008095",
                }}
                justifyContent="center"
                alignItems="center"
            >
                {status === "LOADING" ? (
                    <div className={styles["spinner"]}></div>
                ) : (
                    <Stack>
                        <Typography
                            style={{ color: "white" }}
                            variant="h4"
                            fontWeight={700}
                        >
                            Purchase Successful
                        </Typography>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight={600}
                            style={{ color: "white" }}
                        >
                            Redirecting in {count + 1}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </>
    );
}
