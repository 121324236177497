import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import { watchSagas } from "./sagas";

const sagaMiddleware = createSagaMiddleware();

//redux dev tool
// const composeEnhancers =
//     // @ts-ignore
//     (typeof window !== "undefined" &&
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//     compose;

const store = configureStore({
    reducer: reducers,
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(watchSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

function UnloadHandler() {
    window.removeEventListener("unload", UnloadHandler, false);
}

window.addEventListener("unload", UnloadHandler, false);

window.addEventListener(
    "pageshow",
    function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    },
    false
);
