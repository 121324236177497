import { createSlice } from "@reduxjs/toolkit";

import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    IChangePassword,
    IForgotPassword,
    IPassword,
    IVerifyOtp,
} from "./forgotPassword.types";

const initialState: IPassword = Object.freeze({
    code: "",
});

const reducers = {
    forgotPassword(
        state: typeof initialState,
        { payload }: { payload: IForgotPassword & IActionPayload }
    ) {},
    verifyOtp(
        state: typeof initialState,
        { payload }: { payload: IVerifyOtp & IActionPayload }
    ) {},
    changePassword(
        state: typeof initialState,
        { payload }: { payload: IChangePassword & IActionPayload }
    ) {},
    resetPasswords(state: typeof initialState) {},
};

export const passwordSlice = createSlice({
    name: "forgotPassword",
    initialState,
    reducers,
});

export default passwordSlice.reducer;

export const forgotPassword = passwordSlice.actions.forgotPassword;
export const verifyOtp = passwordSlice.actions.verifyOtp;
export const changePassword = passwordSlice.actions.changePassword;
export const resetPasswords = passwordSlice.actions.resetPasswords;
