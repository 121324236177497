import { Spin } from "antd";
import style from "./index.module.scss";

export default function Loader({
  size = "default",
}: {
  size?: "small" | "large" | "default";
}) {
  return (
    <div className={style["loader"]}>
      <Spin size={size} />
      <br />
      <div>
        <p>Loading</p>
      </div>
    </div>
  );
}
