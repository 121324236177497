import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";

export type IActionPayload = {
    action: string;
    authToken?: string;
    _id?: string;
};

type ILoaderState = {
    actions: string[];
};

const initialState: ILoaderState = {
    actions: [],
};

const loadersSlice = createSlice({
    name: "loaders",
    initialState,
    reducers: {
        startAction: (
            state: ILoaderState,
            { payload }: { payload: { action: string } }
        ) => {
            state.actions = state.actions.concat(payload.action);
        },
        stopAction: (
            state: ILoaderState,
            { payload }: { payload: { action: string } }
        ) => {
            state.actions = state.actions.filter((t) => t !== payload.action);
        },
    },
});

export const startAction = loadersSlice.actions.startAction;
export const stopAction = loadersSlice.actions.stopAction;
export const checkIfLoading = (type: string) => (state: RootState) =>
    state.loaders.actions.includes(type);

export default loadersSlice.reducer;
