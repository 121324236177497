import { useDispatch, useSelector } from "react-redux";

import { closeIcon, goldenStar } from "../../constant";
import { selectUser, setUser } from "../../pages/Signin/Signin.reducer";
import styles from "./NAEmailAlert.module.scss";

export default function NAEmailAlert() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const handleClose = () => {
        dispatch(
            setUser({
                ...user,
                firstEmailNA: "Final",
            })
        );
    };

    if (
        !user.firstEmailNA ||
        user.firstEmailNA === "Initial" ||
        user.firstEmailNA === "Final"
    )
        return null;
    return (
        <div className={styles["naEmailAlert"]}>
            <img
                src={closeIcon}
                alt="close-icon"
                className={styles["naEmailAlert__close"]}
                onClick={handleClose}
            />
            <p className={styles["naEmailAlert__text"]}>
                We understand there will be few where personal emails might not
                be available. <br />
                <b>We have a good news for you!</b> <br />
                Send a connection request or inmail to everyone,{" "}
                <b>without spending any additional credit!</b>{" "}
                {Array(3)
                    .fill("0")
                    .map((item, index) => (
                        <img
                            key={`${item}${index}`}
                            src={goldenStar}
                            alt="star-icon"
                            className={styles["naEmailAlert__star"]}
                        />
                    ))}
            </p>
        </div>
    );
}
