import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import InfoButton, {
    InfoMessage,
    WorkflowInfo,
} from "../../../../components/SetupFlow/InfoButton";
import {
    selectEnableFollowUpEmail,
    setEnableFollowUpEmail,
} from "../../../template/index.reducer";

const connectionReqMessages: InfoMessage[] = [
    {
        id: nanoid(),
        answer: "Disable this to skip sending a follow-up email during your outreach.",
    },
];

export default function DisableFollowUpEmailSwitch() {
    const dispatch = useDispatch();
    const enableFollowUpEmail = useSelector(selectEnableFollowUpEmail);

    return (
        <Stack direction="row">
            <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                    <Switch
                        checked={enableFollowUpEmail}
                        onChange={(e) => {
                            dispatch(setEnableFollowUpEmail(e.target.checked));
                        }}
                    />
                }
                label={enableFollowUpEmail ? "Disable" : "Enable"}
            />
            <InfoButton>
                <WorkflowInfo messages={connectionReqMessages} />
            </InfoButton>
        </Stack>
    );
}
