import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { exportToCsvc } from "../../../utils/helper";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    getHcUserList,
    leavePage,
    setHcUserList,
    setTotalUserCount,
} from "./UserList.reducers";
import { IGetUserListPayload } from "./UserList.type";

function* getHcUserListSaga({
    payload,
}: {
    payload: IActionPayload & IGetUserListPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const sortBy = get(state, "userList.sortBy");
        const response = yield call(
            new API().get,
            `/super-admin/hc/userList?start=${payload.start}&limit=${payload.limit}&sortBy=${sortBy}`
        );
        if (!response?.data.users) return;

        yield put(setHcUserList(response.data.users));
        yield put(setTotalUserCount(response.data.total));

        if (payload.isExportAll) {
            exportToCsvc(response.data.users);
        }
        yield put(stopAction({ action: payload.action }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getHcUserList.type, getHcUserListSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
