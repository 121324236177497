import { createSlice } from "@reduxjs/toolkit";
import { uniq, uniqBy } from "lodash";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { randomEmail } from "../../utils/helper";
import { IGetProjectPayload } from "../allProjects/allProjects.types";
import {
    IAddToWorkflowPayload,
    ICandidate,
    ICandidateEmailData,
    ICandidateEmailPayload,
    ICopyToProjectPayload,
    IEmailStats,
    IGetProjectWorkflows,
    ILinkedInStats,
    IMaxExpPayload,
    IMinExpPayload,
    IMostRecentPayload,
    IMoveToPayload,
    IPersonalEmailPayload,
    IProjectFiltersPayload,
    IProjectStats,
    IProjectWorkflows,
    IRelevantCandPayload,
    IRootState,
    ISSEEventPayload,
    ISelectedCandidateId,
    ISelectedCandidatePayload,
} from "./project.types";

const initialState: IRootState = {
    projectStats: null,
    projectWorkflows: [],
    showByRelevance: false,
    showByMaxExperience: false,
    showByMinExperience: false,
    showByPersonalEmails: false,
    showByMostRecent: true,
    projectFilters: {
        PIPELINE: false,
        CONTACTED: false,
        RESPONDED: false,
        SHORTLISTED: false,
        REJECTED: false,
        NOT_INTERESTED: false,
        ON_HOLD: false,
    },
    candidates: [],
    totalCandidates: 0,
    selectedCandidates: [],
    candidateCurrentPage: 1,
    selectAllCandidates: false,
    emailStats: null,
    linkedInStats: null,
    pageSize: 50,
};

const reducers = {
    copyToProject(
        state: typeof initialState,
        { payload }: { payload: ICopyToProjectPayload }
    ) {},
    moveTo(
        state: typeof initialState,
        { payload }: { payload: IMoveToPayload }
    ) {},
    addToWorkflow(
        state: typeof initialState,
        { payload }: { payload: IAddToWorkflowPayload }
    ) {},
    getCandidateEmail(
        state: typeof initialState,
        { payload }: { payload: IActionPayload & ICandidateEmailPayload }
    ) {},
    getProjectStats(
        state: typeof initialState,
        {
            payload,
        }: { payload: { projectId?: string | number } & IActionPayload }
    ) {},
    setProjectStats(
        state: typeof initialState,
        { payload }: { payload: IProjectStats }
    ) {
        state.projectStats = {
            totalCandidate: payload.totalCandidate,
            responded: payload.responded,
            contacted: payload.responded + payload.contacted,
        };
    },
    getProjectWorkflows(
        state: typeof initialState,
        { payload }: { payload: IGetProjectWorkflows & IActionPayload }
    ) {},
    setProjectWorkflows(
        state: typeof initialState,
        { payload }: { payload: Array<IProjectWorkflows> }
    ) {
        state.projectWorkflows = payload;
    },
    getShowByRelevance(
        state: typeof initialState,
        { payload }: { payload: IRelevantCandPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.showByRelevance = payload.showByRelevance;
        state.showByMinExperience = false;
        state.showByMaxExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByMinExperience(
        state: typeof initialState,
        { payload }: { payload: IMinExpPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.showByMinExperience = payload.showByMinExperience;
        state.showByRelevance = false;
        state.showByMaxExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByMaxExperience(
        state: typeof initialState,
        { payload }: { payload: IMaxExpPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.showByMaxExperience = payload.showByMaxExperience;
        state.showByRelevance = false;
        state.showByMinExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByRecent(
        state: typeof initialState,
        { payload }: { payload: IMostRecentPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.showByMostRecent = payload.showByMostRecent;
        state.showByMinExperience = false;
        state.showByMaxExperience = false;
        state.showByRelevance = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByPersonalEmails(
        state: typeof initialState,
        { payload }: { payload: IPersonalEmailPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.showByPersonalEmails = payload.showByPersonalEmails;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    setShowByPersonalEmails(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.showByPersonalEmails = payload;
    },
    getShowByProjectFilters(
        state: typeof initialState,
        { payload }: { payload: IProjectFiltersPayload & IActionPayload }
    ) {
        state.pageSize = 50;
        state.projectFilters = payload.projectFilters;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    setCandidates(
        state: typeof initialState,
        { payload }: { payload: ICandidate[] }
    ) {
        state.candidates = payload;
    },
    addSSECandidateDetails(
        state: typeof initialState,
        { payload }: { payload: ISSEEventPayload }
    ) {
        const candidates = [...state.candidates];
        const existingCandidateIndex = candidates.findIndex(
            (candidate) => candidate._id === payload._id
        );
        const newCandidate = {
            ...payload,
            stage: "PIPELINE",
            canShowEmail: true,
            email: ["Fetching email..."],
        };
        if (existingCandidateIndex === -1) {
            candidates.splice(0, 0, newCandidate);
        } else {
            candidates[existingCandidateIndex] = newCandidate;
        }
        state.candidates = candidates;
    },
    getProjectCandidates(
        state: typeof initialState,
        { payload }: { payload: IGetProjectPayload & IActionPayload }
    ) {
        state.selectedCandidates = [];
    },
    setSelectedCandidates(
        state: typeof initialState,
        { payload }: { payload: ISelectedCandidatePayload }
    ) {
        if (payload.deselectAll) {
            const filteredSelectedCandidates = state.selectedCandidates.filter(
                (item: ISelectedCandidateId) =>
                    item.pageNo !== payload.deselectAll
            );
            state.selectedCandidates = filteredSelectedCandidates;
            return;
        }

        const candidateWithPageNos = payload.candidateId.map((item) => {
            return {
                candidateId: item,
                pageNo: payload.pageNo || 1,
            };
        });
        const uniqueSelectedCandidates = uniqBy(
            [...state.selectedCandidates, ...candidateWithPageNos],
            "candidateId"
        );
        state.selectedCandidates = uniqueSelectedCandidates;
        if (payload.canSelectAll) {
            state.selectAllCandidates = true;
        }
        if (state.selectedCandidates.length !== state.candidates.length) {
            state.selectAllCandidates = false;
        }
    },
    removeSelectedCandidate(
        state: typeof initialState,
        { payload }: { payload: string }
    ) {
        state.selectedCandidates = state.selectedCandidates.filter(
            (item: ISelectedCandidateId) => item.candidateId !== payload
        );
    },
    setCandidatePage(
        state: typeof initialState,
        { payload }: { payload: number }
    ) {
        state.candidateCurrentPage = payload;
    },
    setSelectAllCandidates(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        if (!payload) state.selectedCandidates = [];
        state.selectAllCandidates = payload;
    },
    setTotalCandidates(
        state: typeof initialState,
        { payload }: { payload: number }
    ) {
        state.totalCandidates = payload;
    },
    getWorkflowEmailStats(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IActionPayload & { workflowId: string; projectId: string };
        }
    ) {},
    setWorkflowEmailStats(
        state: typeof initialState,
        { payload }: { payload: IEmailStats }
    ) {
        state.emailStats = payload;
    },
    getWorkflowLinkedInStats(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IActionPayload & { workflowId: string; projectId: string };
        }
    ) {},
    setWorkflowLinkedInStats(
        state: typeof initialState,
        { payload }: { payload: ILinkedInStats }
    ) {
        state.linkedInStats = payload;
    },
    setCandidateEmails(
        state: typeof initialState,
        { payload }: { payload: ICandidateEmailData }
    ) {
        const tmpCandidates = state.candidates.map((item) => {
            const showCandidate = payload.showEmail.find(
                ({ _id }) => item._id === _id
            );
            if (showCandidate) {
                const professionalEmails = item.professional_email || [];
                const showCandidateProfessionalEmails =
                    showCandidate?.professional_email || [];

                return {
                    ...item,
                    canShowEmail: true,
                    email: showCandidate?.personal_email,
                    professional_email: uniq([
                        ...professionalEmails,
                        ...showCandidateProfessionalEmails,
                    ]),
                };
            }

            return item;
        });
        state.candidates = tmpCandidates;
    },
    setResumeWorkflow(
        state: typeof initialState,
        { payload }: { payload: string }
    ) {
        const tmpWorkflows = state.projectWorkflows.map((workflow) => {
            if (workflow._id === payload) {
                return {
                    ...workflow,
                    status: "RESUME",
                };
            }
            return workflow;
        });
        state.projectWorkflows = tmpWorkflows;
    },
    setPageSize(state: typeof initialState, { payload }: { payload: number }) {
        state.pageSize = payload;
    },
    leaveProject(state: typeof initialState) {},
};

export const projectPage = createSlice({
    name: "project",
    initialState,
    reducers,
});

export default projectPage.reducer;

export const {
    copyToProject,
    moveTo,
    addToWorkflow,
    getProjectStats,
    setProjectStats,
    leaveProject,
    getCandidateEmail,
    getProjectWorkflows,
    setProjectWorkflows,
    setCandidates,
    getShowByRelevance,
    getShowByMinExperience,
    getShowByMaxExperience,
    getShowByRecent,
    getShowByProjectFilters,
    addSSECandidateDetails,
    getProjectCandidates,
    getShowByPersonalEmails,
    setShowByPersonalEmails,
    getWorkflowEmailStats,
    getWorkflowLinkedInStats,
    setSelectedCandidates,
    removeSelectedCandidate,
    setCandidatePage,
    setSelectAllCandidates,
    setTotalCandidates,
    setWorkflowEmailStats,
    setWorkflowLinkedInStats,
    setCandidateEmails,
    setResumeWorkflow,
    setPageSize,
} = projectPage.actions;

export const selectProjectCandidates = (state: RootState) =>
    state.project.candidates;
export const selectCandidateCurrentPage = (state: RootState) =>
    state.project.candidateCurrentPage;
export const selectProjectStats = (state: RootState) =>
    state.project.projectStats;
export const selectProjectWorkflows = (state: RootState) =>
    state.project.projectWorkflows;
export const selectWorkflowEmailStats = (state: RootState) =>
    state.project.emailStats;
export const selectWorkflowLinkedInStats = (state: RootState) =>
    state.project.linkedInStats;
export const selectSelectedCandidates = (state: RootState) =>
    state.project.selectedCandidates;
export const selectProjectTotalCandidates = (state: RootState) =>
    state.project.totalCandidates;
export const selectProjectAllCandidates = (state: RootState) =>
    state.project.selectAllCandidates;
export const selectShowByRelevance = (state: RootState) =>
    state.project.showByRelevance;
export const selectShowByPersonalEmails = (state: RootState) =>
    state.project.showByPersonalEmails;
export const selectShowByMostRecent = (state: RootState) =>
    state.project.showByMostRecent;
export const selectShowByMinExperience = (state: RootState) =>
    state.project.showByMinExperience;
export const selectShowByMaxExperience = (state: RootState) =>
    state.project.showByMaxExperience;
export const selectProjectFilters = (state: RootState) =>
    state.project.projectFilters;
export const selectPageSize = (state: RootState) => state.project.pageSize;
