import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { type ActionMeta, type SingleValue } from "react-select";

import { Option } from "../../common";
import style from "../../pages/project/components/selectWorkflowModal/index.module.scss";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    changePerson,
    fetchPersons,
    selectAllPersons,
    selectPerson,
    sendSlackMessage,
    setShowModal,
} from "../../store/reducers/slackSend.reducer";
import ButtonTextWithLoading from "../ButtonTextWithLoading";

function CustomFormLabel({ text }: { text: string }) {
    return <Typography>{text}</Typography>;
}

type ContainerProps = {
    label: React.ReactNode;
    control: React.ReactNode;
};

function Container({ label, control }: ContainerProps) {
    return (
        <Stack spacing={1}>
            {label}
            {control}
        </Stack>
    );
}

// function WorkspaceInput() {
//     const dispatch = useDispatch();
//     const [query, setQuery] = useState("");
//     const workspace = useSelector(select);
//     const allWorkspaces = useSelector(selectAllWorkspaces);
//     const handleChange = (
//         newValue: SingleValue<Option>,
//         { action }: ActionMeta<Option>
//     ) => {
//         console.log({ newValue, action });
//         if (action === "select-option") {
//             dispatch(changeWorkspace(newValue));
//         }

//         if (action === "clear" || action === "remove-value") {
//             dispatch(changeWorkspace({ label: "", value: "" }));
//         }
//     };
//     return (
//         <Container
//             control={
//                 <ReactSelect
//                     options={allWorkspaces}
//                     isSearchable={true}
//                     inputValue={query}
//                     onChange={handleChange}
//                     isClearable
//                     classNames={{
//                         container: (_) => style["react-select-container"],
//                         menu: (_) => style["react-select-menu"],
//                     }}
//                     classNamePrefix="react-select"
//                     onInputChange={(newValue) => setQuery(newValue)}
//                     placeholder="Select Project"
//                     value={workspace?.value ? workspace : undefined}
//                 />
//             }
//             label={<CustomFormLabel text="Workspace" />}
//         />
//     );
// }

function PersonsInput() {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const isFetching = useSelector(checkIfLoading(fetchPersons.type));
    const person = useSelector(selectPerson);
    const allPersons = useSelector(selectAllPersons);
    const handleChange = (
        newValue: SingleValue<Option>,
        { action }: ActionMeta<Option>
    ) => {
        if (action === "select-option") {
            dispatch(changePerson(newValue));
        }

        if (action === "clear" || action === "remove-value") {
            dispatch(changePerson({ label: "", value: "" }));
        }
    };
    return (
        <Container
            label={<CustomFormLabel text="Members" />}
            control={
                <ReactSelect
                    // isMulti={true}
                    isLoading={isFetching}
                    options={allPersons}
                    isSearchable={true}
                    inputValue={query}
                    onChange={handleChange}
                    isClearable
                    classNames={{
                        container: (_) => style["react-select-container"],
                        menu: (_) => style["react-select-menu"],
                    }}
                    classNamePrefix="react-select"
                    onInputChange={(newValue) => setQuery(newValue)}
                    placeholder="Select Members"
                    value={person?.value ? person : undefined}
                />
            }
        />
    );
}

// function MessageInput() {
//     const message = useSelector(selectMessage);
//     const dispatch = useDispatch();
//     const messageLength = message.length;
//     const isMessageLarger = messageLength >= 200;
//     return (
//         <Container
//             label={<CustomFormLabel text="Message" />}
//             control={
//                 <Stack spacing={1}>
//                     <StyledTextarea
//                         value={message}
//                         style={{ width: "100%", minHeight: "80px" }}
//                         onChange={(e) =>
//                             dispatch(c(e.target.value))
//                         }
//                     />
//                     <Typography
//                         variant="caption"
//                         style={{
//                             alignSelf: "flex-end",
//                             color: isMessageLarger ? "#ff0000d6" : "#000",
//                         }}
//                     >
//                         {messageLength}/200
//                     </Typography>
//                 </Stack>
//             }
//         />
//     );
// }

export default function SlackSendModal() {
    const dispatch = useDispatch();
    const isSubmitting = useSelector(checkIfLoading(sendSlackMessage.type));

    const closeModal = () => dispatch(setShowModal(false));

    useEffect(() => {
        dispatch(fetchPersons({ action: fetchPersons.type }));
    }, []);

    return (
        <BaseModal onClose={closeModal}>
            <Stack spacing={2}>
                <Typography variant="h5">Send To Slack</Typography>
                <form style={{ width: "100%" }}>
                    <Stack spacing={2}>
                        <PersonsInput />
                        <Stack
                            direction="row"
                            spacing={1.5}
                            justifyContent="flex-end"
                        >
                            <Button>Cancel</Button>
                            <Button
                                disabled={isSubmitting}
                                variant="contained"
                                onClick={() =>
                                    dispatch(
                                        sendSlackMessage({
                                            action: sendSlackMessage.type,
                                        })
                                    )
                                }
                            >
                                <ButtonTextWithLoading
                                    text="Submit"
                                    isLoading={isSubmitting}
                                />
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </BaseModal>
    );
}
