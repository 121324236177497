import { useRef } from "react";
import Portal from "@mui/base/Portal";
import { Button } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
    setAuthorizeModal,
    setCanContinueFlow,
    setContinueFlow,
} from "../../../../components/MailAlert/mailAlert.reducers";
import Nudge from "../../../../components/Nudges/Nudges";
import {
    selectAddToWorkflowNudge,
    selectInstallExtensionNudge,
    selectShowEmailNudge,
} from "../../../../components/Nudges/Nudges.reducer";
import { selectUser } from "../../../Signin/Signin.reducer";
import style from "../../index.module.scss";
import { IProject } from "../../project.types";

export default function AddToWorkflow({
    handleClick,
}: {
    handleClick: () => void;
}) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);
    const showEmailNudge = useSelector(selectShowEmailNudge);
    const projectData = useSelector<any, IProject>((state) =>
        get(state, "allProjects.project")
    );
    const addToWorkflowNudge = useSelector(selectAddToWorkflowNudge);

    const container = useRef<HTMLButtonElement | null>(null);

    const showNudgeCondn =
        installExtensionNudge &&
        showEmailNudge &&
        !addToWorkflowNudge &&
        !(
            projectData?.projectStatus === "IN_PROGRESS" ||
            projectData?.projectStatus === "PENDING"
        );

    const continueFlow = () => {
        dispatch(setAuthorizeModal(false));
        dispatch(setCanContinueFlow(false));
        handleClick();
    };

    const handleReachout = () => {
        // if (!user.emailAuthorized) {
        //     dispatch(setAuthorizeModal(true));
        //     dispatch(setCanContinueFlow(true));
        //     dispatch(setContinueFlow(continueFlow));
        //     return;
        // }
        handleClick();
    };

    return (
        <>
            <Button
                ref={container}
                type="primary"
                shape="round"
                onClick={handleReachout}
                className={style["sp__primaryBtn"]}
                id="addToWorkflow"
            >
                Reach out to candidates
            </Button>
            {showNudgeCondn && (
                <Portal container={container.current}>
                    <Nudge
                        variant="bottom"
                        type="addToWorkflow"
                        text={"Finally, start outreach <br />from here! ✅"}
                        wrapperStyle={{
                            position: "absolute",
                            bottom: "110%",
                            left: "60%",
                            minWidth: 220,
                            textAlign: "left",
                            cursor: "default",
                        }}
                        arrowStyle={{
                            margin: "0 1.5rem 0.75rem",
                        }}
                    />
                </Portal>
            )}
        </>
    );
}
