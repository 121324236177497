import { ReactNode, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import LogoSvg from "../../assets/img/hqlogo.png";
import InstallExtensionModal from "../../components/ChromeExtension/InstallExtensionModal";
import InterActiveWalkthroughModal from "../../components/InterActiveWalkthroughModal/InterActiveWalkthroughModal";
import { selectCanHideWalkThrough } from "../../components/InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import MailAlert from "../../components/MailAlert";
import { selectAuthorizeEmail } from "../../components/MailAlert/mailAlert.reducers";
import MailAuthModal from "../../components/MailAuthModal";
import Sidebar from "../../components/Sidebar";
import {
    selectTokenExpired,
    selectUser,
} from "../../pages/Signin/Signin.reducer";
import TokenExpired from "../../pages/Signin/TokenExpired";
import styles from "./AppContainer.module.css";
import { selectIsExtensionInstalled } from "./AppContainer.reducer";

interface AppContainerProps {
    children: ReactNode;
}

export default function AppContainer({ children }: AppContainerProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const canHideWalkThrough = useSelector(selectCanHideWalkThrough);
    const isTokenExpired = useSelector(selectTokenExpired);
    const openAuthorizeModal = useSelector(selectAuthorizeEmail);
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);

    const isMailAlertVisible = !user.emailAuthorized && !user.isSuperAdmin;

    useEffect(() => {
        (async () => {
            const auth = firebase.auth();
            const webToken = await auth.currentUser?.getIdToken(true);
            const cookie = document.cookie.split("; ");
            const accessTokenString =
                cookie
                    .find((item) => item.indexOf("accessToken") > -1)
                    ?.split("=") || [];
            const token = accessTokenString[1];

            if (!webToken && !token) {
                navigate("/signin", {
                    state: {
                        from: location,
                    },
                });
            }
        })();
    }, []);

    return (
        <>
            <div className={styles.mobileView}>
                <img
                    src={LogoSvg}
                    className={styles.logo}
                    alt="hirequotient-logo"
                />
                <div className={styles.text}>
                    Please try to login using our web application. Currently,{" "}
                    <br />
                    EasySource only supports web application.
                </div>
            </div>
            <div className={styles.app}>
                <div style={{ display: "flex", height: "100%" }}>
                    <Sidebar />
                    <div className={styles.mainContainer}>{children}</div>
                </div>
                {isMailAlertVisible ? <MailAlert /> : null}
                {isTokenExpired && <TokenExpired open={isTokenExpired} />}
                {!canHideWalkThrough && <InterActiveWalkthroughModal />}
                {openAuthorizeModal ? <MailAuthModal /> : null}
                <InstallExtensionModal open={!isExtensionInstalled} />
            </div>
        </>
    );
}
