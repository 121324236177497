import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    changePassword,
    forgotPassword,
    resetPasswords,
    verifyOtp,
} from "./forgotPassword.reducer";
import {
    IChangePassword,
    IForgotPassword,
    IPasswordResponse,
    IVerifyOtp,
} from "./forgotPassword.types";

function* forgotPasswordSaga({
    payload,
}: {
    payload: IForgotPassword & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IPasswordResponse = yield call(
            new API().post,
            "/auth/forgot-password",
            { email: payload.email }
        );
        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error("**forgotPasswordSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* verifyOtpSaga({
    payload,
}: {
    payload: IVerifyOtp & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IPasswordResponse = yield call(
            new API().post,
            "/auth/verify-otp",
            { email: payload.email, code: payload.code }
        );
        if (!response) return;

        if (response.codeVerification) {
            payload.onSuccess();
            yield put(setSuccessNotification(response.message));
        } else yield put(setErrorNotification(response.message));
    } catch (error) {
        console.error("**verifyOtpSagaError", { error });
        yield put(stopAction({ action: payload.action }));
        handleException(error);
    }
}

function* changePasswordSaga({
    payload,
}: {
    payload: IChangePassword & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IPasswordResponse = yield call(
            new API().post,
            "/auth/change-password",
            { email: payload.email, newPassword: payload.newPassword }
        );
        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error("**changePasswordName", { error });
        yield put(stopAction({ action: payload.action }));
        handleException(error);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(forgotPassword.type, forgotPasswordSaga),
        // @ts-ignore
        yield takeLatest(verifyOtp.type, verifyOtpSaga),
        // @ts-ignore
        yield takeLatest(changePassword.type, changePasswordSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetPasswords.type, CancelSagas, tasks);
}
