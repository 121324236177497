import React, { useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addToGreenhouseAgency,
    getGreenhouseJobs,
    selectGreenhouseJobs,
} from "../../../integrations/integrations.reducer";

type GreenhouseModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
};

type GreenhouseState = "PROSPECT" | "JOBS";

export default function GreenhouseModal({
    open,
    onClose,
    id,
    selectedCandidateIds,
}: GreenhouseModalProps) {
    const dispatch = useDispatch();
    const greenhouseJobs = useSelector(selectGreenhouseJobs);
    const isGreenhouseJobsLoading = useSelector(
        checkIfLoading(getGreenhouseJobs.type)
    );
    const isGreenhouseSubmitting = useSelector(
        checkIfLoading(addToGreenhouseAgency.type)
    );

    const [greenhouse, setGreenhouse] = useState<GreenhouseState>("PROSPECT");
    const [jobId, setJobId] = useState("");

    const handleCloseModal = () => {
        setGreenhouse("PROSPECT");
        setJobId("");
        onClose();
    };

    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "JOBS") {
            dispatch(getGreenhouseJobs({ action: getGreenhouseJobs.type }));
        }
        setGreenhouse(e.target.value as GreenhouseState);
    };

    const handleAddGreenhouse = () => {
        if (greenhouse === "JOBS" && jobId === "") {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        dispatch(
            addToGreenhouseAgency({
                projectId: Number(id),
                candidateIds: selectedCandidateIds,
                action: addToGreenhouseAgency.type,
                jobId,
                onSuccess: handleCloseModal,
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth
            maxWidth={"xs"}
        >
            <DialogTitle>Send to Greenhouse</DialogTitle>
            <DialogContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Typography>Select Type:</Typography>
                    <TextField
                        hiddenLabel
                        value={greenhouse}
                        onChange={handleTypeChange}
                        select
                        sx={{
                            "& .MuiSelect-select.MuiInputBase-input": {
                                fontSize: 14,
                            },
                        }}
                    >
                        <MenuItem value="PROSPECT">Prospect</MenuItem>
                        <MenuItem value="JOBS">Candidate</MenuItem>
                    </TextField>
                </Stack>
                {greenhouse === "JOBS" ? (
                    isGreenhouseJobsLoading ? (
                        <Stack alignItems="center" mt={2}>
                            <CircularProgress size={24} />
                        </Stack>
                    ) : greenhouseJobs?.length ? (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt={2}
                            spacing={2}
                        >
                            <Typography>Select Job:</Typography>
                            <TextField
                                hiddenLabel
                                value={jobId}
                                onChange={(e) => setJobId(e.target.value)}
                                select
                                placeholder="Select..."
                                sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                }}
                            >
                                {greenhouseJobs.map((job) => (
                                    <MenuItem value={job.id}>
                                        {job.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    ) : (
                        <Typography
                            fontSize={14}
                            mt={2}
                            textAlign="center"
                            color="red"
                        >
                            No jobs found. Create one on your greenhouse
                            dashboard
                        </Typography>
                    )
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    disabled={isGreenhouseJobsLoading}
                    variant="contained"
                    onClick={handleAddGreenhouse}
                >
                    <ButtonTextWithLoading
                        isLoading={isGreenhouseSubmitting}
                        text={"Confirm"}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
