export const SCRAP_REQ_ACK = 1;
export const SCRAP_REQ_SEARCH_RESULTS = 2;
export const LINKEDIN_PER_PROFILE_RESULT = 3;
export const NON_AUTH_ROUTES = ["/signin", "/signup", "/forgot-password"];
export const IS_HQ_EXTENSION_INSTALLED = "IS_HQ_EXTENSION_INSTALLED";
export const HQ_EASYSOURCE_WEBSITE = "HQ_EASYSOURCE_WEBSITE";
export const HQ_OPEN_IN_NEW_PAGE_EXTENSION = "HQ_OPEN_IN_NEW_PAGE_EXTENSION";
export const HQ_EXTENSION_TOKEN = "HQ_EXTENSION_TOKEN";
export const HQ_EASYSOURCE_EXTENSION = "HQ_EASYSOURCE_EXTENSION"
export const IS_HQ_EXTENSION_INSTALLED_ACK = "IS_HQ_EXTENSION_INSTALLED_ACK";
export const HQ_EXTENSION_ON_BOARDING = "HQ_EXTENSION_ON_BOARDING";
export const FETCH_CANDIDATE_NAMES = "FETCH_CANDIDATE_NAMES";
export const LINKED_CONN_REQ_WORD_LIMIT = 50;
export const EXTENSION_LINK =
    "https://chrome.google.com/webstore/detail/easysource-free-outbound/dnaodjplhbebpfjdkbieelgfgpdcbcph?hl=en";

export const TRACKING = {
    CreateNewProject: "Clicked Create New Project",
    CreateNewProject_JobTitle:
        "<strong>Create new project:</strong> <br />Entered job title",
    CreateNewProject_Skills:
        "<strong>Create new project:</strong> <br />Entered skills",
    CreateNewProject_Education:
        "<strong>Create new project:</strong>  <br />Entered Education",
    CreateNewProject_ProjectName:
        "<strong>Create new project:</strong> <br />Entered project name",
    CreateNewProject_Experience:
        "<strong>Create new project:</strong> <br />Entered experience",
    CreateNewProject_Industry:
        "<strong>Create new project:</strong> <br />Entered industry",
    CreateNewProject_Geography:
        "<strong>Create new project:</strong> <br />Entered geography",
    CreateNewProject_USWorkAuthorization:
        "<strong>Create new project:</strong> <br />Entered US work authorization",
    CreateNewProject_StartSearch:
        "<strong>Create new project:</strong> <br />Clicked start search",
    GoToDashboard: "Clicked Go to dashboard",
    AllProjects: "Clicked All Projects",
    ExtractProfile_SubmitExtractProfiles:
        "<strong>Extract profile:</strong> <br />Submit extract profiles",
    CandidateList: "Clicked project",
    CandidateList_addMoreCandidate:
        "<strong>Candidate list:</strong> <br />Clicked add more candidate",
};
export const FREE_PLAN_POINTS = [
    "25 credits",
    "1 user",
    "Personal Email",
    "Candidate stage/pipeline view",
    "Candidate Relevancy",
    "Send Email, LinkedIn connection request with a note, LinkedIn inmail",
    "Embedded ChatGPT for personalised outreach",
    "Gmail Integration",
    "Email Support",
];

export const PREMIUM_PLAN_POINTS = [
    "1500 credits",
    "Unlimited users",
    "Personal Email",
    "Professional Email",
    "Candidate stage/pipeline view",
    "Candidate Relevancy",
    "Immediate Joiners",
    "US Work Authorization",
    "Send Email, LinkedIn connection request with a note, LinkedIn inmail",
    "Embedded ChatGPT for personalised outreach",
    "Gmail + Outlook Integration",
    "Email Support",
    "Phone Support",
    "Custom Training and Onboarding",
    "Dedicated Account Manager",
];
