import Instructions from "./components/instructions";
import Signup from "./components/signup";
import styles from "./signupOnBoard.module.scss";

const SignupOnBoard = () => {
    return (
        <div className={styles.container}>
            <Instructions />
            <Signup />
        </div>
    );
};

export default SignupOnBoard;
