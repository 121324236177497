import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import { selectHyperPersonalizeMode, setWorkflowMode } from "../index.reducer";

const CustomTabs = styled(Tabs)({
    borderRadius: "0.7rem",
    width: "fit-content",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

const CustomTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        borderRadius: "0.7rem",
        border: 0,
        fontSize: "14px",
        margin: "0.5rem",
        padding: "0.5rem 1rem",
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: "#000",
        "&:hover": {
            opacity: 1,
            color: "#479bd2",
        },
        "&.Mui-selected": {
            color: "white",
            backgroundColor: "#479bd2",
            fontWeight: theme.typography.fontWeightMedium,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "#d1eaff",
        },
    })
);

type InfoBannerProps = {
    children: React.ReactNode;
};

function InfoBanner({ children }: InfoBannerProps) {
    return (
        <Typography
            style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: "73px",
                backgroundColor: "white",
                borderRadius: "0.7rem",
                padding: "1rem",
                zIndex: 1,
            }}
        >
            {children}
        </Typography>
    );
}

export default function WorkflowTabs() {
    const dispatch = useDispatch();
    const [isStandardHover, setStandardHover] = useState(false);
    const [isPersonalizedHover, setPersonalizedHover] = useState(false);
    const isHyperPersonalizedMode = useSelector(selectHyperPersonalizeMode);
    const [value, setValue] = useState(() => (isHyperPersonalizedMode ? 1 : 0));
    const enableStandardHover = () => setStandardHover(true);
    const disableStandardHover = () => setStandardHover(false);

    const enablePersonalizedHover = () => setPersonalizedHover(true);
    const disablePersonalizedHover = () => setPersonalizedHover(false);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        dispatch(
            setWorkflowMode(
                newValue === 0 ? "REGULAR_WORKFLOW" : "PERSONALIZED_WORKFLOW"
            )
        );
    };

    return (
        <Box sx={{ position: "relative" }}>
            <CustomTabs value={value} onChange={handleChange}>
                <CustomTab
                    label="Standard OutReach"
                    onMouseEnter={enableStandardHover}
                    onMouseLeave={disableStandardHover}
                />
                <CustomTab
                    label="Hyper-Personalized OutReach"
                    onMouseEnter={enablePersonalizedHover}
                    onMouseLeave={disablePersonalizedHover}
                />
            </CustomTabs>
            {isStandardHover && (
                <InfoBanner>
                    Empowering users to craft personalized messages from scratch
                    for effective communication.
                </InfoBanner>
            )}
            {isPersonalizedHover && (
                <InfoBanner>
                    Leveraging AI to create tailored messages by analyzing
                    candidate information and job roles, saving time and effort
                    while ensuring impactful outreach.{" "}
                </InfoBanner>
            )}
        </Box>
    );
}
