import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../store";

export interface Option {
    label: string;
    value: string;
}

const initialState = {
    candidates: [],
    selectedCandidate: [],
    canShowAddToProject: false,
    errors: {},
};

const reducers = {
    setCandidates(state: typeof initialState, { payload }: { payload: any }) {
        state.candidates = payload;
    },
    selectCandidate(state: typeof initialState, { payload }: { payload: any }) {
        state.selectedCandidate = payload;
    },
    setCanShowAddToProject(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.canShowAddToProject = payload;
    },
    addToProject(
        state: typeof initialState,
        { payload }: { payload: number }
    ) {},
    createProject(
        state: typeof initialState,
        { payload }: { payload: string }
    ) {},
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "advancedFilterCandidates",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    setCandidates,
    selectCandidate,
    setCanShowAddToProject,
    addToProject,
    createProject,
    leavePage,
} = page.actions;

export const selectCandidates = (state: RootState) =>
    state.advancedFilterCandidates.candidates;

export const selectSelectedCandidate = (state: RootState) =>
    state.advancedFilterCandidates.selectedCandidate;

export const selectCanShowAddToProject = (state: RootState) =>
    state.advancedFilterCandidates.canShowAddToProject;
