import { useCallback, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
    Button,
    Divider,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { Spin } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { formatDate } from "../../../../utils/helper";
import Delay from "../../../editWorkflow/components/delay";
import Email from "../../../editWorkflow/components/email";
import Reminder from "../../../editWorkflow/components/reminder";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { resumeProjectWorkflow } from "../../../workflow/index.reducer";
import { EventBody } from "../../../workflow/workflow.types";
import {
    getProjectWorkflows,
    selectProjectWorkflows,
} from "../../index.reducer";
import { IProjectWorkflows } from "../../project.types";
import style from "./index.module.scss";

interface IWorkflowModal {
    projectId: number;
    onClose: () => void;
}

type WorkflowTableProps = {
    workflow: IProjectWorkflows;
    handleSelectWorkflow: (
        key: {
            isOpen: boolean;
            eventEnum: number;
            eventName: string;
            eventBody: EventBody;
            _id: string;
        }[]
    ) => void;
};

function WorkflowTable({ workflow, handleSelectWorkflow }: WorkflowTableProps) {
    const dispatch = useDispatch();
    const isResumingWorkflow = useSelector(
        checkIfLoading(`${workflow._id}${resumeProjectWorkflow.type}`)
    );

    const isLinkedInWorkflow = workflow.steps.find(
        (item) => item.eventEnum === 4 || item.eventEnum === 6
    );
    const status = workflow.status;

    const handleRowClick = () => {
        if (!workflow?.steps?.length) return;
        const tmpArr = workflow.steps.map((step) => {
            return {
                ...step,
                isOpen: false,
            };
        });

        handleSelectWorkflow(tmpArr);
    };

    const handleResumeWorkflow = () => {
        dispatch(
            resumeProjectWorkflow({
                action: `${workflow._id}${resumeProjectWorkflow.type}`,
                workflowId: workflow._id,
            })
        );
    };

    return (
        <TableRow>
            <TableCell>{workflow.name}</TableCell>
            <TableCell align="center">{workflow.noOfSteps}</TableCell>
            <TableCell align="center">{workflow.numberOfCandidates}</TableCell>
            <TableCell>{formatDate(workflow.triggeredAt)}</TableCell>
            <TableCell
                sx={{
                    color: status === "STOPPED" ? "red" : "black",
                }}
            >
                {status}
            </TableCell>
            <TableCell>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {status === "STOPPED" && (
                        <Tooltip
                            title={
                                isLinkedInWorkflow
                                    ? 'Before clicking "Resume," please make sure to sign in to your LinkedIn account.'
                                    : null
                            }
                        >
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleResumeWorkflow}
                                disabled={isResumingWorkflow}
                            >
                                <ButtonTextWithLoading
                                    isLoading={isResumingWorkflow}
                                    text="Resume"
                                />
                            </Button>
                        </Tooltip>
                    )}
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleRowClick}
                    >
                        View
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    );
}

export default function WorkflowModal({ projectId, onClose }: IWorkflowModal) {
    const dispatch = useDispatch();
    const projectWorkflows = useSelector(selectProjectWorkflows);
    const checkWorkflowsLoading = useSelector(
        checkIfLoading(getProjectWorkflows.type)
    );

    const [selectedWorkflow, setSelectedWorkflow] =
        useState<IProjectWorkflows | null>(null);

    useEffect(() => {
        if (!projectId) return;

        dispatch(
            getProjectWorkflows({ action: getProjectWorkflows.type, projectId })
        );
    }, [projectId]);

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            if (isEmpty(selectedWorkflow)) return;

            let tmpArr = [...selectedWorkflow.steps];
            tmpArr[tarInd].isOpen = !selectedWorkflow.steps[tarInd].isOpen;
            setSelectedWorkflow({
                ...selectedWorkflow,
                steps: tmpArr,
            });
        },
        [selectedWorkflow]
    );

    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                minWidth: 650,
                width: "fit-content",
                padding: "1rem 1.5rem",
                maxHeight: "80vh",
                overflowY: "auto",
            }}
        >
            <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={1}>
                Associated Reachouts
            </Typography>
            <Divider
                sx={{
                    borderBottomWidth: "initial",
                }}
            />
            {checkWorkflowsLoading ? (
                <div className={style["rvp__loading-wrapper"]}>
                    <Spin size={"small"} />
                </div>
            ) : selectedWorkflow?.name ? (
                <>
                    <div className={style["rvp__workflowHeader"]}>
                        <IconButton onClick={() => setSelectedWorkflow(null)}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <p className={style["rvp__workflowHeaderName"]}>
                            {selectedWorkflow.name}
                        </p>
                    </div>
                    <div>
                        {selectedWorkflow.steps.map((val, i) => {
                            if (val.eventEnum === 1) {
                                return (
                                    <Email
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.subject}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"email"}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 3) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"rem"}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 2) {
                                return (
                                    <Delay
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        toggleCollapse={handleCollapseChange}
                                        hours={val.eventBody.hours}
                                        mins={val.eventBody.mins}
                                        days={val.eventBody.days}
                                        weeks={val.eventBody.weeks}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 5) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"linked-in-msg"}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 4) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"connection-request"}
                                        readable={true}
                                    />
                                );
                            } else if (val.eventEnum === 6) {
                                return (
                                    <Email
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.subject}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"in-mail"}
                                        readable={true}
                                    />
                                );
                            } else return null;
                        })}
                    </div>
                </>
            ) : (
                <div className={style["rvp"]}>
                    {projectWorkflows.length ? (
                        <TableContainer
                            component={Paper}
                            sx={{
                                maxHeight: "calc(100vh - 180px)",
                                marginTop: 2,
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Workflow Name</TableCell>
                                        <TableCell>Stages</TableCell>
                                        <TableCell>Contacted</TableCell>
                                        <TableCell>Date & time</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectWorkflows.map((workflow) => (
                                        <WorkflowTable
                                            key={workflow._id}
                                            workflow={workflow}
                                            handleSelectWorkflow={(wk) => {
                                                setSelectedWorkflow({
                                                    ...workflow,
                                                    steps: wk,
                                                });
                                            }}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p className={style["rvp__noItem"]}>
                            No reachouts triggered. To reach out to candidates,
                            start your reachout now.
                        </p>
                    )}
                </div>
            )}
        </BaseModal>
    );
}
