import { Avatar } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import { numberOfNewMessages } from "../../../../utils/helper";
import { IMessageTab } from "../../message.types";
import style from "./index.module.scss";

interface MessageListProps {
    emailData: any;
    tab: IMessageTab;
}

export default function MessageList({ emailData, tab }: MessageListProps) {
    const { id } = useParams();
    const lastEmail = emailData[emailData.length - 1];
    const truncatedBody = lastEmail.body.substring(0, 100);
    const msgBody =
        lastEmail.body.length > 100 ? `${truncatedBody}...` : truncatedBody;

    const candidate = emailData[0].candidate;
    const newMessageCount = numberOfNewMessages(emailData);

    return (
        <Link
            to={`/messages/${tab}/${candidate._id}`}
            className={style["msgl__itemBox"]}
            key={candidate._id}
        >
            <div
                className={`${
                    id === candidate._id ? style["msgl__item-active"] : ""
                } ${style["msgl__item"]}`}
            >
                <Avatar
                    src={
                        candidate?.profileImage?.startsWith("data:")
                            ? candidate?.name
                            : candidate?.profileImage
                    }
                    alt={candidate?.name}
                    className={style["msgl__img"]}
                />
                <div className={style["msgl__detail"]}>
                    <p className={style["msgl__name"]}>
                        {candidate.name}
                        {tab === "inbox" && newMessageCount > 0 ? (
                            <span>{newMessageCount}</span>
                        ) : null}
                    </p>
                    <div
                        className={style["msgl__msg"]}
                        dangerouslySetInnerHTML={{
                            __html: msgBody,
                        }}
                    />
                </div>
                <div className={style["msgl__dateBox"]}>
                    <p className={style["msgl__date"]}>
                        {new Date(lastEmail.createdAt).toLocaleDateString()}
                    </p>
                </div>
            </div>
        </Link>
    );
}
