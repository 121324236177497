import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import {
    setErrorNotification,
    setSuccessNotification,
} from "../../../components/Notification/index.reducer";
import { zohoRecruitLogo } from "../../../constant";
import useOAuth2 from "../../../hooks/useOAuth2";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, setUser } from "../../Signin/Signin.reducer";
import { revokeZohoRecruit } from "../integrations.reducer";
import ConnectWrapper from "./connectWrapper";

function ZohoRecruitConnect() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isRevoking = useSelector(checkIfLoading(revokeZohoRecruit.type));

    const zohoRefreshToken = user.zohoRefreshToken;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `https://accounts.zoho.com/oauth/v2/auth`,
        clientId: `${process.env.REACT_APP_ZOHO_CLIENT_ID}`,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/authorize-zoho`,
        scope: "ZohoRecruit.modules.candidate.ALL",
        responseType: "code",
        extraQueryParameters: { access_type: "offline" },
        exchangeCodeForTokenServerURL: `${process.env.REACT_APP_BASE_URL}/api/zoho/token`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    zohoRefreshToken: response.refresh_token,
                })
            );
            dispatch(setSuccessNotification(response.message));
        },
        onError: () =>
            dispatch(setErrorNotification("Error in connecting zoho recruit")),
    });

    const handleConnect = () => {
        if (zohoRefreshToken) {
            dispatch(
                revokeZohoRecruit({
                    action: revokeZohoRecruit.type,
                })
            );
            return;
        }
        getAuth();
    };

    return (
        <Button
            variant={zohoRefreshToken ? "outlined" : "contained"}
            color={zohoRefreshToken ? "error" : "primary"}
            onClick={handleConnect}
            disabled={loading || isRevoking}
        >
            <ButtonTextWithLoading
                isLoading={loading || isRevoking}
                text={zohoRefreshToken ? "Revoke" : "Connect"}
            />
        </Button>
    );
}

export default function ZohoRecruitAts() {
    return (
        <ConnectWrapper
            logo={zohoRecruitLogo}
            logoAlt="zoho-recruit-logo"
            title="Zoho Recruit"
            description="Zoho Recruit is a cloud based applicant tracking system that’s built to provide diverse, end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce."
            disabled={true}
        >
            <ZohoRecruitConnect />
        </ConnectWrapper>
    );
}
