import { Button, Stack, Typography } from "@mui/material";

import BaseModal from "../../../triggerWorkflow/components/BaseModal";

type DeleteModalProps = {
    open: boolean;
    title: string;
    onClose: () => void;
    onSubmit: () => void;
};

export default function DeleteModal({
    open,
    title,
    onClose,
    onSubmit,
}: DeleteModalProps) {
    if (!open) return null;
    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                padding: "1rem 1.5rem",
            }}
        >
            <Typography>{title}</Typography>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                mt={2}
            >
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                    Delete
                </Button>
            </Stack>
        </BaseModal>
    );
}
