// import { samplePdf } from "../../../../constant";
import style from "./index.module.scss";

export default function ResumeTab() {
    return (
        <div className={style["res"]}>
            {/* <object data={samplePdf} /> */}
            <p>Resume not available!</p>
        </div>
    );
}
