import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";

import { hideWalkThrough } from "./interActiveWalkthorughModal.reducer";

export default function InterActiveWalkthroughModal() {
    const dispatch = useDispatch();

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                zIndex: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
        >
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    position: "relative",
                    paddingBottom: "calc(40.0417% + 41px)",
                    width: "80%",
                    height: 0,
                }}
            >
                <iframe
                    src="https://demo.arcade.software/kNCiI37rsp8ZQkCDUnPJ?embed"
                    title="Easysource Dashboard"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        border: 0,
                        padding: "1rem",
                    }}
                    allowFullScreen
                    data-hj-allow-iframe
                />
                <IconButton
                    style={{
                        position: "absolute",
                        top: "-18px",
                        right: "-25px",
                        backgroundColor: "red",
                        color: "white",
                        padding: "4px",
                    }}
                    onClick={() => dispatch(hideWalkThrough())}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
        </div>
    );
}
