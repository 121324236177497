import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import API from "../../../utils/API";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    addCredits,
    getOrgList,
    leavePage,
    setOrgList,
} from "./orgList.reducers";

function* getWorkflowListSaga(): SagaIterator {
    try {
        const response = yield call(new API().get, "/super-admin/orgList");
        if (!response?.data) return;

        yield put(setOrgList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* addCreditsSaga({
    payload,
}: {
    payload: { credits: number; orgId: number };
}): SagaIterator {
    try {
        const state = yield select();
        const organizations  = JSON.parse(JSON.stringify(get(state, "orgList.organizations" || "")));
        const response = yield call(new API().post, "/super-admin/addCredit", {
            orgId: payload.orgId,
            credits: payload.credits,
        });

        if (!response?.data) return;
        const currentOrg = organizations.find((item: any) => item._id === payload.orgId);
        currentOrg.allCredits.emailFetchCredit = response?.data.allCredits.emailFetchCredit;
        yield put(setOrgList(organizations));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getOrgList.type, getWorkflowListSaga),
        // @ts-ignore
        yield takeLatest(addCredits.type, addCreditsSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
