import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { getAllProjectsList } from "../allProjects/index.reducer";
import {
    acceptInvite,
    addInvitation,
    changeInvitationStatus,
    changeOrgName,
    fetchInvitations,
    fetchMembers,
    fetchPendingInvitations,
    inviteMembers,
    rejectInvite,
    resetTeams,
    setInvitations,
    setMembers,
    setOrgName,
    setPendingInvitations,
} from "./manageTeams.reducers";
import {
    IChangeNameResponse,
    IFetchInvitationsResponse,
    IInvitationUpdatePayload,
    IInvitationUpdateStatusResponse,
    IInvitationsTable,
    IInvitePayload,
    IInviteResponse,
    IMembersResponse,
} from "./manageTeams.types";

function* fetchMembersSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IMembersResponse = yield call(
            new API().get,
            "/organization/members"
        );
        if (!response?.data) return;

        yield put(setMembers(response.data));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* inviteMembersSaga({
    payload,
}: {
    payload: IInvitePayload & IActionPayload;
}): SagaIterator {
    try {
        const { openSlackModal } = payload;
        yield put(startAction({ action: payload.action }));
        const response: IInviteResponse = yield call(
            new API().post,
            "/invitations/create",
            {
                email: payload.email,
                name: payload.name,
                role: payload.role,
            }
        );
        if (!response) return;

        const invite: IInvitationsTable = {
            ...response.data.recipient,
            id: response.data._id,
            status: response.data.status,
            rejectedOn: response.data.updatedAt,
            org: response.data.orgId.name,
            senderName: response.data.sender.name,
        };

        yield put(addInvitation(invite));
        openSlackModal();
        yield put(setSuccessNotification("Invitation sent successfully"));
        handleCleverTap("Invite Member", {
            email: payload.email,
            role: payload.role,
        });
    } catch (error) {
        yield put(setErrorNotification("error while sending invitation"));
        console.error("**inviteMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* changeOrgNameSaga({
    payload,
}: {
    payload: IActionPayload & { name: string };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IChangeNameResponse = yield call(
            new API().post,
            "/organization/edit",
            {
                name: payload.name,
            }
        );

        if (!response?.data) return;
        yield put(setOrgName({ name: response.data.name }));
        // yield put(addInvitation(response.data));
    } catch (error) {
        console.error("**changeOrgName", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchInvitationsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IFetchInvitationsResponse = yield call(
            new API().get,
            "/invitations/get"
        );

        if (!response?.data) return;

        const invitations: IInvitationsTable[] = response.data.map(
            ({
                updatedAt,
                status,
                _id,
                recipient: { ...rest },
                orgId,
                sender,
            }) => ({
                ...rest,
                status,
                rejectedOn: updatedAt,
                id: _id,
                org: orgId.name,
                senderName: sender.name,
            })
        );

        yield put(setInvitations(invitations));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* acceptInvitationSaga({
    payload,
}: {
    payload: IActionPayload & IInvitationUpdatePayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IInvitationUpdateStatusResponse = yield call(
            new API().post,
            "/invitations/edit",
            {
                _id: payload._id,
                status: payload.status,
            }
        );
        if (!response?.data) return;

        yield put(
            changeInvitationStatus({
                _id: response.data._id,
                status: response.data.status,
            })
        );

        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* rejectInvitationSaga({
    payload,
}: {
    payload: IActionPayload & IInvitationUpdatePayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IInvitationUpdateStatusResponse = yield call(
            new API().post,
            "/invitations/edit",
            {
                _id: payload._id,
                status: payload.status,
            }
        );
        if (!response?.data) return;

        yield put(
            changeInvitationStatus({
                _id: response.data._id,
                status: response.data.status,
            })
        );
    } catch (error) {
        yield put(stopAction({ action: payload.action }));
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    }
}

function* pendingInvitationsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IFetchInvitationsResponse = yield call(
            new API().get,
            "/invitations/pending"
        );

        if (!response?.data) return;

        const invitations: IInvitationsTable[] = response.data.map(
            ({
                updatedAt,
                status,
                _id,
                recipient: { ...rest },
                orgId,
                sender,
            }) => ({
                ...rest,
                status,
                rejectedOn: updatedAt,
                id: _id,
                org: orgId.name,
                senderName: sender.name,
            })
        );

        yield put(setPendingInvitations(invitations));
    } catch (error) {
        console.error("**fetchMembersSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchMembers.type, fetchMembersSaga),
        // @ts-ignore
        yield takeLatest(inviteMembers.type, inviteMembersSaga),
        // @ts-ignore
        yield takeLatest(changeOrgName.type, changeOrgNameSaga),
        // @ts-ignore
        yield takeLatest(fetchInvitations.type, fetchInvitationsSaga),
        // @ts-ignore
        yield takeLatest(acceptInvite.type, acceptInvitationSaga),
        // @ts-ignore
        yield takeLatest(rejectInvite.type, rejectInvitationSaga),
        // @ts-ignore
        yield takeLatest(fetchPendingInvitations.type, pendingInvitationsSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetTeams.type, CancelSagas, tasks);
}
