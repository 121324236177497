import ReactQuill from "react-quill";

import { deleteIcon, editIcon } from "../../../../constant";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { nameInitials } from "../../../../utils/helper";
import { selectUser } from "../../../Signin/Signin.reducer";
import { INote } from "../../../allProjects/allProjects.types";
import {
    createNewNote,
    editNote,
    getNotes,
    selectNotes,
} from "../../../allProjects/index.reducer";
import style from "./index.module.scss";

interface notesModalProps {
    id: string;
    closeModal: () => void;
}

export default function NotesModal(props: notesModalProps) {
    const { id, closeModal } = props;

    const notes = useSelector(selectNotes);
    const user = useSelector(selectUser);
    const isNotesLoading = useSelector(checkIfLoading(getNotes.type));
    const isNoteAdding = useSelector(checkIfLoading(createNewNote.type));
    const isNoteEditing = useSelector(checkIfLoading(editNote.type));

    const dispatch = useDispatch();
    const [quillVal, setQuillVal] = useState("");
    const [isEditing, setIsEditing] = useState(-1);

    useEffect(() => {
        dispatch(getNotes({ id, action: getNotes.type }));
    }, []);

    const handleEdit = (index: number, editorVal: string) => {
        setIsEditing(index);
        setQuillVal(editorVal);
    };

    const handleUpdate = () => {
        dispatch(
            editNote({
                candidateId: notes[isEditing].candidateId,
                title: "Note",
                body: quillVal,
                notesId: notes[isEditing]._id,
                action: editNote.type,
                onSuccess: () => {
                    setIsEditing(-1);
                    setQuillVal("");
                },
            })
        );
    };

    const handleDelete = (candidateId: string, _id: number, val: string) => {
        dispatch(
            editNote({
                candidateId: candidateId,
                active: false,
                title: "Notes",
                body: val,
                notesId: _id,
                action: editNote.type,
            })
        );
    };

    const handleCreate = () => {
        dispatch(
            createNewNote({
                candidateId: id,
                title: "Note",
                body: quillVal,
                action: createNewNote.type,
            })
        );
        setQuillVal("");
    };

    return (
        <div className={style["nmodal"]}>
            <div className={style["nmodal__container"]}>
                <ReactQuill value={quillVal} onChange={setQuillVal} />
                <div className={style["nmodal__btnBox"]}>
                    <Button shape="round" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            if (isEditing === -1) handleCreate();
                            else handleUpdate();
                        }}
                        loading={isNoteAdding || isNoteEditing}
                        style={{
                            marginLeft: '1rem',
                            backgroundColor: "#479BD2",
                        }}
                    >
                        {isEditing === -1 ? "Add" : "Update"}
                    </Button>
                </div>
                {isNotesLoading ? (
                    <div className={style["nmodal__loader"]}>
                        <Spin />
                    </div>
                ) : (
                    <div className={style["nmodal__body"]}>
                        {notes.length ? (
                            notes.map((val: INote, i: number) => (
                                <div className={style["nmodal__item"]} key={i}>
                                    {/* @ts-ignore */}
                                    {user.photoURL ? (
                                        <img
                                            //@ts-ignore
                                            src={user.photoURL}
                                            alt="user profile icon"
                                            className={style["nmodal__profile"]}
                                        />
                                    ) : (
                                        <p className={style["nmodal__avatar"]}>
                                            {nameInitials(
                                                //@ts-ignore
                                                user.displayName
                                                    ? //@ts-ignore
                                                      user.displayName
                                                    : "JD"
                                            )}
                                        </p>
                                    )}
                                    <div className={style["nmodal__desc"]}>
                                        <div
                                            className={style["nmodal__text"]}
                                            dangerouslySetInnerHTML={{
                                                __html: val.body,
                                            }}
                                        />
                                        <p className={style["nmodal__date"]}>
                                            {new Date(
                                                val.createdAt
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                    <div className={style["nmodal__action"]}>
                                        <IconButton
                                            onClick={() =>
                                                handleEdit(i, val.body)
                                            }
                                        >
                                            <img
                                                className={
                                                    style["nmodal__icon"]
                                                }
                                                src={editIcon}
                                                alt="edit-icon"
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleDelete(
                                                    val.candidateId,
                                                    val._id,
                                                    val.body
                                                )
                                            }
                                        >
                                            <img
                                                className={
                                                    style["nmodal__icon"]
                                                }
                                                src={deleteIcon}
                                                alt="delete-icon"
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <>No notes found.</>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
