import { useState } from "react";
import { Radio, Select, type RadioChangeEvent, type SelectProps } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNewWorkflow } from "../../../pages/workflow/index.reducer";
import Button from "../../Button";
import style from "./index.module.scss";

interface workflowModalProps {
    closeModal: () => void;
}

const memberDropDown: SelectProps["options"] = [
    { label: "Harsh", value: 1 },
    { label: "Shreya", value: 2 },
    { label: "John", value: 3 },
    { label: "Cena", value: 4 },
    { label: "Kane", value: 5 },
    { label: "Red", value: 6 },
    { label: "Blue", value: 7 },
    { label: "Green", value: 8 },
];

export default function WorkflowModal(props: workflowModalProps) {
    const { closeModal } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visibleVal, setVisibleVal] = useState(1);
    const [selectedMember, setSelectedMember] = useState<number[]>();
    const [wkName, setWkName] = useState("");

    const onRadioChange = (e: RadioChangeEvent) =>
        setVisibleVal(e.target.value);
    const onSelectMemberChange = (value: number[]) => setSelectedMember(value);

    const handleAddWorkflow = function () {
        dispatch(
            createNewWorkflow({
                name: wkName,
                steps: [],
                completed: false,
            })
        );
        closeModal();
        navigate("/workflows");
    };

    return (
        <div className={style["wmodal"]}>
            <div className={style["wmodal__container"]}>
                <div className={style["wmodal__inputBox"]}>
                    <input
                        className={style["wmodal__input"]}
                        type="text"
                        placeholder="Workflow name"
                        value={wkName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setWkName(e.target.value)
                        }
                    />
                </div>

                {/* <div className={style["wmodal__dateBox"]}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date&Time picker"
              value={dayValue}
              onChange={setDayValue}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> */}

                <div className={style["wmodal__item"]}>
                    <label className={style["wmodal__label"]}>
                        Project Visible to:
                    </label>
                    <Radio.Group
                        className={style["wmodal__checkboxGroup"]}
                        onChange={onRadioChange}
                        value={visibleVal}
                    >
                        <Radio value={1}>Only you</Radio>
                        <br />
                        <Radio value={2}>Individual member</Radio>
                        <br />

                        {visibleVal === 2 ? (
                            <div className={style["wmodal__memberBox"]}>
                                <Select
                                    className={style["wmodal__memberInput"]}
                                    mode="multiple"
                                    allowClear
                                    bordered={false}
                                    placeholder="Select Members"
                                    style={{ width: "100%" }}
                                    value={selectedMember}
                                    onChange={onSelectMemberChange}
                                    options={memberDropDown}
                                />
                            </div>
                        ) : (
                            ""
                        )}

                        <br />
                        <Radio value={3}>Everyone in the team</Radio>
                    </Radio.Group>
                </div>

                <div className={style["wmodal__btnBox"]}>
                    <Button
                        label="Cancel"
                        variant="secondary"
                        onClick={closeModal}
                    />
                    <Button
                        label="Create"
                        variant="primary"
                        onClick={handleAddWorkflow}
                    />
                </div>
            </div>
        </div>
    );
}
