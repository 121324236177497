
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import API from "../../../utils/API";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    getHcChatHistory,
    leavePage,
    setHcChatHistory,
    getHcConversation,
    setHcConversation,
} from "./ChatHistory.reducer";

function* getHcChatHistorySaga({
    payload,
}: {
    payload: string
}): SagaIterator {
    try {
        const response = yield call(
            new API().get,
            `/super-admin/hc/conversationList?orgId=${payload}`
        );
        if (!response?.data) return;
        yield put(setHcChatHistory(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}
function* getHcConversationSaga({
    payload,
}: {
    payload: string
}): SagaIterator {
    try {
        const state = yield select();
        const orgId = get(state, 'hcChatHistory.orgId', "");
        if(!orgId || !payload) return;

        const response = yield call(
            new API().get,
            `/super-admin/hc/conversation?orgId=${orgId}&id=${payload}`,
        );

        if (!response?.data?.messages) return;
        yield put(setHcConversation(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getHcChatHistory.type, getHcChatHistorySaga),

        // @ts-ignore
        yield takeLatest(getHcConversation.type, getHcConversationSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
