import { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../../../components/Button";
import { linkedInLogo } from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { shortenTotalExperience } from "../../../../utils/helper";
import {
    getCandidateEmail,
    removeSelectedCandidate,
    selectProjectCandidates,
    setSelectedCandidates,
} from "../../index.reducer";
import {
    ICandidate,
    IProjectView,
    ISelectedCandidateId,
    TotalExperience,
} from "../../project.types";
import style from "./index.module.scss";

const GridView = ({
    data,
    view,
}: {
    data: ICandidate[];
    view: IProjectView;
}) => {
    const candidatePageNo = useSelector<any, number>((state) =>
        get(state, "project.candidateCurrentPage")
    );
    const selectedCandidates = useSelector<any, ISelectedCandidateId[]>(
        (state) => get(state, "project.selectedCandidates")
    );
    const candidates = useSelector(selectProjectCandidates);
    const isSampleData = candidates.every((item) => item.isSample);

    const dispatch = useDispatch();
    const [showEmailBtn, setShowEmailBtn] = useState<boolean>(true);
    const emailLoading = useSelector(checkIfLoading(getCandidateEmail.type));

    useEffect(() => {
        if (data.every((item: ICandidate) => item.canShowEmail)) {
            setShowEmailBtn(false);
        }
    }, []);

    const ShowAllEmail = () => {
        const canShowEmailCandidateIds = data
            .filter((item: ICandidate) => item.email && !item.canShowEmail)
            .map((item: ICandidate) => item._id);

        if (isEmpty(canShowEmailCandidateIds)) return;

        setShowEmailBtn(false);
        dispatch(
            getCandidateEmail({
                action: getCandidateEmail.type,
                candidateIds: canShowEmailCandidateIds,
            })
        );
    };

    const onClickEmail = (candidate: ICandidate) => {
        dispatch(
            getCandidateEmail({
                action: getCandidateEmail.type,
                candidateIds: [candidate._id],
            })
        );
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string, record: ICandidate) => {
                if (view === "gridB") {
                    return (
                        <Link
                            to={record.profileUrl}
                            target={"_blank"}
                            className={style["projectGrid__linkedInImage"]}
                        >
                            <p>{text ? text : "N/A"}</p>
                            <img src={linkedInLogo} alt="linkedin-logo" />
                        </Link>
                    );
                }
                return <p>{text ? text : "N/A"}</p>;
            },
        },
        {
            title: "Experience",
            dataIndex: "totalExperience",
            key: "totalExperience",
            render: (text: TotalExperience, record: ICandidate) => {
                return (
                    <>
                        {record.experience &&
                        record.experience.length &&
                        record.experience[0].position &&
                        record.experience[0].org ? (
                            <div>
                                <p className={style["projectGrid__position"]}>
                                    {record.experience[0].position}
                                </p>
                                <p className={style["projectGrid__org"]}>
                                    {record.experience[0].org
                                        .split("·")[0]
                                        .trim()}{" "}
                                    (
                                    {record.totalExperience
                                        ? shortenTotalExperience(
                                              record.totalExperience.label
                                          ) ?? "N/A"
                                        : "N/A"}
                                    )
                                </p>
                            </div>
                        ) : (
                            "N/A"
                        )}
                    </>
                );
            },
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            render: (text: string) => <p>{text ? text : "N/A"}</p>,
        },
        {
            title: () => (
                <div className={style["projectGrid__emailBox"]}>
                    <p>Email</p>
                    {isSampleData
                        ? null
                        : showEmailBtn && (
                              <Button
                                  label={"Show all"}
                                  variant="primary-outlined"
                                  style={{
                                      marginLeft: 20,
                                      padding: "4px 16px",
                                      fontSize: 14,
                                      borderRadius: 8,
                                  }}
                                  onClick={ShowAllEmail}
                              />
                          )}
                </div>
            ),
            dataIndex: "email",
            key: "email",
            width: 250,
            render: (text: any, record: ICandidate) => {
                return (
                    <>
                        {!record.canShowEmail ? (
                            emailLoading ? (
                                <Spin
                                    size="small"
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                />
                            ) : (
                                <p onClick={() => onClickEmail(record)}>
                                    {text}
                                </p>
                            )
                        ) : text.length ? (
                            text.map((item: string) => (
                                <>
                                    {item}
                                    <br />
                                </>
                            ))
                        ) : (
                            <p className={style["spcard__showHideEmail-text"]}>
                                Personal email not available
                            </p>
                        )}
                        {record.professional_email &&
                        record.professional_email.length ? (
                            <p
                                className={
                                    style["projectGrid__professionalEmail"]
                                }
                                dangerouslySetInnerHTML={{
                                    __html: record.professional_email
                                        .map((email: string) => `${email}<br>`)
                                        .join(""),
                                }}
                            />
                        ) : null}
                    </>
                );
            },
        },
    ];

    if (view === "gridA") {
        columns.splice(1, 0, {
            title: "LinkedIn",
            dataIndex: "profileUrl",
            key: "profileUrl",
            render: (text: string) =>
                text ? (
                    <Link
                        to={text}
                        target={"_blank"}
                        className={style["projectGrid__linkedInColumn"]}
                    >
                        <img src={linkedInLogo} alt="linkedin-logo" />
                    </Link>
                ) : (
                    <>N/A</>
                ),
        });
    }

    const rowSelection = {
        hideSelectAll: true,
        selectedRowKeys: selectedCandidates.map((item) => item.candidateId),
        onSelect: function (record: ICandidate, selected: boolean) {
            if (selected) {
                dispatch(
                    setSelectedCandidates({
                        candidateId: [record._id],
                        pageNo: candidatePageNo,
                    })
                );
            } else {
                dispatch(removeSelectedCandidate(record._id));
            }
        },
    };

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowSelection={rowSelection}
            rowKey={(record) => record._id}
        />
    );
};

export default GridView;
