import { StepType, TourProvider } from "@reactour/tour";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    selectTourCurrentStep,
    setTourCurrentStep,
    setTourIsOpen,
    setTourRelevanceToggle,
    setTourTriggerModal,
} from "./TourContainer.reducer";

const steps: StepType[] = [
    {
        selector: "#card0",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    Extracted candidates are present here, with relevant
                    information about the candidates.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
    {
        selector: ".ant-dropdown-menu-item",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    Keep the relevant candidates on the top. Our AI based
                    relevancy model scores candidates based on their skillsets
                    (listed in different sections of profile), relevant years of
                    experience, availability to join.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
    {
        selector: "#card0ShowHideEmail",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    You can unlock personal email IDs from here. We guarantee
                    100% valid email IDs, and the system will deduct a credit
                    only if the valid personal email ID is available.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
    {
        selector: "#personalEmailToggle",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    Using this toggle, you can filter candidates that have
                    personal email IDs.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
    {
        selector: "#addToWorkflow",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    For outreach, you can add the selected candidates to a
                    workflow from here. Workflow is an customised automation for
                    sending emails, connection requests and in mails.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
    {
        selector: "#wkSidebar",
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    Drag and drop these elements to create your own workflow.
                    You can use ChatGPT recommended templates customised to your
                    job role.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
        highlightedSelectors: ["#wkSidebar", "#wkRecommendation"],
    },
    {
        selector: ".ant-modal-content",
        position: "right",
        styles: {
            popover: (base) => ({
                ...base,
                minWidth: 400,
                color: "#fff",
                borderRadius: 16,
                background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
            }),
        },
        content: ({ currentStep }: { currentStep: number }) => (
            <>
                <p>
                    Schedule the workflow from here and you are done! Our
                    outreach system will optimise to send the emails within your
                    account limits every day to avoid it being flagged.
                </p>
                <p style={{ textAlign: "right", fontSize: 14 }}>
                    Step {currentStep + 1} of {steps.length}
                </p>
            </>
        ),
    },
];

export default function TourContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    const currentStep = useSelector(selectTourCurrentStep);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleStep = (step: number) => {
        switch (step) {
            case 0: {
                dispatch(setTourRelevanceToggle(false));
                dispatch(setTourCurrentStep(step));
                break;
            }
            case 1: {
                dispatch(setTourRelevanceToggle(true));
                setTimeout(() => {
                    dispatch(setTourCurrentStep(step));
                }, 200);
                break;
            }
            case 2: {
                dispatch(setTourRelevanceToggle(false));
                dispatch(setTourCurrentStep(step));
                break;
            }
            case 4: {
                navigate("/projects/9999");
                dispatch(setTourCurrentStep(step));
                break;
            }
            case 5: {
                navigate("/workflows");
                dispatch(setTourTriggerModal(false));
                dispatch(setTourCurrentStep(step));
                break;
            }
            case 6: {
                dispatch(setTourTriggerModal(true));
                setTimeout(() => {
                    dispatch(setTourCurrentStep(step));
                }, 350);
                break;
            }
            default: {
                dispatch(setTourCurrentStep(step));
            }
        }
    };

    const handleClickMask = () => {
        dispatch(setTourIsOpen(false));
        dispatch(setTourTriggerModal(false));
        dispatch(setTourRelevanceToggle(false));
        dispatch(setTourCurrentStep(0));
        navigate("/");
    };

    return (
        <TourProvider
            steps={steps}
            scrollSmooth
            showBadge={false}
            showCloseButton={currentStep === 6}
            currentStep={currentStep}
            setCurrentStep={(val) => handleStep(val as number)}
            onClickMask={handleClickMask}
            onClickClose={handleClickMask}
            disableDotsNavigation
            disableInteraction
            inViewThreshold={100}
            keyboardHandler={(e, clickProps) => {
                if (e.key === "Escape") handleClickMask();
                if (e.key === "ArrowLeft")
                    clickProps?.setCurrentStep(
                        Math.max(clickProps.currentStep - 1, 0)
                    );
                if (e.key === "ArrowRight")
                    clickProps?.setCurrentStep(
                        Math.min(
                            clickProps?.currentStep + 1,
                            (clickProps.steps?.length || 0) - 1
                        )
                    );
            }}
            styles={{
                popover: (base) => ({
                    ...base,
                    minWidth: 500,
                    color: "#fff",
                    borderRadius: 16,
                    background:
                        "linear-gradient(180deg, #215E80 0%, #077991 100%)",
                }),
                close: (base) => ({
                    ...base,
                    color: "#fff",
                    width: 12,
                    height: 12,
                }),
                arrow: (base) => ({ ...base, color: "#fff" }),
            }}
        >
            {children}
        </TourProvider>
    );
}
