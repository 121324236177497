import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Left from "@mui/icons-material/ChevronLeft";
import Right from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tab, { type TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { isEmpty, isUndefined } from "lodash";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import { Candidate } from "../../../pages/triggerWorkflow/types";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { FETCH_CANDIDATE_NAMES } from "../../../utils/Constants";
import handleCleverTap from "../../../utils/clevertap";
import { MainContent } from "../../editWorkflow/components/recommendation";
import styles from "../../editWorkflow/components/recommendation/index.module.scss";
import {
    changeCandidateTemplate,
    fetchSingleCandidateTemplates,
    nextCandidate,
    prevCandidate,
    selectCandidate,
    selectCandidates,
    selectEditTemplatesModalState,
    selectIndex,
    sendUpdatedTemplates,
    setCandidateIndex,
    setEditTemplatesModal,
    submitPersonalizedInputs,
} from "../reducers/personalizedWorkflow.slice";
import quillStyles from "../styles/hyperPersonalizedModal.module.scss";
import BaseModal from "./BaseModal";

const CustomTextField = styled((props: TextFieldProps) => (
    <TextField
        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: "14px",
        top: "-3px",
    },
    "& .MuiInputLabel-shrink": {
        top: 0,
    },
    "& .MuiInputBase-root": {
        fontSize: 13,
    },
    "& .MuiInputBase-input": {
        padding: "10px 12px",
    },
    "& .MuiFilledInput-root": {
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const TransparentTabs = styled(Tabs)({
    "&.MuiTabs-root": {
        minHeight: "fit-content",
        height: "fit-content",
    },
    "& .MuiTabs-scroller": {
        height: "fit-content",
    },
    "& .MuiTabs-flexContainer": {
        justifyContent: "center",
        gap: "0.5rem",
        alignItems: "center",
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
    },
});

const CustomTab = styled((props: TabProps) => <Tab {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        background: "rgba(0, 0, 0, 0.05)",
        fontSize: "12px",
        borderRadius: "10px",
        minHeight: "fit-content",
        padding: "0.5rem",
        color: "#000",
        "&:hover": {
            color: "#479bd2",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#fff",
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: "#479bd2",
        },
    })
);

type SideTabProps = {
    handleClick: () => void;
    label: string;
};

function SideTab({ handleClick, label }: SideTabProps) {
    return (
        <div
            className={styles["wk__emailRecommendation-closed"]}
            onClick={handleClick}
        >
            <svg
                width="10"
                height="20"
                viewBox="0 0 13 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.75 1.51514L2.25 11.0633L11.75 20.6114"
                    stroke="#479BD2"
                    strokeWidth="2"
                />
            </svg>
            <p className={styles["wk__emailRecommendation-closedText"]}>
                {label}
            </p>
        </div>
    );
}

type PaneTitleProps = {
    children: React.ReactNode;
};

function PaneTitle({ children }: PaneTitleProps) {
    return (
        <Stack
            direction="row"
            sx={{ backgroundColor: "#fff", height: "fit-content" }}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            px={1}
            pl={2}
        >
            <Typography
                variant="h6"
                sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
            >
                Personalized Message sample
            </Typography>
            {children}
        </Stack>
    );
}

type PaneNavigationProps = {
    variant?: "HYPER_PERSONALIZATION_PANE" | "DEFAULT";
};

function checkIfTemplatesEmpty(
    candidate: Candidate,
    key: "email" | "inMail" | "connectionReq"
) {
    if (isUndefined(candidate?.[key]) || isEmpty(candidate?.[key])) {
        return true;
    }

    if (isUndefined(candidate?.[key]?.body) || !candidate[key]?.body) {
        return true;
    }

    if ((key === "email" || key === "inMail") && !candidate?.[key]?.subject) {
        return true;
    }

    if (key === "connectionReq" && candidate?.[key]?.body) {
        return true;
    }

    return false;
}

export function PaneNavigation({
    variant = "HYPER_PERSONALIZATION_PANE",
}: PaneNavigationProps) {
    const index = useSelector(selectIndex);
    const isSubmitting = useSelector(checkIfLoading(FETCH_CANDIDATE_NAMES));
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const location = useLocation();
    const selectedProjectFromWorkflows = location?.state?.projectId;
    const isHyperPersonalizedVariant = variant === "HYPER_PERSONALIZATION_PANE";
    const candidate = useSelector(selectCandidate);

    // check whether one of the templates are empty or not otherwise make request again
    const isEmailEmpty = checkIfTemplatesEmpty(candidate, "email");
    const isInMailEmpty = checkIfTemplatesEmpty(candidate, "inMail");
    const isConnReqEmpty = checkIfTemplatesEmpty(candidate, "connectionReq");

    const next = () => {
        dispatch(nextCandidate());
        if (isEmailEmpty || isConnReqEmpty) {
            const id = candidates[index + 1].id;
            // if (isHyperPersonalizedVariant) {
            //     setEditTemplatesModal(true);
            // }
            dispatch(
                fetchSingleCandidateTemplates({
                    action: `${setCandidateIndex.type}_${id}`,
                    id,
                    index: index + 1,
                    projectId: selectedProjectFromWorkflows,
                })
            );
        }
    };
    const prev = () => dispatch(prevCandidate());

    const isPrevDisabled = index <= 0;

    const isNextDisabled =
        variant === "HYPER_PERSONALIZATION_PANE"
            ? index > 2
            : index >= candidates.length - 1;

    if (!candidates?.length) {
        return <Skeleton variant="rounded" width={250} height={40} />;
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns:
                    variant === "HYPER_PERSONALIZATION_PANE"
                        ? "1fr 2fr 1fr"
                        : "20px 1fr 20px",
                gridTemplateRows: "1fr",
                gap: "0.5rem",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
            }}
        >
            <IconButton
                size={isHyperPersonalizedVariant ? "small" : "large"}
                onClick={prev}
                disabled={isPrevDisabled}
                sx={{ width: "fit-content" }}
            >
                <Left fontSize="inherit" />
            </IconButton>
            {isSubmitting ? (
                <Skeleton
                    variant="text"
                    sx={{ fontSize: "16px", width: "100%" }}
                />
            ) : (
                <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{
                        fontSize:
                            variant === "HYPER_PERSONALIZATION_PANE"
                                ? "14px"
                                : "18px",
                        fontWeight:
                            variant === "HYPER_PERSONALIZATION_PANE"
                                ? "500"
                                : "600",
                    }}
                >
                    Candidate {index + 1}: {candidates[index].name}
                </Typography>
            )}
            <IconButton
                size={isHyperPersonalizedVariant ? "small" : "large"}
                onClick={next}
                disabled={isNextDisabled}
                sx={{ width: "fit-content" }}
            >
                <Right fontSize="inherit" />
            </IconButton>
        </div>
    );
}

type EmailProps = {
    subject: string;
    body: string;
};

function Email({ subject, body }: EmailProps) {
    const dispatch = useDispatch();
    const copyText = (text: string, type: string, contentType: string) => {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(setSuccessNotification("Copied"));
        });
        handleCleverTap("Copy workflow template", {
            type,
            contentType,
        });
    };
    return (
        <>
            <MainContent
                title="Subject:"
                onClick={() => copyText(subject, "Email", "Subject")}
                content={subject}
            />
            <br />
            <MainContent
                title="Body:"
                onClick={() => copyText(body, "Email", "Body")}
                content={body}
                type={"BODY"}
            />
        </>
    );
}

type InMailProps = EmailProps;

function InMail({ body, subject }: InMailProps) {
    const dispatch = useDispatch();
    const copyText = (text: string, type: string, contentType: string) => {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(setSuccessNotification("Copied"));
        });
        handleCleverTap("Copy workflow template", {
            type,
            contentType,
        });
    };
    return (
        <>
            <MainContent
                title="Subject:"
                onClick={() => copyText(subject, "Inmail", "Subject")}
                content={subject}
            />
            <br />
            <MainContent
                title="Body:"
                onClick={() => copyText(body, "Inmail", "Body")}
                content={body}
                type={"BODY"}
            />
        </>
    );
}

type LinkedInRequestProps = {
    request_note: string;
};

function LinkedInRequest({ request_note }: LinkedInRequestProps) {
    const dispatch = useDispatch();
    const copyText = (text: string, type: string, contentType: string) => {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(setSuccessNotification("Copied"));
        });
        handleCleverTap("Copy workflow template", {
            type,
            contentType,
        });
    };
    return (
        <MainContent
            title="Note:"
            onClick={() => copyText(request_note, "Note", "")}
            content={request_note}
        />
    );
}

function PaneContent() {
    const [value, setValue] = useState(0);
    const isSubmitting = useSelector(
        checkIfLoading(submitPersonalizedInputs.type)
    );
    const index = useSelector(selectIndex);
    const candidates = useSelector(selectCandidates);
    const candidate = candidates[index];
    const isIndividualTemplatesLoading =
        useSelector(
            checkIfLoading(`${setCandidateIndex.type}_${candidate?.id}`)
        ) || false;
    const isLoading = isIndividualTemplatesLoading || isSubmitting;

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Stack spacing={1}>
            <TransparentTabs value={value} onChange={handleChange}>
                <CustomTab label="Email" />
                <CustomTab label="In-Mail" />
                <CustomTab label="LinkedIn Req." />
            </TransparentTabs>
            <Divider />
            <Box style={{ maxHeight: "80vh", overflow: "auto" }}>
                {isLoading ? (
                    <Stack justifyContent="center" alignItems="center" py={20}>
                        <CircularProgress
                            size={30}
                            style={{
                                color: "#479bd2",
                            }}
                        />
                    </Stack>
                ) : (
                    <Stack p={2} sx={{ paddingBottom: "130px" }}>
                        {value === 0 &&
                        candidate?.email?.subject &&
                        candidate?.email?.body ? (
                            <Email
                                subject={candidate.email?.subject}
                                body={candidate.email?.body}
                            />
                        ) : value === 1 &&
                          candidate?.inMail?.body &&
                          candidate?.inMail.subject ? (
                            <InMail
                                subject={candidate?.inMail?.subject}
                                body={candidate?.inMail?.body}
                            />
                        ) : value === 2 && candidate?.connectionReq?.body ? (
                            <LinkedInRequest
                                request_note={candidate?.connectionReq?.body}
                            />
                        ) : (
                            <Typography
                                variant="body2"
                                textAlign="center"
                                py={10}
                            >
                                Templates Unavailable
                            </Typography>
                        )}
                    </Stack>
                )}
            </Box>
        </Stack>
    );
}

function EditContentFooter() {
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedProjectFromWorkflows = location?.state?.projectId;
    const handleSubmit = () => {
        if (selectedProjectFromWorkflows === "9999") {
            dispatch(setSuccessNotification("Template edited successfully"));
            return;
        }
        dispatch(
            sendUpdatedTemplates({ projectId: selectedProjectFromWorkflows })
        );
    };
    const isSubmitting = useSelector(checkIfLoading(sendUpdatedTemplates.type));
    return (
        <Stack direction="row" spacing={3} justifyContent="space-between">
            <Typography
                variant="caption"
                fontStyle="italic"
                style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
                **The content editing is made on the individual candidate.
            </Typography>
            <Button
                variant="contained"
                sx={{ width: "fit-content" }}
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                <ButtonTextWithLoading isLoading={isSubmitting} text="Submit" />
            </Button>
        </Stack>
    );
}

export function EmptyTemplatePlaceholder() {
    const dispatch = useDispatch();
    const candidate = useSelector(selectCandidate);
    const candidateIndex = useSelector(selectIndex);
    const location = useLocation();
    const selectedProjectFromWorkflows = location?.state?.projectId;
    const handleClick = () => {
        dispatch(
            setCandidateIndex({
                index: candidateIndex,
                action: `${setCandidateIndex.type}_${candidate.id}`,
                id: candidate.id,
                projectId: selectedProjectFromWorkflows,
            })
        );
    };
    return (
        <Stack alignItems="center" spacing={2} py={10}>
            <Typography
                variant="body2"
                textAlign="center"
                style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    maxWidth: "400px",
                }}
            >
                Network error occurred. Please reload.
            </Typography>
            <Button
                variant="contained"
                aria-label="delete"
                size="large"
                style={{
                    height: "fit-content",
                    width: "fit-content",
                    fontSize: "14px",
                    padding: "0.5rem 2rem",
                }}
                onClick={handleClick}
            >
                Reload
            </Button>
        </Stack>
    );
}

function EditEmail() {
    const candidate = useSelector(selectCandidate);
    const dispatch = useDispatch();

    if (
        isUndefined(candidate?.email) ||
        isUndefined(candidate.email?.body) ||
        isUndefined(candidate.email?.subject)
    ) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleSubjectChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "EMAIL",
                subject: value,
            })
        );
    };

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "EMAIL",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <CustomTextField
                label="Subject"
                style={{ flex: 1 }}
                value={candidate.email.subject}
                onChange={(e) => handleSubjectChange(e.target.value)}
            />
            <ReactQuill
                value={candidate.email.body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
            />
            <EditContentFooter />
        </Stack>
    );
}

function EditInMail() {
    const candidate = useSelector(selectCandidate);
    const dispatch = useDispatch();

    if (
        isUndefined(candidate?.inMail) ||
        isUndefined(candidate.inMail?.body) ||
        isUndefined(candidate.inMail?.subject)
    ) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleSubjectChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "IN_MAIL",
                subject: value,
            })
        );
    };

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "IN_MAIL",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <CustomTextField
                label="Subject"
                style={{ flex: 1 }}
                value={candidate.inMail.subject}
                onChange={(e) => handleSubjectChange(e.target.value)}
            />
            <ReactQuill
                value={candidate.inMail.body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
            />
            <EditContentFooter />
        </Stack>
    );
}

function EditConnectionRequest() {
    const candidate = useSelector(selectCandidate);
    const dispatch = useDispatch();

    if (
        isUndefined(candidate?.connectionReq) ||
        isUndefined(candidate.connectionReq?.body)
    ) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "CONN_REQ",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <ReactQuill
                value={candidate.connectionReq.body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
            />
            <EditContentFooter />
        </Stack>
    );
}

function EditFollowUpEmail() {
    const candidate = useSelector(selectCandidate);
    const dispatch = useDispatch();

    if (
        isUndefined(candidate?.followupEmail) ||
        isUndefined(candidate.followupEmail?.body)
    ) {
        return <EmptyTemplatePlaceholder />;
    }

    const handleBodyChange = (value: string) => {
        dispatch(
            changeCandidateTemplate({
                type: "FOLLOW_UP",
                body: value,
            })
        );
    };

    return (
        <Stack spacing={2}>
            <ReactQuill
                value={candidate.followupEmail.body?.replaceAll("\n", "<br>")}
                className={quillStyles.hyperQuill}
                onChange={(value) => handleBodyChange(value)}
            />
            <EditContentFooter />
        </Stack>
    );
}

function EditContent() {
    const [value, setValue] = useState(0);
    const candidate = useSelector(selectCandidate);
    const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const isTemplateLoading = useSelector(
        checkIfLoading(submitPersonalizedInputs.type)
    );
    const isIndividualTemplatesLoading = useSelector(
        checkIfLoading(`${setCandidateIndex.type}_${candidate.id}`)
    );
    const isLoading = isTemplateLoading || isIndividualTemplatesLoading;

    return (
        <Stack spacing={3}>
            <TransparentTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
            >
                <CustomTab label="Email" />
                {/* <CustomTab label="In-Mail" /> */}
                <CustomTab label="LinkedIn Req." />
                <CustomTab label="Follow-up Email" />
            </TransparentTabs>
            {isLoading ? (
                <Stack justifyContent="center" alignItems="center" py={20}>
                    <CircularProgress
                        size={30}
                        style={{
                            color: "#479bd2",
                        }}
                    />
                </Stack>
            ) : candidate?.name ? (
                <>
                    {value === 0 ? (
                        <EditEmail />
                    ) : // ) : value === 1 ? (
                    //     <EditInMail />
                    value === 1 ? (
                        <EditConnectionRequest />
                    ) : value === 2 ? (
                        <EditFollowUpEmail />
                    ) : (
                        <Typography variant="body2" textAlign="center" py={10}>
                            Templates Unavailable
                        </Typography>
                    )}
                </>
            ) : (
                <Typography variant="body2" textAlign="center" py={10}>
                    Templates Unavailable
                </Typography>
            )}
        </Stack>
    );
}

function CandidatesList() {
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const location = useLocation();
    const selectedProjectFromWorkflows = location?.state?.projectId;
    const handleClick = (index: number, id: string) =>
        dispatch(
            setCandidateIndex({
                index,
                action: `${setCandidateIndex.type}_${id}`,
                id,
                projectId: selectedProjectFromWorkflows,
            })
        );
    const candidateIndex = useSelector(selectIndex);
    return (
        <Stack spacing={2}>
            <Typography
                variant="body1"
                textAlign="center"
                fontWeight={600}
                sx={{ fontSize: "16px" }}
            >
                Candidates Name
            </Typography>
            <Stack
                spacing={1.5}
                // sx={{ height: "400px", overflow: "auto" }}
                className={quillStyles.candidateListContainer}
                px={3}
            >
                {candidates.length ? (
                    candidates.map(({ id, name }, index) => {
                        const isIndexMatching = candidateIndex === index;
                        return (
                            <Button
                                key={id}
                                variant="text"
                                onClick={() => handleClick(index, id)}
                                sx={{
                                    borderColor: "transparent",
                                    backgroundColor: isIndexMatching
                                        ? "#479bd2"
                                        : "transparent",
                                    color: isIndexMatching ? "#fff" : "#000",
                                    ":hover": {
                                        backgroundColor:
                                            "rgb(71, 155, 210, 0.1)",
                                        color: "#000",
                                    },
                                }}
                            >
                                {name.length > 21
                                    ? name.slice(0, 21) + "..."
                                    : name}
                            </Button>
                        );
                    })
                ) : (
                    <Typography variant="body2" textAlign="center" py={11}>
                        Candidates Unavailable
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

export function HyperPersonalizationEditModal() {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(setEditTemplatesModal(false));
    return (
        <BaseModal
            onClose={handleClose}
            overlayStyles={{
                width: "60vw",
                height: "80vh",
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "auto 1fr",
                    gap: "2rem",
                }}
            >
                <Typography variant="h6" style={{ flex: 1 }}>
                    Hyper Personalized Messages
                </Typography>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto 2.5fr",
                        gridTemplateRows: "1fr",
                        gap: "1rem",
                    }}
                >
                    <CandidatesList />
                    <Divider orientation="vertical" />
                    <EditContent />
                </div>
            </div>
        </BaseModal>
    );
}

export default function HyperPersonalizationPane() {
    const [showPane, setShowPane] = useState(true);
    const showModal = useSelector(selectEditTemplatesModalState);
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);

    const handleModalOpen = () => dispatch(setEditTemplatesModal(true));

    const handlePaneClose = () => {
        setShowPane(false);
    };

    if (!showPane) {
        return (
            <SideTab
                handleClick={() => setShowPane(true)}
                label="Hyper Personalization"
            />
        );
    }

    return (
        <Stack
            spacing={1}
            py={2}
            sx={{ backgroundColor: "#fff", maxWidth: "350px" }}
            borderRadius={2}
        >
            <PaneTitle>
                <Stack direction="row">
                    <Tooltip title="Edit Personalized Messages">
                        <IconButton size="small" onClick={handleModalOpen}>
                            <SettingsIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close">
                        <IconButton size="small" onClick={handlePaneClose}>
                            <CancelIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </PaneTitle>
            {!!candidates.length && (
                <>
                    <Divider />
                    <PaneNavigation />
                </>
            )}
            <Divider />
            <PaneContent />
            {showModal ? <HyperPersonalizationEditModal /> : null}
        </Stack>
    );
}
