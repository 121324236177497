import { useState } from "react";
import { Table as AntTable } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import style from "./index.module.scss";

interface DataType {
    key: string;
    name: string;
    step: string;
    sentOn: string;
    nextSchedule: string;
    scheduledVia: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => {
            return (
                <div className={style["table__name"]}>
                    <div className={`${style["table__legend"]} bg-red`}></div>
                    <p className={style["table__text"]}>{text}</p>
                </div>
            );
        },
    },
    {
        title: "Step",
        dataIndex: "step",
        key: "step",
        render: (text) => <p className={style["table__text"]}>{text}</p>,
    },
    {
        title: "Trigger on",
        dataIndex: "sentOn",
        key: "sentOn",
        render: (text) => <p className={style["table__subText"]}>{text}</p>,
    },
    {
        title: "Next schedule",
        dataIndex: "nextSchedule",
        key: "nextSchedule",
        render: (text) => <p className={style["table__subText"]}>{text}</p>,
    },
    {
        title: "Project name",
        dataIndex: "scheduledVia",
        key: "scheduledVia",
        render: (text) => <p className={style["table__text"]}>{text}</p>,
    },
];

const data: DataType[] = [
    {
        key: "1",
        name: "Rajesh",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "2",
        name: "Sameual",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "3",
        name: "Shreya",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "4",
        name: "Pratik",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "5",
        name: "Ayush",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "7",
        name: "Name 6",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "8",
        name: "Name 7",
        step: "AS",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "9",
        name: "Name 8",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
    {
        key: "10",
        name: "Name 9",
        step: "1st email",
        sentOn: "Jan 24, 2023 04:53pm",
        nextSchedule: "Jan 29, 2023 05:00pm",
        scheduledVia: "LinkedIn",
    },
];

export default function Table() {
    const [, setSelectedRows] = useState<DataType[]>([]);
    const navigate = useNavigate();

    const rowSelection = {
        onChange: (rowKeys: React.Key[], rows: DataType[]) =>
            setSelectedRows(rows),
    };

    return (
        <div className={style["table"]}>
            <div className={style["table__container"]}>
                <AntTable
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 350 }}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) =>
                                navigate("/workflows/edit/" + record.key),
                        };
                    }}
                />
            </div>
        </div>
    );
}
