import { ChangeEvent, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { editProject } from "../../../allProjects/index.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { IProject } from "../../project.types";

interface IProps {
    open: boolean;
    onClose: () => void;
    data: IProject | null;
}

export default function EditModal(props: IProps) {
    const { open, data, onClose } = props;

    const dispatch = useDispatch();
    const isProjectEditing = useSelector(checkIfLoading(editProject.type));

    const [nameInput, setNameInput] = useState(data?.name);

    const handleSubmit = () => {
        dispatch(
            editProject({
                _id: data?._id,
                name: nameInput,
                action: editProject.type,
                onSuccess: onClose,
            })
        );
    };

    if (!open) return null;
    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                padding: "1rem 1.5rem",
            }}
        >
            <Stack spacing={2}>
                <Typography fontSize={20} fontWeight={500} color="#334d6e">
                    Edit Project Name
                </Typography>
                <TextField
                    type="text"
                    placeholder="Project Name"
                    value={nameInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNameInput(e.target.value)
                    }
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isProjectEditing}
                    >
                        <ButtonTextWithLoading
                            isLoading={isProjectEditing}
                            text="Edit"
                        />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
