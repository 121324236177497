import { useEffect } from "react";
import { useTour } from "@reactour/tour";
import firebase from "firebase/compat/app";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter,
    Route,
    Routes,
    useSearchParams,
} from "react-router-dom";

import "firebase/compat/auth";
import Tap from "@tapfiliate/tapfiliate-js";
import { get } from "lodash";

import Notification from "./components/Notification";
import AppContainer from "./container/AppContainer/AppContainer";
import Signin from "./pages/Signin/Signin";
import {
    authLogin,
    setFromExtension,
    setFromInvitation,
} from "./pages/Signin/Signin.reducer";
import AllProjects from "./pages/allProjects";
import EditWorkflow from "./pages/editWorkflow";
import ForgotPassword from "./pages/forgotPassword";
import Homepage from "./pages/homepage";
import ManageTeam from "./pages/manageTeams";
import Messages from "./pages/message";
import MyAccount from "./pages/myAccount/myAccount";
import Project from "./pages/project/project";
import ProjectWalkthrough from "./pages/projectWalkthrough/projectWalkthrough";
import Signup from "./pages/signup";
import ExtensionTrack from "./pages/superAdmin/extensionTrack/extensionTrack";
import OrgList from "./pages/superAdmin/orgList/orgList";
import UserList from "./pages/superAdmin/userList/userList";
import Template from "./pages/template";
import TriggerWorkflow from "./pages/triggerWorkflow/triggerWorkflow";
import Unsubscribe from "./pages/unsubscribe/unsubscribe";
import Workflow from "./pages/workflow";
import "./App.scss";
import Loader from "./components/Loader";
import { OAuthPopup } from "./components/OAuthPopup";
import SlackConnect from "./components/SlackConnect/SlackConnect";
import ThankYou from "./components/ThankYou/ThankYou";
import {
    listenExtension,
    selectIsExtensionInstalled,
    selectLiBasedToken,
    selectShowLoginLoader,
    setShowLoginLoader,
} from "./container/AppContainer/AppContainer.reducer";
import TourContainer from "./container/TourContainer/TourContainer";
import { selectTourIsOpen } from "./container/TourContainer/TourContainer.reducer";
import AdvancedFilters from "./pages/AdvancedFilters/AdvancedFilters";
import ChatHistory from "./pages/HumanCircles/ChatHistory/ChatHistory";
import HumanCirclesUser from "./pages/HumanCircles/UserList/UserList";
import { signinType } from "./pages/Signin/Signin.types";
import Integrations from "./pages/integrations/integrations";
import ProjectStatistics from "./pages/projectStatistics/projectStatistics";
import SignupOnBoard from "./pages/signupOnBoard/signupOnBoard";
import DailyMetrics from "./pages/superAdmin/dailyMetrics/dailyMetrics";
import FunnelMovement from "./pages/superAdmin/funnelMovement/funnelMovement";

Tap.init(process.env.REACT_APP_TAPFILIATE_ACC_ID);

function App() {
    return (
        <>
            <BrowserRouter>
                <TourContainer>
                    <RoutesContainer />
                </TourContainer>
            </BrowserRouter>
            <Notification />
        </>
    );
}

const RoutesContainer = () => {
    const showLoginLoader = useSelector(selectShowLoginLoader);
    const tourIsOpen = useSelector(selectTourIsOpen);
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    const liBasedToken = useSelector(selectLiBasedToken);

    const { setIsOpen } = useTour();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listenExtension());

        const fromExtension = searchParams.get("fromExtension");
        const fromInvite = searchParams.get("fromInvite");

        if (fromExtension) {
            dispatch(setFromExtension(!!fromExtension));
        }

        if (fromInvite) {
            dispatch(setFromInvitation(!!fromInvite));
        }

        const auth = firebase.auth();
        auth.onAuthStateChanged(async (user) => {
            dispatch(setShowLoginLoader(false));
            if (user) {
                const currentUser: signinType =
                    get(user, "multiFactor.user") || {};
                const { displayName, email } = currentUser;
                dispatch(
                    authLogin({
                        action: authLogin.type,
                        user: currentUser,
                        displayName,
                        email,
                    })
                );
            } else {
                const cookie = document.cookie.split("; ");
                const accessTokenString =
                    cookie
                        .find((item) => item.indexOf("accessToken") > -1)
                        ?.split("=") || [];
                const token = accessTokenString[1];

                if (token) {
                    dispatch(
                        authLogin({
                            action: authLogin.type,
                            user: { name: "", email: "" },
                        })
                    );
                }
            }
        });
    }, []);

    useEffect(() => {
        if (isExtensionInstalled) return;
        if (liBasedToken) {
            dispatch(
                authLogin({
                    action: authLogin.type,
                    user: { name: "", email: "" },
                })
            );
        }
    }, [isExtensionInstalled, liBasedToken]);

    useEffect(() => {
        setIsOpen(tourIsOpen);
    }, [tourIsOpen]);

    return showLoginLoader ? (
        <Loader />
    ) : (
        <Routes>
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/slack-connect" element={<SlackConnect />} />
            <Route
                path="/"
                element={<AppContainer children={<AllProjects />} />}
            />
            <Route
                path="/home"
                element={<AppContainer children={<Homepage />} />}
            />
            <Route
                path="/messages"
                element={<AppContainer children={<Messages />} />}
            />
            <Route
                path="/messages/:status"
                element={<AppContainer children={<Messages />} />}
            />
            <Route
                path="/messages/:status/:id"
                element={<AppContainer children={<Messages />} />}
            />
            <Route
                path="/projects/:id"
                element={<AppContainer children={<Project />} />}
            />
            <Route
                path="/projects/:id/statistics"
                element={<AppContainer children={<ProjectStatistics />} />}
            />
            <Route
                path="/projects/9999"
                element={<AppContainer children={<ProjectWalkthrough />} />}
            />
            <Route
                path="/workflows"
                element={<AppContainer children={<TriggerWorkflow />} />}
            />
            <Route
                path="/workflows/:id"
                element={<AppContainer children={<Workflow />} />}
            />
            <Route
                path="/workflows/edit/:id"
                element={<AppContainer children={<EditWorkflow />} />}
            />
            <Route
                path="/template"
                element={<AppContainer children={<Template />} />}
            />
            <Route
                path="/teams"
                element={<AppContainer children={<ManageTeam />} />}
            />
            <Route
                path="/my-account/:tab"
                element={<AppContainer children={<MyAccount />} />}
            />
            <Route path="/auth/gmail" element={<OAuthPopup />} />
            <Route path="/auth/outlook" element={<OAuthPopup />} />
            <Route
                path="/integrations"
                element={<AppContainer children={<Integrations />} />}
            />
            <Route
                path="/superAdmin/orgList"
                element={<AppContainer children={<OrgList />} />}
            />
            <Route
                path="/superAdmin/userList"
                element={<AppContainer children={<UserList />} />}
            />
            <Route
                path="/superAdmin/daily-metrics"
                element={<AppContainer children={<DailyMetrics />} />}
            />
            <Route
                path="/superAdmin/funnel-movement"
                element={<AppContainer children={<FunnelMovement />} />}
            />
            <Route
                path="/humanCircles/userList"
                element={<AppContainer children={<HumanCirclesUser />} />}
            />
            <Route
                path="/humanCircles/chatsHistory/:orgId"
                element={<AppContainer children={<ChatHistory />} />}
            />
            <Route
                path="/advanced-filter"
                element={<AppContainer children={<AdvancedFilters />} />}
            />
            <Route path="/unsubscribe-emails" element={<Unsubscribe />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/onboarding-signup" element={<SignupOnBoard />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
                path="/extension-track/:id"
                element={<AppContainer children={<ExtensionTrack />} />}
            />
            <Route path="/authorize-zoho" element={<OAuthPopup />} />
            <Route path="/authorize-lever" element={<OAuthPopup />} />
        </Routes>
    );
};

export default App;
