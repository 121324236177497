import { useEffect, useState } from "react";
import { Tabs, type TabsProps } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import handleCleverTap from "../../../../utils/clevertap";
import {
    getChatGptTemplates,
    selectChatGptTemplates,
} from "../../../template/index.reducer";
import { ITabContent, ITemplate } from "../../../template/template.types";
import CopyIcon from "../copyIcon";
import Loader from "../loader";
import style from "./index.module.scss";

export const MainContent = ({
    title,
    onClick,
    content,
    type = "NORMAL",
}: {
    title: string;
    onClick: () => void;
    content: string;
    type?: "BODY" | "NORMAL";
}) => {
    return (
        <>
            <div className={style["wk__emailRecommendation-header-wrapper"]}>
                <p style={{ fontSize: "16px" }}>
                    <b>{title}</b>
                </p>
                <CopyIcon onClick={onClick} />
            </div>
            <p
                style={{ fontSize: "14px" }}
                dangerouslySetInnerHTML={{
                    __html:
                        type === "BODY"
                            ? content?.replaceAll("\n", "<br>")
                            : content,
                }}
            />
        </>
    );
};

export default function Recommendation({ open = false }: { open?: boolean }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const template = useSelector(selectChatGptTemplates);

    const isTemplateLoading = useSelector(
        checkIfLoading(getChatGptTemplates.type)
    );
    const [activeTab, setActiveTab] = useState<string>("email");
    const [closeWindow, setCloseWindow] = useState<boolean>(false);
    const [tabContent, setTabContent] = useState<ITabContent>({
        email: {} as ITemplate,
        inmail: {} as ITemplate,
        request_note: "",
    });

    const fetchTemplates = (key = "email") => {
        const templateData: {
            type: string;
            action: string;
            projectId?: string;
        } = {
            type: key,
            action: getChatGptTemplates.type,
        };
        if (location.state?.projectId)
            templateData.projectId = location.state.projectId;

        dispatch(getChatGptTemplates(templateData));
    };

    const copyText = (text: string, type: string, contentType: any) => {
        handleCleverTap("Copy workflow template", {
            type,
            contentType,
        });

        navigator.clipboard.writeText(text).then(() => {
            dispatch(setSuccessNotification("Copied"));
        });
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => {
        setCloseWindow(open);
    }, [open]);

    useEffect(() => {
        if (isEmpty(template)) return;

        setTabContent({
            ...tabContent,
            [activeTab]: template,
        });
    }, [template]);

    const onChange = (key: string) => {
        setActiveTab(key);
        fetchTemplates(key);
    };

    const items: TabsProps["items"] = [
        {
            key: "email",
            label: `Email`,
            children: (
                <>
                    {isTemplateLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <MainContent
                                title="Subject:"
                                onClick={() =>
                                    copyText(
                                        tabContent.email.subject,
                                        "Email",
                                        "Subject"
                                    )
                                }
                                content={tabContent.email.subject}
                            />
                            <br />
                            <MainContent
                                title="Body:"
                                onClick={() =>
                                    copyText(
                                        tabContent.email.body,
                                        "Email",
                                        "Body"
                                    )
                                }
                                content={tabContent.email.body}
                                type={"BODY"}
                            />
                        </>
                    )}
                </>
            ),
        },
        {
            key: "inmail",
            label: `Inmail`,
            children: (
                <>
                    {isTemplateLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <>
                                <MainContent
                                    title="Subject:"
                                    onClick={() =>
                                        copyText(
                                            tabContent.email.subject,
                                            "Inmail",
                                            "Subject"
                                        )
                                    }
                                    content={tabContent.email.subject}
                                />
                                <br />
                                <MainContent
                                    title="Body:"
                                    onClick={() =>
                                        copyText(
                                            tabContent.email.body,
                                            "Inmail",
                                            "Body"
                                        )
                                    }
                                    content={tabContent.email.body}
                                    type={"BODY"}
                                />
                            </>
                        </>
                    )}
                </>
            ),
        },
        {
            key: "request_note",
            label: `LinkedIn Req.`,

            children: (
                <>
                    {isTemplateLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <MainContent
                                title="Note:"
                                onClick={() =>
                                    copyText(
                                        tabContent.request_note,
                                        "Note",
                                        "Note"
                                    )
                                }
                                content={tabContent.request_note}
                            />
                        </>
                    )}
                </>
            ),
        },
    ];

    if (closeWindow) {
        return (
            <div
                className={style["wk__emailRecommendation-closed"]}
                onClick={() => setCloseWindow(false)}
            >
                <svg
                    width="10"
                    height="20"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.75 1.51514L2.25 11.0633L11.75 20.6114"
                        stroke="#479BD2"
                        strokeWidth="2"
                    />
                </svg>
                <p className={style["wk__emailRecommendation-closedText"]}>
                    Recommendation
                </p>
            </div>
        );
    }

    return (
        <div className={style["wk__emailRecommendation"]} id="wkRecommendation">
            <div className={style["wk__emailRecommendation-header"]}>
                <p className={style["wk__emailRecommendation-title"]}>
                    Recommendation
                </p>
                <div className={style["wk__emailRecommendation-icons"]}>
                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className={style["wk__emailRecommendation-copy"]}
                        onClick={() => fetchTemplates(activeTab)}
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                            clipRule="evenodd"
                        />
                    </svg> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className={style["wk__emailRecommendation-copy"]}
                        onClick={() => setCloseWindow(true)}
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
            </div>
            <div className={style["wk__emailRecommendation-tabs"]}>
                <Tabs
                    defaultActiveKey={activeTab}
                    items={items}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}
