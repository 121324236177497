import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { orgTypes } from "./orgList.types";

const initialState = Object.freeze({
    organizations: [],
    loading: false,
});

const reducers = {
    getOrgList(state: any) {
        state.loading = true;
    },
    setOrgList(state: any, { payload }: { payload: orgTypes[] }) {
        state.loading = false;
        state.organizations = payload;
    },
    addCredits(
        state: any,
        { payload }: { payload: { credits: number; orgId: number } }
    ) {
        state.loading = true;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "orgList",
    initialState,
    reducers,
});

export default page.reducer;
export const { getOrgList, setOrgList, leavePage, addCredits } = page.actions;

// export const selectUser = (state: RootState) =>
// state.signin.user;
// export const selectFromExtension = (state: RootState) =>
//     state.signin.fromExtension;

export const selectOrganizations = (state: RootState) =>
    state.orgList.organizations;
