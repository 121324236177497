import { createSlice } from "@reduxjs/toolkit";
import { MultiValue } from "react-select";

import { RootState } from "../../../../store";
import { advancedFilterType, errorsType, optionType } from "./Filters.type";

export interface Option {
    label: string;
    value: string;
}

const initialState = {
    jobFunction: { label: "", value: "" },
    jobTitles: [],
    jobTitleOptions: [],
    jobTitleSuggestions: [],
    skills: [],
    skillSuggestions: [],
    skillOptions: [],
    experience: [],
    experienceOptions: [
        { value: "0-1", label: "Less than 1 year" },
        { value: "1-2", label: "1 to 2 Years" },
        { value: "3-5", label: "3 to 5 Years" },
        { value: "6-10", label: "5 to 10 Years" },
        { value: "10", label: "More than 10 years" },
    ],
    industry: [],
    industryOptions: [],
    industrySuggestions: [],
    location: [{ label: "United States (USA)", value: "103644278" }],
    locationOptions: [
        { label: "United States (USA)", value: "103644278" },
        { label: "New York, United States", value: "105080838" },
        { label: "California, United States", value: "102095887" },
    ],
    locationSuggestions: [],
    visa: { label: "Yes", value: "yes" },
    visaOptions: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
    ],
    course: "",
    projectName: "My Project",
    education: [],
    educationOptions: [],
    educationSuggestions: [],
    errors: {
        title: "",
        location: "",
    },
};

const reducers = {
    setErrors(
        state: typeof initialState,
        { payload }: { payload: errorsType }
    ) {
        state.errors = payload;
    },
    setJobFunction(
        state: typeof initialState,
        { payload }: { payload: optionType }
    ) {
        state.jobFunction = payload;
    },
    getTypeAhead(state: any, { payload }: { payload: optionType }) {},
    setJobTitleOptions(state: any, { payload }: { payload: optionType[] }) {
        state.jobTitleOptions = payload;
    },
    setLocationOptions(state: any, { payload }: { payload: optionType[] }) {
        state.locationOptions = payload;
    },
    setSkillOptions(state: any, { payload }: { payload: optionType[] }) {
        state.skillOptions = payload;
    },
    setIndustryOptions(state: any, { payload }: { payload: optionType[] }) {
        state.industryOptions = payload;
    },

    setEducationOptions(state: any, { payload }: { payload: optionType[] }) {
        state.educationOptions = payload;
    },
    addJobTitle(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.jobTitles = payload;
    },
    addLocation(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.location = payload;
    },
    addEducation(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.education = payload;
    },
    addSkills(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.skills = payload;
    },
    addIndustry(state: any, { payload }: { payload: MultiValue<optionType> }) {
        state.industry = payload;
    },
    addExperience(
        state: any,
        { payload }: { payload: MultiValue<optionType> }
    ) {
        state.experience = payload;
    },
    searchCandidates(state: any) {},
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "acvancedFilters",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    setErrors,
    setJobFunction,
    addJobTitle,
    addSkills,
    addEducation,
    addLocation,
    addIndustry,
    addExperience,
    getTypeAhead,
    setJobTitleOptions,
    setLocationOptions,
    setSkillOptions,
    setEducationOptions,
    setIndustryOptions,
    searchCandidates,
    leavePage,
} = page.actions;

export const selectJobFunction = (state: RootState) =>
    state.advancedFilters.jobFunction;

export const selectJobTitles = (state: RootState) =>
    state.advancedFilters.jobTitles;
export const selectSkills = (state: RootState) => state.advancedFilters.skills;
export const selectExperience = (state: RootState) =>
    state.advancedFilters.experience;
export const selectIndustry = (state: RootState) =>
    state.advancedFilters.industry;
export const selectLocations = (state: RootState) =>
    state.advancedFilters.location;

export const selectJobTitleOptions = (state: RootState) =>
    state.advancedFilters.jobTitleOptions;
export const selectLocationOptions = (state: RootState) =>
    state.advancedFilters.locationOptions;
export const selectSkillOptions = (state: RootState) =>
    state.advancedFilters.skillOptions;
export const selectEducationOptions = (state: RootState) =>
    state.advancedFilters.educationOptions;
export const selectIndustryOptions = (state: RootState) =>
    state.advancedFilters.industryOptions;
export const selectVisaOptions = (state: RootState) =>
    state.advancedFilters.visaOptions;
export const selectExperienceOptions = (state: RootState) =>
    state.advancedFilters.experienceOptions;
export const selectErrors = (state: RootState) => state.advancedFilters.errors;
