import { useEffect, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { get, isEmpty } from "lodash";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { arrowDownIcon, trashGrayMedium } from "../../constant";
import NewTemplateModal from "./components/templateModal";
import style from "./index.module.scss";
import { editTemplate, getTemplateList } from "./index.reducer";

export default function Template() {
    const dispatch = useDispatch();
    const templateList = useSelector((state) =>
        get(state, "template.templateList")
    );
    const [templateData, setTemplateData] = useState({
        email: [],
        inmail: [],
        conn: [],
    });

    const [type, setType] = useState("");
    const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
    const showNewModal = () => setIsNewModalOpen(true);
    const closeNewModal = () => setIsNewModalOpen(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => {
        setCurrTemp({});
        setIsDeleteModalOpen(false);
    };

    const [quillVal, setQuillVal] = useState(["", "", ""]);
    const [nameVal, setNameVal] = useState(["", "", ""]);
    const [subjectVal, setSubjectVal] = useState(["", "", ""]);
    const [currTemp, setCurrTemp] = useState({});

    useEffect(() => {
        dispatch(getTemplateList());
    }, []);

    useEffect(() => {
        if (isEmpty(currTemp)) return;

        //@ts-ignore
        if (currTemp.action === "delete") {
            showDeleteModal();
        } else {
            let index = -1;

            //@ts-ignore
            if (currTemp.type === "CONNECTION_REQUEST") index = 0;
            //@ts-ignore
            else if (currTemp.type === "EMAIL") index = 1;
            //@ts-ignore
            else if (currTemp.type === "INMAIL") index = 2;

            let tmpEditor = [...quillVal];
            //@ts-ignore
            tmpEditor[index] = currTemp.body;
            let tmpSubject = [...subjectVal];
            //@ts-ignore
            tmpSubject[index] = currTemp.subject;
            let tmpName = [...nameVal];
            //@ts-ignore
            tmpName[index] = currTemp.name;

            setQuillVal(tmpEditor);
            setNameVal(tmpName);
            setSubjectVal(tmpSubject);
        }
    }, [currTemp]);

    useEffect(() => {
        if (isEmpty(templateList)) return;

        let tmp = { email: [], inmail: [], conn: [] };

        //@ts-ignore
        for (let val of templateList) {
            //@ts-ignore
            if (val.type === "EMAIL") tmp.email.push(val);
            //@ts-ignore
            else if (val.type === "INMAIL") tmp.inmail.push(val);
            //@ts-ignore
            else if (val.type === "CONNECTION_REQUEST") tmp.conn.push(val);
        }

        setTemplateData(tmp);
    }, [templateList]);

    useEffect(() => {
        if (!type) return;
        showNewModal();
    }, [type]);

    const handleSelect = (val: any) => {
        //@ts-ignore
        setCurrTemp(val);
    };

    const handleEdit = () => {
        let payload = { ...currTemp };

        let index = -1;

        //@ts-ignore
        if (currTemp.type === "CONNECTION_REQUEST") index = 0;
        //@ts-ignore
        else if (currTemp.type === "EMAIL") index = 1;
        //@ts-ignore
        else if (currTemp.type === "INMAIL") index = 2;
        //@ts-ignore
        payload.name = nameVal[index];
        //@ts-ignore
        payload.subject = subjectVal[index];
        //@ts-ignore
        payload.body = quillVal[index];

        let tmpEditor = [...quillVal];
        tmpEditor[index] = "";

        let tmpSubject = [...subjectVal];
        tmpSubject[index] = "";

        let tmpName = [...nameVal];
        tmpName[index] = "";

        dispatch(editTemplate(payload));
        setCurrTemp({});
        setQuillVal(tmpEditor);
        setNameVal(tmpName);
        setSubjectVal(tmpSubject);
    };

    const handleDelete = () => {
        dispatch(
            editTemplate({
                //@ts-ignore
                name: currTemp.name,
                //@ts-ignore
                type: currTemp.type,
                //@ts-ignore
                body: currTemp.body,
                //@ts-ignore
                active: false,
                //@ts-ignore
                _id: currTemp._id,
            })
        );

        setCurrTemp({});
        closeDeleteModal();
    };

    const handleQuillChange = (index: number, newVal: string) => {
        let tmp = [...quillVal];
        tmp[index] = newVal;
        setQuillVal(tmp);
    };

    const addTemplateDropDown: MenuProps["items"] = [
        {
            label: (
                <p
                    className={style["tmp__link"]}
                    onClick={() => setType("Connection Request")}
                >
                    Connection Request
                </p>
            ),
            key: "0",
        },
        {
            type: "divider",
        },
        {
            label: (
                <p
                    className={style["tmp__link"]}
                    onClick={() => setType("Inmail")}
                >
                    Inmail
                </p>
            ),
            key: "1",
        },
        {
            type: "divider",
        },
        {
            label: (
                <p
                    className={style["tmp__link"]}
                    onClick={() => setType("Email")}
                >
                    Email
                </p>
            ),
            key: "2",
        },
    ];

    const personaliseDropDown: MenuProps["items"] = [
        {
            label: (
                <p
                    className={style["tmp__link"]}
                    onClick={() =>
                        navigator.clipboard.writeText("{{candidate.name}}")
                    }
                >
                    Name
                </p>
            ),
            key: "0",
        },
        {
            type: "divider",
        },
        {
            label: (
                <p
                    className={style["tmp__link"]}
                    onClick={() =>
                        navigator.clipboard.writeText("{{candidate.email}}")
                    }
                >
                    Email
                </p>
            ),
            key: "1",
        },
    ];

    return (
        <div className={style["tmp"]}>
            <div className={style["tmp__container"]}>
                <Navbar />

                <div className={style["tmp__header"]}>
                    <div className={style["tmp__heading"]}>
                        <p>Templates</p>
                    </div>

                    <div className={style["tmp__dateBox"]}>
                        <Dropdown
                            trigger={["click"]}
                            menu={{ items: addTemplateDropDown }}
                        >
                            <Button
                                label="Add new template"
                                variant="primary"
                            />
                        </Dropdown>
                    </div>
                </div>

                <div className={style["tmp__main"]}>
                    <div className={style["tmp__body"]}>
                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Connection Request</h3>
                            </div>

                            {templateData.conn.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a
                                    new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.conn.map((val, i) => (
                                        <div
                                            className={style["tmp__checkBox"]}
                                            key={i}
                                        >
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div
                                                className={
                                                    style["tmp__actionWrapper"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__actionBox"]
                                                    }
                                                >
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore 
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.conn.length &&
                                        (isEmpty(currTemp) ||
                                            //@ts-ignore
                                            currTemp.type !==
                                                "CONNECTION_REQUEST" ||
                                            //@ts-ignore
                                            currTemp.action === "delete") ? (
                                            <p
                                                className={
                                                    style["tmp__placeholder"]
                                                }
                                            >
                                                Select a templete to edit
                                            </p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                    //@ts-ignore
                                    currTemp.type === "CONNECTION_REQUEST" &&
                                    //@ts-ignore
                                    currTemp.action !== "delete" ? (
                                        <>
                                            <div
                                                className={
                                                    style["tmp__nameBox"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__name"]
                                                    }
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[0]}
                                                        onChange={(e) =>
                                                            setNameVal([
                                                                e.target.value,
                                                                nameVal[1],
                                                                nameVal[2],
                                                            ])
                                                        }
                                                    />
                                                </div>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseDropDown,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            style["tmp__select"]
                                                        }
                                                    >
                                                        <p>
                                                            Personalise &nbsp;{" "}
                                                            <img
                                                                src={
                                                                    arrowDownIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </p>
                                                    </div>
                                                </Dropdown>
                                            </div>

                                            <div
                                                className={
                                                    style["tmp__textareaBox"]
                                                }
                                            >
                                                <ReactQuill
                                                    value={quillVal[0]}
                                                    onChange={(e) =>
                                                        handleQuillChange(0, e)
                                                    }
                                                />

                                                <div
                                                    className={
                                                        style["tmp__btnBox"]
                                                    }
                                                >
                                                    <Button
                                                        label="Save"
                                                        variant="primary"
                                                        onClick={handleEdit}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Email Template</h3>
                            </div>

                            {templateData.email.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a
                                    new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.email.map((val, i) => (
                                        <div
                                            className={style["tmp__checkBox"]}
                                            key={i}
                                        >
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div
                                                className={
                                                    style["tmp__actionWrapper"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__actionBox"]
                                                    }
                                                >
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        //@ts-ignore
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.email.length &&
                                        (isEmpty(currTemp) ||
                                            //@ts-ignore
                                            currTemp.type !== "EMAIL" ||
                                            //@ts-ignore
                                            currTemp.action === "delete") ? (
                                            <p
                                                className={
                                                    style["tmp__placeholder"]
                                                }
                                            >
                                                Select a templete to edit
                                            </p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                    //@ts-ignore
                                    currTemp.type === "EMAIL" &&
                                    //@ts-ignore
                                    currTemp.action !== "delete" ? (
                                        <>
                                            <div
                                                className={
                                                    style["tmp__nameBox"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__name"]
                                                    }
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[1]}
                                                        onChange={(e) =>
                                                            setNameVal([
                                                                nameVal[0],
                                                                e.target.value,
                                                                nameVal[2],
                                                            ])
                                                        }
                                                    />
                                                </div>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseDropDown,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            style["tmp__select"]
                                                        }
                                                    >
                                                        <p>
                                                            Personalise &nbsp;{" "}
                                                            <img
                                                                src={
                                                                    arrowDownIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </p>
                                                    </div>
                                                </Dropdown>
                                            </div>

                                            <div
                                                className={
                                                    style["tmp__subjectBox"]
                                                }
                                            >
                                                <p>Subject : </p>
                                                <input
                                                    type="text"
                                                    placeholder="Subject"
                                                    value={subjectVal[1]}
                                                    onChange={(e) =>
                                                        setSubjectVal([
                                                            subjectVal[0],
                                                            e.target.value,
                                                            subjectVal[2],
                                                        ])
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    style["tmp__textareaBox"]
                                                }
                                            >
                                                <ReactQuill
                                                    value={quillVal[1]}
                                                    onChange={(e) =>
                                                        handleQuillChange(1, e)
                                                    }
                                                />

                                                <div
                                                    className={
                                                        style["tmp__btnBox"]
                                                    }
                                                >
                                                    <Button
                                                        label="Save"
                                                        variant="primary"
                                                        onClick={handleEdit}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Inmail Template</h3>
                            </div>

                            {templateData.inmail.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a
                                    new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.inmail.map((val, i) => (
                                        <div
                                            className={style["tmp__checkBox"]}
                                            key={i}
                                        >
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div
                                                className={
                                                    style["tmp__actionWrapper"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__actionBox"]
                                                    }
                                                >
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.conn.length &&
                                        (isEmpty(currTemp) ||
                                            //@ts-ignore
                                            currTemp.type !== "INMAIL" ||
                                            //@ts-ignore
                                            currTemp.action === "delete") ? (
                                            <p
                                                className={
                                                    style["tmp__placeholder"]
                                                }
                                            >
                                                Select a templete to edit
                                            </p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                    //@ts-ignore
                                    currTemp.type === "INMAIL" &&
                                    //@ts-ignore
                                    currTemp.action !== "delete" ? (
                                        <>
                                            <div
                                                className={
                                                    style["tmp__nameBox"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["tmp__name"]
                                                    }
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[2]}
                                                        onChange={(e) =>
                                                            setNameVal([
                                                                nameVal[0],
                                                                nameVal[1],
                                                                e.target.value,
                                                            ])
                                                        }
                                                    />
                                                </div>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseDropDown,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            style["tmp__select"]
                                                        }
                                                    >
                                                        <p>
                                                            Personalise &nbsp;{" "}
                                                            <img
                                                                src={
                                                                    arrowDownIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </p>
                                                    </div>
                                                </Dropdown>
                                            </div>

                                            <div
                                                className={
                                                    style["tmp__subjectBox"]
                                                }
                                            >
                                                <p>Subject : </p>
                                                <input
                                                    type="text"
                                                    placeholder="Subject"
                                                    value={subjectVal[2]}
                                                    onChange={(e) =>
                                                        setSubjectVal([
                                                            subjectVal[0],
                                                            subjectVal[1],
                                                            e.target.value,
                                                        ])
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    style["tmp__textareaBox"]
                                                }
                                            >
                                                <ReactQuill
                                                    value={quillVal[2]}
                                                    onChange={(e) =>
                                                        handleQuillChange(2, e)
                                                    }
                                                />

                                                <div
                                                    className={
                                                        style["tmp__btnBox"]
                                                    }
                                                >
                                                    <Button
                                                        label="Save"
                                                        variant="primary"
                                                        onClick={handleEdit}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    open={isNewModalOpen}
                    onOk={closeNewModal}
                    onCancel={closeNewModal}
                    title={`New ${type} Template`}
                    width={"70%"}
                >
                    <NewTemplateModal type={type} closeModal={closeNewModal} />
                </Modal>

                <Modal
                    open={isDeleteModalOpen}
                    onOk={closeDeleteModal}
                    onCancel={closeDeleteModal}
                    title={"Delete Template"}
                    width={"50%"}
                >
                    <div className={style["tmp__delBox"]}>
                        <Button
                            label="Cancel"
                            variant="secondary"
                            onClick={closeDeleteModal}
                        />
                        <Button
                            label="Confirm"
                            variant="primary"
                            onClick={handleDelete}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
