import { useEffect, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { isEmpty } from "lodash";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import {
    getTemplateList,
    selectTemplateList,
} from "../../../template/index.reducer";
import "react-quill/dist/quill.snow.css";
import { Button } from "@mui/material";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import InputWithWordCount from "../../../../components/InputWithWordCount/InputWithWordCount";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import quillWordCount from "../../../../utils/quillWordCount";
import { sendEmail } from "../../../allProjects/index.reducer";
import { ITemplateList } from "../../../template/template.types";
import style from "./index.module.scss";

type MsgModalProps = {
    handleSendMsg: () => void;
    msgValue: string;
    setMsgValue: React.Dispatch<React.SetStateAction<string>>;
    msgSubject: string;
    setMsgSubject: React.Dispatch<React.SetStateAction<string>>;
};

export default function MsgModal(props: MsgModalProps) {
    const { handleSendMsg, msgValue, setMsgValue, msgSubject, setMsgSubject } =
        props;

    const dispatch = useDispatch();
    const templateList = useSelector(selectTemplateList);
    const isEmailSending = useSelector(checkIfLoading(sendEmail.type));

    const [tempDropDown, setTempDropDown] = useState<MenuProps["items"]>([]);

    useEffect(() => {
        dispatch(getTemplateList());
    }, []);

    useEffect(() => {
        if (isEmpty(templateList)) return;
        let tmp: ITemplateList = { email: [], inmail: [], conn: [] };
        for (let val of templateList) {
            if (val.type === "EMAIL") tmp.email.push(val);
            else if (val.type === "INMAIL") tmp.inmail.push(val);
            else if (val.type === "CONNECTION_REQUEST") tmp.conn.push(val);
        }

        const dropDown = [];
        for (let i = 0; i < tmp.email.length; i++) {
            dropDown.push({
                label: (
                    <p
                        className={style["tab__link"]}
                        onClick={() => {
                            setMsgValue(tmp.email[i].body);
                            setMsgSubject(tmp.email[i].subject);
                        }}
                    >
                        {tmp.email[i].name}
                    </p>
                ),
                key: i.toString(),
            });
        }
        setTempDropDown(dropDown);
    }, [templateList]);

    const handleChange = (val: string) => {
        setMsgSubject(val);
    };

    const wordCount = quillWordCount(msgValue);

    return (
        <div className={style["tab"]}>
            <div className={style["tab__container"]}>
                <div className={style["headerContainer"]}>
                    <div className={style["tab__selectBox"]}>
                        <Dropdown
                            trigger={["click"]}
                            menu={{ items: tempDropDown }}
                        >
                            <div className={style["tab__select"]}>
                                <p>Select Template</p>
                            </div>
                        </Dropdown>
                    </div>
                    <div className={style["tab__ccInputBox"]}>
                        <label>Subject :</label>
                        <InputWithWordCount
                            wrapperStyle={{
                                marginLeft: "0.5rem",
                            }}
                            inputStyle={{
                                minWidth: 350,
                            }}
                            wordLimit={10}
                            customValue={msgSubject}
                            handleOnChange={handleChange}
                        />
                    </div>
                </div>
                <div className={style["tab__quillBox"]}>
                    <ReactQuill value={msgValue} onChange={setMsgValue} />
                    <p className={style["bodyWords"]}>
                        <span
                            style={{
                                color: wordCount > 125 ? "red" : "inherit",
                            }}
                        >
                            {wordCount}
                        </span>
                        /125 words
                    </p>
                </div>
                <div className={style["tab__btnBox"]}>
                    <Button
                        variant="contained"
                        disabled={isEmailSending}
                        onClick={handleSendMsg}
                    >
                        <ButtonTextWithLoading
                            isLoading={isEmailSending}
                            text="Send"
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
}
