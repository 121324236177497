import {
    Box,
    CircularProgress,
    Stack,
    StackProps,
    Typography,
    styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { googleLogo, outlookLogo } from "../../constant";
import useOAuth2 from "../../hooks/useOAuth2";
import { selectUser, setUser } from "../../pages/Signin/Signin.reducer";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import style from "../MailAlert/index.module.scss";
import {
    selectCanContinueFlow,
    selectContinueFlow,
    setAuthorizeModal,
} from "../MailAlert/mailAlert.reducers";
import {
    setErrorNotification,
    setSuccessNotification,
} from "../Notification/index.reducer";

const CustomStack = styled((props: StackProps) => <Stack {...props} />)(() => ({
    cursor: "pointer",
    borderRadius: 8,
    padding: "20px 50px",
    border: "1px solid #c4c4c4",
    width: "100%",
    "&:hover": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const CustomStack2 = styled((props: StackProps) => <Stack {...props} />)(
    () => ({
        cursor: "pointer",
        borderRadius: 8,
        width: "100%",
        maxWidth: 300,
        padding: "0.5rem 1rem",
        border: "1px solid #c4c4c4",
        "&:hover": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    })
);

function GmailConnect({ variant }: { variant: "horizontal" | "vertical" }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
        clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/auth/gmail`,
        scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.modify",
        responseType: "code",
        extraQueryParameters: {
            prompt: "consent",
            access_type: "offline",
        },
        exchangeCodeForTokenServerURL: `${process.env.REACT_APP_BASE_URL}/api/auth/update-gmail-auth`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    emailAuthorized: true,
                })
            );
            dispatch(setAuthorizeModal(false));
            dispatch(setSuccessNotification(response.message));
        },
        onError: () =>
            dispatch(setErrorNotification("Error in connecting gmail")),
    });

    const handleConnect = () => {
        if (loading) return;
        getAuth();
    };

    if (variant === "vertical") {
        return (
            <CustomStack2
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{
                    opacity: loading ? 0.5 : 1,
                }}
                onClick={handleConnect}
            >
                {loading ? (
                    <CircularProgress size={26} />
                ) : (
                    <>
                        <Box
                            component="img"
                            sx={{
                                width: 25,
                                height: "auto",
                            }}
                            alt="google-logo"
                            src={googleLogo}
                        />
                        <Typography>Gmail</Typography>
                    </>
                )}
            </CustomStack2>
        );
    }
    return (
        <CustomStack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                opacity: loading ? 0.5 : 1,
            }}
            onClick={handleConnect}
        >
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <>
                    <Box
                        component="img"
                        className={style["app__mailprovider-gmail"]}
                        alt="google-logo"
                        src={googleLogo}
                    />
                    <p className={style["app__mailprovider-text"]}>Gmail</p>
                </>
            )}
        </CustomStack>
    );
}

function OutlookConnect({ variant }: { variant: "horizontal" | "vertical" }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const { loading, getAuth } = useOAuth2({
        authorizeUrl:
            "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
        clientId: `${process.env.REACT_APP_OUTLOOK_CLIENT_ID}`,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/auth/outlook`,
        scope: "Mail.Send Mail.Read offline_access openid profile User.Read",
        responseType: "code",
        extraQueryParameters: {
            response_mode: "query",
        },
        exchangeCodeForTokenServerURL: `${process.env.REACT_APP_BASE_URL}/api/auth/update-outlook-auth`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    emailAuthorized: true,
                })
            );
            dispatch(setAuthorizeModal(false));
            dispatch(setSuccessNotification(response.message));
        },
        onError: () =>
            dispatch(setErrorNotification("Network error, please try again!")),
    });

    const handleConnect = () => {
        if (loading) return;
        getAuth();
    };

    if (variant === "vertical") {
        return (
            <CustomStack2
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                    opacity: loading ? 0.5 : 1,
                }}
                onClick={handleConnect}
            >
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <Box
                        component="img"
                        sx={{
                            width: 100,
                            height: "auto",
                        }}
                        alt="outlook-logo"
                        src={outlookLogo}
                    />
                )}
            </CustomStack2>
        );
    }
    return (
        <CustomStack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                opacity: loading ? 0.5 : 1,
            }}
            onClick={handleConnect}
        >
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <Box
                    component="img"
                    className={style["app__mailprovider-outlook"]}
                    alt="outlook-logo"
                    src={outlookLogo}
                />
            )}
        </CustomStack>
    );
}

export function MailAuthConnect({
    variant,
}: {
    variant: "horizontal" | "vertical";
}) {
    return (
        <Stack
            direction={variant === "vertical" ? "column" : "row"}
            alignItems="center"
            spacing={2}
        >
            <GmailConnect variant={variant} />
            <OutlookConnect variant={variant} />
        </Stack>
    );
}

export default function MailAuthModal() {
    const dispatch = useDispatch();
    const continueFlow = useSelector(selectContinueFlow);
    const canContinueFlow = useSelector(selectCanContinueFlow);

    const closeAuthorizeModal = () => {
        dispatch(setAuthorizeModal(false));
    };

    return (
        <BaseModal
            onClose={closeAuthorizeModal}
            overlayStyles={{
                width: "fit-content",
                padding: "1rem 2rem 1rem 1.5rem",
            }}
        >
            <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={2}>
                Connect your email provider to send emails
            </Typography>
            <MailAuthConnect variant="horizontal" />
            {canContinueFlow ? (
                <Typography
                    mt={1}
                    fontSize={14}
                    color="#bbb"
                    textAlign="end"
                    fontStyle="italic"
                    sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                    onClick={continueFlow}
                >
                    Skip
                </Typography>
            ) : null}
        </BaseModal>
    );
}
