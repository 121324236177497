import React, { useState } from "react";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addToLever,
    getLeverJobs,
    getLeverStages,
    selectLeverJobs,
    selectLeverStages,
} from "../../../integrations/integrations.reducer";
import {
    ILeverOpportunity,
    ILeverOpportunityType,
    ILeverStageType,
} from "../../../integrations/integrations.types";

type LeverModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
};

export default function LeverModal({
    open,
    onClose,
    id,
    selectedCandidateIds,
}: LeverModalProps) {
    const dispatch = useDispatch();
    const leverJobs = useSelector(selectLeverJobs);
    const leverStages = useSelector(selectLeverStages);
    const isLeverJobsLoading = useSelector(checkIfLoading(getLeverJobs.type));
    const isLeverStagesLoading = useSelector(
        checkIfLoading(getLeverStages.type)
    );
    const isLeverSubmitting = useSelector(checkIfLoading(addToLever.type));

    const [opportunityType, setOpportunityType] =
        useState<ILeverOpportunityType>({
            type: "GENERAL",
            jobId: "",
        });
    const [stageType, setStageType] = useState<ILeverStageType>({
        open: false,
        stageId: "",
    });

    const handleCloseModal = () => {
        setOpportunityType({
            type: "GENERAL",
            jobId: "",
        });
        setStageType({
            open: false,
            stageId: "",
        });
        onClose();
    };

    const handleOpportunityChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "SPECIFIC") {
            dispatch(
                getLeverJobs({
                    action: getLeverJobs.type,
                })
            );
        }
        setOpportunityType({
            ...opportunityType,
            type: e.target.value as ILeverOpportunity,
        });
    };

    const handleStageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch(
                getLeverStages({
                    action: getLeverStages.type,
                })
            );
        }
        setStageType({
            ...stageType,
            open: e.target.checked,
        });
    };

    const handleLeverAdd = () => {
        if (opportunityType.type === "SPECIFIC" && !opportunityType.jobId) {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        if (stageType.open && !stageType.stageId) {
            dispatch(setErrorNotification("Please select stage"));
            return;
        }

        dispatch(
            addToLever({
                projectId: Number(id),
                candidateIds: selectedCandidateIds,
                action: addToLever.type,
                job: opportunityType,
                stage: stageType,
                onSuccess: handleCloseModal,
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth
            maxWidth={"sm"}
        >
            <DialogTitle>Send to Lever</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Typography>Select Opportunity Type:</Typography>
                        <TextField
                            hiddenLabel
                            value={opportunityType.type}
                            onChange={handleOpportunityChange}
                            select
                            sx={{
                                "& .MuiSelect-select.MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                        >
                            <MenuItem value="GENERAL">General</MenuItem>
                            <MenuItem value="SPECIFIC">Specific Job</MenuItem>
                        </TextField>
                    </Stack>
                    {opportunityType.type === "SPECIFIC" ? (
                        isLeverJobsLoading ? (
                            <Stack alignItems="center">
                                <CircularProgress size={24} />
                            </Stack>
                        ) : leverJobs?.length ? (
                            <Stack>
                                <Alert severity="info">
                                    These are recent published/internal postings
                                    from the past 6 months that have not been
                                    archived.
                                </Alert>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={2}
                                    mt={2}
                                >
                                    <Typography>Select Job:</Typography>
                                    <TextField
                                        hiddenLabel
                                        value={opportunityType.jobId}
                                        onChange={(e) =>
                                            setOpportunityType({
                                                ...opportunityType,
                                                jobId: e.target.value,
                                            })
                                        }
                                        select
                                        placeholder="Select..."
                                        sx={{
                                            "& .MuiSelect-select.MuiInputBase-input":
                                                {
                                                    fontSize: 14,
                                                },
                                        }}
                                    >
                                        {leverJobs.map((job) => (
                                            <MenuItem value={job.id}>
                                                {job.text}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Stack>
                            </Stack>
                        ) : (
                            <Typography
                                fontSize={14}
                                textAlign="center"
                                color="red"
                            >
                                No jobs found. Create one on your lever
                                dashboard
                            </Typography>
                        )
                    ) : null}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography>
                            Map candidates to specific stage: <br />
                            (Optional)
                        </Typography>
                        <Switch
                            checked={stageType.open}
                            onChange={handleStageChange}
                        />
                    </Stack>
                    {stageType.open ? (
                        isLeverStagesLoading ? (
                            <Stack alignItems="center">
                                <CircularProgress size={24} />
                            </Stack>
                        ) : leverStages?.length ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography>Select Stage:</Typography>
                                <TextField
                                    hiddenLabel
                                    value={stageType.stageId}
                                    onChange={(e) =>
                                        setStageType({
                                            ...stageType,
                                            stageId: e.target.value,
                                        })
                                    }
                                    select
                                    placeholder="Select..."
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input":
                                            {
                                                fontSize: 14,
                                            },
                                    }}
                                >
                                    {leverStages.map((stage) => (
                                        <MenuItem value={stage.id}>
                                            {stage.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <Typography
                                fontSize={14}
                                textAlign="center"
                                color="red"
                            >
                                No stages found. Create one on your lever
                                dashboard
                            </Typography>
                        )
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        isLeverSubmitting ||
                        isLeverJobsLoading ||
                        isLeverStagesLoading
                    }
                    variant="contained"
                    onClick={handleLeverAdd}
                >
                    <ButtonTextWithLoading
                        isLoading={isLeverSubmitting}
                        text={"Confirm"}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
