import dayjs from "dayjs";
import { isObject } from "lodash";

import { signinType } from "../pages/Signin/Signin.types";
import { IMessage } from "../pages/message/message.types";
import { IProjectExperience } from "../pages/project/project.types";

const experienceFields = {
    "0-1": "Less than 1 year",
    "1-2": "1 to 2 Years",
    "3-5": "3 to 5 Years",
    "6-10": "5 to 10 Years",
    "10": "More than 10 years",
};

export const nameInitials = (name: string) =>
    name && name !== "-"
        ? name
              .split(" ")
              .slice(0, 2)
              .map((word: string) => word.charAt(0).toLocaleUpperCase())
        : "N/A";

export const getExperience = (str: IProjectExperience) => experienceFields[str];

export function formatDate(timestamp: string): string {
    if (!dayjs(timestamp).isValid()) return "Invalid date";

    return dayjs(timestamp).format("D MMM, YYYY");
}

export function formatSignupEmail(email: string) {
    if (!email) return;

    return (
        email[0] +
        email.substring(1, email.indexOf("@")).replace(/./g, "*") +
        email.substring(email.indexOf("@"), email.length)
    );
}

export function numberOfNewMessages(messages: IMessage[]) {
    let count = 0;

    for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i];

        if (!message.sentByRecruiter && !message.read) {
            count++;
        } else {
            break; // Stop counting when a message doesn't meet the criteria
        }
    }

    return count;
}

export function removeHtmlTags(text?: string) {
    if (!text) return;
    return text.replace(/<[^>]*>/g, "");
}

export function shortenTotalExperience(str: string) {
    const [num, unit] = str.split(" ");
    if (!num || !unit) return str;

    const isYear = unit.includes("year");
    let months;
    if (isYear) {
        const [, , num, unit] = str.split(" ");
        if (num && unit) {
            const isMonth = unit.includes("month");
            if (isMonth) months = num;
        }
    }
    const converted = isYear
        ? months
            ? (Number(num) * 12 + Number(months)) / 12
            : Number(num)
        : Number(num) / 12;
    const formatted = Math.trunc(converted * 10) / 10;
    return `${formatted} yrs`;
}

export function checkPlaceholdersFilled(str?: string) {
    if (!str) return;

    const regex = /\[(.*?)\]/g; // regular expression to match text between square brackets
    const matches = str.match(regex); // array of matches found in the input text

    if (matches?.length) {
        // no matches found, all placeholders are already filled
        return {
            value: false,
            matches: matches,
        };
    }

    return {
        value: true,
    };
}

export const getCreditPercentage = (user: signinType) => {
    const totalCredits = user.orgData?.allCredits.emailFetchCredit || 0;
    const currentCredits = user.orgData?.usedCredits.emailFetchCredit || 0;
    const remainingCredits = totalCredits
        ? ((totalCredits - currentCredits) / totalCredits) * 100
        : 0;

    return {
        totalCredits,
        currentCredits,
        remainingCredits,
    };
};

export const exportToCsvc = (data: Record<string, any>[]) => {
    const csvContent = convertToCsvString(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
};

const convertToCsvString = (data: Record<string, any>[]) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item) =>
        Object.values(item)
            .map((value) => {
                if (Array.isArray(value) || isObject(value)) {
                    value = JSON.stringify(value)
                        .replace(/,/g, " | ")
                        .replace(/"/g, "");
                }
                return `"${value}"`;
            })
            .join(",")
    );
    return header + "\r\n" + rows.join("\r\n");
};

export function randomEmail() {
    const domains = ["gmail.com", "yahoo.com", "outlook.com"];
    const domain = domains[Math.floor(Math.random() * domains.length)];
    const usernameLength = Math.floor(Math.random() * 3) + 5;
    const username = "*".repeat(usernameLength);
    let email = `${username}@${domain}`;
    return email;
}

export function isWordsLimitExceeded(customValue: string, wordLimit: number) {
    return (customValue.match(/\S+/g)?.length || 0) > wordLimit;
}

export function isValidPersonName(name: string) {
    if (!name) return;

    const regName = /^[a-zA-Z ]+$/;
    return regName.test(name);
}

export function isValidEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
