import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import NoDataImg from "../../assets/img/no-data-2.png";
import Loader from "../../components/Loader";
import Navbar from "../../components/Navbar";
import { getInboxEmails, groupUserEmail } from "../../constant";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { getUserEmail, selectUserEmails } from "../allProjects/index.reducer";
import MessageList from "./components/messageList";
import SingleMessage from "./components/singleMessage";
import style from "./index.module.scss";
import { IEmailData, IMessageTab } from "./message.types";

export default function Messages() {
    const { id, status } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userEmail = useSelector(selectUserEmails);
    const checkMessagesLoading = useSelector(checkIfLoading(getUserEmail.type));
    const emailData: IEmailData[] = groupUserEmail(userEmail);
    const inboxData: IEmailData[] = getInboxEmails(emailData);
    const tab = (status as IMessageTab) || "inbox";

    useEffect(() => {
        dispatch(getUserEmail({ action: getUserEmail.type, navigate, tab }));
    }, []);

    const messagesData =
        tab === "sent" ? emailData : tab === "inbox" ? inboxData : [];

    return (
        <div className={style["msg"]}>
            <div className={style["msg__container"]}>
                <Navbar />
                <div className={style["msg__actionbar"]}>
                    <Link
                        to={"/messages/inbox"}
                        className={`${style["msg__actionItem"]} ${
                            tab === "inbox"
                                ? style["msg__actionItem--active"]
                                : ""
                        }`}
                    >
                        Inbox <span>{inboxData?.length || 0}</span>
                    </Link>
                    <Link
                        to={"/messages/sent"}
                        className={`${style["msg__actionItem"]} ${
                            tab === "sent"
                                ? style["msg__actionItem--active"]
                                : ""
                        }`}
                    >
                        Sent <span>{emailData?.length || 0}</span>
                    </Link>
                    {/* <div className={style["msg__actionItem"]}>
                      Template <span>25</span>
                    </div>
                    <div className={style["msg__actionItem"]}>
                      Awaiting <span>25</span>
                    </div> */}
                    {/* <div className={style["msg__actionItem"]}>
                        Archived <span>25</span>
                    </div> */}
                </div>
                <div className={style["msg__body"]}>
                    {checkMessagesLoading ? (
                        <Loader />
                    ) : isEmpty(messagesData) ? (
                        <div>
                            <img
                                src={NoDataImg}
                                alt="no-data-found"
                                className={style["msg__no-data-img"]}
                            />
                            <p className={style["msg__no-data-text"]}>
                                To reach out to candidates, source relevant
                                candidates using our chrome extension and reach
                                out to them on personal email and linkedin
                                connection request. All responses can be tracked
                                here.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className={style["msg__left"]}>
                                <div className={style["msgl"]}>
                                    <div className={style["msgl__container"]}>
                                        <div
                                            className={style["msgl__titlebox"]}
                                        >
                                            <p className={style["msgl__title"]}>
                                                Messages
                                            </p>
                                        </div>
                                        <div className={style["msgl__body"]}>
                                            {messagesData.map(
                                                (singleThread: IEmailData) => (
                                                    <MessageList
                                                        key={singleThread.id}
                                                        emailData={
                                                            singleThread.msgs
                                                        }
                                                        tab={tab}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style["msg__right"]}>
                                {id ? (
                                    <SingleMessage
                                        emailData={
                                            messagesData.find(
                                                (msg) => msg.id === id
                                            )?.msgs || []
                                        }
                                        chatId={id}
                                    />
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
