import { useEffect } from "react";
import { Spin } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import NoDataImg from "../../../../assets/img/no-data-1.png";
import { groupUserEmail, sendIcon, userIcon } from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { getUserEmail } from "../../../allProjects/index.reducer";
import style from "./index.module.scss";

export default function MsgAndEmail() {
    const dispatch = useDispatch();
    const userEmail = useSelector((state) =>
        get(state, "allProjects.userEmail")
    );
    const checkMessagesLoading = useSelector(checkIfLoading(getUserEmail.type));
    const emailData = groupUserEmail(userEmail);

    useEffect(() => {
        dispatch(getUserEmail({ action: getUserEmail.type }));
    }, []);

    return (
        <div className={style["msge__container"]}>
            <div className={style["msge__titlebox"]}>
                <p className={style["msge__title"]}>Messages & Email</p>
            </div>

            <div className={style["msge__body"]}>
                {checkMessagesLoading ? (
                    <Spin size="small" />
                ) : Object.keys(emailData).length ? (
                    //@ts-ignore
                    Object.keys(emailData).map((key) => {
                        //@ts-ignore
                        let body = emailData[key][0].body.substring(0, 100);
                        body =
                            //@ts-ignore
                            emailData[key][0].body.length > 100
                                ? `${body}...`
                                : body;

                        //@ts-ignore
                        if (emailData[key].length === 0) return "";
                        return (
                            <Link
                                className={style["msge__itemBox"]}
                                to={
                                    "/messages/" +
                                    //@ts-ignore
                                    emailData[key][0].candidate._id
                                }
                            >
                                <div className={style["msge__item"]} key={key}>
                                    <div className={style["msge__user"]}>
                                        <img
                                            //@ts-ignore
                                            // src={emailData[key][0].candidate.profileImage}
                                            src={userIcon}
                                            alt="user-profile"
                                        />
                                    </div>
                                    <div className={style["msge__main"]}>
                                        <div
                                            className={style["msge__detailBox"]}
                                        >
                                            <p className={style["msge__name"]}>
                                                {
                                                    //@ts-ignore
                                                    emailData[key][0].candidate
                                                        .name
                                                }
                                                <span
                                                    className={
                                                        style["msge__number"]
                                                    }
                                                >
                                                    {
                                                        //@ts-ignore
                                                        emailData[key].length
                                                    }
                                                </span>
                                            </p>
                                            <div
                                                className={style["msge__desc"]}
                                                //@ts-ignore
                                                dangerouslySetInnerHTML={{
                                                    __html: body,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={style["msge__sendBox"]}>
                                        <img src={sendIcon} alt="" />
                                        <p className={style["msge__date"]}>
                                            {new Date(
                                                //@ts-ignore
                                                emailData[key][0].createdAt
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        );
                    })
                ) : (
                    <>
                        <img
                            src={NoDataImg}
                            alt="no-data-1"
                            className={style["msge__no-data-img"]}
                        />
                        <p className={style["msge__no-data-text"]}>
                            No existing messages
                            <br />
                            Contact candidates in pipelines by creating a new
                            workflow
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}
