import ReadMore from "../../../../components/ReadMore";
import { buildings } from "../../../../constant";
import style from "./index.module.scss";

interface propsType {
    data: any;
}

export default function ProfileTab(props: propsType) {
    const { data } = props;

    return (
        <div className={style["pro"]}>
            <div className={style["pro__container"]}>
                {data?.description && data.description !== "-" ? (
                    <div className={style["pro__aboutBox"]}>
                        <h2>About</h2>
                        <ReadMore>{data.description}</ReadMore>
                    </div>
                ) : null}
                {data?.experience?.length ? (
                    <div className={style["pro__expBox"]}>
                        <h2>Experience</h2>
                        {data.experience.map((val: any, i: number) => {
                            return (
                                <div className={style["pro__expItem"]} key={i}>
                                    <img
                                        src={buildings}
                                        alt="buildings-icon"
                                        className={style["pro__expImg"]}
                                    />
                                    <div className={style["pro__expDesc"]}>
                                        <p className={style["pro__expName"]}>
                                            {val.position}{" "}
                                            <span>@ {val.org}</span>
                                        </p>
                                        <div className={style["pro__expTime"]}>
                                            {val.location &&
                                            val.location !== "-" ? (
                                                <p
                                                    className={
                                                        style[
                                                            "pro__expLocation"
                                                        ]
                                                    }
                                                >
                                                    {val.location}
                                                </p>
                                            ) : null}
                                            {val.duration &&
                                            val.duration !== "-" ? (
                                                <p
                                                    className={
                                                        style["pro__expSpan"]
                                                    }
                                                >
                                                    <span />
                                                    {val.duration}
                                                </p>
                                            ) : null}
                                        </div>
                                        {val?.description &&
                                        val.description !== "-" ? (
                                            <ReadMore
                                                className={
                                                    style["pro__expDetail"]
                                                }
                                            >
                                                {val?.description}
                                            </ReadMore>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {data?.education?.length ? (
                    <div className={style["pro__eduBox"]}>
                        <h2>Education</h2>
                        {data.education.map((val: any) => {
                            return (
                                <div className={style["pro__eduItem"]}>
                                    <img
                                        src={buildings}
                                        alt="buildings-icon"
                                        className={style["pro__eduImg"]}
                                    />
                                    <div className={style["pro__eduDesc"]}>
                                        <p>{val.institute}</p>
                                        <p>{val.course}</p>
                                        {val?.description &&
                                        val.description !== "-" ? (
                                            <ReadMore
                                                className={
                                                    style["pro__eduDetail"]
                                                }
                                            >
                                                {val.description}
                                            </ReadMore>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {data.skills?.length ? (
                    <div className={style["pro__skillBox"]}>
                        <h2>Skills</h2>
                        <div className={style["pro__skillContainer"]}>
                            {data.skills.map(
                                (val: { name: string }, i: number) => {
                                    return (
                                        <p
                                            className={style["pro__skillItem"]}
                                            key={i}
                                        >
                                            {val.name}
                                        </p>
                                    );
                                }
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
