import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { setErrorNotification } from "../../components/Notification/index.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { formatSignupEmail } from "../../utils/helper";
import LogoSvg from "../Signin/Images/logo.svg";
import styles from "../Signin/Signin.module.scss";
import {
    changePassword,
    forgotPassword,
    verifyOtp,
} from "./forgotPassword.reducer";

const Signin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkForgotPassLoading = useSelector(
        checkIfLoading(forgotPassword.type)
    );
    const checkVerifyLoading = useSelector(checkIfLoading(verifyOtp.type));

    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [password, setPassword] = useState<{ new: string; confirm: string }>({
        new: "",
        confirm: "",
    });
    const [showForgotPassword, setShowForgotPassword] = useState<{
        new: boolean;
        confirm: boolean;
    }>({
        new: false,
        confirm: false,
    });
    const [handleScreen, setHandleScreen] = useState<0 | 1 | 2>(0);

    const handleSubmit = () => {
        if (!email) {
            dispatch(setErrorNotification("Please fill in the email"));
            return;
        }

        dispatch(
            forgotPassword({
                action: forgotPassword.type,
                email,
                onSuccess: () => setHandleScreen(1),
            })
        );
    };

    const handleVerifyOtp = () => {
        if (!code) {
            dispatch(
                setErrorNotification("Please fill in the verification code")
            );
            return;
        }

        dispatch(
            verifyOtp({
                action: verifyOtp.prototype,
                email,
                code,
                onSuccess: () => setHandleScreen(2),
            })
        );
    };

    const handleChangePassword = () => {
        if (!password.new) {
            dispatch(setErrorNotification("Please fill in the new password"));
            return;
        }
        if (!password.confirm) {
            dispatch(
                setErrorNotification("Please fill in the confirm password")
            );
            return;
        }
        if (password.new !== password.confirm) {
            dispatch(setErrorNotification("Both passwords should match"));
            return;
        }

        dispatch(
            changePassword({
                action: changePassword.type,
                email,
                newPassword: password.new,
                onSuccess: () => navigate("/signin"),
            })
        );
    };

    const handleBackScreen1 = () => {
        setCode("");
        setHandleScreen(0);
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <img
                    src={LogoSvg}
                    className={styles.logo}
                    alt="hirequotient-logo"
                />
                <p className={styles.leftHeading}>Client Dashboard</p>
                {handleScreen === 0 ? (
                    <>
                        <div className={styles["inputBox"]}>
                            <label>Forgot password?</label>
                            <p className={styles["resetText"]}>
                                Reset password in 2 quick steps
                            </p>
                            <input
                                className={styles["inputEmail"]}
                                type="email"
                                placeholder="Please enter your email"
                                value={email}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={styles["btnBox"]}>
                            <button
                                onClick={handleSubmit}
                                disabled={checkForgotPassLoading}
                            >
                                Reset password
                            </button>
                            <Link to="/signin">Back</Link>
                        </div>
                    </>
                ) : null}
                {handleScreen === 1 ? (
                    <>
                        <div className={styles["inputBox"]}>
                            <label>We have sent a code to your email</label>
                            <p className={styles["resetText"]}>
                                Enter the verification code sent to
                                <br />
                                {formatSignupEmail(email)}
                            </p>
                            <div className={styles["inputCodeWrapper"]}>
                                <input
                                    className={styles["inputCode"]}
                                    type="text"
                                    placeholder="Verification code"
                                    value={code}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setCode(e.target.value)}
                                />
                                <p
                                    className={styles["resendOtp"]}
                                    onClick={handleSubmit}
                                >
                                    Resend code
                                </p>
                            </div>
                        </div>
                        <div className={styles["btnBox"]}>
                            <button
                                onClick={handleVerifyOtp}
                                disabled={checkVerifyLoading}
                            >
                                Submit
                            </button>
                            <span onClick={handleBackScreen1}>Back</span>
                        </div>
                    </>
                ) : null}
                {handleScreen === 2 ? (
                    <>
                        <div className={styles["inputBox"]}>
                            <label>New password</label>
                            <div className={styles["inputWrapper"]}>
                                <input
                                    type={
                                        showForgotPassword.new
                                            ? "text"
                                            : "password"
                                    }
                                    placeholder="Enter your new password"
                                    value={password.new}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setPassword({
                                            ...password,
                                            new: e.target.value,
                                        })
                                    }
                                />
                                <div
                                    className={styles["showPassword"]}
                                    onClick={() =>
                                        setShowForgotPassword({
                                            ...showForgotPassword,
                                            new: !showForgotPassword.new,
                                        })
                                    }
                                >
                                    {showForgotPassword.new ? "Hide" : "Show"}
                                </div>
                            </div>
                        </div>
                        <div className={styles["inputBox"]}>
                            <label>Confirm password</label>
                            <div className={styles["inputWrapper"]}>
                                <input
                                    type={
                                        showForgotPassword.confirm
                                            ? "text"
                                            : "password"
                                    }
                                    placeholder="Please confirm your new password"
                                    value={password.confirm}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setPassword({
                                            ...password,
                                            confirm: e.target.value,
                                        })
                                    }
                                />
                                <div
                                    className={styles["showPassword"]}
                                    onClick={() =>
                                        setShowForgotPassword({
                                            ...showForgotPassword,
                                            confirm:
                                                !showForgotPassword.confirm,
                                        })
                                    }
                                >
                                    {showForgotPassword.confirm
                                        ? "Hide"
                                        : "Show"}
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={handleChangePassword}
                            className={styles["changePwdBtn"]}
                        >
                            Submit
                        </button>
                    </>
                ) : null}
            </div>
            <div className={styles["singinBg"]}>
                <div className={styles["signinContainer"]}>
                    <div className={styles["signinRightText"]}>
                        <h1>
                            Effortlessly
                            <br />
                            Discover Top Talent
                            <br />
                            with EasySource
                        </h1>
                        <h2 className={styles["efficientHiring"]}>
                            Your Go-to Hiring Solution
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;
