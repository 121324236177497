import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

// import { setSuccessNotification } from "../../components/Notification/index.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setUserCredits } from "../Signin/Signin.reducer";
import {
    createCheckoutSession,
    fetchPlans,
    fetchUserPlan,
    resetMyAccount,
    setPlans,
    setUserPlan,
} from "./myAccount.reducer";
import { ICheckoutPayload, IOrgPlanResponse } from "./myAccount.types";

function* fetchUserPlanSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: IOrgPlanResponse = yield call(
            new API().get,
            "/organization/get-credit"
        );
        const totalCredits = response.data.totalEmailFetchCredit;
        const remainingCredits = response.data.remainingEmailFetchCredit;
        const currentCredits = totalCredits - remainingCredits;
        const remainingCreditsPercent = (remainingCredits / totalCredits) * 100;
        yield put(
            setUserPlan({
                totalCredits,
                remainingCredits,
                name: response.data.plan.name,
                createdAt: response.data.plan.createdAt,
                validTill: response.data.plan.validTill,
            })
        );
        yield put(
            setUserCredits({
                currentCredits,
                totalCredits,
                remainingCredits: remainingCreditsPercent,
            })
        );
    } catch (error) {
        console.error("**fetchUserPlanSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* fetchPlansSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/payment/list-products");
        yield put(setPlans(response.data));
    } catch (error) {
        console.error("**fetchPlansSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* createCheckoutSessionSaga({
    payload,
}: {
    payload: IActionPayload & ICheckoutPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(
            new API().post,
            "/payment/create-checkout-session",
            {
                priceId: payload.default_price,
            }
        );
        window.location.href = response.url;
    } catch (error) {
        console.error("**createCheckoutSessionSagaError", { error });
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchUserPlan.type, fetchUserPlanSaga),
        // @ts-ignore
        yield takeLatest(fetchPlans.type, fetchPlansSaga),
        // @ts-ignore
        yield takeLatest(createCheckoutSession.type, createCheckoutSessionSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetMyAccount.type, CancelSagas, tasks);
}
