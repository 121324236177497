import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { Action, Option } from "../../common";
import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import { MembersResponse } from "../../components/SlackSendModal/types";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import {
    doNotCall,
    fetchPersons,
    resetState,
    sendSlackMessage,
    setPersons,
    setShowModal,
} from "../reducers/slackSend.reducer";

function* fetchPersonsSaga({ payload }: { payload: Action }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const response: MembersResponse = yield call(
            new API().get,
            "/slack/members"
        );

        const members: Option[] = response.data.map(({ id, name }) => {
            return { label: name, value: id };
        });

        yield put(setPersons(members));
    } catch (error) {
        console.error({ error });
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* sendSlackMessageSaga({ payload }: { payload: Action }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const person: Option = get(state, "slackSlice.person");

        if (!person.value) {
            yield put(setErrorNotification("Please Select a Member."));
            return;
        }

        const requestPayload = {
            memberId: person.value,
            memberName: person.label,
        };

        yield call(new API().post, "/slack/sendDM", requestPayload);

        yield put(setShowModal(false));
        yield put(resetState());
        yield put(setSuccessNotification("Message Send Successfully"));
    } catch (error) {
        console.error({ error });
        yield put(setErrorNotification("Error While Sending Message"));
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchPersons.type, fetchPersonsSaga),
        // @ts-ignore
        yield takeLatest(sendSlackMessage.type, sendSlackMessageSaga),
    ];

    yield takeLatest(doNotCall.type, CancelSagas, tasks);
}
