import howToUse from "../../../assets/img/how_to_use.mp4";
import hqLogo from "../../../assets/img/hq-logo-onboard.svg";
import styles from "../signupOnBoard.module.scss";

const Instructions = () => {
    return (
        <div className={styles.instructionContainer}>
            <div>
                <img src={hqLogo} alt="hirequotient-logo" />
                <div className={styles.instructions}>
                    <p className={styles.title}>
                        Effortlessly Discover Top Talent with{" "}
                        <span className={styles.styledHeading}>
                            Easy<strong>Source</strong>
                        </span>
                    </p>
                    <p className={styles.content}>
                        EasySource is the most efficient way to find and reach
                        out to the right candidates for your job openings. With
                        just a few clicks, find a list of interested and
                        qualified candidates. What's more? You can track and
                        automate your outreach process with AI-driven
                        personalized messaging to maximize response rates.
                    </p>

                    <p className={styles.heading}>How to use EasySource?</p>
                    <video
                        width="300"
                        height="350"
                        src={howToUse}
                        controls={true}
                        autoPlay={true}
                        muted={true}
                        title="YouTube video player"
                        className={styles.video}
                    ></video>
                    {/* <ul className={styles.contentWrapper}>
                    <li className={styles.content}>Create a new account</li>
                    <li className={styles.content}>
                        Start a new search on LI by adding the relevant filters
                    </li>
                    <li className={styles.content}>
                        Extract the profiles and go to dashboard
                    </li>
                    <li className={styles.content}>
                        Here you can capture the personal email IDs, and
                        relevancy quotient for each candidate
                    </li>
                    <li className={styles.content}>
                        Add selected candidates to the workflow (pre-created or
                        new) to send them automated outreach
                    </li>
                    <li className={styles.content}>
                        Track the responses on dashboard and make data-driven
                        hiring decisions
                    </li>
                </ul> */}
                </div>
            </div>
        </div>
    );
};

export default Instructions;
