import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Avatar,
    Skeleton,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { Dropdown, Spin } from "antd";
import { isEmpty, uniq } from "lodash";
import { MenuInfo } from "rc-menu/lib/interface";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Nudge from "../../../../components/Nudges/Nudges";
import {
    selectInstallExtensionNudge,
    selectShowEmailNudge,
} from "../../../../components/Nudges/Nudges.reducer";
import {
    blueTickIcon,
    candidateStageItems,
    linkedInLogo,
} from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectCurrProject } from "../../../allProjects/index.reducer";
import {
    getCandidateEmail,
    moveTo,
    selectProjectCandidates,
    setCandidates,
} from "../../index.reducer";
import { ICandidate, IProjectStage } from "../../project.types";
import Skills from "../skillsSection";
import style from "./index.module.scss";

const SkeletonUI = () => {
    return (
        <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", marginLeft: 1 }}
            width={100}
        />
    );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#E1F3FF",
        color: "#6c6c6c",
        maxWidth: 200,
    },
}));

type CandidateProfileCardProps = {
    variant: "card" | "modal";
    projectId?: string;
    index?: number;
    candidate: ICandidate;
};

export default function CandidateProfileCard({
    variant,
    projectId,
    index,
    candidate,
}: CandidateProfileCardProps) {
    const {
        _id,
        name,
        email,
        visa,
        location,
        skills,
        stage,
        experience,
        totalExperience,
        joiningStatus,
        profileUrl = "",
        courses,
        roleWarning,
        relevancy_skills = [],
        profileImage,
        canShowEmail,
        professional_email,
        linkedInExp,
        scrapStatus,
        isSample,
    } = candidate;

    const dispatch = useDispatch();
    const projectData = useSelector(selectCurrProject);
    const candidates = useSelector(selectProjectCandidates);
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);
    const showEmailNudge = useSelector(selectShowEmailNudge);

    const emailLoading = useSelector(
        checkIfLoading(`${getCandidateEmail.type}${_id}`)
    );
    const groupCandidatesLoading =
        useSelector(checkIfLoading(getCandidateEmail.type)) && !canShowEmail;
    const showNudgeCondn = false;
    // variant === 'card' &&
    // installExtensionNudge &&
    // !showEmailNudge &&
    // props.index === 0 &&
    // !emailLoading &&
    // !groupCandidatesLoading &&
    // !isSample &&
    // !(
    //     projectData?.projectStatus === "IN_PROGRESS" ||
    //     projectData?.projectStatus === "PENDING"
    // );

    const normalSkills = isEmpty(skills)
        ? []
        : skills.map((item: { name: string }) => item.name);
    const all_skills = uniq([...relevancy_skills, ...normalSkills]);

    const isScrapStatusCompleted =
        scrapStatus === "COMPLETED" || scrapStatus === "CANCELLED";
    const isLoading =
        !isScrapStatusCompleted &&
        !(
            projectData?.projectStatus === "COMPLETED" ||
            projectData?.projectStatus === "CANCELLED"
        );

    const handleShowEmail = () => {
        if (isSample) {
            const tmpCandidates = candidates.map((item: ICandidate) => {
                if (item._id === _id) {
                    return {
                        ...item,
                        canShowEmail: true,
                        email: ["sampleemail3@gmail.com"],
                    };
                }

                return item;
            });
            dispatch(setCandidates(tmpCandidates));
            return;
        }
        dispatch(
            getCandidateEmail({
                candidateIds: [_id],
                action: `${getCandidateEmail.type}${_id}`,
            })
        );
    };

    const handleChangeStatus = (id: string, type: IProjectStage) => {
        if (isSample) {
            const tmpCandidates = candidates.map((item: ICandidate) => {
                if (item._id === _id) {
                    return {
                        ...item,
                        stage: type,
                    };
                }

                return item;
            });
            dispatch(setCandidates(tmpCandidates));
            return;
        }
        dispatch(
            moveTo({
                candidateIds: [id],
                stage: type,
                projectId: Number(projectId),
            })
        );
        return;
    };

    return (
        <>
            {isSample ? (
                <p className={style["spcard__demo"]}>Sample data</p>
            ) : null}
            <div className={style["spcard__card-content"]}>
                <Avatar
                    src={
                        profileImage?.startsWith("data:") ? name : profileImage
                    }
                    alt={name}
                    className={style["spcard__card-avatar"]}
                    // sx={{
                    //     width: 60,
                    //     height: 60,
                    //     marginRight: "1.5rem",
                    //     fontSize: "2rem",
                    // }}
                />
                <div className={style["spcard__card-wrapper-main"]}>
                    <div
                        className={style["spcard__card-wrapper"]}
                        style={{
                            marginBottom: showNudgeCondn ? "2.5rem" : 0,
                        }}
                    >
                        <div className={style["spcard__cardLeft"]}>
                            <div className={style["spcard__item"]}>
                                <div className={style["spcard__itemRight"]}>
                                    <p className={style["spcard__name"]}>
                                        {name}{" "}
                                        {joiningStatus ? (
                                            <span>
                                                {Number(joiningStatus) === 1
                                                    ? "Immediate Joiner"
                                                    : null}
                                            </span>
                                        ) : null}
                                    </p>
                                    {experience &&
                                    experience.length &&
                                    experience[0].position &&
                                    experience[0].org ? (
                                        <p className={style["spcard__desc"]}>
                                            {experience[0].position},{" "}
                                            <b>
                                                {experience[0].org
                                                    .split("·")[0]
                                                    .trim()}
                                            </b>
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className={style["spcard__cardRight"]}>
                            <div className={style["spcard__cardRightTop"]}>
                                {showNudgeCondn && (
                                    <Nudge
                                        variant="top"
                                        type="showEmail"
                                        text={
                                            "Click here to extract personal email IDs 📧"
                                        }
                                        wrapperStyle={{
                                            position: "absolute",
                                            top: "110%",
                                            left: 0,
                                            textAlign: "left",
                                            cursor: "default",
                                        }}
                                        containerStyle={{
                                            flexDirection: "row-reverse",
                                        }}
                                        arrowStyle={{
                                            margin: "0.75rem 1.5rem 0",
                                        }}
                                        closeIconStyle={{
                                            top: "calc(100% - 18px)",
                                        }}
                                    />
                                )}
                                <div
                                    className={style["spcard__showHideEmail"]}
                                    id={`card${index}ShowHideEmail`}
                                >
                                    {emailLoading || groupCandidatesLoading ? (
                                        <Spin
                                            size="small"
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        />
                                    ) : !canShowEmail ? (
                                        <>
                                            <button
                                                className={
                                                    style[
                                                        "spcard__showHideEmail-btn"
                                                    ]
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleShowEmail();
                                                }}
                                            >
                                                Show
                                            </button>
                                            <span
                                                className={
                                                    style[
                                                        "spcard__showHideEmail-text"
                                                    ]
                                                }
                                            >
                                                {email}
                                            </span>
                                        </>
                                    ) : email.length ? (
                                        <p
                                            className={
                                                style[
                                                    "spcard__showHideEmail-text"
                                                ]
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: email
                                                    .map(
                                                        (item: string) =>
                                                            `${item}<br>`
                                                    )
                                                    .join(""),
                                            }}
                                        />
                                    ) : isEmpty(professional_email) ? (
                                        <p
                                            className={
                                                style[
                                                    "spcard__showHideEmail-text"
                                                ]
                                            }
                                        >
                                            Email not available
                                        </p>
                                    ) : null}
                                </div>
                                {professional_email &&
                                professional_email.length ? (
                                    <p
                                        className={
                                            style["spcard__professionalEmail"]
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: professional_email
                                                .map(
                                                    (email: string) =>
                                                        `${email}<br>`
                                                )
                                                .join(""),
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={style["spcard__card-wrapper"]}>
                        <div className={style["spcard__cardLeft"]}>
                            <div className={style["spcard__item"]}>
                                <div
                                    className={`${style["spcard__itemRight"]} ${style["spcard__info"]}`}
                                >
                                    <p className={style["spcard__infoItem"]}>
                                        <b>Experience</b>:{" "}
                                        {isLoading ? (
                                            <SkeletonUI />
                                        ) : totalExperience ? (
                                            totalExperience?.value !==
                                                linkedInExp?.value &&
                                            linkedInExp?.label ? (
                                                <CustomTooltip
                                                    placement="right"
                                                    title={`*As opposed to ${linkedInExp.label} from LinkedIn`}
                                                >
                                                    <Typography
                                                        fontSize={12}
                                                        ml={0.5}
                                                    >
                                                        {totalExperience.label}*
                                                    </Typography>
                                                </CustomTooltip>
                                            ) : totalExperience.label ? (
                                                totalExperience.label
                                            ) : (
                                                "N/A"
                                            )
                                        ) : (
                                            "N/A"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className={style["spcard__item-last"]}>
                                <div className={style["spcard__skillsWrapper"]}>
                                    <p
                                        className={
                                            style[
                                                "spcard__skillsWrapper-heading"
                                            ]
                                        }
                                    >
                                        <b>Skills</b>:
                                    </p>
                                    <Skills
                                        all_skills={all_skills}
                                        relevancy_skills={relevancy_skills}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style["spcard__cardRight"]}>
                            <div className={style["spcard__location"]}>
                                <p className={style["spcard__infoItem"]}>
                                    <b>Location</b>:{" "}
                                    {isLoading ? (
                                        <SkeletonUI />
                                    ) : location ? (
                                        location
                                    ) : (
                                        "N/A"
                                    )}
                                </p>
                            </div>
                            <div className={style["spcard__infoCompanies"]}>
                                <p
                                    className={
                                        style["spcard__infoCompanies-title"]
                                    }
                                >
                                    <b>Past Companies</b>:&nbsp;
                                </p>
                                {isLoading ? (
                                    <SkeletonUI />
                                ) : (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                experience && experience.length
                                                    ? uniq(
                                                          experience.map(
                                                              (ex: {
                                                                  org: string;
                                                              }) =>
                                                                  ex.org.split(
                                                                      " ·"
                                                                  )[0]
                                                          )
                                                      )
                                                          .slice(0, 3)
                                                          .join("<br>")
                                                    : "N/A",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style["spcard__cardLast"]}>
                    <div className={style["spcard__cardTop"]}>
                        {visa ? (
                            <div className={style["spcard__visaItem"]}>
                                <img src={blueTickIcon} alt="blue-tick-icon" />
                                {/* <Tooltip title={"asdsada"} placement="top"> */}
                                <p className={style["spcard__visaItem-text"]}>
                                    VISA
                                </p>
                                {/* </Tooltip> */}
                            </div>
                        ) : null}
                        {profileUrl ? (
                            <Link
                                to={profileUrl}
                                target={"_blank"}
                                className={style["spcard__linkedIn"]}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <img
                                    className={style["spcard__linkedInLogo"]}
                                    src={linkedInLogo}
                                    alt="linkedin-logo"
                                />
                            </Link>
                        ) : null}
                    </div>
                    {variant === "modal" ? (
                        <Dropdown
                            trigger={["click"]}
                            menu={{
                                items: candidateStageItems,
                                onClick: (e: MenuInfo) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeStatus(
                                        _id,
                                        e.key as IProjectStage
                                    );
                                },
                            }}
                        >
                            <div
                                className={style["spcard__candStatus"]}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <p>{stage ? stage : "PIPELINE"}</p>
                                <ArrowDropDownIcon />
                            </div>
                        </Dropdown>
                    ) : null}
                </div>
            </div>
            {roleWarning || courses ? (
                <div className={style["spcard__relevanceText"]}>
                    {!isEmpty(roleWarning) ? (
                        <>
                            <p>
                                Candidate is at the {roleWarning.role} role for
                                only {roleWarning.months} months. Kindly check
                                the previous job roles to validate the
                                candidature
                            </p>
                            <br />
                        </>
                    ) : null}
                    {courses.length ? (
                        <p>
                            Relevant courses:{" "}
                            {courses.map((ele: string) => ele).join(", ")}
                        </p>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}
