import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Paper, TextField } from "@mui/material";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../../../components/CustomTable/CustomTable";
import Navbar from "../../../components/Navbar";
import useWindowSize from "../../../hooks/useWindowSize";
import { exportToCsvc, formatDate } from "../../../utils/helper";
import AddCredit from "./components/addCredit/addCredit";
import styles from "./orgList.module.css";
import {
    addCredits,
    getOrgList,
    selectOrganizations,
} from "./orgList.reducers";

const OrgList = () => {
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganizations);
    const [currentOrg, setCurrentOrg] = useState({ id: 0, name: "" });
    const [canShowAddCredit, setCanShowAddCredit] = useState(false);
    const [credits, setCredits] = useState(0);
    const [currentOrgs, setCurrentOrgs] = useState(organizations || []);
    const columns = [
        {
            title: "Org id",
            dataIndex: "_id",
            minWidth: 100,
        },
        {
            title: "Org name",
            dataIndex: "name",
            minWidth: 100,
        },
        {
            title: "Created on",
            dataIndex: "createdAt",
            minWidth: 100,
            render: (record: any) => {
                return formatDate(record.createdAt);
            },
        },
        {
            title: "Total credits",
            dataIndex: "allCredits",
            minWidth: 100,
            render: (record: any) => record.allCredits.emailFetchCredit,
        },
        {
            title: "Used credits",
            dataIndex: "usedCredits",
            minWidth: 100,
            render: (record: any) => record.usedCredits.emailFetchCredit,
        },
        {
            title: "Members count",
            dataIndex: "usedCredits",
            minWidth: 100,
            render: (record: any) => record.members.length,
        },
        {
            title: "Add credits",
            dataIndex: "",
            minWidth: 100,
            render: (record: any) => {
                return (
                    <IconButton
                        color="success"
                        onClick={() => onShowAddCredit(record)}
                    >
                        <AddIcon />
                    </IconButton>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getOrgList());
    }, []);

    useEffect(() => {
        setCurrentOrgs(organizations);
    }, [organizations]);

    const onAddCredit = () => {
        dispatch(
            addCredits({
                orgId: currentOrg.id,
                credits,
            })
        );

        setTimeout(() => {
            setCanShowAddCredit(false);
        }, 2000);
    };

    const onShowAddCredit = (item: any) => {
        setCurrentOrg({
            id: item._id,
            name: item.name,
        });
        setCanShowAddCredit(true);
    };
    const size = useWindowSize();
    const tableHeight = size.height - 150;

    const onSearchByName = (text: any) => {
        if (!text) {
            setCurrentOrgs(organizations);
            return;
        }
        const newOrgs = organizations.filter((item: any) => {
            if (item.name) {
                const isMatched = item.name.indexOf(text) > -1;
                if (isMatched) return item;
            }
        });
        setCurrentOrgs(newOrgs);
    };

    return (
        <div className={styles.orgContainer}>
            <Navbar />
            <Paper sx={{ padding: "10px", textAlign: "right" }}>
                <Typography className={styles.orgCount}>
                    Total org: {currentOrgs.length}
                </Typography>
                <TextField
                    size="small"
                    className={styles.searchInput}
                    placeholder="Search with org name"
                    onChange={(e) => onSearchByName(e.target.value)}
                />
                <Button
                    className={styles.exportButton}
                    onClick={() => exportToCsvc(organizations)}
                >
                    Export orgs
                </Button>
            </Paper>
            <CustomTable
                columns={columns}
                total={currentOrgs.length}
                rows={currentOrgs}
                tableHeight={tableHeight}
            />
            <AddCredit
                open={canShowAddCredit}
                orgName={currentOrg.name}
                onOk={onAddCredit}
                isDisabled={credits < 1}
                onCancel={() => setCanShowAddCredit(false)}
                onChangeCredit={(e: any) => setCredits(e?.target?.value)}
            />
        </div>
    );
};

export default OrgList;
