import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import JSConfetti from "js-confetti";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    getProject,
    selectCurrProject,
    updateProjectCredits,
} from "../../../allProjects/index.reducer";
import style from "../../index.module.scss";

export default function CreditRewardModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectData = useSelector(selectCurrProject);
    const isProjectCreditsSubmitting = useSelector(
        checkIfLoading(updateProjectCredits.type)
    );
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));

    const jsConfetti = new JSConfetti();

    const [openRewardModal, setOpenRewardModal] = useState(false);

    const showCreditsRewardModal = () => setOpenRewardModal(true);
    const closeCreditsRewardModal = () => setOpenRewardModal(false);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (!projectData?.creditsRewarded && projectData?._id) {
            showCreditsRewardModal();
            jsConfetti.addConfetti();
            timer = setTimeout(() => {
                dispatch(
                    updateProjectCredits({
                        action: updateProjectCredits.type,
                        project: {
                            _id: Number(projectData?._id),
                            creditsRewarded: true,
                        },
                    })
                );
                closeCreditsRewardModal();
            }, 2500);
        }

        return () => {
            if (timer) clearTimeout(timer);
            jsConfetti.clearCanvas();
        };
    }, [projectData, isProjectLoading]);

    const onSubmit = () => {
        dispatch(
            updateProjectCredits({
                action: updateProjectCredits.type,
                project: {
                    _id: Number(projectData?._id),
                    creditsRewarded: true,
                },
                onSuccess: () => {
                    navigate(`/projects/${projectData?._id}`, {
                        replace: true,
                        state: {
                            fromProjectCreditsReward: true,
                        },
                    });
                    closeCreditsRewardModal();
                },
            })
        );
    };

    return (
        <Dialog
            open={openRewardModal}
            onClose={() => null}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle
                sx={{
                    color: "#747474",
                    fontSize: 24,
                    textAlign: "center",
                }}
            >
                <span className={style["sp__creditsRewardTitle"]}>
                    Congratulations
                </span>{" "}
                🎉
            </DialogTitle>
            <DialogContent
                sx={{
                    color: "#747474",
                    textAlign: "center",
                }}
            >
                You have created a new project. Candidates retrieval in
                progress.
            </DialogContent>
            {/* <DialogActions
                sx={{
                    justifyContent: "center",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        background:
                            "linear-gradient(92.1deg, #E44E4E -2.91%, #FFE600 101%);",
                        border: "none",
                    }}
                    disabled={isProjectCreditsSubmitting}
                    onClick={onSubmit}
                >
                    <ButtonTextWithLoading
                        text="Thank You"
                        isLoading={isProjectCreditsSubmitting}
                    />
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
