import { useEffect } from "react";
import { Table as AntTable, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";

import useWindowSize from "../../../../hooks/useWindowSize";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { fetchMembers, selectMembers } from "../../manageTeams.reducers";
import style from "./index.module.scss";

interface DataType {
    name: string;
    email: string;
    role: string;
}

export default function Table(props: any) {
    const members = useSelector(selectMembers);
    const dispatch = useDispatch();
    const isMembersLoading = useSelector(checkIfLoading(fetchMembers.type));
    const size = useWindowSize();
    const overflowTableHeight = size.height - 404;

    useEffect(() => {
        dispatch(fetchMembers({ action: fetchMembers.type }));
    }, []);

    const handleRoleChange = (index: number, newValue: string) => {
        // let tmpData = Array.from(tableDate);
        members[index].role = newValue;
        // setTableDate(tmpData);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text) => <p className={style["table__text"]}>{text}</p>,
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (text, record, index) => {
                return (
                    <Select
                        bordered={false}
                        className={style["table__select"]}
                        placeholder="Select Role"
                        value={members[index].role.toLocaleLowerCase()}
                        onChange={(e: any) => handleRoleChange(index, e)}
                        options={[
                            { value: "admin", label: "Admin" },
                            { value: "member", label: "Member" },
                        ]}
                        disabled={true}
                    />
                );
            },
        },
        // {
        //   title: 'Action',
        //   dataIndex: 'createdAt',
        //   key: 'createdAt',
        //   render: (text, record, i) => {
        //     let modify = members[i].role
        //     return (
        //       <Button label={modify ? "Remove" : "Modify"} variant={modify ? "secondary" : "primary"} />
        //     )
        //   }
        // }
    ];

    return (
        <AntTable
            loading={isMembersLoading}
            columns={columns}
            dataSource={members}
            scroll={{ y: overflowTableHeight }}
            pagination={false}
        />
    );
}
