import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ActionMeta, MultiValue } from "react-select";

import CreatableSelect from "../../../../components/CreatableSelect/CreatableSelect";
import styles from "./Filters.module.css";
import {
    addEducation,
    addExperience,
    addIndustry,
    addJobTitle,
    addLocation,
    addSkills,
    getTypeAhead,
    searchCandidates,
    selectEducationOptions,
    selectErrors,
    selectExperience,
    selectExperienceOptions,
    selectIndustry,
    selectIndustryOptions,
    selectJobTitleOptions,
    selectJobTitles,
    selectLocationOptions,
    selectLocations,
    selectSkillOptions,
    selectSkills,
    selectVisaOptions,
} from "./Filters.reducer";
import { errorsType, optionType } from "./Filters.type";
import JobFunction from "./JobFunction";

const Filters = () => {
    const dispatch = useDispatch();
    const locationOptions = useSelector(selectLocationOptions);
    const skillOptions = useSelector(selectSkillOptions);
    const educationOptions = useSelector(selectEducationOptions);
    const industryOptions = useSelector(selectIndustryOptions);
    const jobTitleOptions = useSelector(selectJobTitleOptions);
    const visaOptions = useSelector(selectVisaOptions);
    const experienceOptions = useSelector(selectExperienceOptions);
    const errors: errorsType = useSelector(selectErrors);

    // const jobTitles = useSelector(selectJobTitles);
    // const skills = useSelector(selectSkills);
    // const experience = useSelector(selectExperience);
    // const industry = useSelector(selectIndustry);
    const locations = useSelector(selectLocations);

    const [typeAhed, setTypeAhead] = useState<optionType>({
        value: "",
        label: "",
    });

    const onChangeTypedField = (
        newValue: MultiValue<optionType>,
        action: ActionMeta<optionType>
    ) => {
        const isJobTitle = action.name === "role";
        const isLocation = action.name === "location";
        const isSkills = action.name === "skill";
        const isIndustry = action.name === "industry";
        const isEducation = action.name === "education";
        const isExperience = action.name === "experience";

        const isCreate =
            action.action === "create-option" ||
            action.action === "select-option" ||
            action.action === "remove-value";
        const canClear =
            action.action === "clear" || action.action === "remove-value";
        // const isValueReomved = action.action === "remove-value";

        if (isCreate) {
            isJobTitle && dispatch(addJobTitle(newValue));
            isLocation && dispatch(addLocation(newValue));
            isSkills && dispatch(addSkills(newValue));
            isIndustry && dispatch(addIndustry(newValue));
            isExperience && dispatch(addExperience(newValue));
            isEducation && dispatch(addEducation(newValue));
        } else if (canClear) {
        }
    };

    useEffect(() => {
        if (!typeAhed.value) return;
        dispatch(getTypeAhead(typeAhed));
    }, [typeAhed]);

    return (
        <Stack className={styles.container}>
            <JobFunction />
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Job title
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    onInputChange={debounce(setTypeAhead, 500)}
                    placeholder="eg: Account Executive"
                    name="role"
                    options={jobTitleOptions.length > 1 ? jobTitleOptions : []}
                />
                <Typography className={styles.error} variant="body2">
                    {errors.title}
                </Typography>
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Geography
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add location"
                    name="location"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={locationOptions.length > 1 ? locationOptions : []}
                />
                <Typography className={styles.error} variant="body2">
                    {errors.location}
                </Typography>
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Skills
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="eg: Sales Prospecting"
                    name="skill"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={skillOptions.length > 1 ? skillOptions : []}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    US Work Authorization
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Please Select an Option from Menu..."
                    name="Visa"
                    options={visaOptions}
                    isSearchable={false}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Experience
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add experience"
                    name="experience"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={experienceOptions}
                    isSearchable={false}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Education
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add education"
                    name="education"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={
                        educationOptions.length > 1 ? educationOptions : []
                    }
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Industry
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add industry"
                    name="industry"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={industryOptions.length > 1 ? industryOptions : []}
                />
            </Stack>
            <Button
                variant="contained"
                onClick={() => dispatch(searchCandidates())}
            >
                Search
            </Button>
        </Stack>
    );
};

export default Filters;
