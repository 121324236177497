import { useEffect, useRef, useState } from "react";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SaveIcon from "@mui/icons-material/Save";
import {
    Button,
    ButtonProps,
    Skeleton,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Dropdown, MenuProps } from "antd";
import { Dayjs } from "dayjs";
import { get, isEmpty } from "lodash";
import { Draggable, type DraggableProvided } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ButtonTextWithLoading from "../../../../components/ButtonTextWithLoading";
import DateModal from "../../../../components/DateModal";
import DisableWorkflowComponentOverlay from "../../../../components/DisableWorkflowComponentOverlay";
import InputWithWordCount from "../../../../components/InputWithWordCount/InputWithWordCount";
import Modal from "../../../../components/Modal";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import TextAreaWithWordCount from "../../../../components/TextAreaWithWordCount/TextAreaWithWordCount";
import {
    chevronDownIconBlue,
    cloneIcon,
    personaliseIcon,
    trashGrayIcon,
} from "../../../../constant";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import quillWordCount from "../../../../utils/quillWordCount";
import { selectUser } from "../../../Signin/Signin.reducer";
import CustomMUIInput from "../../../project/components/CustomMUIInput";
import {
    createNewTemplate,
    selectEnableFollowUpEmail,
    selectTemplateList,
    setEditMsgPopup,
    setEnableFollowUpEmail,
} from "../../../template/index.reducer";
import { ITemplate, ITemplateList } from "../../../template/template.types";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import {
    selectCandidates,
    selectIndex,
    setEditTemplatesModal,
} from "../../../triggerWorkflow/reducers/personalizedWorkflow.slice";
import {
    postTestMail,
    selectHyperPersonalizeMode,
} from "../../../workflow/index.reducer";
import DisableFollowUpEmailSwitch from "./DisableFollowUpEmailSwitch";
import style from "./index.module.scss";

interface emailPropsType {
    isOpen: boolean;
    index: number;
    subject?: string;
    editorVal?: string;
    setSubject?: (tarInd: number, newValue: string) => void;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    cloneDisabled?: boolean;
    isLoading?: boolean;
}

const CustomButton = styled((props: ButtonProps) => <Button {...props} />)(
    () => ({
        color: "#fff",
        backgroundColor: "#088397",
        borderRadius: 8,
        borderColor: "#088397",
        padding: "0 1rem",
        "&:hover": {
            backgroundColor: "#088397",
            borderColor: "#088397",
        },
        "&:disabled": {
            backgroundColor: "#088397",
            borderColor: "#088397",
        },
    })
);

export default function Email(props: emailPropsType) {
    const {
        index,
        editorVal,
        setEditorVal,
        isOpen,
        toggleCollapse,
        handleRemove,
        handleDuplicate,
        subject = "",
        setSubject,
        type,
        readable,
        cloneDisabled,
        isLoading,
    } = props;

    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(selectUser);
    const candidateIndex = useSelector(selectIndex);
    const candidates = useSelector(selectCandidates);
    const templateList = useSelector(selectTemplateList);
    const isTestMailLoading = useSelector(
        checkIfLoading(`${index}email${postTestMail.type}`)
    );

    const subjectRef = useRef<HTMLInputElement | null>(null);
    const bodyRef = useRef(null);
    const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [tempDropDown, setTempDropDown] = useState<MenuProps["items"]>([]);
    const [title, setTitle] = useState<string>("");
    const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
    const [checkInputFocus, setCheckInputFocus] = useState<{
        index: number;
        value: string;
    }>({
        index: 0,
        value: "",
    });
    const [selectedTemplate, setSelectedTemplate] =
        useState<string>("Select Template");
    const [emailBodyWordCount, setEmailBodyWordCount] = useState(
        editorVal?.split(" ").length || 0
    );
    const [testMailModal, setTestMailModal] = useState(false);
    const [testMailInput, setTestMailInput] = useState("");
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    const enableFollowUpEmail = useSelector(selectEnableFollowUpEmail);

    // const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    // const showDateModal = () => setIsDateModalOpen(true);
    const closeDateModal = () => setIsDateModalOpen(false);

    const openTestMailModal = () => setTestMailModal(true);
    const closeTestMailModal = () => {
        setTestMailInput("");
        setTestMailModal(false);
    };

    const handleSelectDate = function (dayVal: Dayjs | null) {
        closeDateModal();
    };

    const showTemplateModal = () => {
        if (!subject) {
            dispatch(setErrorNotification("Subject can't be empty"));
            return;
        } else if (!editorVal?.replace(/<[^>]*>/g, "")) {
            dispatch(setErrorNotification("Body can't be empty"));
            return;
        } else {
            setOpenTemplateModal(true);
        }
    };

    const closeTemplateModal = () => {
        setTitle("");
        setOpenTemplateModal(false);
    };

    useEffect(() => {
        if (isEmpty(templateList)) return;
        let emailTemplates: ITemplate[] = [];
        for (let val of templateList) {
            if (type === "in-mail") {
                if (val.type === "INMAIL") emailTemplates.push(val);
            } else {
                if (val.type === "EMAIL") emailTemplates.push(val);
            }
        }

        const dropDown: MenuProps["items"] = [];
        for (let i = 0; i < emailTemplates.length; i++) {
            dropDown.push({
                label: (
                    <p
                        className={style["sp__link"]}
                        onClick={() => {
                            setSelectedTemplate(emailTemplates[i].name);
                            if (setEditorVal) {
                                setEditorVal(index, emailTemplates[i].body);
                            }
                            if (setSubject) {
                                setSubject(index, emailTemplates[i].subject);
                            }
                        }}
                    >
                        {emailTemplates[i].name}
                    </p>
                ),
                key: i.toString(),
            });
        }
        setTempDropDown(dropDown);
    }, [templateList]);

    const handleSave = () => {
        if (!title) {
            dispatch(setErrorNotification("Template name can't be empty"));
            return;
        }

        dispatch(
            createNewTemplate({
                name: title,
                body: editorVal,
                subject: subject,
                type: type === "in-mail" ? "INMAIL" : "EMAIL",
            })
        );
        closeTemplateModal();
    };

    const handlePersonaliseValues = (key: string) => {
        if (!checkInputFocus.value || !setSubject || !setEditorVal) return;

        if (checkInputFocus.value === "subject") {
            const cursorPosition = subjectRef.current?.selectionStart || 0;
            const tempSubject =
                subject?.substring(0, cursorPosition) +
                key +
                subject?.substring(cursorPosition);
            setSubject(checkInputFocus.index, tempSubject);
            subjectRef.current?.setSelectionRange(
                cursorPosition + 1,
                cursorPosition + 1
            );
        } else if (checkInputFocus.value === "body") {
            // @ts-ignore
            bodyRef.current.editor.focus();
            // @ts-ignore
            const selection = bodyRef.current.editor.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            // @ts-ignore
            bodyRef.current.editor.insertText(cursorPosition, key);
        }
    };

    const dispatchTestMail = (email?: string) => {
        dispatch(
            postTestMail({
                email: email,
                action: `${index}email${postTestMail.type}`,
                body: editorVal || "",
                projectId: location.state?.projectId,
                candidateId: candidates[candidateIndex]?.id,
                onSuccess: closeTestMailModal,
            })
        );
    };

    const sendTestMail = () => {
        if (user.emailAuthorized) {
            dispatchTestMail();
        } else {
            openTestMailModal();
        }
    };

    const handleReactQuillClick = () => {
        dispatch(setEditTemplatesModal(true));
        dispatch(setEditMsgPopup(true));
        setTimeout(() => {
            dispatch(setEditMsgPopup(false));
        }, 500);
    };

    const personaliseOptions: MenuProps["items"] = [
        {
            label: "{firstName}",
            key: "{firstName}",
            onClick: () => handlePersonaliseValues("{firstName}"),
        },
        {
            label: "{fullName}",
            key: "{fullName}",
            onClick: () => handlePersonaliseValues("{fullName}"),
        },
        {
            label: "{senderName}",
            key: "{senderName}",
            onClick: () => handlePersonaliseValues("{senderName}"),
        },
        {
            label: "{jobTitle}",
            key: "{jobTitle}",
            onClick: () => handlePersonaliseValues("{jobTitle}"),
        },
        {
            label: "{industry}",
            key: "{industry}",
            onClick: () => handlePersonaliseValues("{industry}"),
        },
        {
            label: "{organization}",
            key: "{organization}",
            onClick: () => handlePersonaliseValues("{organization}"),
        },
    ];

    if (
        readable ||
        !handleDuplicate ||
        !handleRemove ||
        !setSubject ||
        !setEditorVal
    )
        return (
            <>
                <div
                    className={`${style["email__legendBox"]} ${
                        index === 0 ? style["email__legendBox-start"] : ""
                    }`}
                >
                    {isOpen ? (
                        <div className={style["email__actionbar"]}>
                            {handleDuplicate ? (
                                <div
                                    className={style["email__actionBtn"]}
                                    onClick={() => {
                                        handleDuplicate(index);
                                    }}
                                >
                                    <Tooltip title={"Duplicate"}>
                                        <img src={cloneIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {handleRemove ? (
                                <div
                                    className={style["email__actionBtn"]}
                                    onClick={() => handleRemove(index)}
                                >
                                    <Tooltip title={"Delete"}>
                                        <img src={trashGrayIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <p
                        className={style["email__legend"]}
                        onClick={() => toggleCollapse(index)}
                    >
                        {type && type === "in-mail"
                            ? "LinkedIn Inmail"
                            : "Email"}{" "}
                        <img
                            className={isOpen ? style["email__invert"] : ""}
                            src={chevronDownIconBlue}
                            alt=""
                        />
                    </p>
                </div>
                <Collapse in={isOpen}>
                    <div className={style["email__body"]}>
                        <div className={style["email__selectBox"]}>
                            <input
                                ref={subjectRef}
                                type="text"
                                placeholder="Subject"
                                value={subject}
                                disabled={readable}
                                style={{
                                    width: "100%",
                                    padding: "0.5rem 1rem",
                                    borderRadius: "0.5rem",
                                }}
                            />
                        </div>
                        <ReactQuill
                            ref={bodyRef}
                            value={editorVal}
                            readOnly={readable}
                        />
                    </div>
                </Collapse>
            </>
        );
    return (
        <>
            <Draggable draggableId={`email${index}`} index={index}>
                {(dragProvider: DraggableProvided) => (
                    <div
                        className={style["email"]}
                        ref={dragProvider.innerRef}
                        {...dragProvider.dragHandleProps}
                        {...dragProvider.draggableProps}
                    >
                        <div
                            className={style["email__container"]}
                            id="workflow-email"
                        >
                            <div
                                className={`${style["email__legendBox"]} ${
                                    index === 0
                                        ? style["email__legendBox-start"]
                                        : ""
                                }`}
                            >
                                {/* {isOpen ? (
                                    <div className={style["email__actionbar"]}>
                                        {!cloneDisabled ? (
                                            <div
                                                className={
                                                    style["email__actionBtn"]
                                                }
                                                onClick={() =>
                                                    handleDuplicate(index)
                                                }
                                            >
                                                <Tooltip title={"Duplicate"}>
                                                    <img
                                                        src={cloneIcon}
                                                        alt=""
                                                    />
                                                </Tooltip>
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                style["email__actionBtn"]
                                            }
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Tooltip title={"Delete"}>
                                                <img
                                                    src={trashGrayIcon}
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : null} */}
                                <p
                                    className={style["email__legend"]}
                                    onClick={() => toggleCollapse(index)}
                                >
                                    {type && type === "in-mail"
                                        ? "LinkedIn Inmail"
                                        : "Email"}{" "}
                                    <img
                                        className={
                                            isOpen ? style["email__invert"] : ""
                                        }
                                        src={chevronDownIconBlue}
                                        alt=""
                                    />
                                </p>
                            </div>
                            <Collapse in={isOpen}>
                                <div
                                    className={style["email__body"]}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {index === 4 && (
                                        <>
                                            <Stack
                                                style={{
                                                    maxHeight: "50px",
                                                    padding: "0.3rem 1rem",
                                                    position: "absolute",
                                                    top: "-40px",
                                                    right: "50px",
                                                    backgroundColor: "white",
                                                    borderTopLeftRadius:
                                                        "0.7rem",
                                                    borderTopRightRadius:
                                                        "0.7rem",
                                                }}
                                            >
                                                <DisableFollowUpEmailSwitch />
                                            </Stack>
                                            {!enableFollowUpEmail && (
                                                <DisableWorkflowComponentOverlay>
                                                    Enable this step to send
                                                    follow-up email.
                                                </DisableWorkflowComponentOverlay>
                                            )}
                                        </>
                                    )}
                                    <div className={style["email__selectBox"]}>
                                        {isLoading ? (
                                            <Skeleton
                                                variant="rounded"
                                                width="100%"
                                                height={40}
                                            />
                                        ) : (
                                            <InputWithWordCount
                                                ref={subjectRef}
                                                wordLimit={10}
                                                disabled={isHyperPersonalized}
                                                placeholder="Subject"
                                                customValue={subject}
                                                handleOnChange={(val) =>
                                                    setSubject(index, val)
                                                }
                                                wrapperStyle={{
                                                    width: "100%",
                                                }}
                                                inputStyle={{
                                                    width: "100%",
                                                }}
                                                onClick={
                                                    isHyperPersonalized
                                                        ? handleReactQuillClick
                                                        : undefined
                                                }
                                                onFocus={() => {
                                                    setCheckInputFocus({
                                                        index,
                                                        value: "subject",
                                                    });
                                                    const cursorPosition =
                                                        subjectRef.current
                                                            ?.selectionStart ||
                                                        0;
                                                    subjectRef.current?.setSelectionRange(
                                                        cursorPosition,
                                                        cursorPosition
                                                    );
                                                }}
                                            />
                                        )}
                                        <div
                                            className={
                                                style["email__templateBox"]
                                            }
                                        >
                                            {/* <Dropdown
                                                trigger={["click"]}
                                                menu={{ items: tempDropDown }}
                                            >
                                                <div
                                                    className={
                                                        style["email__select"]
                                                    }
                                                >
                                                    <p>{selectedTemplate}</p>
                                                </div>
                                            </Dropdown> */}
                                            <Tooltip title={"Personalise"}>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseOptions,
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            style[
                                                                "email__select-personalise"
                                                            ]
                                                        }
                                                        src={personaliseIcon}
                                                        alt="personalise-icon"
                                                    />
                                                </Dropdown>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {isLoading ? (
                                        <Skeleton
                                            variant="rounded"
                                            width="100%"
                                            height={200}
                                        />
                                    ) : (
                                        <>
                                            <div
                                                onClick={
                                                    isHyperPersonalized
                                                        ? handleReactQuillClick
                                                        : undefined
                                                }
                                            >
                                                <ReactQuill
                                                    className={
                                                        isHyperPersonalized
                                                            ? "email-quill"
                                                            : ""
                                                    }
                                                    readOnly={
                                                        isHyperPersonalized
                                                    }
                                                    ref={bodyRef}
                                                    value={editorVal}
                                                    onChange={(value) => {
                                                        const wordCount =
                                                            quillWordCount(
                                                                value
                                                            );

                                                        setEmailBodyWordCount(
                                                            wordCount
                                                        );
                                                        setEditorVal(
                                                            index,
                                                            value
                                                        );
                                                    }}
                                                    onFocus={() => {
                                                        setCheckInputFocus({
                                                            index,
                                                            value: "body",
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <Stack
                                                mt={1}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                {type === "in-mail" ? (
                                                    <Stack>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#6C6C6C",
                                                            }}
                                                        >
                                                            *Incase of normal
                                                            LinkedIn licence,
                                                            Inmails will be sent
                                                            only to open
                                                            profiles (no
                                                            Linkedin credits
                                                            will be used)
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#6C6C6C",
                                                            }}
                                                        >
                                                            **Incase of
                                                            Recruiter, Inmails
                                                            will be sent based
                                                            on the remaining
                                                            credits in your
                                                            Recruiter account
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#6C6C6C",
                                                        }}
                                                    >
                                                        *If the email is not
                                                        available for a
                                                        candidate, this step
                                                        will be skipped
                                                    </Typography>
                                                )}
                                                {type === "email" ? (
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        startIcon={
                                                            isTestMailLoading ? null : (
                                                                <ForwardToInboxIcon fontSize="small" />
                                                            )
                                                        }
                                                        disabled={
                                                            isTestMailLoading
                                                        }
                                                        onClick={sendTestMail}
                                                        sx={{
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                        <ButtonTextWithLoading
                                                            isLoading={
                                                                isTestMailLoading
                                                            }
                                                            progressSize={16}
                                                            progressStyle={{
                                                                color: "#479bd2",
                                                            }}
                                                            text="Test Mail"
                                                        />
                                                    </Button>
                                                ) : null}
                                                {/* <p
                                                    className={
                                                        style[
                                                            "email__bodyWords"
                                                        ]
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            color:
                                                                emailBodyWordCount >
                                                                125
                                                                    ? "red"
                                                                    : "inherit",
                                                        }}
                                                    >
                                                        {emailBodyWordCount}
                                                    </span>
                                                    /125 words
                                                </p> */}
                                            </Stack>
                                        </>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                )}
            </Draggable>
            <Modal
                open={isDateModalOpen}
                onOk={closeDateModal}
                onCancel={closeDateModal}
                title={"Select Date"}
                width={"350px"}
            >
                <DateModal
                    closeModal={closeDateModal}
                    handleSelectDate={handleSelectDate}
                />
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                onOk={closeDeleteModal}
                onCancel={closeDeleteModal}
                title={"Confirm Delete"}
                width={"350px"}
            >
                <div className={style["rem__btnBox"]}>
                    <Button variant="outlined" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleRemove(index)}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
            <Modal
                open={openTemplateModal}
                onOk={closeTemplateModal}
                onCancel={closeTemplateModal}
                title={"Save Template"}
                width={"350px"}
            >
                <input
                    className={style["email__input"]}
                    type={"text"}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <div className={style["email__templateBtns"]}>
                    <Button variant="outlined" onClick={closeTemplateModal}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Add
                    </Button>
                </div>
            </Modal>
            {testMailModal ? (
                <BaseModal
                    onClose={closeTestMailModal}
                    overlayStyles={{
                        padding: "1rem 1.5rem 1.5rem",
                    }}
                >
                    <Typography fontSize={20} fontWeight={500} mb={2}>
                        Test Mail
                    </Typography>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            dispatchTestMail(testMailInput);
                        }}
                    >
                        <Stack direction="row" alignItems="stretch" spacing={2}>
                            <CustomMUIInput
                                type="email"
                                required
                                placeholder="Enter your email id"
                                value={testMailInput}
                                fullWidth
                                onChange={(e) =>
                                    setTestMailInput(e.target.value)
                                }
                            />
                            <CustomButton
                                type="submit"
                                disabled={isTestMailLoading}
                            >
                                <ButtonTextWithLoading
                                    isLoading={isTestMailLoading}
                                    text="Submit"
                                />
                            </CustomButton>
                        </Stack>
                    </form>
                </BaseModal>
            ) : null}
        </>
    );
}
