import { Button, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../../components/Loader";
import Navbar from "../../../components/Navbar";
import { TRACKING } from "../../../utils/Constants";
import styles from "./extensionTrack.module.css";
import {
    getTracking,
    selectLoading,
    selectStartDate,
    selectTracking,
    selectUserDetails,
    setDate,
} from "./extensionTrack.reducers";
import { trackingExtType, trackingValue } from "./extensionTracking.type";

const ExtensionTrack = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const date = useSelector(selectStartDate);
    const tracking = useSelector(selectTracking);
    const loading = useSelector(selectLoading);
    const userDetail = useSelector(selectUserDetails);
    const id = params?.id || "";

    return (
        <div className={styles.container}>
            <Navbar />
            <div className={styles.trackingContainer}>
                <div className={styles.dateContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Select date"
                            value={dayjs(date)}
                            onChange={(newValue) =>
                                dispatch(
                                    setDate(
                                        newValue?.format("MM-DD-YYYY") || ""
                                    )
                                )
                            }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <Button
                        className={styles.getTracking}
                        disabled={!date || !id}
                        onClick={() => {
                            dispatch(
                                getTracking({
                                    userId: id,
                                    date,
                                })
                            );
                        }}
                    >
                        Check Tracking
                    </Button>
                </div>
                <Row className={styles.headerContainer}>
                    <Col span={12} className={styles.header}>
                        Name: {userDetail?.name}
                    </Col>
                    <Col span={12} className={styles.header}>
                        Email: {userDetail?.email}
                    </Col>
                </Row>
                <Row className={styles.headerContainer}>
                    <Col span={6} className={styles.header}>
                        Time
                    </Col>
                    <Col span={6} className={styles.header}>
                        Page
                    </Col>
                    <Col span={6} className={styles.header}>
                        Value
                    </Col>
                    <Col span={6} className={styles.header}>
                        Platform
                    </Col>
                </Row>
                <div className={styles.bodyContainer}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div>
                            {!isEmpty(tracking) ? (
                                tracking.map((item: trackingExtType) => (
                                    <TrackingCard tracking={item} />
                                ))
                            ) : (
                                <div>No tracking</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const TrackingCard = ({ tracking }: { tracking: trackingExtType }) => {
    const { key, createdAt, value, platform } = tracking;
    const isoTime = createdAt.split("T");
    const time = isoTime[1].split(".");

    return (
        <Row className={styles.tackingCard}>
            <Col span={6} className={styles.trackingCardItem}>
                {time[0]}
            </Col>
            <Col span={6} className={styles.trackingCardItem}>
                <div
                    dangerouslySetInnerHTML={{
                        //@ts-ignore
                        __html: TRACKING[key],
                    }}
                />
            </Col>
            <Col span={6} className={styles.trackingCardItem}>
                {value.map((item: trackingValue) => (
                    <div>{item.label}</div>
                ))}
            </Col>
            <Col span={6} className={styles.trackingCardItem}>
                {platform}
            </Col>
        </Row>
    );
};

export default ExtensionTrack;
