import React, { useRef, useState } from "react";
import { Button, Input, Select } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import style from "./unsubscribe.module.scss";
import { unsubscribeEmail } from "./unsubscribe.reducer";

export default function Unsubscribe() {
    const unsubscribeStatus = useSelector<any, string>((state) =>
        get(state, "unsubscribeEmail.unsubscribe")
    );
    const isSubmitting = useSelector(checkIfLoading(unsubscribeEmail.type));

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [reason, setReason] = useState<string>("");

    const email = searchParams.get("email");
    const clientId = searchParams.get("clientId");
    const alreadyUnsubscribed = searchParams.get("unsubscribed");
    const unsubscribeReason =
        reason === "OTHER" ? inputRef.current?.value || "Unknown" : reason;

    const handleSelectReason = (val: string) => {
        setReason(val);
    };
    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!clientId) return;

        dispatch(
            unsubscribeEmail({
                token: clientId,
                reason: unsubscribeReason,
                action: unsubscribeEmail.type,
            })
        );
    };

    return (
        <div className={style["unsubscribePage"]}>
            <form
                className={style["unsubscribePage__wrapper"]}
                onSubmit={handleSubmit}
            >
                <div className={style["unsubscribePage__header"]}>
                    <strong>{email}</strong>
                    <br />
                    {alreadyUnsubscribed
                        ? ""
                        : unsubscribeStatus
                        ? "We have received your request and will remove your email from our database within 24 hours. Thank you."
                        : "is subscribed to our mailing list (s)."}
                </div>
                {alreadyUnsubscribed ? (
                    <div className={style["unsubscribePage__body"]}>
                        <strong>Already unsubscribed</strong>
                    </div>
                ) : unsubscribeStatus ? (
                    <div className={style["unsubscribePage__success"]}>
                        <strong>Unsubscribe successful</strong>
                    </div>
                ) : (
                    <>
                        <div className={style["unsubscribePage__body"]}>
                            <strong>Unsubscribe from our mailing list</strong>
                            <br />
                            To help us improve our services, we would be
                            grateful if you could tell us why:
                            <br />
                            <div
                                className={
                                    style["unsubscribePage__selectReason"]
                                }
                            >
                                <Select
                                    defaultValue={reason}
                                    style={{ width: "100%", marginBottom: 20 }}
                                    onChange={handleSelectReason}
                                    options={[
                                        {
                                            value: "",
                                            label: "Please select a reason",
                                        },
                                        {
                                            value: "Not interested in this job",
                                            label: "Not interested in this job",
                                        },
                                        {
                                            value: "Not looking for jobs right now",
                                            label: "Not looking for jobs right now",
                                        },
                                        {
                                            value: "I find this email SPAM",
                                            label: "I find this email SPAM",
                                        },
                                        {
                                            value: "This email is irrelevant to me",
                                            label: "This email is irrelevant to me",
                                        },
                                        { value: "OTHER", label: "Others" },
                                    ]}
                                />
                                {reason === "OTHER" ? (
                                    <Input.TextArea
                                        ref={inputRef}
                                        placeholder={
                                            "Provide your reason here..."
                                        }
                                        rows={4}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={isSubmitting}
                            style={{
                                backgroundColor: "#479BD2",
                                marginTop: 30,
                            }}
                        >
                            Unsubscribe
                        </Button>
                    </>
                )}
            </form>
        </div>
    );
}
