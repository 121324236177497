import React from "react";
import { Button } from "@mui/material";

import Modal from "../../../../../components/Modal";
import styles from './addCredit.module.css';

const AddCredit = (props: any) => {
    const { open, onOk, onCancel, orgName, onChangeCredit, isDisabled } = props;
    return (
        <Modal
            title={`Add credits to ${orgName}`}
            open={open}
            onOk={onOk}
            onCancel={onCancel}
        >
            <input
                className={styles.addCreditInput}
                placeholder="Enter credits"
                type="number"
                onChange={onChangeCredit}
            />
            <br />
            <div className={styles.buttonContainer}>
                <Button 
                    variant="contained" 
                    onClick={onOk}
                    className={styles.sendCredits}
                    disabled={isDisabled}
                >
                    Add credits
                </Button>
            </div>
            
        </Modal>
    )
}

export default AddCredit;