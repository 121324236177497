import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import { EXTENSION_LINK } from "../../utils/Constants";

const InstallExtensionModal = ({ open }: { open: boolean }) => {
    return (
        <Dialog
            open={open}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: 2,
                },
            }}
        >
            <DialogTitle>Install Chrome Extension</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please install the extension to continue using the
                    dashboard.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    href={EXTENSION_LINK}
                    target="_blank"
                    rel="noreferrer"
                >
                    Install
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InstallExtensionModal;
