import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";

const initialState = Object.freeze({
    isSuperAdminView: false,
    userDetails: {
        displayName: "",
        email: "",
        id: 0,
        photoURL: "",
        totalCredits: 0,
        remainingCredits: 0,
        currentCredits: 0,
    },
    loading: false,
});

const reducers = {
    setUserDetail(
        state: any,
        {
            payload,
        }: { payload: { displayName: string; email: string; id: number } }
    ) {
        state.userDetails = payload;
        state.isSuperAdminView = true;
    },
    setIsSuperAdminView(state: any, { payload }: { payload: boolean }) {
        state.isSuperAdminView = payload;
        // if(!payload) window.location.href = "/";
    },
    setUserCredits(
        state: any,
        {
            payload,
        }: {
            payload: {
                totalEmailFetchCredit: number;
                remainingEmailFetchCredit: number;
            };
        }
    ) {
        const { totalEmailFetchCredit, remainingEmailFetchCredit } = payload;
        state.userDetails.totalCredits = totalEmailFetchCredit;
        state.userDetails.remainingCredits = remainingEmailFetchCredit;
        state.userDetails.currentCredits =
            totalEmailFetchCredit - remainingEmailFetchCredit;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "superAdmin",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const { setUserDetail, setUserCredits, setIsSuperAdminView, leavePage } =
    page.actions;

export const selectLoading = (state: RootState) => state.superAdmin.loading;
export const selectUserDetails = (state: RootState) =>
    state.superAdmin.userDetails;
export const selectIsSuperAdminView = (state: RootState) =>
    state.superAdmin.isSuperAdminView;
