import React, { useEffect, useState } from 'react';
import { Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Navbar from '../../../components/Navbar';
import { getHcConversation, getHcChatHistory, selectHcChatsHistory, selectHcTotalChatsHistory, selectCanShowConversations, selectIsHistoryLoading } from './ChatHistory.reducer';
import { useParams } from 'react-router-dom';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { formatDate } from '../../../utils/helper';
import styles from './ChatHistory.module.css';
import store from '../../../store';
import Conversation from './Conversation/Conversation';

const columns = [
    {
        title: "Conversation id",
        dataIndex: "_id",
        minWidth: 100,
    },
    {
        title: "Name",
        dataIndex: "name",
        minWidth: 100,
        render: (record: any) => {
            return (
                <div className={styles.nameContainer}>
                    <Tooltip title="Click to load chats">
                        <Typography
                           variant="body2"
                            className={styles.link}
                            onClick={() => store.dispatch(getHcConversation(record._id))}
                        >
                            {record.name}
                        </Typography>
                    </Tooltip>
                </div>
            );
        },
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        render: (record: any) => {
            return formatDate(record.createdAt);
        },
    },
];

const ChatHistory = () => {
    const dispatch= useDispatch();
    const params = useParams();
    const chatList = useSelector(selectHcChatsHistory);
    const canShowConversations = useSelector(selectCanShowConversations);
    const totalChatHistory = useSelector(selectHcTotalChatsHistory);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const isHistoryLoading = useSelector(selectIsHistoryLoading);

    useEffect(() => {
        dispatch(getHcChatHistory(params?.orgId || ""));
    }, []);;

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => {
        setCurrentPage(page);
    };

    const onChangePageSize = (pageSize: number) => {
        setCurrentPage(0);
        setPageSize(pageSize);
    };

    return (
        <Stack>
            <Navbar />

            {!isHistoryLoading && !chatList?.length ?
                <Paper sx={{ padding: 10, textAlign: 'center' }}>
                    No chats
                </Paper>
                : <CustomTable
                    columns={columns}
                    total={totalChatHistory}
                    rows={chatList}
                    onPageChange={handlePageChange}
                    page={currentPage}
                    pageSize={pageSize}
                    onPageSizeChange={(value) => onChangePageSize(value)}
                    isLoading={isHistoryLoading}
                />
            }
            {canShowConversations && <Conversation />}
        </Stack>
    )
}

export default ChatHistory;