import { all, fork } from "redux-saga/effects";

import interActiveWalkthorugh from "../../components/InterActiveWalkthroughModal/InterActiveWalkthroughModal.sagas";
import nudgesSaga from "../../components/Nudges/Nudges.sagas";
import appContainerSaga from "../../container/AppContainer/AppContainer.sagas";
import CandidatesSaga from "../../pages/AdvancedFilters/Components/Candidates/Cadidates.saga";
import advancedFilters from "../../pages/AdvancedFilters/Components/Filters/Filters.saga";
import hcChatHistory from "../../pages/HumanCircles/ChatHistory/ChatHistory.saga";
import hcUserList from "../../pages/HumanCircles/UserList/UserList.saga";
import signinSaga from "../../pages/Signin/Signin.sagas";
import allProjectSaga from "../../pages/allProjects/index.sagas";
import forgotPasswordSaga from "../../pages/forgotPassword/forgotPassword.saga";
import integrationSaga from "../../pages/integrations/integrations.saga";
import manageTeamsSaga from "../../pages/manageTeams/manageTeams.saga";
import myAccountSaga from "../../pages/myAccount/myAccount.saga";
import projectSaga from "../../pages/project/index.saga";
import extensionTrack from "../../pages/superAdmin/extensionTrack/extensionTrack.sagas";
import orgList from "../../pages/superAdmin/orgList/orgList.sagas";
import superAdmin from "../../pages/superAdmin/superAdmin.sagas";
import userList from "../../pages/superAdmin/userList/userList.sagas";
import templateSaga from "../../pages/template/index.saga";
import personalizedWorkflowSagas from "../../pages/triggerWorkflow/sagas/personalizeWorkflow.sagas";
import unsubscribeEmailSaga from "../../pages/unsubscribe/unsubscribe.saga";
import workflowSaga from "../../pages/workflow/index.saga";
import slackSaga from "./slackSend.saga";
import uploadWorkflowFileSaga from "./workflow-upload.saga";

export function* watchSagas() {
    yield all([
        fork(signinSaga),
        fork(allProjectSaga),
        fork(templateSaga),
        fork(workflowSaga),
        fork(projectSaga),
        fork(manageTeamsSaga),
        fork(forgotPasswordSaga),
        fork(appContainerSaga),
        fork(integrationSaga),
        fork(unsubscribeEmailSaga),
        fork(orgList),
        fork(userList),
        fork(myAccountSaga),
        fork(extensionTrack),
        fork(interActiveWalkthorugh),
        fork(superAdmin),
        fork(nudgesSaga),
        fork(slackSaga),
        fork(personalizedWorkflowSagas),
        fork(hcUserList),
        fork(hcChatHistory),
        fork(advancedFilters),
        fork(CandidatesSaga),
        fork(uploadWorkflowFileSaga),
    ]);
}
