import { useSelector } from "react-redux";

import { getExperience } from "../../../../utils/helper";
import { selectCurrProject } from "../../../allProjects/index.reducer";
import { IProjectExperience } from "../../project.types";
import style from "./projectInfo.module.scss";

export default function ProjectInfo() {
    const projectData = useSelector(selectCurrProject);

    return (
        <div className={style["sp__tooltip"]}>
            <div className={style["sp__tooltipWrapper"]}>
                {projectData?.source ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Source</h2>
                        <p>
                            {projectData?.source === "LINKEDIN"
                                ? "LinkedIn"
                                : "LinkedIn Recruiter"}
                        </p>
                    </div>
                ) : null}
                {projectData?.jobTitle && projectData.jobTitle.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Job Title</h2>
                        {projectData.jobTitle.length > 1 ? (
                            <ul>
                                {projectData.jobTitle.map((title: string) => (
                                    <li>{title}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{projectData.jobTitle[0]}</p>
                        )}
                    </div>
                ) : null}
                {projectData?.experience && projectData.experience.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Experience</h2>
                        {projectData.experience.length > 1 ? (
                            <ul>
                                {projectData.experience.map((ex) => (
                                    <li>
                                        {getExperience(
                                            ex as IProjectExperience
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>
                                {getExperience(
                                    projectData
                                        .experience[0] as IProjectExperience
                                )}
                            </p>
                        )}
                    </div>
                ) : null}
                {projectData?.location && projectData.location.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Geography</h2>
                        {projectData.location.length > 1 ? (
                            <ul>
                                {projectData.location.map(
                                    (loc: { label: string; value: string }) => (
                                        <li>{loc.label}</li>
                                    )
                                )}
                            </ul>
                        ) : (
                            <p>{projectData.location[0].label}</p>
                        )}
                    </div>
                ) : null}
                {projectData?.skills && projectData.skills.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Skills</h2>
                        {projectData.skills.length > 1 ? (
                            <ul>
                                {projectData.skills.map((skill: string) => (
                                    <li>{skill}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{projectData.skills[0]}</p>
                        )}
                    </div>
                ) : null}
                {projectData?.education && projectData.education.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Education</h2>
                        {projectData.education.length > 1 ? (
                            <ul>
                                {projectData.education.map((edu: string) => (
                                    <li>{edu}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{projectData.education[0]}</p>
                        )}
                    </div>
                ) : null}
                {projectData?.industry && projectData.industry.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Industry</h2>
                        {projectData.industry.length > 1 ? (
                            <ul>
                                {projectData.industry.map(
                                    (industry: {
                                        label: string;
                                        value: string;
                                    }) => {
                                        return industry?.label ? (
                                            <li>{industry.label}</li>
                                        ) : null;
                                    }
                                )}
                            </ul>
                        ) : (
                            <p>{projectData.industry[0].label}</p>
                        )}
                    </div>
                ) : null}
                {/* {projectData?.course && projectData.course.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Course</h2>
                        {projectData.course.length > 1 ? (
                            <ul>
                                {projectData.course.map((course: string) => (
                                    <li>{course}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{projectData.course[0]}</p>
                        )}
                    </div>
                ) : null} */}
                <div className={style["sp__tooltipItem"]}>
                    <h2>Visa</h2>
                    <p>{projectData?.visa ? "Yes" : "No"}</p>
                </div>
            </div>
        </div>
    );
}
