import { useState } from "react";
import { useDispatch } from "react-redux";

import { setSuccessNotification } from "../components/Notification/index.reducer";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);
    const dispatch = useDispatch();

    const copy: CopyFn = async (text) => {
        if (!navigator?.clipboard) {
            console.warn("Clipboard not supported");
            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            dispatch(setSuccessNotification("Code Copied Successfully"));
            setCopiedText(text);
            return true;
        } catch (error) {
            console.warn("Copy failed", error);
            setCopiedText(null);
            return false;
        }
    };

    return [copiedText, copy];
}

export default useCopyToClipboard;
