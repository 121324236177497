import { Dropdown, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { filterIcon } from "../../../../constant";
import handleCleverTap from "../../../../utils/clevertap";
import style from "../../index.module.scss";
import {
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByRecent,
    getShowByRelevance,
    selectShowByMaxExperience,
    selectShowByMinExperience,
    selectShowByMostRecent,
    selectShowByRelevance,
} from "../../index.reducer";

export default function SortCandidates() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const showByRelevance = useSelector(selectShowByRelevance);
    const showByMostRecent = useSelector(selectShowByMostRecent);
    const showByMaxExperience = useSelector(selectShowByMaxExperience);
    const showByMinExperience = useSelector(selectShowByMinExperience);

    const sortSelectedKeys = [
        showByRelevance ? "showByRelevance" : "",
        showByMostRecent ? "showByMostRecent" : "",
        showByMaxExperience ? "showByMaxExperience" : "",
        showByMinExperience ? "showByMinExperience" : "",
    ].filter((item) => item);

    const sortDropDown: MenuProps["items"] = [
        {
            label: (
                <p
                    className={style["sp__link"]}
                    onClick={() => {
                        if (!showByRelevance) {
                            dispatch(
                                getShowByRelevance({
                                    projectId: id,
                                    showByRelevance: !showByRelevance,
                                    action: getShowByRelevance.type,
                                })
                            );
                        }
                    }}
                >
                    Most Relevant
                </p>
            ),
            key: "showByRelevance",
        },
        {
            label: (
                <p
                    className={style["sp__link"]}
                    onClick={() => {
                        if (!showByMostRecent) {
                            dispatch(
                                getShowByRecent({
                                    projectId: id,
                                    action: getShowByRecent.type,
                                    showByMostRecent: true,
                                })
                            );
                        }
                    }}
                >
                    Most Recent
                </p>
            ),
            key: "showByMostRecent",
        },
        {
            label: (
                <p
                    className={style["sp__link"]}
                    onClick={() => {
                        if (!showByMaxExperience) {
                            dispatch(
                                getShowByMaxExperience({
                                    projectId: id,
                                    showByMaxExperience: true,
                                    action: getShowByMaxExperience.type,
                                })
                            );
                        }
                    }}
                >
                    Max Experience
                </p>
            ),
            key: "showByMaxExperience",
        },
        {
            label: (
                <p
                    className={style["sp__link"]}
                    onClick={() => {
                        if (!showByMinExperience) {
                            dispatch(
                                getShowByMinExperience({
                                    projectId: id,
                                    showByMinExperience: true,
                                    action: getShowByMinExperience.type,
                                })
                            );
                        }
                    }}
                >
                    Min Experience
                </p>
            ),
            key: "showByMinExperience",
        },
    ];

    const onClickSort = () => {
        handleCleverTap("Sort clicked");
    };

    return (
        <Dropdown
            trigger={["click"]}
            menu={{
                items: sortDropDown,
                selectedKeys: sortSelectedKeys,
            }}
            onOpenChange={onClickSort}
        >
            <div className={style["sp__filter"]}>
                Sort &nbsp;
                <img src={filterIcon} alt="sortBy-icon" />
            </div>
        </Dropdown>
    );
}
