import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { IProject } from "../project/project.types";
import {
    ICreateNotePayload,
    IDeleteProjectPayload,
    IEditNotePayload,
    IGetProjectPayload,
    IGetUserEmailPayload,
    IRemoveCandidateFromProjectPayload,
    IRootState,
    ISendEmailPayload,
    IUpdateProjectCreditsPayload,
} from "./allProjects.types";

const initialState: IRootState = {
    projectList: [],
    project: null,
    notes: [],
    activities: [],
    candidateEmail: null,
    candidate: null,
    userEmail: [],
};

const reducers = {
    getAllProjectsList(
        state: any,
        {
            payload,
        }: {
            payload: IActionPayload;
        }
    ) {},
    setAllProjectsList(state: any, { payload }: { payload: any }) {
        state.projectList = payload;
    },
    createNewProject(state: any, { payload }: { payload: any }) {},
    editProject(state: any, { payload }: { payload: any }) {},
    getProject(
        state: any,
        { payload }: { payload: IActionPayload & IGetProjectPayload }
    ) {},
    setProject(state: any, { payload }: { payload: IProject | {} }) {
        state.project = payload;
    },
    deleteProject(
        state: any,
        { payload }: { payload: IDeleteProjectPayload }
    ) {},
    getNotes(
        state: any,
        { payload }: { payload: { id: string } & IActionPayload }
    ) {},
    setNotes(state: any, { payload }: { payload: any }) {
        state.notes = payload;
    },
    createNewNote(
        state: any,
        { payload }: { payload: ICreateNotePayload & IActionPayload }
    ) {},
    editNote(
        state: any,
        { payload }: { payload: IEditNotePayload & IActionPayload }
    ) {},
    deleteNote(state: any, { payload }: { payload: any }) {},
    getActivities(
        state: any,
        { payload }: { payload: { id: string } & IActionPayload }
    ) {},
    setActivities(state: any, { payload }: { payload: any }) {
        state.activities = payload;
    },
    getCandidateEmail(state: any) {},
    setCandidateEmail(state: any, { payload }: { payload: any }) {
        state.candidateEmail = payload;
    },
    getUserEmail(
        state: any,
        { payload }: { payload: IGetUserEmailPayload & IActionPayload }
    ) {},
    setUserEmail(state: any, { payload }: { payload: any }) {
        state.userEmail = payload;
    },
    sendEmail(
        state: any,
        { payload }: { payload: ISendEmailPayload & IActionPayload }
    ) {},
    getCandidate(state: any, { payload }: { payload: any }) {},
    removeCandidateFromProject(
        state: any,
        { payload }: { payload: IRemoveCandidateFromProjectPayload }
    ) {},
    postEmailReadReceipt(
        state: any,
        { payload }: { payload: IActionPayload & { threadId: string } }
    ) {},
    leaveAllProjects(state: any) {},
    updateProjectCredits(
        state: any,
        { payload }: { payload: IUpdateProjectCreditsPayload & IActionPayload }
    ) {},
};

export const allProjectPage = createSlice({
    name: "allProjects",
    initialState,
    reducers,
});

export default allProjectPage.reducer;

export const {
    getAllProjectsList,
    setAllProjectsList,
    editProject,
    createNewProject,
    getProject,
    setProject,
    deleteProject,
    getNotes,
    setNotes,
    createNewNote,
    editNote,
    deleteNote,
    getActivities,
    setActivities,
    getCandidateEmail,
    setCandidateEmail,
    getUserEmail,
    setUserEmail,
    sendEmail,
    getCandidate,
    removeCandidateFromProject,
    leaveAllProjects,
    postEmailReadReceipt,
    updateProjectCredits,
} = allProjectPage.actions;

export const selectNotes = (state: RootState) => state.allProjects.notes;
export const selectActivities = (state: RootState) =>
    state.allProjects.activities;
export const selectAllProjects = (state: RootState) =>
    state.allProjects.projectList;
export const selectCurrProject = (state: RootState) =>
    state.allProjects.project;
export const selectUserEmails = (state: RootState) =>
    state.allProjects.userEmail;
