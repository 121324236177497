import CancelIcon from "@mui/icons-material/Cancel";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

const styles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
    boxShadow: 24,
    borderRadius: 2,
    border: 0,
    outline: 0,
};

type Props = {
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: React.ReactNode;
    overlayStyles?: SxProps<Theme>;
    hideCloseButton?: boolean;
};

export default function BaseModal(props: Props) {
    const { children, overlayStyles, onClose, hideCloseButton } = props;
    return (
        <Modal open={true} onClose={onClose}>
            <Stack sx={{ ...styles, ...overlayStyles }}>
                {children}
                {!hideCloseButton && (
                    <IconButton
                        onClick={onClose}
                        size="small"
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            marginTop: 0,
                        }}
                    >
                        <CancelIcon color="error" />
                    </IconButton>
                )}
            </Stack>
        </Modal>
    );
}
