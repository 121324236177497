import React from "react";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { ICandidate } from "../../../project/project.types";
import { searchCandidates } from "../Filters/Filters.reducer";
import AddToProject from "./AddToProject";
import styles from "./Candidates.module.css";
import {
    selectCanShowAddToProject,
    selectCandidate,
    selectCandidates,
    selectSelectedCandidate,
    setCanShowAddToProject,
} from "./Candidates.reducer";
import { candidateType } from "./Candidates.type";
import CadidateDetails from "./Components/CandidateDetails";

const Candidates = () => {
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const selectedCandidate: string[] = useSelector(selectSelectedCandidate);
    const canShowAddToProject = useSelector(selectCanShowAddToProject);
    const isCandidateLoading = useSelector(
        checkIfLoading(searchCandidates.type)
    );

    const onSelectAllCandidate = (isChecked: boolean) => {
        let newCandidate: string[] = [];
        if (isChecked) {
            newCandidate = candidates.map((item: candidateType) => item._id);
        }
        dispatch(selectCandidate(newCandidate));
    };

    const isAllChecked = selectedCandidate.length === candidates.length;
    return (
        <Stack className={styles.container} p="10px">
            {isCandidateLoading && <Loader />}
            {!isCandidateLoading && !candidates.length && (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Typography>
                        No candidate found, please start new search
                    </Typography>
                </Stack>
            )}
            {!isCandidateLoading && !!candidates.length && (
                <>
                    <Stack
                        className={styles.buttonContainer}
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Checkbox
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => onSelectAllCandidate(e.target.checked)}
                            checked={isAllChecked}
                            size="small"
                        />
                        <Typography mr="15px" minWidth="100px">
                            {selectedCandidate.length} Selected
                        </Typography>
                        <Button
                            variant="contained"
                            className={styles.addToProject}
                            onClick={() =>
                                dispatch(setCanShowAddToProject(true))
                            }
                            disabled={!selectedCandidate.length}
                        >
                            Add to Project
                        </Button>
                    </Stack>
                    {candidates.map((item: ICandidate) => (
                        <CadidateDetails data={item} />
                    ))}
                    {canShowAddToProject && <AddToProject />}
                </>
            )}
        </Stack>
    );
};

export default Candidates;
