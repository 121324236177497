import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

type ConnectWrapperProps = {
    logo: string;
    logoAlt: string;
    logoStyle?: SxProps<Theme>;
    title?: string;
    description: string;
    children: React.ReactNode;
    disabled?: boolean;
};

export default function ConnectWrapper({
    logo,
    logoAlt,
    logoStyle,
    title,
    description,
    children,
    disabled = false,
}: ConnectWrapperProps) {
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                padding: "20px",
                border: "1px solid #D3D3D3",
                borderRadius: "0.5rem",
                opacity: disabled ? 0.5 : 1,
            }}
        >
            <Stack direction="row" justifyContent="space-between" mb={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                        component="img"
                        sx={{
                            cursor: "pointer",
                            height: 40,
                            width: "auto",
                            maxHeight: { xs: 72, md: 64 },
                            borderRadius: 1,
                            ...logoStyle,
                        }}
                        src={logo}
                        alt={logoAlt}
                    />
                    {title ? (
                        <Typography fontSize={20} fontWeight={700}>
                            {title}
                        </Typography>
                    ) : null}
                </Stack>
                {disabled ? (
                    <Typography fontSize={14} color="red">
                        Coming Soon...
                    </Typography>
                ) : (
                    children
                )}
            </Stack>
            <Typography fontSize={14}>{description}</Typography>
        </Box>
    );
}
