import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    IAddToATSPayload,
    IAddToGreenhousePayload,
    IAddToLeverPayload,
    IGreenhouseJobs,
    IIntegrationRootState,
    IJobviteJobs,
    ILeverJob,
    ILeverStage,
    ILoxoCredentials,
    IPostGreenhouseCredentialsPayload,
    IPostJobviteCredentialsPayload,
    ISendSlackInvitationPayload,
} from "./integrations.types";

const initialState: IIntegrationRootState = {
    loxoAtsCredentials: {
        agencySlug: "",
        apiKey: "",
        apiPassword: "",
    },
    greenhouseJobs: [],
    leverJobs: [],
    leverStages: [],
    jobviteJobs: [],
};

const reducers = {
    fetchLoxoCredentials(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    postLoxoCredentials(
        state: typeof initialState,
        { payload }: { payload: ILoxoCredentials & IActionPayload }
    ) {},
    postGreenhouseCredentials(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IPostGreenhouseCredentialsPayload & IActionPayload;
        }
    ) {},
    setLoxoCredentials(
        state: typeof initialState,
        { payload }: { payload: ILoxoCredentials }
    ) {
        state.loxoAtsCredentials = payload;
    },
    addToLoxoAgency(
        state: typeof initialState,
        { payload }: { payload: IAddToATSPayload & IActionPayload }
    ) {},
    addToGreenhouseAgency(
        state: typeof initialState,
        { payload }: { payload: IAddToGreenhousePayload & IActionPayload }
    ) {},
    getGreenhouseJobs(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setGreenhouseJobs(
        state: typeof initialState,
        { payload }: { payload: IGreenhouseJobs[] }
    ) {
        state.greenhouseJobs = payload;
    },
    sendSlackInvitation(
        state: typeof initialState,
        { payload }: { payload: ISendSlackInvitationPayload }
    ) {},
    revokeSlackInvitation(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    addToZohoRecruit(
        state: typeof initialState,
        { payload }: { payload: IAddToATSPayload & IActionPayload }
    ) {},
    revokeZohoRecruit(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    getLeverJobs(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setLeverJobs(
        state: typeof initialState,
        { payload }: { payload: ILeverJob[] }
    ) {
        state.leverJobs = payload;
    },
    getLeverStages(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setLeverStages(
        state: typeof initialState,
        { payload }: { payload: ILeverStage[] }
    ) {
        state.leverStages = payload;
    },
    addToLever(
        state: typeof initialState,
        { payload }: { payload: IAddToLeverPayload & IActionPayload }
    ) {},
    postJobviteCredentials(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IPostJobviteCredentialsPayload & IActionPayload;
        }
    ) {},
    addToJobvite(
        state: typeof initialState,
        { payload }: { payload: IAddToGreenhousePayload & IActionPayload }
    ) {},
    getJobviteJobs(
        state: typeof initialState,
        { payload }: { payload: IActionPayload }
    ) {},
    setJobviteJobs(
        state: typeof initialState,
        { payload }: { payload: IJobviteJobs[] }
    ) {
        state.jobviteJobs = payload;
    },
    resetIntegration(state: typeof initialState) {},
};

export const integrationSlice = createSlice({
    name: "integrations",
    initialState,
    reducers,
});

export default integrationSlice.reducer;

export const {
    fetchLoxoCredentials,
    postLoxoCredentials,
    postGreenhouseCredentials,
    setLoxoCredentials,
    resetIntegration,
    addToLoxoAgency,
    addToGreenhouseAgency,
    getGreenhouseJobs,
    setGreenhouseJobs,
    sendSlackInvitation,
    revokeSlackInvitation,
    addToZohoRecruit,
    revokeZohoRecruit,
    getLeverJobs,
    setLeverJobs,
    getLeverStages,
    setLeverStages,
    addToLever,
    postJobviteCredentials,
    addToJobvite,
    getJobviteJobs,
    setJobviteJobs,
} = integrationSlice.actions;

export const selectGreenhouseJobs = (state: RootState) =>
    state.integrations.greenhouseJobs;
export const selectLeverJobs = (state: RootState) =>
    state.integrations.leverJobs;
export const selectLeverStages = (state: RootState) =>
    state.integrations.leverStages;
export const selectJobviteJobs = (state: RootState) =>
    state.integrations.jobviteJobs;
