import {
    hyperEmail2Body,
    hyperEmail3Body,
    hyperEmailSubject,
} from "../../../constant";
import { IComponentList } from "../../workflow/workflow.types";

type IProps = {
    componentList: IComponentList[];
};

export default function hyperPersonaliseFollowEmails({
    componentList,
}: IProps) {
    let emailCount = 0;
    const tmpComponentList = componentList.map((component) => {
        if (component.type === "email") {
            emailCount++;

            switch (emailCount) {
                case 1:
                    return {
                        ...component,
                        subject: "",
                        editorVal: "",
                    };
                case 2:
                    return {
                        ...component,
                        subject: hyperEmailSubject,
                        editorVal: hyperEmail2Body,
                    };
                case 3:
                    return {
                        ...component,
                        subject: hyperEmailSubject,
                        editorVal: hyperEmail3Body,
                    };
                default:
                    return component;
            }
        }
        return component;
    });
    return tmpComponentList;
}
