import { Spin } from "antd";

import style from "./index.module.scss";

export default function Loader() {
    return (
        <div className={style["wk__emailRecommendation-loader"]}>
            <Spin size="small" />
            <p className={style["wk__emailRecommendation-loader-text"]}>
                It usually takes 10-15 seconds for the AI generated message to
                load
            </p>
        </div>
    );
}
