import { useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "../../../../components/Button";
import { sendEmail } from "../../../allProjects/index.reducer";
import style from "./index.module.scss";

export default function EmailEditor({
    threadId,
    projectId,
}: {
    threadId: string;
    projectId: number;
}) {
    const params = useParams();
    const dispatch = useDispatch();

    const [editorVal, setEditorVal] = useState("");

    const handleSendEmail = () => {
        if (!params.id) return;

        dispatch(
            sendEmail({
                candidates: [params.id],
                emailRecipients: [],
                subject: "subject",
                body: editorVal,
                threadId,
                projectId,
                action: sendEmail.type,
            })
        );
        setEditorVal("");
    };

    return (
        <div className={style["smsg__footer"]}>
            <div className={style["smsg__inputBox"]}>
                <ReactQuill
                    value={editorVal}
                    onChange={setEditorVal}
                    className={style["smsg__quill"]}
                />
            </div>
            <div className={style["smsg__btnBox"]}>
                <Button
                    label="Send"
                    variant="primary"
                    onClick={handleSendEmail}
                />
            </div>
        </div>
    );
}
