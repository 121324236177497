import { useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Navbar from "../../components/Navbar";
import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import Billing from "./components/billing";
import MyDetails from "./components/myDetails";
import style from "./myAccount.module.scss";

const items: TabsProps["items"] = [
    {
        key: "details",
        label: `My details`,
        children: <MyDetails />,
    },
    // {
    //     key: "plan",
    //     label: `Billing & Purchase`,
    //     children: <Billing />,
    // },
];

export default function MyAccount() {
    const { tab } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const paymentStatus = searchParams.get("payment-status");

    useEffect(() => {
        if (!paymentStatus) return;
        if (paymentStatus === "success")
            dispatch(setSuccessNotification("Payment successful"));
        else if (paymentStatus === "canceled")
            dispatch(setErrorNotification("Payment unsuccessful"));
    }, [paymentStatus]);

    return (
        <div className={style["myAccount"]}>
            <Navbar variant="modified" />
            <div className={style["myAccount__tabs"]}>
                <Tabs
                    activeKey={tab}
                    items={items}
                    onChange={(key: string) => navigate(`/my-account/${key}`)}
                />
            </div>
        </div>
    );
}
