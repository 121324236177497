import { useEffect } from "react";
import { useTour } from "@reactour/tour";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../components/Modal";
import {
    selectOpenTriggerModal,
    setTourTriggerModal,
} from "../../../container/TourContainer/TourContainer.reducer";
import SelectWorkflowModal from "../../project/components/selectWorkflowModal";

const voidFn = () => null;

export default function TourTriggerModal() {
    const { currentStep } = useTour();
    const dispatch = useDispatch();
    const openTriggerModal = useSelector(selectOpenTriggerModal);
    const handleCloseModal = () => dispatch(setTourTriggerModal(false));

    useEffect(() => {
        if (currentStep !== 6) handleCloseModal();
    }, [currentStep]);

    return (
        <Modal
            open={openTriggerModal}
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
            title={"Trigger Workflow"}
            width={"fit-content"}
            keyboard={false}
        >
            <SelectWorkflowModal
                closeModal={handleCloseModal}
                wkDate={new Date()}
                setWkDate={voidFn}
                wkTemp={"Workflow tour"}
                setWkTemp={voidFn}
                handleAddToWk={voidFn}
                triggerWorkflow={true}
                isNameEditable={false}
                saveWk={true}
                onChangeSaveWk={voidFn}
            />
        </Modal>
    );
}
