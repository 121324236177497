import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import howToUse from "../../assets/img/how_to_use.mp4";
import { googleLogo } from "../../constant";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { isValidPersonName } from "../../utils/helper";
import { authLogin, googleLogin, signUp } from "../Signin/Signin.reducer";
import styles from "./index.module.scss";

export default function Signup() {
    const dispatch = useDispatch();
    const user = useSelector((state) => get(state, "signin.user"));
    const userId = get(user, "_id");
    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [registerUser, setRegisterUser] = useState({
        name: "",
        email: "",
        password: "",
        refferalCode: "",
    });
    const checkSignupLoading = useSelector(checkIfLoading(signUp.type));
    const checkAuthLoginLoading = useSelector(checkIfLoading(authLogin.type));
    const isLoading = checkSignupLoading || checkAuthLoginLoading;

    useEffect(() => {
        if (!userId) return;
        navigate("/");
    }, [userId]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isValidPersonName(registerUser.name)) {
            setError("Please enter valid name");
            return;
        }

        dispatch(
            signUp({
                name: registerUser.name.trim(),
                email: registerUser.email.trim(),
                password: registerUser.password.trim(),
                refferalCode: registerUser.refferalCode.trim(),
                action: signUp.type,
            })
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <p className={styles.styledHeading}>
                    Easy<strong>Source</strong>
                </p>
                <p className={styles.leftHeading}>Create Account</p>
                <div className={styles["signinRightText-mobile"]}>
                    <h1>Find, engage & hire top professionals in US</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles["inputBox"]}>
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter your name"
                            value={registerUser.name}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setError("");
                                setRegisterUser((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                    <div className={styles["inputBox"]}>
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={registerUser.email}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setError("");
                                setRegisterUser((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                    <div className={styles["inputBox"]}>
                        <label>Password</label>
                        <input
                            type="password"
                            placeholder="Please enter your password"
                            value={registerUser.password}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setError("");
                                setRegisterUser((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                    <div className={styles["inputBox"]}>
                        <label>Referral code</label>
                        <input
                            type="text"
                            placeholder="Referral/Promo code"
                            value={registerUser.refferalCode}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setRegisterUser((prev) => ({
                                    ...prev,
                                    refferalCode: e.target.value,
                                }))
                            }
                            className={styles.input}
                        />
                    </div>
                    <p className={styles.error}>{error}</p>
                    <div className={styles["btnBox"]}>
                        <button
                            className={styles["signupBtn"]}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={24}
                                    style={{ color: "#fff", margin: "4px 0" }}
                                />
                            ) : (
                                "Signup"
                            )}
                        </button>
                        <strong className={styles.orText}>- OR -</strong>
                        <button
                            className={styles.googleSignupBtn}
                            onClick={() => dispatch(googleLogin(""))}
                        >
                            <img
                                src={googleLogo}
                                alt="signin-with-google"
                                className={styles.googleLogo}
                            />
                            <span className={styles.googleLogoText}>
                                Sign up with Google
                            </span>
                        </button>
                    </div>
                </form>
                <div className={styles["loginBtn"]}>
                    <p>Already have an account?</p>
                    <Link to="/signin">Login here</Link>
                </div>
            </div>
            <div className={styles.signinContainer}>
                <div>
                    <p className={styles.styledHeading}>
                        Easy<strong>Source</strong>
                    </p>
                    <p className={styles.title}>
                        Find, engage & hire top professionals in US
                    </p>
                    <p className={styles.content}>
                        EasySource is the most efficient way to find and reach
                        out to the right candidates for your job openings. With
                        just a few clicks, find a list of interested and
                        qualified candidates. What's more? You can track and
                        automate your outreach process with AI-driven
                        personalized messaging to maximize response rates.
                    </p>
                    <p className={styles.heading}>How to use EasySource?</p>
                    <video
                        width="300"
                        height="350"
                        src={howToUse}
                        controls={true}
                        autoPlay={true}
                        muted={true}
                        title="YouTube video player"
                        className={styles.video}
                    />
                </div>
            </div>
        </div>
    );
}
