import { useEffect, useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Typography } from "@mui/material";
import { Dropdown, type MenuProps } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DateModal from "../../../../components/DateModal";
import Modal from "../../../../components/Modal";
import {
    candidateStageItems,
    chevronLeft,
    userIcon,
} from "../../../../constant";
import { selectUser } from "../../../Signin/Signin.reducer";
import { postEmailReadReceipt } from "../../../allProjects/index.reducer";
import { moveTo } from "../../../project/index.reducer";
import { IProjectStage } from "../../../project/project.types";
import { IMessage } from "../../message.types";
import NotesModal from "../notesModal";
import EmailEditor from "./editor";
import style from "./index.module.scss";

interface SingleMessageProps {
    emailData: IMessage[];
    chatId: string;
}

export default function SingleMessage({
    emailData,
    chatId,
}: SingleMessageProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false);
    const [modal, setModal] = useState({
        open: false,
        title: "",
    });

    const isEveryMsgRead = emailData.every(
        (msg: { read: boolean }) => msg.read
    );
    const lastMsg = emailData[emailData.length - 1];
    const threadId =
        lastMsg.source === "Outlook" ? lastMsg.emailId : lastMsg.threadId;
    const candidate = emailData[0].candidate;

    useEffect(() => {
        if (isEveryMsgRead) return;

        dispatch(
            postEmailReadReceipt({
                action: postEmailReadReceipt.type,
                threadId,
            })
        );
    }, [chatId]);

    const showModal = (type: string) => {
        setModal({
            open: true,
            title: type,
        });
    };

    const closeModal = () => {
        setModal({
            open: false,
            title: "",
        });
    };

    // const showDateModal = () => setIsDateModalOpen(true);
    const closeDateModal = () => setIsDateModalOpen(false);

    const handleSelectDate = function () {
        closeDateModal();
    };

    const addDropDown: MenuProps["items"] = [
        {
            label: (
                <p
                    className={style["smsg__link"]}
                    onClick={() => showModal("Add Notes")}
                >
                    Add notes
                </p>
            ),
            key: "0",
        },
        // {
        //     type: "divider",
        // },
        // {
        //     label: (
        //         <p
        //             className={style["smsg__link"]}
        //             onClick={() => showModal("Add Reminder")}
        //         >
        //             Add reminder
        //         </p>
        //     ),
        //     key: "1",
        // },
    ];

    const handleChangeStatus = (id: string, type: IProjectStage) => {
        dispatch(
            moveTo({
                candidateIds: [id],
                stage: type,
                projectId: emailData[0].projectId,
                type: "get-user-email",
            })
        );
    };

    return (
        <div className={style["smsg__container"]}>
            <div className={style["smsg__header"]}>
                <div className={style["smsg__headerTop"]}>
                    <img
                        src={chevronLeft}
                        alt="chevron-left-icon"
                        className={style["smsg__leftIcon"]}
                        onClick={() => navigate("/messages")}
                    />
                    <div className={style["smsg__userDetails"]}>
                        <Avatar
                            src={
                                candidate?.profileImage?.startsWith("data:")
                                    ? candidate?.name
                                    : candidate?.profileImage
                            }
                            alt={candidate?.name}
                            className={style["smsg__img"]}
                        />
                        <div>
                            <p className={style["smsg__name"]}>
                                {candidate.name}
                            </p>
                            {candidate?.experience[0]?.org ? (
                                <p className={style["smsg__desc"]}>
                                    {candidate.experience[0].org}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className={style["smsg__addIcon"]}>
                        {emailData[0]?.stage ? (
                            <Dropdown
                                trigger={["click"]}
                                menu={{
                                    items: candidateStageItems,
                                    onClick: (e: MenuInfo) => {
                                        e.domEvent.stopPropagation();
                                        handleChangeStatus(
                                            candidate._id,
                                            e.key as IProjectStage
                                        );
                                    },
                                }}
                            >
                                <div
                                    className={style["smsg__candStatus"]}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <p>{emailData[0].stage}</p>
                                    <CaretDownOutlined />
                                </div>
                            </Dropdown>
                        ) : null}
                        <Dropdown
                            trigger={["click"]}
                            menu={{ items: addDropDown }}
                            placement="bottomLeft"
                        >
                            <MoreVertIcon
                                style={{ cursor: "pointer", color: "#000" }}
                                fontSize={"small"}
                            />
                        </Dropdown>
                    </div>
                </div>
                <div className={style["smsg__tabs"]}>
                    {/* <div className={`${style["smsg__tab"]}`}>
                        <p>Messages</p>
                    </div> */}
                    <div
                        className={`${style["smsg__tab"]} ${style["smsg__tab--active"]}`}
                    >
                        <p>Email</p>
                    </div>
                </div>
            </div>
            <div className={style["smsg__body"]}>
                {emailData.map((val: any, i: number) => {
                    return (
                        <div
                            key={`${val.updatedAt}${i}`}
                            className={`${style["smsg__msg"]} ${
                                val.sentByRecruiter
                                    ? ""
                                    : style["smsg__msg--user"]
                            }`}
                        >
                            <div className={style["smsg__item"]}>
                                <div className={style["smsg__msgProfile"]}>
                                    <img src={userIcon} alt="" />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "10px",
                                            alignItems: "center",
                                            justifyContent: !val.sentByRecruiter
                                                ? "flex-end"
                                                : "initial",
                                        }}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            {val.sentByRecruiter
                                                ? user.displayName
                                                : val.candidate.name}
                                        </Typography>
                                        <div className={style["smsg__msgDate"]}>
                                            {new Date(
                                                val.createdAt
                                            ).toLocaleDateString()}
                                        </div>
                                    </div>
                                    <div className={style["smsg__item"]}>
                                        <div
                                            className={style["smsg__msgText"]}
                                            dangerouslySetInnerHTML={{
                                                __html: val.body,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <EmailEditor
                threadId={threadId}
                projectId={emailData[0].projectId}
            />
            <Modal
                open={modal.open}
                onOk={closeModal}
                onCancel={closeModal}
                title={modal.title}
                width={"70%"}
            >
                <NotesModal id={candidate._id} closeModal={closeModal} />
            </Modal>
            <Modal
                open={isDateModalOpen}
                onOk={closeDateModal}
                onCancel={closeDateModal}
                title={"Select Date"}
                width={"350px"}
            >
                <DateModal
                    closeModal={closeDateModal}
                    handleSelectDate={handleSelectDate}
                />
            </Modal>
        </div>
    );
}
