import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { message } from "antd";

import Messages from "../../pages/message";

export type InfoMessage = {
    id: string;
    question?: string;
    answer?: string;
    points?: Array<string>;
};

export function InfoParagraph({
    question,
    answer,
    children,
}: {
    question?: string;
    answer?: string;
    children?: React.ReactNode;
}) {
    return (
        <span>
            {question && (
                <Typography variant="body2" fontWeight="bold">
                    {question}
                </Typography>
            )}
            {answer && (
                <Typography variant="body2" sx={{ color: "white" }}>
                    {answer}
                </Typography>
            )}
            {children}
        </span>
    );
}

export const messages: Array<InfoMessage> = [
    {
        id: nanoid(),
        question: "What is a workflow?",
        answer: "To keep your candidates engaged across channels, you can create automated workflows with emails, Inmails and LinkedIn requests.",
    },
    {
        id: nanoid(),
        question: "When will the workflow stop?",
        answer: "The workflow for a particular candidate will automatically stop if any of these 3 events happen:",
        points: [
            "Email reply is detected",
            "Connection request is accepted",
            "Inmail reply is detected",
        ],
    },
];

type WorkflowInfoProps = {
    messages: Array<InfoMessage>;
};

export function WorkflowInfo(props: WorkflowInfoProps) {
    const { messages } = props;
    return (
        <Stack spacing={1}>
            {messages.map((msg) => {
                return msg?.points && msg.points.length ? (
                    <InfoParagraph
                        key={msg.id}
                        question={msg.question}
                        answer={msg.answer}
                    >
                        <ul style={{ padding: "0 1rem" }}>
                            {msg.points.map((point) => {
                                return (
                                    <li key={point}>
                                        <Typography variant="body2">
                                            {point}
                                        </Typography>
                                    </li>
                                );
                            })}
                        </ul>
                    </InfoParagraph>
                ) : (
                    <InfoParagraph
                        key={msg.id}
                        question={msg.question}
                        answer={msg.answer}
                    />
                );
            })}
        </Stack>
    );
}

type InfoButtonProps = {
    children: React.ReactNode;
};

export default function InfoButton(props: InfoButtonProps) {
    const { children } = props;
    return (
        <Tooltip title={children}>
            <IconButton>
                <InfoIcon
                    fontSize="medium"
                    style={{ color: "rgba(0, 0, 0, 0.3)" }}
                />
            </IconButton>
        </Tooltip>
    );
}
