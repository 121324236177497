import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../../components/Navbar";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import handleCleverTap from "../../utils/clevertap";
import Table from "./components/table";
import style from "./index.module.scss";
import { getAllProjectsList, selectAllProjects } from "./index.reducer";

const projectTypes = [
    {
        name: "Active",
        color: "bg-green",
    },
    {
        name: "Closed",
        color: "bg-red",
    },
    {
        name: "On hold",
        color: "bg-gray",
    },
];

export default function AllProjects() {
    const dispatch = useDispatch();
    const allProjectsList = useSelector(selectAllProjects);
    const checkProjectsListLoading = useSelector(
        checkIfLoading(getAllProjectsList.type)
    );

    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        handleCleverTap("All Projects page", {});
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    const projectData =
        allProjectsList.length && searchText
            ? allProjectsList.filter((item) =>
                  item.name.toLowerCase().includes(searchText.toLowerCase())
              )
            : allProjectsList;

    return (
        <>
            <div className={style["pro"]}>
                <Navbar
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value as string)}
                />
                {/* <div className={style["pro__legendBox"]}>
                    {projectTypes.map((project) => (
                        <div
                            key={project.name}
                            className={style["pro__legend"]}
                        >
                            <div className={project.color} />
                            <p>{project.name}</p>
                        </div>
                    ))}
                </div> */}
                <Table data={projectData} loading={checkProjectsListLoading} />
            </div>
        </>
    );
}
