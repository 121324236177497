import { useState } from "react";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import {
    editGrayIcon,
    infoLightBlueIcon,
    trashGrayMedium,
} from "../../constant";
import ActivitiesModal from "./components/activitiesModal";
import Table from "./components/table";
import style from "./index.module.scss";
import { deleteWorkflow } from "./index.reducer";

export default function Workflow() {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [isActivityModalOpen, setIsActivityModalOpen] =
        useState<boolean>(false);

    // const showActivityModal = () =>  setIsActivityModalOpen(true);
    const closeActivityModal = () => setIsActivityModalOpen(false);

    const handleDelete = () => {
        dispatch(deleteWorkflow(params.id));
        navigate("/workflows");
    };

    const workflowInfo = (
        <div className={style["wk__tooltip"]}>
            <h2 className={style["wk__toolHeading"]}>Workflow Name</h2>
            <div className={style["wk__toolUserBox"]}>
                <p>Harsh Mehta</p>
                <p>12 Jan 2023</p>
            </div>
            <div className={style["wk__toolBody"]}>
                <p className={style["wk__toolBodyTitle"]}>Activity Type</p>
                <ul className={style["wk__toolUl"]}>
                    <li>Message</li>
                    <li>Email</li>
                    <li>Call reminder</li>
                    <li>If then</li>
                </ul>
            </div>
        </div>
    );

    return (
        <div className={style["wk"]}>
            <div className={style["wk__container"]}>
                <Navbar />

                <div className={style["wk__header"]}>
                    <div className={style["wk__heading"]}>
                        <p>Workflow Name</p>
                        <div className={style["wk__headingBtnBox"]}>
                            <div className={style["wk__headingBtn"]}>
                                <img src={editGrayIcon} alt="" />
                            </div>
                            <div
                                className={style["wk__headingBtn"]}
                                onClick={handleDelete}
                            >
                                <img src={trashGrayMedium} alt="" />
                            </div>
                            <div className={style["wk__headingBtn"]}>
                                <Tooltip
                                    title={workflowInfo}
                                    placement="bottom"
                                    color="#fff"
                                >
                                    <img src={infoLightBlueIcon} alt="" />
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className={style["wk__dateBox"]}>
                        <p className={style["wk__createDate"]}>
                            Created on - 26th Jan, 24:00
                        </p>
                        <div className={style["wk__dateBtnBox"]}>
                            {/* <Button label="Activities" variant="secondary" onClick={showActivityModal} /> */}
                            <Button
                                label="View Setup"
                                variant="primary"
                                onClick={() =>
                                    navigate("/workflows/edit/" + params.id, {
                                        state: { ...location.state },
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className={style["wk__legends"]}>
                        <div className={style["wk__legendItem"]}>
                            <div
                                className={`${style["wk__legendColor"]} bg-green`}
                            >
                                &nbsp;
                            </div>
                            <p>Scheduled</p>
                        </div>
                        <div className={style["wk__legendItem"]}>
                            <div
                                className={`${style["wk__legendColor"]} bg-red`}
                            >
                                &nbsp;
                            </div>
                            <p>Not scheduled</p>
                        </div>
                        <div className={style["wk__legendItem"]}>
                            <div
                                className={`${style["wk__legendColor"]} bg-gray`}
                            >
                                &nbsp;
                            </div>
                            <p>Yet to Scheduled</p>
                        </div>
                    </div>
                </div>

                <div className={style["wk__actionbar"]}>
                    <div
                        className={`${style["wk__actionItem"]} ${style["wk__actionItem--active"]}`}
                    >
                        Recipients <span>25</span>
                    </div>
                    <div className={style["wk__actionItem"]}>
                        In progress <span>25</span>
                    </div>
                    <div className={style["wk__actionItem"]}>
                        Completed <span>25</span>
                    </div>
                </div>

                <div className={style["wk__body"]}>
                    <Table />
                </div>

                <Modal
                    open={isActivityModalOpen}
                    onOk={closeActivityModal}
                    onCancel={closeActivityModal}
                    title={"Activities"}
                    width={"50%"}
                >
                    <ActivitiesModal closeModal={closeActivityModal} />
                </Modal>
            </div>
        </div>
    );
}
