import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { getProjectStats, selectProjectStats } from "../../index.reducer";
import style from "./index.module.scss";

export default function ProjectStats() {
    const projectStats = useSelector(selectProjectStats);
    const isStatsLoading = useSelector(checkIfLoading(getProjectStats.type));

    const filterCount = {
        PIPELINE: projectStats?.totalCandidate,
        CONTACTED: projectStats?.contacted,
        RESPONDED: projectStats?.responded,
    };

    return (
        <>
            <div className={style["sp__actionItem"]}>
                Total -&nbsp;
                {isStatsLoading ? (
                    <Skeleton width={16} />
                ) : (
                    <span>{filterCount["PIPELINE"]}</span>
                )}
            </div>
            <div className={style["sp__actionItem"]}>
                Contacted -&nbsp;
                {isStatsLoading ? (
                    <Skeleton width={16} />
                ) : (
                    <span>{filterCount["CONTACTED"]}</span>
                )}
            </div>
            <div className={style["sp__actionItem"]}>
                Responded -&nbsp;
                {isStatsLoading ? (
                    <Skeleton width={16} />
                ) : (
                    <span>{filterCount["RESPONDED"]}</span>
                )}
            </div>
        </>
    );
}
