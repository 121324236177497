import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { exportToCsvc } from "../../../utils/helper";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    getUserList,
    leavePage,
    setTotalCandidatesScraped,
    setTotalEmailsExtracted,
    setTotalProjectsCreated,
    setTotalUserCount,
    setTotalWorkflowsTriggered,
    setUserList,
} from "./userList.reducers";
import { IGetUserListPayload } from "./userList.types";

function* getUserListSaga({
    payload,
}: {
    payload: IActionPayload & IGetUserListPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const sortBy = get(state, 'userList.sortBy');
        const response = yield call(
            new API().get,
            `/super-admin/userList?start=${payload.start}&limit=${payload.limit}&sortBy=${sortBy}`
        );
        if (!response?.data.users) return;

        yield put(setUserList(response.data.users));
        yield put(setTotalUserCount(response.data.total));
        yield put(setTotalProjectsCreated(response.data.totalProjectsCreated));
        yield put(
            setTotalWorkflowsTriggered(response.data.totalWorkflowsTriggered)
        );
        yield put(
            setTotalCandidatesScraped(response.data.totalCandidatesScraped)
        );
        yield put(setTotalEmailsExtracted(response.data.totalEmailsExtracted));

        if (payload.isExportAll) {
            exportToCsvc(response.data.users);
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getUserList.type, getUserListSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
