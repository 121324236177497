import { SxProps, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

type ButtonTextWithLoadingProps = {
    isLoading: boolean;
    text: string;
    progressSize?: number;
    progressStyle?: SxProps<Theme>;
};

const ButtonTextWithLoading = ({
    isLoading,
    text,
    progressSize = 20,
    progressStyle = { color: "#fff" },
}: ButtonTextWithLoadingProps) => {
    if (isLoading) {
        return (
            <CircularProgress
                size={progressSize}
                sx={progressStyle}
                style={{ margin: "0.15rem" }}
            />
        );
    }

    return <>{text}</>;
};

export default ButtonTextWithLoading;
