import Modal from "../Modal";
import ProjectModal from "../Navbar/projectModal";

export default function CreateProjectModal({ open, close }: {
    open: boolean,
    close: () => void
}) {
  return (
    <Modal
      open={open}
      onOk={close}
      onCancel={close}
      title={"Create Project"}
      width={"50%"}
    >
      <ProjectModal closeModal={close} />
    </Modal>
  );
}
