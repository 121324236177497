import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { get } from "lodash";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import { userInitialIcon } from "../../../../constant";
import { authorizeEmail } from "../../../Signin/Signin.reducer";
import { getUserEmail, sendEmail } from "../../../allProjects/index.reducer";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";

import style from "./index.module.scss";

interface propsType {
    id: string;
}

export default function EmailTab(props: propsType) {
    const { id } = props;
    const params = useParams();
    const dispatch = useDispatch();
    const [quillVal, setQuillVal] = useState("");
    const [subject, setSubject] = useState("");
    const emailAuthorized = useSelector((state) =>
        get(state, "signin.user.emailAuthorized")
    );
    const userEmail = useSelector((state) =>
        get(state, "allProjects.userEmail")
    );
    const photoURL = useSelector((state) => get(state, "signin.user.photoURL"));

    useEffect(() => {
        dispatch(getUserEmail({ action: getUserEmail.type }));
    }, []);

    const handleEmailClick = () => {
        dispatch(authorizeEmail());
    };

    const handleSendEmail = () => {
        dispatch(
            sendEmail({
                candidates: [id],
                emailRecipients: [],
                subject: subject,
                body: quillVal,
                projectId: Number(params.id),
                action: sendEmail.type,
            })
        );
    };

    return (
        <div className={style["tab"]}>
            <div className={style["tab__container"]}>
                {!emailAuthorized ? (
                    <div className={style["tab__emailBox"]}>
                        <Button
                            label="Authorize Gmail"
                            variant="primary"
                            onClick={handleEmailClick}
                        />
                    </div>
                ) : (
                    <>
                        <div className={style["tab__actionBox"]}>
                            <div className={style["tab__imgBox"]}>
                                <img src={userInitialIcon} alt="" />
                            </div>
                            <div className={style["tab__selectBox"]}>
                                <Select
                                    bordered={false}
                                    className={style["tab__select"]}
                                    placeholder="Templates"
                                    options={[
                                        { value: "jack", label: "Jack" },
                                        { value: "lucy", label: "Lucy" },
                                        {
                                            value: "Yiminghe",
                                            label: "yiminghe",
                                        },
                                        {
                                            value: "disabled",
                                            label: "Disabled",
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className={style["tab__ccInputBox"]}>
                            <label>Subject :</label>
                            <input
                                type="text"
                                value={subject}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setSubject(e.target.value)}
                            />
                        </div>
                        <p className={style["tab__ccSubtitle"]}></p>

                        <div className={style["tab__quillBox"]}>
                            <ReactQuill
                                value={quillVal}
                                //@ts-ignore
                                onChange={setQuillVal}
                            />
                        </div>

                        <div className={style["tab__btnBox"]}>
                            <Button
                                label="Send"
                                variant="primary"
                                onClick={handleSendEmail}
                            />
                        </div>

                        <div className={style["tab__body"]}>
                            <div className={style["tab__ccBox"]}>
                                <div className={style["tab__ccInputBox"]}>
                                    <label>Cc :</label>
                                    <input type="text" />
                                </div>
                                <p className={style["tab__ccSubtitle"]}>
                                    Note link to job description will be
                                    included automatically
                                </p>
                            </div>

                            <div className={style["tab__prevBox"]}>
                                {
                                    //@ts-ignore
                                    userEmail.map((val: any, i: number) => (
                                        <div
                                            className={style["tab__item"]}
                                            key={i}
                                        >
                                            <div
                                                className={
                                                    style["tab__profile"]
                                                }
                                            >
                                                <img
                                                    className={
                                                        style["tab__img"]
                                                    }
                                                    src={photoURL}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={style["tab__desc"]}>
                                                <div
                                                    className={
                                                        style["tab__text"]
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: val.body,
                                                    }}
                                                >
                                                    {/* {val.body} */}
                                                </div>
                                                <p
                                                    className={
                                                        style["tab__date"]
                                                    }
                                                >
                                                    {new Date(
                                                        val.createdAt
                                                    ).toLocaleDateString()}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    //@ts-ignore
                                    !!userEmail.length === 0 && (
                                        <p className={style["tab__prevText"]}>
                                            No previous email to show
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
