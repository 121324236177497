import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { greenhouseLogo } from "../../../constant";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { isValidEmail } from "../../../utils/helper";
import { selectUser } from "../../Signin/Signin.reducer";
import { postGreenhouseCredentials } from "../integrations.reducer";
import ConnectWrapper from "./connectWrapper";

function GreenhouseConnect() {
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(
        checkIfLoading(postGreenhouseCredentials.type)
    );

    const dispatch = useDispatch();
    const [enterCredsModal, setEnterCredsModal] = useState(false);
    const [greenHouse, setGreenHouse] = useState({
        apiKey: "",
        serviceUser: "",
    });
    const [error, setError] = useState({
        apiKey: false,
        serviceUser: false,
    });

    const isGreenhouseConnected =
        user.greenHouse?.apiKey || user.greenHouse?.serviceUser;

    useEffect(() => {
        if (isEmpty(user)) return;
        setGreenHouse({
            apiKey: user.greenHouse?.apiKey ?? "",
            serviceUser: user.greenHouse?.serviceUser ?? "",
        });
    }, [user]);

    const openCredsModal = () => setEnterCredsModal(true);
    const closeCredsModal = () => {
        setError({
            apiKey: false,
            serviceUser: false,
        });
        setEnterCredsModal(false);
    };

    const handleSubmit = () => {
        if (!greenHouse.apiKey) {
            setError({ ...error, apiKey: true });
            return;
        }
        if (!isValidEmail(greenHouse.serviceUser)) {
            setError({ ...error, serviceUser: true });
            return;
        }

        dispatch(
            postGreenhouseCredentials({
                action: postGreenhouseCredentials.type,
                apiKey: greenHouse.apiKey,
                serviceUser: greenHouse.serviceUser,
                onSuccess: closeCredsModal,
            })
        );
    };

    return (
        <>
            <Button
                variant={isGreenhouseConnected ? "outlined" : "contained"}
                onClick={openCredsModal}
            >
                {isGreenhouseConnected ? "Manage" : "Connect"}
            </Button>
            <Dialog
                open={enterCredsModal}
                onClose={closeCredsModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Connect Greenhouse</DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Typography fontSize={14} noWrap>
                            Candidate Ingestion API Key
                        </Typography>
                        <TextField
                            hiddenLabel
                            placeholder="Enter api key"
                            value={greenHouse.apiKey}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    apiKey: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            error={error.apiKey}
                            helperText={
                                error.apiKey ? "Enter valid api key" : null
                            }
                        />
                    </Stack>
                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography fontSize={14} noWrap>
                            Service User Email
                        </Typography>
                        <TextField
                            hiddenLabel
                            placeholder="Enter email"
                            value={greenHouse.serviceUser}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    serviceUser: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            error={error.serviceUser}
                            helperText={
                                error.serviceUser ? "Enter valid email" : null
                            }
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeCredsModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                    >
                        <ButtonTextWithLoading
                            text="Submit"
                            isLoading={isCredsSubmitting}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default function GreenhouseAts() {
    const user = useSelector(selectUser);

    const isGreenhouseUser =
        user.email === "greenhouse@test.com" ||
        user.email === "kapil@hirequotient.com";

    return (
        <ConnectWrapper
            logo={greenhouseLogo}
            logoAlt="greenhouse-logo"
            title="Greenhouse"
            description="Greenhouse is a talent acquisition software company that offers its suite of tools and services to help businesses with the hiring process."
            disabled={!isGreenhouseUser}
        >
            <GreenhouseConnect />
        </ConnectWrapper>
    );
}
