import { useEffect } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import styles from "./index.module.scss";
import {
    selectErrorNotification,
    selectSuccessNotification,
    selectWarningNotification,
    setErrorNotification,
    setSuccessNotification,
    setWarningNotification,
} from "./index.reducer";

const Notification = () => {
    const dispatch = useDispatch();
    const success = useSelector(selectSuccessNotification);
    const error = useSelector(selectErrorNotification);
    const warning = useSelector(selectWarningNotification);

    useEffect(() => {
        if (!success && !error && !warning) return;

        setTimeout(() => {
            if (success) dispatch(setSuccessNotification(""));
            if (error) dispatch(setErrorNotification(""));
            if (warning) dispatch(setWarningNotification(""));
        }, 3000);
    }, [success || error || warning]);

    let severity = "" as AlertColor;
    if (success) severity = "success";
    if (error) severity = "error";
    if (warning) severity = "warning";

    if (!severity) return <span />;

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!success || !!error || !!warning}
            className={styles.container}
        >
            <Alert
                severity={severity}
                sx={{ width: "100%" }}
                className={styles.message}
            >
                {success || error || warning}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
