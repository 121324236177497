import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import {
    startAction,
    stopAction,
} from "../../../../store/reducers/loaders.reducer";
import API, { apiBase } from "../../../../utils/API";
import { CancelSagas } from "../../../../utils/saga.utils";
import {
    addToProject,
    createProject,
    leavePage,
    setCanShowAddToProject,
} from "../Candidates/Candidates.reducer";
import { optionType } from "./Candidates.type";

function* addToProjectSaga({ payload }: { payload: number }): SagaIterator {
    try {
        const state = yield select();
        const candidates = get(
            state,
            "advancedFilterCandidates.selectedCandidate"
        );
        yield put(startAction({ action: addToProject.type }));

        const response = yield call(
            new API().post,
            `${apiBase}/v2/project/add-candidate`,
            {
                projectId: payload,
                candidateIds: candidates,
            }
        );

        if (!response?.data) return;
        yield put(setCanShowAddToProject(false));
        yield put(setSuccessNotification("Candidate added successfully"));
    } catch (e) {
        console.error("***addToProjectSaga err=", e);
    } finally {
        yield put(stopAction({ action: addToProject.type }));
    }
}
function* createProjectSaga({ payload }: { payload: string }): SagaIterator {
    try {
        const state = yield select();
        const jobFunction = get(state, "advancedFilters.jobFunction");
        const jobTitles = get(state, "advancedFilters.jobTitles");
        const skills = get(state, "advancedFilters.skills");
        const experience = get(state, "advancedFilters.experience");
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const visa = get(state, "advancedFilters.visa");
        const education = get(state, "advancedFilters.education");

        const title = jobTitles.map((item: optionType) => item.value);
        const newSkills = skills.map((item: optionType) => item.value);
        const newExperience = experience.map((item: optionType) => item.value);
        const newEducation = education.map((item: optionType) => item.value);
        yield put(startAction({ action: createProject.type }));

        const requestPayload = {
            name: payload,
            jobFunction: jobFunction?.value || "others",
            education: newEducation,
            experience: newExperience,
            visa: visa.value === "yes" ? true : false,
            industry,
            skills: newSkills,
            jobTitle: title,
            location,
            source: "DASHBOARD",
        };

        const response = yield call(
            new API().post,
            `${apiBase}/v2/project/create`,
            requestPayload
        );

        if (!response?.data) return;
        yield put(addToProject(response?.data?._id));
    } catch (e) {
        console.error("***createProjectSaga err=", e);
    } finally {
        yield put(stopAction({ action: createProject.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(addToProject.type, addToProjectSaga),
        // @ts-ignore
        yield takeLatest(createProject.type, createProjectSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
