import { useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Modal from "../../../../components/Modal";
import handleCleverTap from "../../../../utils/clevertap";
import {
    removeSelectedCandidate,
    selectCandidateCurrentPage,
    selectSelectedCandidates,
    setSelectedCandidates,
} from "../../index.reducer";
import { ICandidate } from "../../project.types";
import CandidateModal from "../candidateModal";
import CandidateProfileCard from "../candidateProfileCard";
import style from "./index.module.scss";

type ProjectCardProps = {
    data: ICandidate;
    index: number;
    isSample?: boolean;
};

export default function ProjectCard(props: ProjectCardProps) {
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const candidatePageNo = useSelector(selectCandidateCurrentPage);

    const { _id } = props.data;

    const { id: projectId } = useParams();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleSelectCandidate = (e: CheckboxChangeEvent) => {
        handleCleverTap("Select candidate", {
            checked: e.target.checked,
            "Project id": projectId,
        });

        if (e.target.checked) {
            dispatch(
                setSelectedCandidates({
                    candidateId: [_id],
                    pageNo: candidatePageNo,
                })
            );
        } else {
            dispatch(removeSelectedCandidate(_id));
        }
    };

    return (
        <div className={style["spcard"]} id={`card${props.index}`}>
            <div className={style["spcard__container"]}>
                <Checkbox
                    checked={Boolean(
                        selectedCandidates.find(
                            (item: any) => item.candidateId === _id
                        )
                    )}
                    onChange={handleSelectCandidate}
                />
                <div className={style["spcard__card"]} onClick={showModal}>
                    <CandidateProfileCard
                        variant="card"
                        projectId={projectId}
                        index={props.index}
                        candidate={props.data}
                    />
                </div>
                <Modal
                    open={isModalOpen}
                    onOk={closeModal}
                    onCancel={closeModal}
                    title="Candidate Profile"
                    width={"70%"}
                >
                    <CandidateModal data={props.data} index={props.index} />
                </Modal>
            </div>
        </div>
    );
}
