import { createSlice } from "@reduxjs/toolkit";

import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { IUnsubscribePayload } from "./unsubscribe.types";

const initialState = {
    unsubscribe: false,
};

const reducers = {
    unsubscribeEmail(
        state: typeof initialState,
        { payload }: { payload: IUnsubscribePayload & IActionPayload }
    ) {},
    setUnsubscribeEmail(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.unsubscribe = payload;
    },
    resetUnsubscribe(state: typeof initialState) {},
};

export const unsubscribeEmailSlice = createSlice({
    name: "unsubscribeEmail",
    initialState,
    reducers,
});

export default unsubscribeEmailSlice.reducer;

export const unsubscribeEmail = unsubscribeEmailSlice.actions.unsubscribeEmail;
export const setUnsubscribeEmail =
    unsubscribeEmailSlice.actions.setUnsubscribeEmail;
export const resetUnsubscribe = unsubscribeEmailSlice.actions.resetUnsubscribe;
