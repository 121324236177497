import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import { deleteIcon, editIcon } from "../../../../constant";
import {
    createNewNote,
    editNote,
    getNotes,
    selectNotes,
} from "../../../allProjects/index.reducer";
import "react-quill/dist/quill.snow.css";
import { Typography } from "@mui/material";
import { Button, Spin } from "antd";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { nameInitials } from "../../../../utils/helper";
import { selectUser } from "../../../Signin/Signin.reducer";
import { INote } from "../../../allProjects/allProjects.types";
import style from "./index.module.scss";

interface propsType {
    id: string;
}

const replaceHTMLTags = (value: string) => {
    const val = value.replace(/<[^>]*>/g, "");

    return val;
};

export default function NotesTab(props: propsType) {
    const isNotesLoading = useSelector(checkIfLoading(getNotes.type));
    const isNoteAdding = useSelector(checkIfLoading(createNewNote.type));
    const isNoteEditing = useSelector(checkIfLoading(editNote.type));
    const notes = useSelector(selectNotes);
    const user = useSelector(selectUser);
    const [error, setError] = useState(false);

    const { id } = props;

    const dispatch = useDispatch();
    const [quillVal, setQuillVal] = useState("");
    const [isEditing, setIsEditing] = useState(-1);

    useEffect(() => {
        dispatch(getNotes({ id, action: getNotes.type }));
    }, []);

    const handleEdit = (index: number, editorVal: string) => {
        setIsEditing(index);
        setQuillVal(editorVal);
    };

    const handleUpdate = () => {
        const plainText = replaceHTMLTags(quillVal);
        if (!plainText) {
            setError(true);
            return;
        }
        dispatch(
            editNote({
                candidateId: notes[isEditing].candidateId,
                title: "Note",
                body: quillVal,
                notesId: notes[isEditing]._id,
                action: editNote.type,
                onSuccess: () => {
                    setIsEditing(-1);
                    setQuillVal("");
                },
            })
        );
    };

    const handleDelete = (candidateId: string, _id: number, val: string) => {
        dispatch(
            editNote({
                candidateId: candidateId,
                active: false,
                title: "Notes",
                body: val,
                notesId: _id,
                action: editNote.type,
            })
        );
    };

    const handleCreate = () => {
        const plainText = replaceHTMLTags(quillVal);
        if (!plainText) {
            setError(true);
            return;
        }
        dispatch(
            createNewNote({
                candidateId: id,
                title: "Note",
                body: quillVal,
                action: createNewNote.type,
            })
        );
        setQuillVal("");
    };

    const handleChange = (value: string) => {
        const plainText = replaceHTMLTags(value);
        if (plainText && error) {
            setError(false);
        } else {
            setQuillVal(value);
        }
    };

    return (
        <div className={style["nmodal"]}>
            <div className={style["nmodal__container"]}>
                <ReactQuill
                    value={quillVal}
                    onChange={(value) => handleChange(value)}
                />
                <div
                    className={style["nmodal__btnBox"]}
                    style={{
                        justifyContent: error ? "space-between" : "flex-end",
                    }}
                >
                    {/* <Checkbox>
                        <p className={style["nmodal__checkBoxText"]}>
                            Notify colleagues
                        </p>
                    </Checkbox> */}
                    {error ? (
                        <Typography variant="body2" color="#ff0000d6">
                            Note Should not be empty!
                        </Typography>
                    ) : null}
                    <Button
                        type="primary"
                        onClick={() => {
                            if (isEditing === -1) handleCreate();
                            else handleUpdate();
                        }}
                        loading={isNoteAdding || isNoteEditing}
                        style={{
                            backgroundColor: "#479BD2",
                            borderRadius: 24,
                            padding: "0 20px",
                            justifySelf: "flex-end",
                        }}
                    >
                        {isEditing === -1 ? "Add" : "Update"}
                    </Button>
                </div>
                {isNotesLoading ? (
                    <div className={style["nmodal__loader"]}>
                        <Spin />
                    </div>
                ) : (
                    <div className={style["nmodal__body"]}>
                        {notes.length ? (
                            notes.map((val: INote, i: number) => (
                                <div className={style["nmodal__item"]} key={i}>
                                    {/* @ts-ignore */}
                                    {user.photoURL ? (
                                        <img
                                            //@ts-ignore
                                            src={user.photoURL}
                                            alt="user profile icon"
                                            className={style["nmodal__profile"]}
                                        />
                                    ) : (
                                        <p className={style["nmodal__avatar"]}>
                                            {nameInitials(
                                                //@ts-ignore
                                                user.displayName
                                                    ? //@ts-ignore
                                                      user.displayName
                                                    : "JD"
                                            )}
                                        </p>
                                    )}
                                    <div className={style["nmodal__desc"]}>
                                        <div
                                            className={style["nmodal__text"]}
                                            dangerouslySetInnerHTML={{
                                                __html: val.body,
                                            }}
                                        />
                                        <p className={style["nmodal__date"]}>
                                            {new Date(
                                                val.createdAt
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                    <div className={style["nmodal__action"]}>
                                        <div
                                            className={style["nmodal__imgBox"]}
                                        >
                                            <img
                                                src={editIcon}
                                                alt="edit-icon"
                                                onClick={() =>
                                                    handleEdit(i, val.body)
                                                }
                                            />
                                        </div>
                                        <div
                                            className={style["nmodal__imgBox"]}
                                        >
                                            <img
                                                src={deleteIcon}
                                                alt="delete-icon"
                                                onClick={() =>
                                                    handleDelete(
                                                        val.candidateId,
                                                        val._id,
                                                        val.body
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <>No notes found.</>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
