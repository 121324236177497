import Navbar from "../../components/Navbar";
import MsgAndEmail from "./components/msgAndEmail";
import ReleventProjects from "./components/releventProjects";
import style from "./index.module.scss";

export default function Homepage() {
    return (
        <div className={style["home"]}>
            <div className={style["home__container"]}>
                <Navbar />
                <div className={style["home__body"]}>
                    <div className={style["home__item"]}>
                        <ReleventProjects />
                    </div>
                    <div className={style["home__item"]}>
                        <MsgAndEmail />
                    </div>
                </div>
            </div>
        </div>
    );
}
