import React, { useState } from "react";
import { Button, ButtonProps, Typography, styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import linkedinLogo from "../../../assets/img/Linkedin-big.svg";
import linkedinRecLogo from "../../../assets/img/linkedin-recruiter-big.svg";
import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import { googleLogo } from "../../../constant";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { openExtensionLink, openLinkedinRecruiter } from "../../../utils/API";
import {
    HQ_EASYSOURCE_WEBSITE,
    HQ_EXTENSION_ON_BOARDING,
} from "../../../utils/Constants";
import {
    authLogin,
    googleLogin,
    selectSignupModalState,
    sendJobFunctions,
    setSignUpModalState,
    signUp,
} from "../../Signin/Signin.reducer";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import styles from "../signupOnBoard.module.scss";

const allJobFunctions = [
    "Sales",
    "Marketing",
    "Finance",
    "Accounting",
    "Technology",
    "Product",
    "Legal",
    "Human Resource",
    "Customer success",
    "Operations",
];

const CustomButton = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    backgroundColor: "#145d75",
    border: "none",
    width: "100%",
    "&:hover": {
        border: "none",
        backgroundColor: "#145d75",
    },
}));

const PersonalizedModal = () => {
    const reduxDispatch = useDispatch();
    const closeModal = () => reduxDispatch(setSignUpModalState("IDLE"));
    const dispatch = useDispatch();
    const [jobFunctions, setJobFunctions] = useState<string[]>([]);
    const isLoading = useSelector(checkIfLoading(sendJobFunctions.type));

    const handleClick = (text: string) => {
        if (jobFunctions.includes(text)) {
            setJobFunctions((prev) => prev.filter((t) => t !== text));
        } else {
            setJobFunctions((prev) => prev.concat(text));
        }
    };

    return (
        <BaseModal onClose={closeModal} overlayStyles={{ width: "50vw" }}>
            <Stack alignItems="center" spacing={2}>
                <Typography variant="h3">👋</Typography>
                <Typography variant="h5" align="center">
                    Let's personalize your experience!
                </Typography>
                <Typography align="center" variant="body1">
                    Tell us for which job functions you are recruiting for?
                </Typography>
                <Stack
                    direction="row"
                    spacing={1}
                    style={{ maxWidth: "100%" }}
                    flexWrap="wrap"
                    justifyContent="center"
                >
                    {allJobFunctions.map((text) => {
                        return (
                            <Button
                                variant={
                                    jobFunctions.includes(text)
                                        ? "contained"
                                        : "outlined"
                                }
                                key={text}
                                style={{
                                    margin: "0.5rem",
                                }}
                                onClick={() => handleClick(text)}
                            >
                                {text}
                            </Button>
                        );
                    })}
                </Stack>
                <Stack mt={8}>
                    <Button
                        variant="contained"
                        style={{ padding: "0.7rem 2rem" }}
                        onClick={() =>
                            dispatch(
                                sendJobFunctions({
                                    jobFunctions,
                                    action: sendJobFunctions.type,
                                })
                            )
                        }
                    >
                        <ButtonTextWithLoading
                            text="Submit"
                            isLoading={isLoading}
                        />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
};

const Signup = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [refferalCode, setRefferalCode] = useState("");
    const checkSignupLoading = useSelector(checkIfLoading(signUp.type));
    const checkAuthLoginLoading = useSelector(checkIfLoading(authLogin.type));
    const isLoading = checkSignupLoading || checkAuthLoginLoading;
    const modalState = useSelector(selectSignupModalState);

    const closeModal = () => dispatch(setSignUpModalState("IDLE"));

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email || !password || !name) return;

        dispatch(
            signUp({
                name: name,
                email: email,
                password: password,
                refferalCode,
                action: signUp.type,
            })
        );
    };

    const onClickLink = (url: string) => {
        window.postMessage(
            {
                type: HQ_EXTENSION_ON_BOARDING,
                from: HQ_EASYSOURCE_WEBSITE,
                url,
            },
            "*"
        );
        closeModal();
    };

    return (
        <div className={styles.signupContainer}>
            <p className={styles.signupHeading}>Create Account</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setName(e.target.value)
                    }
                    className={styles.input}
                    required
                />
                <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                    className={styles.input}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                    className={styles.input}
                    required
                />
                <input
                    type="text"
                    placeholder="Referral/Promo code"
                    value={refferalCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRefferalCode(e.target.value)
                    }
                    className={styles.input}
                />
                <CustomButton type="submit">
                    <ButtonTextWithLoading
                        text="Create Account"
                        isLoading={isLoading}
                    />
                </CustomButton>
            </form>
            <div className={styles.textCenter}>
                <strong className={styles.orText}>- OR -</strong>
                <button
                    className={styles.signupBtn}
                    onClick={() => dispatch(googleLogin(""))}
                >
                    <img
                        src={googleLogo}
                        alt="signin-with-google"
                        className={styles.googleLogo}
                    />
                    <span className={styles.googleLogoText}>
                        Sign up with Google
                    </span>
                </button>
                <p className={styles.loginText}>
                    Already a User? <Link to="/signin">Login here</Link>
                </p>
            </div>
            <Modal
                open={modalState === "SHOW_LINKED_IN_MODE_MODAL"}
                className={styles.textCenter}
                width={1000}
                closable={false}
                footer={null}
            >
                <p className={styles.modalHeading}>Welcome to EasySource!</p>
                <p className={styles.modalContent}>
                    You have successfully created your account
                </p>
                <p className={`${styles.modalContent} ${styles.mt50}`}>
                    To start sourcing profiles, you can choose one of the
                    platforms below:
                </p>
                <div className={styles.logoContainer}>
                    <div className={styles.linkedinContainer}>
                        <img
                            src={linkedinLogo}
                            alt="linkedIn-logo"
                            className={styles.linkedinLogo}
                            onClick={() => {
                                onClickLink(openExtensionLink);
                            }}
                        />
                        <p className={styles.linkedInText}>LinkedIn</p>
                    </div>
                    <div>
                        <img
                            src={linkedinRecLogo}
                            alt="linkedIn-rec-logo"
                            className={styles.linkedinRecLogo}
                            onClick={() => {
                                onClickLink(openLinkedinRecruiter);
                            }}
                        />
                        <p className={styles.linkedInText}>
                            LinkedIn Recruiter
                        </p>
                    </div>
                </div>
            </Modal>
            {modalState === "SHOW_PERSONALIZED_MODAL" ? (
                <PersonalizedModal />
            ) : null}
        </div>
    );
};

export default Signup;
