import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Alert, Input } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import Modal from "../../../components/Modal";
import { loxoAtsLogo } from "../../../constant";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import style from "../integrations.module.scss";
import {
    fetchLoxoCredentials,
    postLoxoCredentials,
} from "../integrations.reducer";
import { ILoxoCredentials } from "../integrations.types";
import ConnectWrapper from "./connectWrapper";

function InputForm({ apiKey, apiPassword, agencySlug }: ILoxoCredentials) {
    const [credentials, setCredentials] = useState<ILoxoCredentials>({
        apiKey: apiKey,
        apiPassword: apiPassword,
        agencySlug: agencySlug,
    });

    const dispatch = useDispatch();
    const isSubmitting = useSelector(checkIfLoading(postLoxoCredentials.type));

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(
            postLoxoCredentials({
                apiKey: credentials.apiKey,
                apiPassword: credentials.apiPassword,
                agencySlug: credentials.agencySlug,
                action: postLoxoCredentials.type,
            })
        );
    };

    return (
        <form
            className={style["integrationPage__footerBody"]}
            onSubmit={handleSubmit}
        >
            <div className={style["integrationPage__input"]}>
                <label>Agency Slug</label>
                <Input
                    type="text"
                    placeholder="Enter agency slug"
                    value={credentials.agencySlug}
                    onChange={(e) =>
                        setCredentials({
                            ...credentials,
                            agencySlug: e.target.value,
                        })
                    }
                    disabled={isSubmitting}
                    required={true}
                    style={{ width: 300 }}
                />
            </div>
            <div className={style["integrationPage__input"]}>
                <label>API Key</label>
                <Input.Password
                    placeholder="Enter api key"
                    value={credentials.apiKey}
                    onChange={(e) =>
                        setCredentials({
                            ...credentials,
                            apiKey: e.target.value,
                        })
                    }
                    disabled={isSubmitting}
                    required={true}
                    style={{ width: 300 }}
                />
            </div>
            {/* <div className={style["integrationPage__input"]}>
                <label>API Username</label>
                <Input.Password
                    placeholder="Enter api username"
                    value={credentials.apiUsername}
                    onChange={(e) =>
                        setCredentials({
                            ...credentials,
                            apiUsername: e.target.value,
                        })
                    }
                    disabled={isSubmitting}
                    required={true}
                    style={{ width: 300 }}
                />
            </div> */}
            <div className={style["integrationPage__input"]}>
                <label>API Password</label>
                <Input.Password
                    placeholder="Enter api password"
                    value={credentials.apiPassword}
                    onChange={(e) =>
                        setCredentials({
                            ...credentials,
                            apiPassword: e.target.value,
                        })
                    }
                    disabled={isSubmitting}
                    required={true}
                    style={{ width: 300 }}
                />
            </div>
            <div className={style["integrationPage__button"]}>
                <Button
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                >
                    <ButtonTextWithLoading
                        text="Submit"
                        isLoading={isSubmitting}
                    />
                </Button>
            </div>
        </form>
    );
}

export default function LoxoAts() {
    const loxoCredentials = useSelector<any, ILoxoCredentials>((state) =>
        get(state, "integrations.loxoAtsCredentials")
    );
    const isGetCredsLoading = useSelector(
        checkIfLoading(fetchLoxoCredentials.type)
    );

    const dispatch = useDispatch();
    const [enterCredsModal, setEnterCredsModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchLoxoCredentials({ action: fetchLoxoCredentials.type }));
    }, []);

    const closeCredsModal = () => setEnterCredsModal(false);

    return (
        <>
            <ConnectWrapper
                logo={loxoAtsLogo}
                logoAlt="loxo-ats"
                description="Executive Search, RPO, in-house and recruiting agencies need an Applicant Tracking System (ATS) designed specifically for their type of recruitment. We understand how different the workflows are and built different versions of Loxo for every type of recruitment."
                disabled={true}
            >
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        backgroundColor: loxoCredentials.apiKey
                            ? "#2e7d32"
                            : "#479BD2",
                    }}
                    disabled={isGetCredsLoading}
                    onClick={() => setEnterCredsModal(true)}
                >
                    <ButtonTextWithLoading
                        text={
                            isGetCredsLoading
                                ? ""
                                : loxoCredentials.apiKey
                                ? "Connected"
                                : "Connect"
                        }
                        isLoading={isGetCredsLoading}
                    />
                </Button>
            </ConnectWrapper>
            <Modal
                open={enterCredsModal}
                onOk={closeCredsModal}
                onCancel={closeCredsModal}
                title={"Connect Loxo ATS"}
                width={"500px"}
            >
                <Alert
                    message="Kindly note that the below credentials will be used by HireQuotient to send candidate details to Loxo"
                    type="warning"
                    style={{ marginBottom: 20 }}
                />
                <InputForm
                    apiKey={loxoCredentials.apiKey}
                    agencySlug={loxoCredentials.agencySlug}
                    apiPassword={loxoCredentials.apiPassword}
                />
            </Modal>
        </>
    );
}
