import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {
    Button as AntdButton,
    Input,
    Select,
    Skeleton,
    Tabs,
    type TabsProps,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import SlackSendModal from "../../components/SlackSendModal/SlackSendModal";
import { slackLogo } from "../../constant";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    selectShowModal,
    setShowModal as showSendSlackModal,
} from "../../store/reducers/slackSend.reducer";
import { selectUser } from "../Signin/Signin.reducer";
import BaseModal from "../triggerWorkflow/components/BaseModal";
import InvitationsTable from "./components/InvitationsTable/invitationsTable";
import RejectInvitations from "./components/RejectInvitations/RejectInvitations";
import Table from "./components/table";
import style from "./index.module.scss";
import {
    changeOrgName,
    fetchMembers,
    inviteMembers,
    selectOrganization,
} from "./manageTeams.reducers";
import { IRole } from "./manageTeams.types";

const onChange = (key: string) => {};

const items: TabsProps["items"] = [
    {
        key: "1",
        label: `Organization Members`,
        children: <Table />,
    },
    {
        key: "2",
        label: `Pending Invitations`,
        children: <InvitationsTable />,
    },
    {
        key: "3",
        label: `Rejected Invitations`,
        children: <RejectInvitations />,
    },
];

const TabsBar: React.FC = () => (
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
);

type IEditOrganizationModal = {
    close: () => void;
    orgName: string;
};

function EditOrganizationModal({ close, orgName }: IEditOrganizationModal) {
    const [name, setName] = useState(orgName);
    const dispatch = useDispatch();
    const isChangeOrgNameLoading = useSelector(
        checkIfLoading(changeOrgName.type)
    );

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(changeOrgName({ action: changeOrgName.type, name }));
        setName("");
        close();
    };

    return (
        <form
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            onSubmit={handleSubmit}
        >
            <Input
                value={name}
                placeholder="Organization Name"
                style={{ fontSize: "16px" }}
                required={true}
                onChange={(e) => setName(e.target.value)}
            />
            <AntdButton
                type="primary"
                htmlType="submit"
                loading={isChangeOrgNameLoading}
                disabled={isChangeOrgNameLoading}
                style={{ width: "fit-content", alignSelf: "flex-end" }}
            >
                Save
            </AntdButton>
        </form>
    );
}

function InviteMembers() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [role, setRole] = useState<IRole | null>(null);
    const user = useSelector(selectUser);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const isSubmitting = useSelector(checkIfLoading(inviteMembers.type));
    const handleRoleChange = (value: string) => {
        setRole(value === "MEMBER" ? "MEMBER" : "ADMIN");
    };
    const navigation = useNavigate();

    const closeModal = () => setShowModal(false);

    const handleReset = () => {
        setEmail("");
        setName("");
        setRole("MEMBER");
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!role) {
            dispatch(setErrorNotification("Please select a role"));
            return;
        }

        dispatch(
            inviteMembers({
                email,
                name,
                role,
                action: inviteMembers.type,
                openSlackModal: () => setShowModal(true),
            })
        );
        handleReset();
    };

    const handleNextModal = () => {
        if (user?.isSlackConnected) {
            setShowModal(false);
            dispatch(showSendSlackModal(true));
        } else {
            dispatch(setErrorNotification(`Click "Connect" to install Slack`));
            navigation("/integrations");
        }
    };

    return (
        <form className={style["team__footerBody"]} onSubmit={handleSubmit}>
            <div className={style["team__inputContainer"]}>
                <div className={style["team__input"]}>
                    <input
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                    />
                </div>
                <div className={style["team__input"]}>
                    <input
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                    />
                </div>
                <div className={style["team__input"]}>
                    <Select
                        value={role}
                        bordered={false}
                        className={style["team__footerSelect"]}
                        placeholder="Select Role"
                        options={[
                            { value: "ADMIN", label: "Admin" },
                            { value: "MEMBER", label: "Member" },
                        ]}
                        onChange={handleRoleChange}
                    />
                </div>
            </div>
            {/* <Button label="Invite" variant="primary" disabled={isSubmitting} html/> */}
            <AntdButton
                className={style.inviteButton}
                type="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                htmlType="submit"
                shape="round"
                style={{ backgroundColor: "#479BD2" }}
            >
                Invite
            </AntdButton>
            {showModal ? (
                <BaseModal onClose={closeModal}>
                    <Stack spacing={2}>
                        <CheckCircleIcon
                            fontSize="large"
                            style={{
                                alignSelf: "center",
                                color: "#4BB543",
                                fontSize: "60px",
                            }}
                        />
                        <Typography
                            variant="body2"
                            style={{ fontSize: "22px" }}
                            textAlign="center"
                        >
                            Invitation sent <br />
                            successfully!
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "18px",
                            }}
                            textAlign="center"
                        >
                            <span>
                                Do you also want to share the invitation on
                                slack
                            </span>
                            <span
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    width={18}
                                    height={18}
                                    alt="slack-logo"
                                    src={slackLogo}
                                    style={{ marginLeft: "0.3rem" }}
                                />
                                ?
                            </span>
                        </Typography>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gap: "1rem",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleNextModal}
                            >
                                Yes
                            </Button>
                            <Button variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </Stack>
                </BaseModal>
            ) : null}
        </form>
    );
}

export default function ManageTeam() {
    const openSlackModal = useSelector(selectShowModal);
    const organization = useSelector(selectOrganization);
    const isLoadingMembers = useSelector(checkIfLoading(fetchMembers.type));

    const [showEditModal, setEditModal] = useState(false);

    const closeNewModal = () => setEditModal(false);

    return (
        <>
            <div className={style["team"]}>
                <div className={style["team__container"]}>
                    <Navbar />

                    <div className={style["team__actionBar"]}>
                        <div className={style["team__actionLeft"]}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                {isLoadingMembers ? (
                                    <Skeleton.Input
                                        active={true}
                                        size="small"
                                    />
                                ) : (
                                    <p>{organization}</p>
                                )}
                                <IconButton
                                    size="small"
                                    onClick={() => setEditModal(true)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Stack>
                        </div>
                    </div>

                    <div className={style["team__footer"]}>
                        <div className={style["team__footerTitle"]}>
                            Invite Member
                        </div>
                        <InviteMembers />
                    </div>

                    <TabsBar />

                    <Modal
                        open={showEditModal}
                        onOk={closeNewModal}
                        onCancel={closeNewModal}
                        title={"Edit team name"}
                        width="30%"
                    >
                        <EditOrganizationModal
                            close={closeNewModal}
                            orgName={organization}
                        />
                        {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Input placeholder="Organization Name" style={{ fontSize: '16px' }} required={true} />
                            <AntdButton type="primary" style={{ width: 'fit-content', alignSelf: 'flex-end' }}>Edit Name</AntdButton>
                        </div> */}
                        {/* <div className={style["team__newModal"]}>
                            <input className={style["team__newInput"]} type="text" placeholder="Team name" />
                            <div className={style["team__newBtnBox"]}>
                            <Button label="Create" variant="primary" onClick={closeNewModal} />
                            </div>
                        </div> */}
                    </Modal>
                </div>
            </div>
            {openSlackModal ? <SlackSendModal /> : null}
        </>
    );
}
